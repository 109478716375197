import { styled } from 'utils/adapters'
import { responsive } from 'assets/scss'
import Icon from 'components/atoms/Icon'

export const ActionIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin: 10px 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  img {
    filter: none;
  }

  ${responsive.md`
    width: 14px;
    height: 14px;
  `}
}`
