export const USER_ROLE = {
  ROOT_ADMIN: 'ROOT_ADMIN',
  ADMIN: 'ADMIN',
  EVENT_ORGANISER: 'EVENT_ORGANISER',
  ATTENDEE: 'ATTENDEE',
}

export const USER_SCOPE = {
  USER_READ: 'USER_READ',
  USER_UPDATE: 'USER_UPDATE',
  USER_CREATE: 'USER_CREATE',
  USER_DELETE: 'USER_DELETE',
  USER_ACTIVE: 'USER_ACTIVE',
  EVENT_CREATE: 'EVENT_CREATE',
  EVENT_DELETE: 'EVENT_DELETE',
  EVENT_DUPLICATE: 'EVENT_DUPLICATE',
  EVENT_EXPORT: 'EVENT_EXPORT',
  EVENT_IMPORT: 'EVENT_IMPORT',
  EVENT_READ: 'EVENT_READ',
  EVENT_UPDATE: 'EVENT_UPDATE',
  ATTENDEE_CHECKIN: 'ATTENDEE_CHECKIN',
  EVENT_SEND_NEWSLETTER: 'EVENT_SEND_NEWSLETTER',
  ATTENDEE_REMOVE: 'ATTENDEE_REMOVE',
  ATTENDEE_REFUND: 'ATTENDEE_REFUND',
}

export const USER_SCOPE_HELP_MESSAGE = {
  USER_READ: 'Allows viewing user information and details',
  USER_UPDATE: 'Permits modifying existing user information',
  USER_CREATE: 'Enables creating new user accounts',
  USER_DELETE: 'Allows permanent removal of user accounts',
  USER_ACTIVE: 'Permits activating or deactivating user accounts',
  EVENT_CREATE: 'Enables creating new events',
  EVENT_DELETE: 'Allows permanent removal of events',
  EVENT_DUPLICATE: 'Permits creating a copy of an existing event',
  EVENT_EXPORT: 'Allows exporting event data',
  EVENT_IMPORT: 'Enables importing event data',
  EVENT_READ: 'Allows viewing event information and details',
  EVENT_UPDATE: 'Permits modifying existing event information',
  ATTENDEE_CHECKIN: 'Enables marking attendees as checked in at an event',
  EVENT_SEND_NEWSLETTER: 'Allows sending newsletters related to events',
  ATTENDEE_REMOVE: 'Permits removing attendees from an event',
  ATTENDEE_REFUND: 'Allows processing refunds for attendees',
}

export const ADMIN_DEFAULT_SCOPES = {
  EVENT_READ: 'EVENT_READ',
  // EVENT_DELETE: 'EVENT_DELETE',
  EVENT_UPDATE: 'EVENT_UPDATE',
  USER_READ: 'USER_READ',
  USER_UPDATE: 'USER_UPDATE',
  USER_CREATE: 'USER_CREATE',
  USER_DELETE: 'USER_DELETE',
  USER_ACTIVE: 'USER_ACTIVE',
  ATTENDEE_CHECKIN: 'ATTENDEE_CHECKIN',
  ATTENDEE_REMOVE: 'ATTENDEE_REMOVE',
  ATTENDEE_REFUND: 'ATTENDEE_REFUND',
}

export enum UserRole {
  Admin,
  RootAdmin,
  Attendee,
}
