import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { toast } from '@/components/ui/use-toast'
import { TwoFactorMethod } from '@/constants/user'
import { useUserStore } from '@/store/zustand'
import {
  enableTwoFA,
  receiveMessage,
  updateTwoFAMethod,
  use2FAStore,
} from '@/store/zustand/TowFA'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { TwoFactorAuthCode } from './TwoFactorAuthCode'
import { TwoFactorAuthCodeSuccess } from './TwoFactorAuthCodeSuccess'

interface IProps {
  isDialog?: boolean
  setOpenDialog?: (value: boolean) => void
}

enum STEP {
  SEND_CODE = 'SEND_CODE',
  VERIFY = 'VERIFY',
  AUTH_SECRET = 'AUTH_SECRET',
}

const TwoFactorMethodsForm: React.FC<IProps> = ({
  setOpenDialog,
  isDialog,
}) => {
  const { t } = useTranslation()
  const { currentUser, setCurrentUser } = useUserStore.getState()
  const [loading, setLoading] = useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [authSecret, setAuthSecret] = useState('')
  const [step, setStep] = useState(STEP.SEND_CODE)

  const { method, setMethod } = use2FAStore.getState()

  const profileFormSchema = z.object({
    method: z.nativeEnum(TwoFactorMethod, {
      required_error: t('You need to select a notification type.'),
    }),
  })

  type ProfileFormValues = z.infer<typeof profileFormSchema>

  const defaultValues: Partial<ProfileFormValues> = {
    method: currentUser?.twoFactorMethod?.toLowerCase() as TwoFactorMethod,
  }
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: 'onChange',
  })

  function onSubmit(data: ProfileFormValues) {
    setLoading(true)
    setMethod(data.method)
    receiveMessage(data.method)
    setStep(STEP.VERIFY)
    setLoading(false)
  }

  const onSubmitOTP = async () => {
    setLoading(true)
    // receive auth secret
    const res = await enableTwoFA(otpValue, method)
    if (!res)
      toast({
        title: t(use2FAStore.getState().errorMessage),
        variant: 'destructive',
      })
    else {
      localStorage.setItem('ACCESS_TOKEN', res.data.accessToken as string)
      localStorage.setItem('REFRESH_TOKEN', res.data.refreshToken as string)
      setAuthSecret(res.message)
      if (currentUser)
        setCurrentUser({
          ...currentUser,
          is2FAEnabled: true,
        })
      setOpenDialog && setOpenDialog(false)
    }
    setLoading(false)
  }

  const onUpdateMethod = async () => {
    setLoading(true)
    const res = await updateTwoFAMethod(otpValue, method)
    if (res) {
      toast({
        title: t('2FA method successfully updated'),
      })
    }
    if (!res)
      toast({
        title: t(use2FAStore.getState().errorMessage),
        variant: 'destructive',
      })
    form.setValue('method', method as TwoFactorMethod)
    setLoading(false)
    setStep(STEP.SEND_CODE)
  }

  switch (step) {
    case STEP.SEND_CODE:
      return (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
            <FormField
              control={form.control}
              name='method'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='sms' id='sms' />
                        </FormControl>
                        <FormLabel className='font-normal' htmlFor='sms'>
                          {t('Text message')}
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value='phone' id='phone' />
                        </FormControl>
                        <FormLabel className='font-normal' htmlFor='phone'>
                          {t('Voice message')}
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem disabled value='totp' id='totp' />
                        </FormControl>
                        <FormLabel className='font-normal' htmlFor='totp'>
                          {t('TOTP authenticator app')}
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {isDialog ? (
              <div className='grid grid-cols-2'>
                <div></div>
                <Button type='submit' disabled={loading}>
                  {loading && (
                    <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                  )}
                  {t('Choose 2FA Method')}
                </Button>
              </div>
            ) : (
              <Button type='submit' disabled={loading}>
                {loading && (
                  <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                )}
                {t('Update 2FA Method')}
              </Button>
            )}
          </form>
        </Form>
      )
    case STEP.VERIFY:
      return (
        <div className='space-y-8'>
          <TwoFactorAuthCode
            otpValue={otpValue}
            setOtpValue={setOtpValue}
            destination={currentUser?.phoneNumber}
          />
          {isDialog ? (
            <div className='grid grid-cols-2'>
              <div></div>
              <Button onClick={onSubmitOTP} disabled={loading}>
                {loading && (
                  <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                )}
                {t('Verify')}
              </Button>
            </div>
          ) : (
            <Button onClick={onUpdateMethod} disabled={loading}>
              {loading && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('Verify')}
            </Button>
          )}
        </div>
      )
    case STEP.AUTH_SECRET:
      return (
        <div className='space-y-8'>
          <TwoFactorAuthCodeSuccess authSecret={authSecret} />
        </div>
      )
  }
}

export default TwoFactorMethodsForm
