export enum EmailConsumerType {
  PASSWORD_RESET = 'PASSWORD_RESET',
  USER_CREATE = 'USER_CREATE',
  EVENT_REGISTER = 'EVENT_REGISTER',
  EVENT_SEND_MAIL = 'EVENT_SEND_MAIL',
  EVENT_CANCEL = 'EVENT_CANCEL',
  USER_VERIFICATION_CODE = 'USER_VERIFICATION_CODE',
}

export enum ChangelogOperation {
  ADDED = 'Added',
  CHANGED = 'Changed',
  DELETED = 'Deleted',
  EMAIL_SENT = 'EmailSent',
}

export const changesLogFilterOption = [
  {
    value: 1,
    label: 'Last 1 day',
  },
  {
    value: 7,
    label: 'Last 7 days',
  },
  {
    value: 30,
    label: 'Last 30 days',
  },
]

export const formatOptions = (data: any) => {
  return Object.keys(data).map(key => ({
    value: data[key as keyof typeof data],
    label: key.replace(/_/g, ' '),
  }))
}

export const LIMIT_PAGINATION = [30, 40, 50]

export const emailConsumerTypeOptions = formatOptions(EmailConsumerType)
export const changelogOperationOptions = formatOptions(ChangelogOperation)
