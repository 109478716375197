import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  authSecret: string
}

export const TwoFactorAuthCodeSuccess: FC<IProps> = ({ authSecret }) => {
  const { t } = useTranslation()

  return (
    <div className='space-y-6'>
      <p className='text-sm text-muted-foreground'>
        {t('Your auth secret: ')} {authSecret}
      </p>
    </div>
  )
}
