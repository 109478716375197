import { useState } from 'react'
import { LANGUAGE } from '@/constants/language'
import SupportLanguage from '../../molecules/SupportLanguage'
import { useTranslation } from 'react-i18next'

const LanguageDropdown = () => {
  const { i18n } = useTranslation()

  const [language, setLanguage] = useState<string>(
    (localStorage.getItem('i18nextLng') as string) || LANGUAGE.ENGLISH
  )

  const onChangeLanguage = (selectedLanguage: string) => {
    setLanguage(selectedLanguage)
    i18n.changeLanguage(selectedLanguage)
  }

  return (
    <SupportLanguage language={language} onSelectLanguage={onChangeLanguage} />
  )
}

export default LanguageDropdown
