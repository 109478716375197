import DatePicker from 'react-datepicker'

export type CustomDatePickerProps = {
  placeholder?: string
  onChange?: any
  name?: string
  value?: null | Date
  dateFormat?: string
  timeIntervals?: number
  className?: string
  minDate?: Date
  maxDate?: Date
  showTimeSelect?: boolean
}

export default function CustomDatePicker({
  value,
  onChange,
  name,
  dateFormat,
  timeIntervals,
  className,
  minDate,
  maxDate,
  showTimeSelect = false,
}: CustomDatePickerProps) {
  return (
    <div className='border border-[#E1E2E5] h-[40px] p-2 rounded items-center'>
      <DatePicker
        autoComplete='off'
        selected={value}
        className={className}
        name={name}
        onChange={onChange}
        dateFormat={dateFormat}
        timeIntervals={timeIntervals}
        {...(minDate && { minDate: minDate })}
        {...(maxDate && { maxDate: maxDate })}
        showTimeSelect={showTimeSelect}
        onChangeRaw={e => e.preventDefault()}
      />
    </div>
  )
}
