import { ATTENDANT_STATUS, COLORS } from 'constants/colors'
import { t } from 'i18next'

export const STATUS = {
  registered: 'registered',
  checked_in: 'checked_in',
  imported: 'imported',
  invitation_sent_out: 'invitation_sent_out',
  invited: 'invited',
  // payment_processing: 'payment_processing',
  cancelled_refunded: 'cancelled_refunded',
  cancelled_refused: 'cancelled_refused',
  cancelled_waiting: 'cancelled_waiting',
  cancelled: 'cancelled',
}

const statusColorMap = {
  [STATUS.invited]: ATTENDANT_STATUS.INVITED,
  [STATUS.registered]: ATTENDANT_STATUS.REGISTERD,
  [STATUS.checked_in]: ATTENDANT_STATUS.CHECKED_IN,
  [STATUS.cancelled]: ATTENDANT_STATUS.CANCELLED,
  [STATUS.imported]: ATTENDANT_STATUS.IMPORTED,
  // [STATUS.payment_processing]: ATTENDANT_STATUS.PAYMENT_PROCESSING,
  [STATUS.cancelled_refunded]: ATTENDANT_STATUS.CANCELLED_REFUNDED,
  [STATUS.cancelled_waiting]: ATTENDANT_STATUS.CANCELLED_WAITING,
  [STATUS.cancelled_refused]: ATTENDANT_STATUS.CANCELLED_REFUSED,
  [STATUS.invitation_sent_out]: ATTENDANT_STATUS.INVITATION_SENT_OUT,
}

const getStatusLabelMap = () => ({
  [STATUS.invited]: t('Invited'),
  [STATUS.invitation_sent_out]: t('Invitation sent out'),
  [STATUS.registered]: t('Registered'),
  [STATUS.checked_in]: t('Checked In'),
  [STATUS.cancelled]: t('Cancelled'),
  // [STATUS.payment_processing]: t('Payment Processing'),
  [STATUS.cancelled_refunded]: t('Cancelled Refunded'),
  [STATUS.cancelled_waiting]: t('Cancelled Waiting'),
  [STATUS.cancelled_refused]: t('Cancelled Refused'),
  [STATUS.imported]: t('Imported'),
})

export const renderStatusBgColor = (status: string): string | undefined => {
  return statusColorMap[status]
}

export const renderStatusLabel = (status: string): string => {
  return getStatusLabelMap()[status] || status
}

export const AttendeeStatusLabel = () =>
  Object.entries(getStatusLabelMap()).map(([value, label]) => ({
    label,
    value,
  }))

export enum ActionRefundCancelBookingStatus {
  Accept = 'Accept',
  Refuse = 'Refuse',
}

export const isAttendeeCancelled = (status: string) => {
  return (
    status === STATUS.cancelled ||
    status === STATUS.cancelled_refunded ||
    status === STATUS.cancelled_refused ||
    status === STATUS.cancelled_waiting
  )
}

export const getCancelLabelAttendeeStatus = (
  status: string
): {
  label: string
  color: COLORS
} => {
  switch (status) {
    case STATUS.cancelled_refunded:
      return { label: t('Refunded'), color: COLORS.INFO }
    case STATUS.cancelled_refused:
      return { label: `${t('Refused')} ;(`, color: COLORS.ERROR }
    case STATUS.cancelled_waiting:
      return {
        label: `${t('Waiting')} ^^`,
        color: COLORS.PENDING,
      }
    default:
      return { label: t('Cancelled'), color: COLORS.ICON_BACKGROUND }
  }
}
