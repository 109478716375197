import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'utils/adapters'

import BasicTable from '@/components/organisms/BasicTable'
import { GET_ORGANIZATIONS } from '@/graphql/queries'
import { defaultTimezone, isRootAdmin } from '@/utils/helpers'
import { ColumnDef } from '@tanstack/react-table'
import FilterColumnModal from 'components/organisms/FilterModal'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import {
  changelogOperationOptions,
  changesLogFilterOption,
} from 'constants/changesLog'
import moment from 'moment'
import {
  getChangesLogData,
  resetFilters,
  useChangesLogStore,
} from 'store/zustand/ChangesLog'
import { displayTooltipContent } from '../SendMailLog'
import { FULL_DATE_TIMEZONE_FORMAT } from '@/constants/time'

const ChangesLog: FC = () => {
  const { t } = useTranslation()
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  const lang = localStorage.getItem('i18nextLng') as string

  const changesLogData = useChangesLogStore(state => state.changesLogData)
  const { filters, setFilters, totalItems } = useChangesLogStore()

  const { data: organizationsData } = useQuery(GET_ORGANIZATIONS)

  const organizationsOption = organizationsData?.organizations.map(
    organization => ({
      value: organization._id,
      label: organization.name,
    })
  )

  const organizationId = isRootAdmin()
    ? organizationsOption && organizationsOption[0].value
    : user.organization._id

  useEffect(() => {
    const params = {
      page: filters.page,
      limit: filters.limit,
      timeFrame: filters.timeFrame,
    }
    const organizationId = filters.organizationId
    if (organizationId) getChangesLogData(organizationId, params)
  }, [filters])

  useEffect(() => {
    if (organizationsOption) setFilters({ ...filters, organizationId })
  }, [organizationsData])

  useEffect(() => {
    return () => resetFilters(organizationId)
  }, [])

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: t('No'),
        cell: ({ row }) => <b>{row?.index + 1}</b>,
        // size: 50,
      },
      {
        header: t('Operation'),
        accessorKey: 'op',
        cell: ({ row }) => <b>{row?.original?.op.replace(/_/g, ' ')}</b>,
        Filter: ({ column }) => (
          <FilterColumnModal
            column={column}
            filterData={changelogOperationOptions}
          />
        ),
        enableSorting: false,
        // allow multi filter
        filterFn: (row, id, value) => {
          if (!value || value.length === 0) {
            return true
          }
          return value.includes(row.getValue(id))
        },
        // size: 180,
      },
      {
        header: t('Last updated'),
        accessorKey: 'lastUpdated',
        cell: ({ row }) => {
          const displayDate = moment(
            new Date(Number(row?.original?.lastUpdated))
          )
            .tz(defaultTimezone)
            .format(FULL_DATE_TIMEZONE_FORMAT)
          return (
            <div>
              {displayTooltipContent(
                String(moment(Number(row?.original?.lastUpdated)).fromNow()),
                `${displayDate} GMT`
              )}
            </div>
          )
        },
      },

      {
        header: t('Message'),
        accessorKey: 'message',
        cell: ({ row }) => (
          <>
            <p>{row?.original?.message}</p>
            <br />
            <p>{JSON.stringify(row?.original?.items)}</p>
          </>
        ),
        // size: 500,
      },
    ],
    [lang]
  )

  if (!filters?.organizationId) return null

  return (
    <div className='flex flex-col'>
      <div className='mx-auto overflow-x-auto p-[16px] max-w-full md:p-[40px] w-full'>
        <div className='mb-[40px] flex justify-between'>
          {isRootAdmin() && (
            <Select
              defaultValue={filters?.organizationId}
              onValueChange={value => {
                setFilters({ ...filters, organizationId: value })
              }}
            >
              <SelectTrigger className='w-[180px]'>
                <SelectValue placeholder='Select organization' />
              </SelectTrigger>
              <SelectContent>
                {organizationsOption?.map(option => (
                  <SelectItem key={option.value} value={String(option.value)}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}

          <Select
            defaultValue={String(changesLogFilterOption[0].value)}
            onValueChange={value =>
              setFilters({ ...filters, timeFrame: value })
            }
          >
            <SelectTrigger className='w-[180px]'>
              <SelectValue placeholder='Select filter' />
            </SelectTrigger>
            <SelectContent>
              {changesLogFilterOption.map(option => (
                <SelectItem key={option.value} value={String(option.value)}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <BasicTable
          columns={columns}
          data={changesLogData}
          filters={filters}
          setFilters={setFilters}
          totalItems={totalItems}
        />
      </div>
    </div>
  )
}

export default ChangesLog
