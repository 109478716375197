import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'

import { ImportAttendeeType } from 'models'
import { useLazyQuery } from 'utils/adapters'
import { useAppDispatch, useModal } from 'utils/hooks'
import ModalEditAttendee from './components/ModalEditAttendee'
import { removeImportAttendee } from 'store/Events'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'
import { getLanguage } from '@/constants/language'
import { GET_SALUTATIONS } from '@/graphql/queries'
import { SALUTATION } from '@/constants/salutation'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { FileDown, PencilIcon, TrashIcon } from 'lucide-react'
import { Input } from '@/components/ui/input'

const headerTable = [
  'Email',
  'First Name',
  'Last Name',
  'Job title',
  'Company',
  'Street',
  'Zip',
  'City',
  'Country',
  'Work phone',
  'Mobile phone',
  'Fax',
  'Salutation',
  'Academic Title',
]

type ImportAttendeesProps = {
  array: any
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSubmit: () => void
  handleOnClick: (e: any) => void
  isDisableImport: boolean
  setIsDisableImport: (state: boolean) => void
  sendMailAfterImport: boolean
  setSendMailAfterImport: (value: boolean) => void
  handleChangeEncoding: (value: boolean) => void
  isAsciiEncoding: boolean
}

const ImportAttendees: FC<ImportAttendeesProps> = ({
  array,
  handleOnChange,
  handleSubmit,
  handleOnClick,
  isDisableImport,
  setIsDisableImport,
  sendMailAfterImport,
  setSendMailAfterImport,
  handleChangeEncoding,
  isAsciiEncoding,
}) => {
  const { t } = useTranslation()

  const { isShow, showModal, hideModal } = useModal()
  const [selectedAttendee, setSelectedAttendee] = useState<ImportAttendeeType>({
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    company: '',
    address: {
      street: '',
      zipCode: '',
      city: '',
      country: '',
    },
    workPhone: '',
    mobilePhone: '',
    fax: '',
    salutation: '',
    professional: '',
  })
  const dispatch = useAppDispatch()

  const handleShowModal = (id: number) => {
    const attendee = array.find((item: any) => item.id === id)
    setSelectedAttendee(attendee)
    showModal()
  }

  const appLanguage = localStorage.getItem('i18nextLng')

  const [salutationList, setSalutationList] = useState<
    { key: string; value: string }[]
  >([])

  const [getSalutationList, { data: salutationData }] = useLazyQuery(
    GET_SALUTATIONS,
    {
      onCompleted: () => {
        setSalutationList(salutationData?.fetchSalutations?.data)
      },
      onError: ({ message }) => {
        console.log(message)
      },
      variables: {
        language: getLanguage(appLanguage),
      },
    }
  )
  useEffect(() => {
    getSalutationList()
  }, [appLanguage])

  return (
    <div style={{ textAlign: 'center' }} className='space-y-4'>
      <div className='flex flex-col space-y-4'>
        <div className='flex items-center space-x-4'>
          <form>
            <Input
              type='file'
              id='csvFileInput'
              accept='.csv'
              onChange={handleOnChange}
              onClick={handleOnClick}
            />
          </form>
          <Button variant='outline' asChild>
            <a
              href='https://conference-direct.s3-eu-central-1.ionoscloud.com/sample.csv'
              download='sample.csv'
            >
              <FileDown className='mr-2 h-4 w-4' />
              {t('Download sample')}
            </a>
          </Button>
        </div>

        <div className='text-left text-sm text-muted-foreground'>
          <p>
            <span className='font-semibold'>{t('Salutation')}</span>{' '}
            {t('Could be')}: mr, ms, mrs, divers
          </p>
          <p>
            <span className='font-semibold'>{t('Academic Title')}</span>{' '}
            {t('Could be')}: prof, dr, prof_dr
          </p>
        </div>
      </div>
      <div className='flex justify-between items-end mt-[20px]'>
        <div>
          <div>
            {t(
              'Import with charset (Require re-upload file when changing charset):'
            )}
          </div>
          <div className='flex items-center space-x-2'>
            <Label
              htmlFor='charset-utf8'
              className={`cursor-pointer ${
                !isAsciiEncoding ? 'font-bold' : ''
              }`}
            >
              UTF-8
            </Label>
            <Switch
              id='charset'
              checked={isAsciiEncoding}
              onCheckedChange={handleChangeEncoding}
            />
            <Label
              htmlFor='charset-ascii'
              className={`cursor-pointer ${isAsciiEncoding ? 'font-bold' : ''}`}
            >
              ASCII
            </Label>
          </div>
        </div>
        <div className='flex items-center space-x-2 mt-3'>
          <Switch
            id='airplane-mode'
            checked={sendMailAfterImport}
            onCheckedChange={(value: boolean) => setSendMailAfterImport(value)}
          />
          <Label htmlFor='airplane-mode'>{t('Send email')}</Label>
        </div>
      </div>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            <TableRow key={'header'}>
              <TableHead>{t('No.')}</TableHead>
              {headerTable.map((item: any) => (
                <TableHead key={item}>{t(item)}</TableHead>
              ))}
              <TableHead>{t('Action')}</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {array.length > 0 ? (
              array.map((item: ImportAttendeeType) => {
                let country = item?.address?.country
                if (typeof item?.address?.country == 'object') {
                  country = item?.address?.country['value']
                }
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.firstName}</TableCell>
                    <TableCell>{item.lastName}</TableCell>
                    <TableCell>{item.jobTitle}</TableCell>
                    <TableCell>{item.company}</TableCell>
                    <TableCell>{item?.address?.street}</TableCell>
                    <TableCell>{item?.address?.zipCode}</TableCell>
                    <TableCell>{item?.address?.city}</TableCell>
                    <TableCell>{country}</TableCell>
                    <TableCell>{item.workPhone}</TableCell>
                    <TableCell>{item.mobilePhone}</TableCell>
                    <TableCell>{item.fax}</TableCell>
                    <TableCell>{t(SALUTATION[item.salutation])}</TableCell>
                    <TableCell>{item.professional}</TableCell>
                    <TableCell className='sticky right-0 bg-white'>
                      <div className='flex space-x-2'>
                        <Button
                          variant='outline'
                          size='icon'
                          onClick={() => {
                            handleShowModal(item.id)
                            setIsDisableImport(false)
                          }}
                        >
                          <PencilIcon className='h-4 w-4' />
                        </Button>
                        <Button
                          variant='destructive'
                          size='icon'
                          onClick={() => {
                            if (
                              window.confirm(
                                t(
                                  'Are you sure you want to delete this attendee? This action cannot be undone.'
                                )
                              )
                            ) {
                              dispatch(removeImportAttendee(item.id))
                              setIsDisableImport(false)
                            }
                          }}
                        >
                          <TrashIcon className='h-4 w-4' />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headerTable.length + 2}
                  className='text-center py-4'
                >
                  {t('import_attendees.no_data')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className='mt-8 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4'>
        <div className='text-sm text-gray-600 bg-gray-100 rounded-lg p-3 flex-grow'>
          {array.length === 0 ? (
            <p className='font-medium'>{t('import_attendees.no_attendees')}</p>
          ) : isDisableImport ? (
            <p className='font-medium text-amber-600'>
              {t('import_attendees.import_disabled')}
            </p>
          ) : (
            <p className='font-medium text-green-600'>
              {t('import_attendees.ready_to_import', {
                count: array.length,
              })}
            </p>
          )}
        </div>
        <Button
          type='submit'
          disabled={!array.length || isDisableImport}
          onClick={handleSubmit}
          className='w-full sm:w-auto px-6 py-3 text-lg font-semibold transition-all duration-200 ease-in-out transform hover:scale-105'
        >
          {t('import_attendees.import')}
        </Button>
      </div>
      <ModalEditAttendee
        isShow={isShow}
        hideModal={hideModal}
        attendeeInfo={selectedAttendee}
        salutationList={salutationList}
      />
    </div>
  )
}

export default ImportAttendees
