import { FC } from 'react'
import { styled } from 'utils/adapters'
import DOMPurify from 'dompurify'

type IconProps = {
  source: any
  height?: string
  width?: string
  className?: string
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const Icon: FC<IconProps> = ({
  source,
  height,
  width,
  className,
  onClick,
  ...props
}) => {
  const filename = source.split('/')[source.split('/').length - 1]
  return (
    <IconSource
      onClick={onClick}
      className={className}
      height={height || '20px'}
      width={width || '20px'}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(
          `<img src=${source} loading="lazy" alt=${filename}/>`
        ),
      }}
      {...props}
    />
  )
}

const IconSource = styled('div')<{
  height: string
  width: string
}>`
  line-height: 0;
  img {
    height: ${props => props.height};
    width: ${props => props.width};
    filter: brightness(0) invert(1);
  }
`
export default Icon
