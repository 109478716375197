import { FC } from 'react'
import { styled } from 'utils/adapters'

import { FilterByIcon, SortAsc, SortDesc } from 'assets/images'
import Icon from 'components/atoms/Icon'
import {
  getDirectionOptions,
  getOrderByOptions,
  SORT_BY_TYPE,
  SORT_TYPE,
} from 'constants/sortEvent'
import { DatePickerWithRange } from './DateRangePicker'
import ReactSelectAdapter from '@/components/atoms/Select/SelectAdapter'

interface IProps {
  orderBy: SORT_BY_TYPE
  direction: SORT_TYPE
  setOrderBy: (value: SORT_BY_TYPE) => void
  setDirection: (value: SORT_TYPE) => void
}

const FilterEvent: FC<IProps> = ({
  orderBy,
  direction,
  setOrderBy,
  setDirection,
}) => {
  const handleChangeSortBy = (option: any) => {
    setOrderBy(option.value)
  }

  const handleChangeDirection = (option: any) => {
    setDirection(option.value)
  }

  return (
    <div className='flex flex-col items-end lg:flex-row space-y-2 gap-4 md:justify-end'>
      <DatePickerWithRange />
      <div className='flex gap-4'>
        <div className='relative'>
          <FilterIconWrapper>
            <Icon source={FilterByIcon} width='16px' />
          </FilterIconWrapper>

          <SelectStyled className='w-[170px]'>
            <ReactSelectAdapter
              input={{
                value: getOrderByOptions().filter(
                  item => item.value === orderBy
                ),
                onChange: handleChangeSortBy,
                options: getOrderByOptions(),
                isSearchable: false,
                classNamePrefix: 'sort-select',
                className: 'cursor-pointer',
              }}
              meta=''
            />
          </SelectStyled>
        </div>
        <div className='relative'>
          <FilterIconWrapper>
            <Icon
              source={direction === SORT_TYPE.DESC ? SortDesc : SortAsc}
              height='16px'
            />
          </FilterIconWrapper>
          <SelectStyled className='w-[130px]'>
            <ReactSelectAdapter
              input={{
                value: getDirectionOptions().filter(
                  item => item.value === direction
                ),
                onChange: handleChangeDirection,
                options: getDirectionOptions(),
                isSearchable: false,
                classNamePrefix: 'sort-select',
                className: 'h-full',
              }}
              meta=''
            />
          </SelectStyled>
        </div>
      </div>
    </div>
  )
}

const SelectStyled = styled.div`
  .sort-select {
    &__single-value {
      padding-left: 20px;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
`

const FilterIconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  img {
    filter: none;
  }
`

export default FilterEvent
