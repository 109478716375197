import Quill from 'quill'
import { FC, useEffect, useRef } from 'react'
import './style.scss'
import { cn } from '@/lib/utils'

type HyperLinkInputType = {
  value: string
  onChange: (value: string) => void
  className: string
}

const HyperLinkInput: FC<HyperLinkInputType> = ({
  value,
  onChange,
  className,
}) => {
  const toolbarOptions = [['link']]

  const editorRef = useRef(null)

  useEffect(() => {
    if (editorRef.current) {
      // Initialize Quill editor with mention module
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions,
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: () => false, // Prevent enter key from creating new line
              },
            },
          },
        },
      })

      // set initial value for editor
      if (value) {
        quill.root.innerHTML = value
      }

      // onChange editor
      quill.on('text-change', () => {
        // remove style tag and all tag except a tag
        const content = quill.root.innerHTML
          .replace(/style="[^"]*"/g, '')
          .replace(/<(?!\/?a(?=>|\s.*>))\/?.*?>/g, '')

        // Check if content contains a link
        const hasLink = content.includes('<a href=')
        if (hasLink) {
          onChange(content)
        } else {
          const text = quill.getText()
          onChange(text)
        }
      })

      return () => {
        editorRef.current = null
      }
    }
  }, [editorRef.current])

  return (
    <div className={cn('editor-hyperlink', className)}>
      <div ref={editorRef} id='editor-hyperlink' className='' />
    </div>
  )
}

export default HyperLinkInput
