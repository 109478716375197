export const pageSizeOptions = [10, 20, 30, 40, 50, 100]

export const IMAGE_MAX_SIZE = 5 * 1024 * 1024 // 5MB

export const ACCEPT_FILES = ['jpeg', 'jpg', 'png', 'pjpeg']

export const FEEDBACK_FORM_TYPE = {
  TEXT: 'TEXT',
  RATING: 'RATING',
}
