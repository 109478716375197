import { FC } from 'react'
import { styled } from 'utils/adapters'
import SuccessRegisterModal from './components/SuccessModal'
import SuccessRegister from './SuccessRegister'
import { Background as BackgroundJPG } from 'assets/images'

interface IProps {
  isBookableEvent?: boolean
  isUpdateBooking: boolean
}

const SuccessRegisterContainer: FC<IProps> = ({
  isBookableEvent,
  isUpdateBooking,
}) => {
  const event = JSON.parse(sessionStorage.getItem('currentEvent') as string)
  const isPendingPayment = JSON.parse(
    sessionStorage.getItem('isPendingPayment') as string
  )

  return (
    <Wrapper
      backgroundImage={event?.eventSetting?.backgroundPicture || BackgroundJPG}
    >
      {isBookableEvent ? (
        <SuccessRegister isUpdateBooking={isUpdateBooking}>
          <SuccessRegisterModal isPendingPayment={isPendingPayment} />
        </SuccessRegister>
      ) : (
        <SuccessRegisterModal />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.backgroundImage || BackgroundJPG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`

export default SuccessRegisterContainer
