import { COLORS, DnD } from 'constants/colors'
import { FC } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { styled } from 'utils/adapters'

type DragableType = {
  item: { _id: string }
  index: number
  className?: string
}

const DragableItem: FC<DragableType> = ({
  item,
  index,
  children,
  className,
}) => {
  return (
    <Draggable key={item._id} draggableId={item._id} index={index}>
      {(provided, snapshot) => (
        <DnDItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isDragging={snapshot.isDragging}
          className={className}
        >
          {children}
        </DnDItem>
      )}
    </Draggable>
  )
}

const DnDItem = styled.div<{ isDragging: boolean }>`
  border-radius: 4px;
  user-select: none;
  padding: 8px;
  box-shadow: 0 ${props => (props.isDragging ? '0' : '1px')} 0
    ${DnD.ITEM_SHADOW};
  background: ${DnD.ITEM_BACKGROUND};
  border: 1px dashed ${props => (props.isDragging ? COLORS.BLUE : 'none')};
  &:hover {
    background: ${DnD.ITEM_HOVER};
  }
`

export default DragableItem
