import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { setFormData } from 'store/Events'

import { Field, Form, styled } from 'utils/adapters'
import { blockTypingNonNumberic, formatPrice } from 'utils/helpers'

import { useAppDispatch, useAppSelector } from 'utils/hooks'

import Input from 'components/atoms/Input'
import { Column, Label, Row } from '../EventInformation.style'

import { Currency } from 'constants/currency'
import { COLORS } from 'constants/colors'
import { toast } from '@/components/ui/use-toast'

const parse = (value: any) => {
  return !parseFloat(value) || !Number(value) || value.endsWith('.')
    ? value
    : parseFloat(value.replace(/,/g, '.'))
}

type PropsType = {
  onClose?: React.MouseEventHandler<any>
  triggerCloseModal: () => void
}

const BookingWithPaymentForm: FC<PropsType> = ({
  onClose,
  triggerCloseModal,
}) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { formData } = useAppSelector(state => state.eventsReducer)
  const onSubmit = (values: any) => {
    dispatch(
      setFormData({
        ...formData,
        isBookable: true,
        price: Number(values.price),
        currency: values.currency,
      })
    )

    toast({
      description: t('Activate booking with payment success!'),
    })

    triggerCloseModal()
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...formData }}
      render={({ handleSubmit, submitting }: any) => (
        <FormContainer onSubmit={handleSubmit}>
          <Column>
            <PriceWrapper>
              <Field
                name='price'
                defaultValue={0}
                parse={parse}
                format={formatPrice}
              >
                {({ input, meta }: any) => (
                  <Row>
                    <Input
                      {...input}
                      label={t('Price per person')}
                      meta={meta}
                      isRequired
                      type='text'
                      onKeyDown={(e: any) => {
                        blockTypingNonNumberic(e)
                      }}
                      maxLength='15'
                    />
                  </Row>
                )}
              </Field>
              <Row>
                <Label>Currency</Label>
                <SelectCurrency
                  name='currency'
                  component='select'
                  placeholder={t('Select box')}
                >
                  {Currency.map((currency, index) => (
                    <option key={index} value={currency.value}>
                      {currency.label}
                    </option>
                  ))}
                </SelectCurrency>
              </Row>
            </PriceWrapper>
          </Column>

          <Footer>
            <SubmitButton type='submit' disabled={submitting}>
              OK
            </SubmitButton>
            <Button type='button' onClick={onClose}>
              Cancel
            </Button>
          </Footer>
        </FormContainer>
      )}
    ></Form>
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  max-width: 650px;
`

const PriceWrapper = styled.div`
  display: flex;
  gap: 5px;
`

const SelectCurrency = styled(Field)`
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #e2eded;

  &:focus {
    outline: none;
  }

  option {
    padding: 8px 0;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  gap: 20px;
`

const Button = styled.button`
  padding: 8px;
  width: 80px;
  color: ${COLORS.BLUE};
  border: 1px solid ${COLORS.BLUE};
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`

const SubmitButton = styled(Button)`
  color: white;
  background-color: ${COLORS.BLUE};
`

export default BookingWithPaymentForm
