import { useReactiveVar } from '@apollo/client'
import { isCollapseNavbar } from '../../graphql/variables/common'

const useOverlapVar = () => {
  const isCollapse = useReactiveVar(isCollapseNavbar)
  const setIsCollapse = (isCollapse: boolean) => {
    isCollapseNavbar(isCollapse)
  }
  return {
    isCollapse,
    setIsCollapse,
  }
}

export default useOverlapVar
