import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  clearFormData,
  setEventAction,
  setFormData,
  setStep,
} from 'store/Events'
import { GET_EVENT_INPUT } from 'graphql/queries'

import { styled, useParams, useQuery } from 'utils/adapters'
import { AdminEventStep } from 'utils/helpers'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import Header from 'components/organisms/Header'
import EmailStep from './components/EmailStep'
import Loading from 'components/atoms/Loading'
import { EventAction } from 'models'
import EventForm from './components/EventForm'
import EventSetting from './components/EventSetting'
import EventCustomField from './components/EventCustomField'
import EventCustomFieldForm from './components/EventCustomFieldForm'
import { toast } from '@/components/ui/use-toast'
import { FormBuilder } from './components/FormBuilder'

interface ParamTypes {
  id: string
}

const EventInformation: FC = () => {
  const { t } = useTranslation()

  // reset form when unmounted
  useEffect(() => {
    return () => {
      dispatch(clearFormData())
      dispatch(setStep(0))
    }
  }, [])

  const dispatch = useAppDispatch()
  const { step } = useAppSelector(state => state.eventsReducer)
  const { id } = useParams<ParamTypes>()

  if (id) {
    const { loading, data } = useQuery(GET_EVENT_INPUT, {
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
      variables: {
        id,
      },
      fetchPolicy: 'no-cache',
    })

    useEffect(() => {
      if (data && !loading) {
        const formData = {
          ...data.event,
          address: {
            ...data.event.address,
            country: {
              value: data.event.address?.country,
              label: data.event.address?.country,
            },
          },
          eventSetting: {
            ...data.event.eventSetting,
          },
        }
        dispatch(setFormData(formData))
        dispatch(setEventAction(EventAction.UPDATE))
      }
    }, [data, loading])

    if (loading) {
      return <Loading />
    }
  }

  const renderContainer = (step: number) => {
    switch (step) {
      case AdminEventStep.EventDetailForm:
        return <EventForm />
      case AdminEventStep.FormBuilder:
        return <FormBuilder />
      case AdminEventStep.EventSettings:
        return <EventSetting />
      case AdminEventStep.EmailStep:
        return <EmailStep />
      case AdminEventStep.CustomField:
        return <EventCustomField />
      case AdminEventStep.AddCustomField:
        return <EventCustomFieldForm />
      default:
        return null
    }
  }

  return (
    <Wrapper>
      {!id && (
        <Header title={t('Event Information')} showLanguageSelector={true} />
      )}
      {renderContainer(step)}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export default EventInformation
