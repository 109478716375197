import Quill from 'quill'

// custom div tag
const BlockBlot = Quill.import('blots/block')

class CustomBlockBlot extends BlockBlot {
  static create(value) {
    const node = super.create(value)
    node.style.lineHeight = 'normal'
    node.style.padding = '0'
    node.style.margin = '0'
    return node
  }
}
CustomBlockBlot.tagName = 'div'

Quill.register('formats/block', CustomBlockBlot)

// custom span tag
const Inline = Quill.import('blots/inline')

class CustomSpanBlot extends Inline {
  static create(value) {
    const node = super.create(value)
    node.style.lineHeight = 'normal'
    node.style.padding = '0'
    node.style.margin = '0'
    return node
  }
}
CustomSpanBlot.tagName = 'span'

Quill.register('formats/custom-span', CustomSpanBlot)

// custom header tag
const Header1 = Quill.import('formats/header')

class CustomHeader extends Header1 {
  static create(value) {
    const node = super.create(value)
    CustomHeader.blotName = 'header'
    switch (node.tagName) {
      case 'H1':
        node.style.fontSize = '2em'
        break
      case 'H2':
        node.style.fontSize = '1.5em'
        break
      case 'H3':
        node.style.fontSize = '1.17em'
        break
      case 'H4':
        node.style.fontSize = '1em'
        break
      case 'H5':
        node.style.fontSize = '.83em'
        break
      case 'H6':
        node.style.fontSize = '.67em'
        break
    }
    node.style.fontWeight = 'bold'
    node.style.margin = '0'
    return node
  }
}
Quill.register(CustomHeader, true)
