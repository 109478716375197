import * as React from 'react'
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Button, ButtonProps, buttonVariants } from '@/components/ui/button'
import { t } from 'i18next'

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role='navigation'
    aria-label='pagination'
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn('flex flex-row items-center gap-1 list-none', className)}
    {...props}
  />
))
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<'li'>
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, 'size'> &
  React.ComponentProps<'a'>

const PaginationLink = ({
  className,
  isActive,
  size = 'icon',
  ...props
}: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      className
    )}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

const SimplePaginationLink = ({
  className,
  size = 'icon',
  disabled,
  ...props
}: ButtonProps) => (
  <Button
    disabled={disabled}
    className={cn(className, 'cursor-pointer')}
    size={size}
    variant={!disabled ? 'outline' : 'ghost'}
    {...props}
  />
)
SimplePaginationLink.displayName = 'SimplePaginationLink'

const PaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => {
  return (
    <PaginationLink
      aria-label='Go to previous page'
      size='default'
      className={cn('gap-1 pl-2.5', className)}
      {...props}
    >
      <ChevronLeft className='h-4 w-4' />
      <span>{t('Previous')}</span>
    </PaginationLink>
  )
}
PaginationPrevious.displayName = 'PaginationPrevious'

// Simple means the pagination in Frontend, it's not a link
const SimplePaginationPrevious = ({
  className,
  ...props
}: React.ComponentProps<typeof SimplePaginationLink>) => {
  return (
    <SimplePaginationLink
      aria-label='Go to previous page'
      size='default'
      className={cn('gap-1 pl-2.5', className)}
      {...props}
    >
      <ChevronLeft className='h-4 w-4' />
      <span>{t('Previous')}</span>
    </SimplePaginationLink>
  )
}
SimplePaginationPrevious.displayName = 'SimplePaginationPrevious'

const PaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof PaginationLink>) => {
  return (
    <PaginationLink
      aria-label='Go to next page'
      size='default'
      className={cn('gap-1 pr-2.5', className)}
      {...props}
    >
      <span>{t('Next')}</span>
      <ChevronRight className='h-4 w-4' />
    </PaginationLink>
  )
}
PaginationNext.displayName = 'PaginationNext'

// Simple means the pagination in Frontend, it's not a link
const SimplePaginationNext = ({
  className,
  ...props
}: React.ComponentProps<typeof SimplePaginationLink>) => {
  return (
    <SimplePaginationLink
      aria-label='Go to next page'
      size='default'
      className={cn('gap-1 pr-2.5', className)}
      {...props}
    >
      <span>{t('Next')}</span>
      <ChevronRight className='h-4 w-4' />
    </SimplePaginationLink>
  )
}
SimplePaginationNext.displayName = 'SimplePaginationNext'

const PaginationEllipsis = ({
  className,
  ...props
}: React.ComponentProps<'span'>) => (
  <span
    aria-hidden
    className={cn('flex h-9 w-9 items-center justify-center', className)}
    {...props}
  >
    <MoreHorizontal className='h-4 w-4' />
    <span className='sr-only'>More pages</span>
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  SimplePaginationPrevious,
  SimplePaginationNext,
  SimplePaginationLink,
}
