import React from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useMutation } from '@/utils/adapters'
import { UPDATE_CURRENT_USER } from '@/graphql/mutations'
import { useTranslation } from 'react-i18next'
import { Icons } from '@/components/ui/icon'
import { useUserStore } from '@/store/zustand'
import { MAX_LENGTH } from '@/constants/regex'
import { MESSAGE } from '@/constants/message'

export function ProfileForm() {
  const { t } = useTranslation()

  const profileFormSchema = z.object({
    email: z.string().email(),
    firstName: z
      .string()
      .max(MAX_LENGTH.COMMON_FIELD, {
        message: t(MESSAGE.TOO_LONG, {
          max: MAX_LENGTH.COMMON_FIELD,
        }),
      })
      .optional(),
    lastName: z
      .string()
      .max(MAX_LENGTH.COMMON_FIELD, {
        message: t(MESSAGE.TOO_LONG, {
          max: MAX_LENGTH.COMMON_FIELD,
        }),
      })
      .optional(),
    company: z
      .string()
      .max(MAX_LENGTH.COMMON_FIELD, {
        message: t(MESSAGE.TOO_LONG, {
          max: MAX_LENGTH.COMMON_FIELD,
        }),
      })
      .optional(),
  })
  type ProfileFormValues = z.infer<typeof profileFormSchema>

  const { currentUser: user } = useUserStore.getState()

  const defaultValues: Partial<ProfileFormValues> = {
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    company: user?.company,
  }
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: 'onChange',
  })

  const [mutateUpdateUser, { loading }] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: () => {
      toast({
        title: t('Profile successfully updated'),
      })
    },

    onError: err => {
      toast({
        title: t('Error: Internal Server Error'),
        variant: 'destructive',
      })
      console.error(err)
    },
  })

  function onSubmit(data: ProfileFormValues) {
    try {
      mutateUpdateUser({ variables: { user: data } })
    } catch (e) {
      console.log('error: ', e)
    }
  }

  const { isSubmitting } = form.formState

  const disabled = loading || isSubmitting

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input disabled {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='firstName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('First name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='lastName'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('Last name')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='company'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('Company')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={disabled} type='submit'>
          {disabled && <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />}
          {t('Update profile')}
        </Button>
      </form>
    </Form>
  )
}
