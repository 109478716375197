import { t } from 'i18next'

export const checkCapacity = () => {
  const checkMaxRegister = (
    capacity?: number | null,
    attendeesCount?: number
  ) => {
    if (capacity && capacity !== null && attendeesCount) {
      if (attendeesCount < capacity) {
        return true
      } else {
        return false
      }
    } else if (capacity === 0) {
      return false
    } else {
      return true
    }
  }

  const checkSubTitle = (
    isExpired?: boolean,
    capacity?: number | null,
    attendeesCount?: number,
    isLockedRegister?: boolean
  ) => {
    if (isExpired) {
      return t('Event Expired')
    } else if (!checkMaxRegister(capacity, attendeesCount)) {
      return t('Fully booked')
    } else if (isLockedRegister) {
      return t('The event is locked')
    } else {
      return ''
    }
  }

  return { checkMaxRegister, checkSubTitle }
}
