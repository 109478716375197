import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router'
import { GET_ORGANIZATIONS } from 'graphql/queries'
import { useOrganizationIdVar } from 'utils/hooks'

import OrganizationSkeleton from 'components/molecules/Skeleton/OrganizationSkeleton'
import SelectOrganization from 'components/molecules/SelectOrganization'
import { USER_ROLE } from 'constants/userRole'
import { IOrganization } from 'models'
import { useTranslation } from 'react-i18next'
import { toast } from '@/components/ui/use-toast'
import { decryptArray } from '@/utils/helpers/role'

const OrganizationsContainer: FC = () => {
  const { t } = useTranslation()
  const { organizationId, setOrganizationId } = useOrganizationIdVar()
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  const history = useHistory()

  const isFetchOrganization: boolean =
    (user && decryptArray(user.roles).includes(USER_ROLE.ROOT_ADMIN)) ||
    (organizationId &&
      organizationId !== 'undefined' &&
      organizationId !== 'null')

  const { data: organizationsDato, loading: organizationsLoading } = useQuery(
    GET_ORGANIZATIONS,
    {
      onCompleted() {
        if (organizationsDato) {
          if (
            organizationId &&
            organizationId !== 'undefined' &&
            organizationId !== 'null'
          ) {
            localStorage.setItem('organizationId', organizationId)
          } else {
            const organizationId = organizationsDato?.organizations[0]?._id
            localStorage.setItem('organizationId', organizationId)
            setOrganizationId(organizationId)
          }
        }
      },
      onError: err => {
        err.graphQLErrors.map(({ message }) => {
          toast({
            title: t('Error'),
            description: t(message),
            variant: 'destructive',
          })
        })
      },
      skip: !isFetchOrganization,
    }
  )

  const onChange = (organizationId: string) => {
    setOrganizationId(organizationId)
    localStorage.setItem('organizationId', organizationId)
    removeQuery('query')
  }

  const removeQuery = (key: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete(key)
    history.push({
      search: searchParams.toString(),
    })
  }

  if (organizationsLoading) return <OrganizationSkeleton />

  const organizations = organizationsDato?.organizations.filter(
    (organization: IOrganization) => !organization.isRemoved
  )

  return (
    <>
      {organizationsDato && organizationsDato.organizations.length > 0 && (
        <SelectOrganization
          organizationsData={organizations}
          value={organizationId ? organizationId : organizations[0]._id}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default OrganizationsContainer
