import { responsive } from 'assets/scss/mixin'
import { Field, styled } from 'utils/adapters'

export const Column = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 74px;
  ${responsive.md`
      gap: 10px;
  `}
`

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${responsive.sm`
      width: 100%;
  `}
`

export const Label = styled.label<{ bold?: boolean }>`
  font-size: 14px;
  color: #757575;
  display: flex;

  span {
    color: red;
    padding: 0 5px;
  }

  font-weight: ${props => (props.bold ? 'bold' : '400')};
`