import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setAmountOfUser, setResetPageFlag, setTotalUser } from 'store/User'
import {
  activeUser,
  getAllUsers,
  getCurrentUser,
  getOrganizations,
  removeUser,
  useUserFilterVar,
} from '../mixins'
import { GET_ALL_USERS } from 'graphql/queries'

import { styled, useHistory } from 'utils/adapters'
import {
  useAppDispatch,
  useAppSelector,
  useModal,
  useOrganizationIdVar,
  usePersistedState,
} from 'utils/hooks'

import { IOrganization, IUser } from 'models'
import SelectOrganization from 'components/molecules/SelectOrganization'
import GenericTable from 'components/organisms/GenericTable'
import Permission from 'components/atoms/Permission'

import { CheckIcon, EditIcon, RemoveIcon } from 'assets/images'

import { USER_ROLE, USER_SCOPE } from 'constants/userRole'
import { IMAGES } from 'constants/colors'
import { MESSAGE } from 'constants/message'
import { setUserCreated } from 'store/User/UserSlide'
import Modal from '@/components/molecules/Modal'
import Icon from '@/components/atoms/Icon'
import { Check, CheckCircle, Edit, Plus, Trash2, X } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { UserTableFilter } from '@/components/pages/Users/components/UserTableFilter'
import { PATH_NAME } from '@/routes/routesMap'
import { Button } from '@/components/ui/button'

export const UserStatuses = [
  {
    value: 'active',
    label: 'Active',
    icon: Check,
  },
  {
    value: 'inactive',
    label: 'Inactive',
    icon: X,
  },
]

export const UserRoles = [
  {
    value: USER_ROLE.ADMIN,
    label: USER_ROLE.ADMIN,
  },
  {
    value: USER_ROLE.EVENT_ORGANISER,
    label: USER_ROLE.EVENT_ORGANISER,
  },
]

const UsersTable: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { resetPageFlag } = useAppSelector(state => state.userReducer)
  const { organizationId, setOrganizationId } = useOrganizationIdVar()
  const userPageIndex = Number(localStorage.getItem('userPageIndex') as string)
  const { isShow, showModal, hideModal } = useModal()

  const [tableData, setTableData] = useState<IUser[]>([])

  const [capacity, setCapacity] = useState<number | string>(0)
  const [pageCount, setPageCount] = useState(0)
  const [, _setPageIndex] = usePersistedState('userPageIndex')
  const [selectedRow, setSelectedRow] = useState<any>()

  const { data: user } = getCurrentUser()

  const { organizationsData } = getOrganizations()
  const { data: userData, loading: userLoading } = getAllUsers()

  const { mutateRemoveUser } = removeUser()
  const { mutateActiveUser } = activeUser()
  const { role, status, setUserFilterByRole, setUserFilterByStatus } =
    useUserFilterVar()
  const history = useHistory()
  const columns = React.useMemo(
    () => [
      {
        header: t('First Name'),
        accessor: 'firstName',
        id: 'firstName',
        accessorFn: row => `${row.firstName}`,
      },
      {
        header: t('Last Name'),
        accessor: 'lastName',
        id: 'lastName',
        accessorFn: row => `${row.lastName}`,
      },

      {
        header: t('Email'),
        accessor: 'email',
        id: 'email',
        accessorFn: row => `${row.email}`,
      },
      {
        header: t('Company'),
        accessor: 'company',
        id: 'company',
        accessorFn: row => `${row.company}`,
      },
      {
        header: t('Status'),
        id: 'status',
        accessorFn: row => `${row.isRemoved ? 'inactive' : 'active'}`,
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id))
        },
        cell: ({ row }: any) => {
          const status = UserStatuses.find(
            status =>
              status.value === (!row.original.isRemoved ? 'active' : 'inactive')
          )

          if (!status) {
            return null
          }

          return (
            <div className='flex w-[100px] items-center'>
              <Badge variant='secondary'>
                {status.icon && (
                  <status.icon
                    className={cn(
                      'mr-2 h-4 w-4 text-muted-foreground',
                      status.value === 'active'
                        ? 'text-green-500'
                        : 'text-red-500'
                    )}
                  />
                )}
                <span>{status.label}</span>
              </Badge>
            </div>
          )
        },
      },
      {
        header: t('Role'),
        accessorKey: 'role',
        id: 'roles',
        cell: ({ row }: any) => {
          return row.original.roles.map((role: string, index: number) => (
            <Badge key={index} variant='outline'>
              {role}
            </Badge>
          ))
        },
      },
      {
        header: t('Actions'),
        id: 'actions',
        cell: ({ row }: any) => (
          <>
            <div className='flex items-center justify-center space-x-2'>
              <Permission scope={USER_SCOPE.USER_UPDATE}>
                <Button
                  variant='ghost'
                  size='icon'
                  onClick={() => {
                    history.push(`/admin/users/edit/${row.original._id}`)
                  }}
                >
                  <Edit className='h-4 w-4' />
                </Button>
              </Permission>
              <Permission role={USER_ROLE.ADMIN}>
                {row.original.isRemoved ? (
                  <Button
                    variant='outline'
                    size='sm'
                    onClick={() => {
                      setSelectedRow(row)
                      showModal()
                    }}
                    className='text-green-600 hover:bg-green-100 hover:text-green-700'
                  >
                    <CheckCircle className='h-4 w-4 mr-2' />
                    {t('Activate')}
                  </Button>
                ) : (
                  <Button
                    variant='destructive'
                    size='sm'
                    onClick={() => {
                      setSelectedRow(row)
                      showModal()
                    }}
                    className='hover:bg-destructive/90'
                  >
                    <Trash2 className='h-4 w-4' />
                    {t('Delete')}
                  </Button>
                )}
              </Permission>
            </div>
          </>
        ),
      },
    ],
    []
  )

  useEffect(() => {
    if (userData?.users) setTableData(userData?.users)
  }, [userData])

  useEffect(() => {
    if (organizationsData && organizationId) {
      organizationsData.organizations.forEach((organization: IOrganization) => {
        if (organization._id === organizationId) {
          dispatch(setAmountOfUser(organization.capacity))
          dispatch(setUserCreated(organization.userCreated))
          setCapacity(organization.capacity || '∞')
        }
      })
    } else {
      dispatch(setAmountOfUser(user?.currentUser.organization.capacity))
    }
  }, [organizationId, organizationsData])

  const onChange = (organizationId: string) => {
    _setPageIndex(0)
    setUserFilterByStatus(undefined)
    setUserFilterByRole(undefined)
    dispatch(setResetPageFlag(true))
    setOrganizationId(organizationId)
  }

  const onFilterStatus = (e: string[]) => {
    const status = e?.length ? e[0] : ''
    _setPageIndex(0)
    dispatch(setResetPageFlag(true))

    console.log('status', status)
    if (status === 'true' || status === 'false') {
      const _status = JSON.parse(status)
      setUserFilterByStatus(_status)
    } else {
      setUserFilterByStatus(undefined)
    }
  }

  const onFilterRole = (e: string[]) => {
    const role = e?.length ? e[0] : ''
    setUserFilterByRole(role)
    _setPageIndex(0)
    dispatch(setResetPageFlag(true))
    setUserFilterByRole(role)
  }

  const onConfirm = () => {
    if (selectedRow.original.isRemoved) {
      mutateActiveUser({
        variables: {
          activeUserId: selectedRow.original._id,
        },

        update: (cache, mutationResult) => {
          const activeUser = mutationResult.data.activeUser
          if (organizationId) {
            const data = cache.readQuery<any>({
              query: GET_ALL_USERS,
              variables: {
                organizationId,
              },
            })

            cache.writeQuery({
              query: GET_ALL_USERS,
              variables: {
                organizationId,
              },
              data: {
                users: data?.users.map((user: IUser) =>
                  user?._id === selectedRow.original._id
                    ? { ...user, ...activeUser }
                    : user
                ),
              },
            })
          } else {
            const data = cache.readQuery<any>({
              query: GET_ALL_USERS,
            })

            cache.writeQuery({
              query: GET_ALL_USERS,
              data: {
                users: data?.users.map((user: IUser) =>
                  user?._id === selectedRow.original._id
                    ? { ...user, ...activeUser }
                    : user
                ),
              },
            })
          }
        },
      })
    } else {
      mutateRemoveUser({
        variables: {
          removeUserId: selectedRow.original._id,
        },

        update: (cache, mutationResult) => {
          const removeUser = mutationResult.data.removeUser
          if (organizationId) {
            const data = cache.readQuery<any>({
              query: GET_ALL_USERS,
              variables: {
                organizationId,
              },
            })

            cache.writeQuery({
              query: GET_ALL_USERS,
              variables: {
                organizationId,
              },
              data: {
                users: data?.users.map((user: IUser) =>
                  user?._id === selectedRow.original._id
                    ? { ...user, ...removeUser }
                    : user
                ),
              },
            })
          } else {
            const data = cache.readQuery<any>({
              query: GET_ALL_USERS,
            })

            cache.writeQuery({
              query: GET_ALL_USERS,
              data: {
                users: data?.users.map((user: IUser) =>
                  user?._id === selectedRow.original._id
                    ? { ...user, ...removeUser }
                    : user
                ),
              },
            })
          }
        },
      })
    }

    hideModal()
  }

  const organizations = organizationsData?.organizations?.filter(
    (organization: IOrganization) => !organization.isRemoved
  )

  return (
    <>
      <div className='flex justify-between items-center bg-gray-50 p-4 rounded-lg shadow-sm'>
        <CapacityContainer className='text-lg font-semibold text-gray-700'>
          <span>{t('Total users')}:</span>
          <span className='ml-2 text-primary'>{userData?.users?.length}</span>
          {organizationsData && organizationId && (
            <span className='ml-2'>
              / <strong className='text-secondary'>{capacity}</strong>
            </span>
          )}
        </CapacityContainer>
        <Button
          onClick={() => history.push(PATH_NAME.CREATE_USER)}
          className='bg-primary text-white hover:bg-primary-dark transition-colors duration-300 flex items-center'
        >
          <Plus className='mr-2 h-5 w-5' />
          <span>{t('Add new')}</span>
        </Button>
      </div>

      <div className='flex space-x-2 items-center mb-4'>
        {organizationsData && organizationId ? (
          <SelectOrganization
            organizationsData={organizations}
            value={
              organizationId
                ? organizationId
                : organizationsData?.organizations[0]
            }
            onChange={onChange}
          />
        ) : null}
      </div>
      <GenericTable
        columns={columns}
        data={tableData}
        noDataText={t('No users have been recorded as of now.')}
        pageCount={pageCount}
        pageName='userPageIndex'
        loading={userLoading}
        searchColumns={['email']}
        filterColumns={
          [
            // {
            //   name: 'status',
            //   header: t('Status'),
            //   options: UserStatuses,
            // },
            // {
            //   name: 'role',
            //   header: t('Roles'),
            //   options: UserRoles,
            // },
          ]
        }
        toolbar={
          <UserTableFilter
            onFilterStatus={onFilterStatus}
            onFilterRole={onFilterRole}
          />
        }
      />

      <Modal
        isShow={isShow}
        title={t(MESSAGE.WARNING)}
        description={t('Do you really want to {{action}} this user?', {
          action: selectedRow?.original?.isRemoved ? 'activate' : 'remove',
        })}
        onClose={hideModal}
        onConfirm={onConfirm}
      />
    </>
  )
}

const CapacityContainer = styled.div`
  display: flex;
  padding: 10px 0;
`

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const ActionIcon = styled(Icon)`
  cursor: pointer;

  img {
    filter: none;

    &:hover {
      opacity: 0.8;
    }
  }
`

const StyleRemoveIcon = styled(ActionIcon)`
  img {
    filter: ${IMAGES.NONE};
  }
`

const StatusText = styled.span<{ isRemoved: boolean }>`
  color: ${props => (props.isRemoved ? 'red' : 'green')};
`

export default UsersTable
