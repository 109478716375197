import { FC } from 'react'
import { keyframes, styled } from 'utils/adapters'

type LoadingProps = {
  className?: string
}

const Loading: FC<LoadingProps> = ({ className }) => {
  return (
    <LoadingRing className={className}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingRing>
  )
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoadingRing = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #333;
    border-radius: 50%;
    animation: ${rotate} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #333 transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

export default Loading
