import Checkbox from '@/components/atoms/Checkbox'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { SelectAdapter } from '@/components/ui/select-adapter'
import { Textarea } from '@/components/ui/textarea'
import { FEEDBACK_FORM_TYPE } from '@/constants'
import { Field } from '@/utils/adapters'
import { trimWhiteSpace } from '@/utils/helpers'
import { t } from 'i18next'
import { FC } from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

interface FeedbackFormItemProps {
  name: string
  index: number
  type: string
  activeSection: number
}

const required = (value: any) =>
  value ? undefined : t('This field is required!')

export const FeedbackFormItem: FC<FeedbackFormItemProps> = ({
  name,
  index,
  type,
  activeSection,
}) => {
  const { t } = useTranslation()

  const form = useForm()
  const isRequired = form.getState().values.questions[index].required
  const label = form.getState().values.questions[index].label

  const renderItemByType = (type: string) => {
    switch (type) {
      case FEEDBACK_FORM_TYPE.TEXT:
        if (activeSection === index) {
          return
        }
        return (
          <div className='flex gap-6 flex-col md:flex-row'>
            <div className='w-full'>
              <p className='font-bold text-lg'>
                {label} {isRequired && '*'}
              </p>
              <Input
                className='font-bold border-b border-gray-300 px-0 rounded-none border-t-0 border-x-0 py-6 focus-visible:ring-0 
												focus-visible:ring-offset-0 focus:border-b-2 focus:border-primary'
              />
            </div>
          </div>
        )

      case FEEDBACK_FORM_TYPE.RATING:
        if (activeSection === index) return

        return (
          <>
            <p className='font-bold text-lg'>
              {label} {isRequired && '*'}
            </p>
            <div className='flex flex-row justify-between items-center mt-8'>
              <div className='font-bold text-muted-foreground'>
                {t('feedback_form.very_dissatisfied')}
              </div>

              <div className='flex flex-col gap-2'>
                <RadioGroup className='flex flex-row gap-20 justify-center'>
                  {[1, 2, 3, 4, 5].map(value => (
                    <div
                      key={value}
                      className='flex items-center flex-col gap-4'
                    >
                      <Label htmlFor={`r${value}`} className='font-bold'>
                        {value}
                      </Label>
                      <RadioGroupItem
                        value={value.toString()}
                        id={`r${value}`}
                      />
                    </div>
                  ))}
                </RadioGroup>
              </div>

              <div className='font-bold text-muted-foreground'>
                {t('feedback_form.additional_comments')}
              </div>
            </div>
            <div className='mt-8'>
              <FormLabel className='font-bold'>
                {t('Additional comments')}
              </FormLabel>
              <Textarea
                className='mt-2 text-base border-b border-gray-300 bg-transparent focus-visible:ring-0
                     focus-visible:ring-offset-0 focus:border-2 focus:border-primary'
              />
            </div>
          </>
        )
    }
  }

  return (
    <div className=''>
      {activeSection === index && (
        <div>
          <div className='z-999 flex items-end gap-2 justify-between'>
            <Field name={`${name}.type`} placeholder={t('Select box')}>
              {({ input, meta }: any) => (
                <FormItem>
                  <div className='w-[200px] md-w-[170px]'>
                    <SelectAdapter
                      input={input}
                      meta={meta}
                      options={Object.values(FEEDBACK_FORM_TYPE).map(type => ({
                        label: type,
                        value: type,
                      }))}
                      title={t('feedback_form.type')}
                      className='font-bold'
                    />
                  </div>
                </FormItem>
              )}
            </Field>
            <Field name={`${name}.uid`} type='hidden'>
              {({ input, meta }: any) => (
                <FormItem>
                  <div className='w-[200px] md-w-[170px]'>
                    <Input {...input} hidden />
                  </div>
                </FormItem>
              )}
            </Field>
            <Field
              name={`${name}.required`}
              type='checkbox'
              defaultValue={false}
            >
              {({ input, meta }: any) => (
                <div className='flex gap-2'>
                  <Checkbox {...input} />
                  <div className=''>{t('Is required?')}</div>
                </div>
              )}
            </Field>
          </div>
          <div className='flex gap-6 flex-col md:flex-row mt-6'>
            <div className='w-full'>
              <Field
                name={`${name}.label`}
                type='text'
                validate={required}
                format={trimWhiteSpace}
                formatOnBlur
              >
                {({ input, meta }: any) => (
                  <FormItem className='w-full'>
                    <FormLabel meta={meta} isRequired className='font-bold'>
                      {t('feedback_form.question_title')}
                    </FormLabel>
                    <Input
                      {...input}
                      meta={meta}
                      autoFocus={activeSection === index}
                      className='font-bold border-b border-gray-300 focus-visible:ring-0 
												focus-visible:ring-offset-0 focus:border-primary'
                    />
                  </FormItem>
                )}
              </Field>
            </div>
          </div>
        </div>
      )}
      {renderItemByType(type)}
    </div>
  )
}
