import { FC, useState } from 'react'
import Papa from 'papaparse'
import { useTranslation } from 'react-i18next'

import { setImportAttendees } from 'store/Events'
import { useMutation, useParams } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { IMPORT_ATTENDEES } from 'graphql/mutations'
import ImportAttendees from './ImportAttendees'
import { ImportAttendeeCSVDataType } from '@/models'
import { toast } from '@/components/ui/use-toast'
import { Separator } from '@/components/ui/separator'
import { AlertCircle, CheckIcon } from 'lucide-react'

const ImportAttendeesContainer: FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()
  const [isDisableImport, setIsDisableImport] = useState<boolean>(true)
  const [createdAttendees, setCreatedAttendees] = useState<any>([])
  const [errorAttendees, setErrorAttendees] = useState<any>([])
  const [sendMailAfterImport, setSendMailAfterImport] = useState(false)
  const [isAsciiEncoding, setIsAsciiEncoding] = useState(false)

  const dispatch = useAppDispatch()
  const { importAttendeesData } = useAppSelector(state => state.eventsReducer)

  const handleDisableButton = (state: boolean) => {
    setIsDisableImport(state)
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files?.[0]
    if (file) {
      Papa.parse(file, {
        header: true,
        encoding: isAsciiEncoding ? 'ascii' : 'utf-8',
        complete: function (results: any) {
          const data = results.data

          //format address data
          const formatData = data.map((el: any) => {
            const resultObj: any = {}
            const addressObj: any = {}

            Object.entries(el).map(([key, val]) => {
              let addressObjKey: any
              if (key.includes('.')) {
                const addressKey = key.split('.')[1]
                addressObjKey = key.split('.')[0]
                if (addressKey === 'country') {
                  addressObj[addressKey] = {
                    value: val,
                    label: val,
                  }
                } else {
                  addressObj[addressKey] = val
                }
              } else {
                resultObj[key] = val
              }
              if (addressObjKey) resultObj[addressObjKey] = addressObj
              return resultObj
            })
            return resultObj
          })

          // format key data
          const result = formatData.map((el: any) => {
            const resultObj: any = {}
            Object.entries(el).map(([key, val]) => {
              //replace space and lowercase the first letter
              const formattedKey = key.toLowerCase().replace(/\W+/g, '_')
              // .replace(key[0], key[0].toLowerCase())
              resultObj[formattedKey] = val
              return resultObj
            })
            return resultObj
          })

          // remove empty item
          const formattedData = result.filter((item: any) => item.email !== '')

          const importAttendeeData = formattedData.map(
            (item: ImportAttendeeCSVDataType, index: number) => ({
              id: index + 1,
              firstName: item.first_name,
              lastName: item.last_name,
              jobTitle: item.job_title,
              company: item.company,
              address: item.address,
              email: item.email,
              workPhone: item.work_phone,
              mobilePhone: item.mobile_phone,
              fax: item.fax,
              salutation: item.salutation,
              professional: item.academic_title,
            })
          )
          dispatch(setImportAttendees(importAttendeeData))
        },
      })
    }
  }

  // accept choose same file
  const handleOnClick = (e: any) => {
    e.target.value = null
    setIsDisableImport(false)
  }

  const [mutateImportAttendees] = useMutation(IMPORT_ATTENDEES, {
    onCompleted({ importAttendees }) {
      const { createdAttendees, errorAttendees } = importAttendees
      setCreatedAttendees(createdAttendees)
      setErrorAttendees(errorAttendees)

      toast({
        description: createdAttendees.length
          ? t('Import attendee success')
          : t('Import attendee error'),
        variant: createdAttendees?.length ? 'default' : 'destructive',
      })
    },
    onError: err => {
      err.graphQLErrors.map(({ message }) => {
        toast({
          title: t('Error'),
          description: t(message),
          variant: 'destructive',
        })
      })
    },
  })

  const handleSubmit = () => {
    setIsDisableImport(true)
    console.log(importAttendeesData)
    const attendees = importAttendeesData
      .filter(({ email }) => !!email)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(({ id, email, address, ...rest }) => ({
        email,
        ...rest,
        address: {
          ...address,
          country:
            typeof address?.country === 'string'
              ? address?.country
              : address?.country['value'],
        },
      }))

    mutateImportAttendees({
      variables: {
        eventId: id,
        attendees,
        sendMailAfterImport,
      },
    })
  }

  const handleChangeSendMailAfterImport = (value: boolean) => {
    setSendMailAfterImport(value)
  }

  const handleChangeEncoding = (value: boolean) => {
    setIsAsciiEncoding(value)
  }

  return (
    <div className='h-full flex-1 flex-col p-4 md:p-8 md:flex'>
      <h2 className='text-2xl font-bold tracking-tight'>
        {t('import_attendees.title')}
      </h2>
      <p className='text-muted-foreground'>
        {t('import_attendees.subtitle')}
      </p>
      <Separator className='my-6' />
      <ImportAttendees
        array={importAttendeesData}
        handleOnChange={handleOnChange}
        handleSubmit={handleSubmit}
        handleOnClick={handleOnClick}
        isDisableImport={isDisableImport}
        setIsDisableImport={handleDisableButton}
        sendMailAfterImport={sendMailAfterImport}
        setSendMailAfterImport={handleChangeSendMailAfterImport}
        handleChangeEncoding={handleChangeEncoding}
        isAsciiEncoding={isAsciiEncoding}
      />
      <div className='mt-4'>
        {createdAttendees.length > 0 && (
          <div className='space-y-2'>
            <div className='flex items-center space-x-2 font-bold text-green-600'>
              <CheckIcon className='h-5 w-5' />
              <span>{t('import.success.text')}:</span>
            </div>
            <ul className='list-disc list-inside space-y-1'>
              {createdAttendees.map((item: any) => (
                <li key={item.email}>{item.email}</li>
              ))}
            </ul>
          </div>
        )}
        {errorAttendees.length > 0 && (
          <div className='space-y-2'>
            <div className='flex items-center space-x-2 font-bold text-red-600'>
              <AlertCircle className='h-5 w-5' />
              <span>{t('import.error.text')}:</span>
            </div>
            <ul className='list-disc list-inside space-y-1'>
              {errorAttendees.map((item: string) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
            <div className='flex items-center space-x-2 font-bold text-red-600'>
              <AlertCircle className='h-5 w-5' />
              <span>{t('import.error.sub_text')}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ImportAttendeesContainer
