import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GET_ORGANIZATIONS } from 'graphql/queries'

import { useQuery } from 'utils/adapters'

import { IOrganization } from 'models'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'

type PropsType = {
  currentOrganizationId: string
  onClick: (organizationId?: string) => void
  title: string | undefined
}
const OrganizationMenu: FC<PropsType> = ({
  currentOrganizationId,
  onClick,
  title,
}) => {
  const { t } = useTranslation()

  const [organizationId, setOrganizationId] = useState<string | undefined>()

  const [organizations, setOrganizations] = useState<IOrganization[]>([])

  const { data: organizationsData } = useQuery(GET_ORGANIZATIONS, {
    onError: err => {
      toast({
        title: t(err.message),
        description: t('Subject is required'),
        variant: 'destructive',
      })
    },
  })

  useEffect(() => {
    if (organizationsData) {
      const data = organizationsData.organizations.filter(
        (organization: IOrganization) =>
          !organization.isRemoved && organization._id !== currentOrganizationId
      )
      setOrganizations(data)
    }
  }, [organizationsData])

  return (
    <DrawerContent>
      <div className='mx-auto w-full max-w-sm'>
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerDescription>
            {t('Move the event to another organization')}
          </DrawerDescription>
        </DrawerHeader>
        <div className='p-4 pb-0'>
          <div className='flex items-center justify-center space-x-2'>
            <Select onValueChange={setOrganizationId}>
              <SelectTrigger className='w-full'>
                <SelectValue placeholder={t('Organization')} />
              </SelectTrigger>
              <SelectContent>
                {organizations?.map((organization: IOrganization) => (
                  <SelectItem key={organization._id} value={organization._id}>
                    {organization?.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <DrawerFooter>
          <Button
            disabled={!organizationId}
            onClick={() => onClick(organizationId)}
          >
            {t('Submit')}
          </Button>
          <DrawerClose>
            <Button variant='outline' className='w-full'>
              {t('Cancel')}
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </div>
    </DrawerContent>
  )
}

export default OrganizationMenu
