export type TInvitationList = {
  _id: string
  firstName: string
  lastName: string
  email: string
  invitationStatus: InvitationResponseStatus
  message: string
  role?: InvitationRole
}

enum InvitationResponseStatus {
  Attending = 'Attending',
  Declined = 'Declined'
}

export enum InvitationRole {
  Guest = 'guest',
  Attendee = 'attendee'
}


