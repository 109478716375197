import { LIMIT_PAGINATION } from '@/constants/changesLog'
import { IFeedback, IFeedbackFilter } from 'models/IFeedback'
import { getFeedbacksList } from 'services/feedbacksServices'
import { create } from 'zustand'
import { FeedbacksState } from './Feedback'

export const useFeedbacksStore = create<FeedbacksState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  feedbacksData: [],
  setFeedbacksData: (feedbacksData: IFeedback[]) =>
    set({
      feedbacksData,
    }),
  filters: {
    page: 1,
    limit: LIMIT_PAGINATION[0],
    total: 0,
  },
  setFilters: (filters: IFeedbackFilter) =>
    set({
      filters,
    }),
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),
  totalItems: 0,
  setTotalItems: (totalItems: number) => set({ totalItems }),
}))

export const getFeedbacksData = async params => {
  try {
    useFeedbacksStore.getState().setLoading(true)
    const { data } = await getFeedbacksList(params)
    if (data) {
      useFeedbacksStore.getState().setFeedbacksData(data.data)
      useFeedbacksStore.getState().setTotalItems(data.pagination.total)
      useFeedbacksStore.getState().setLoading(false)
    }
  } catch (error) {
    useFeedbacksStore.getState().setErrorMessage('')
  }
}

export const resetFilters = () => {
  useFeedbacksStore.getState().setFilters({
    page: 1,
    limit: LIMIT_PAGINATION[0],
    total: 0,
  })
}
