import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CREATE_CUSTOM_FIELD, EDIT_CUSTOM_FIELD } from 'graphql/mutations'

import {
  ARRAY_ERROR,
  arrayMutators,
  Field,
  FieldArray,
  Form,
  styled,
  useHistory,
  useMutation,
  useParams,
} from 'utils/adapters'
import { useAppDispatch } from 'utils/hooks'
import {
  blockTypingNonNumberic,
  formatPrice,
  requiredValidation,
  trimWhiteSpace,
} from 'utils/helpers'

import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import SelectAdapter from 'components/atoms/Select'
import { Button } from '@/components/ui/button'
import Checkbox from 'components/atoms/Checkbox'
import { COLORS } from 'constants/colors'
import { CUSTOM_FIELD_TYPE, customFieldType } from 'constants/customFieldType'

import { responsive } from 'assets/scss/mixin'
import { AddCircleIcon, RemoveIcon } from 'assets/images'
import DotsLoading from '@/components/atoms/DotsLoading'
import ValidationText from '@/components/atoms/ValidationText'
import Icon from '@/components/atoms/Icon'
import { toast } from '@/components/ui/use-toast'
import HyperLinkInput from '@/components/atoms/HyperLinkInput'

const DUPLICATE_ERROR_MESSAGE = 'Duplicated name of the option!'
const CHOOSE_AT_LEAST_ERROR_MESSAGE = 'Please choose at least one option!'

interface ParamTypes {
  id: string
}

const CustomFieldForm: FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<ParamTypes>()

  const history = useHistory()
  const dispatch = useAppDispatch()

  const selectedCustomField = JSON.parse(
    localStorage.getItem('selected_custom_field') as string
  )

  const search = window.location.search
  const params = new URLSearchParams(search)
  const organizationId = params.get('orgId') as string

  const formCustomField = {
    ...selectedCustomField,
    type: {
      value: selectedCustomField?.type || '',
      label: selectedCustomField?.type || '',
    },
    options: selectedCustomField?.options?.map((item: any) => ({
      ...item,
    })),
  }

  const selectboxConfig = {
    isSearchable: false,
  }

  const [mutateCreateCustomField, { loading: loadingCreateCustomfield }] =
    useMutation(CREATE_CUSTOM_FIELD, {
      onCompleted: ({ createCustomField }) => {
        if (createCustomField) {
          toast({
            title: t('Created'),
            description: t('Create custom field {{title}} success!', {
              title: '',
            }),
          })
          history.push('/admin/custom-field')
        }
      },
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    })

  const [mutateUpdateCustomField, { loading: loadingUpdateCustomfield }] =
    useMutation(EDIT_CUSTOM_FIELD, {
      onCompleted: ({ updateCustomField }) => {
        if (updateCustomField) {
          toast({
            title: t('Updated'),
            description: t('Update custom field {{title}} success!', {
              title: '',
            }),
          })
          history.push('/admin/custom-field')
          localStorage.setItem('selected_custom_field', JSON.stringify({}))
        }
      },

      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    })

  const renderOptions = (type: string) => {
    switch (type) {
      case CUSTOM_FIELD_TYPE.CHECK_BOX:
        return true
      case CUSTOM_FIELD_TYPE.SELECT_BOX:
        return true
      case CUSTOM_FIELD_TYPE.RADIO_BUTTON:
        return true
      default:
        return false
    }
  }

  const required = requiredValidation

  const parse = (value: any) => {
    return !parseFloat(value) || !Number(value) || value.endsWith('.')
      ? value
      : parseFloat(value.replace(/,/g, '.'))
  }
  const onSubmit = (values: any) => {
    const options = values?.options
      ? values?.options.map((option: any) => ({
          ...option,
          fee: option.fee ? Number(option.fee) : 0,
        }))
      : []

    const formData = {
      title: values.title,
      isRequired: values.isRequired || false,
      type: values.type.value,
      options,
    }
    if (!id) {
      mutateCreateCustomField({
        variables: {
          createCustomFieldInput: { ...formData, organization: organizationId },
        },
      })
    } else {
      const updateFormData = {
        ...formData,
        _id: id,
      }
      mutateUpdateCustomField({
        variables: { updateCustomFieldInput: updateFormData },
      })
    }
  }
  type IOption = {
    name: string
    fee: number
  }
  const optionValidate = (values: IOption[] | any) => {
    if (values && values?.length > 0) {
      const errorsArray = [] as any
      values.forEach((value: any) => {
        if (value) {
          const errors = {} as any
          if (!value.name) {
            errors.name = t('This field is required!')
          }
          if (typeof value?.fee === 'string' && !value?.fee) {
            errors.fee = t('This field is required!')
          }
          console.log(errors)
          errorsArray.push(errors)
        }
      })
      return errorsArray
    }
  }

  const checkDuplicated = (options: { name: string; fee: number }[]) => {
    const optionsNames = options.map(item => {
      return item.name
    })
    return optionsNames.some(
      (option, index: number) => optionsNames.indexOf(option) !== index
    )
  }

  const renderLabelSubmitButton = () => {
    return loadingCreateCustomfield || loadingUpdateCustomfield ? (
      <DotsLoading />
    ) : id ? (
      t('Update Custom Field')
    ) : (
      t('Create Custom Field')
    )
  }

  const renderEditor: FC<{ input: any }> = ({ input }) => {
    return (
      <HyperLinkInput
        value={input.value}
        onChange={content => {
          input.onChange(content)
        }}
        className='text-base h-10 rounded-none flex flex-row-reverse w-full'
      />
    )
  }

  return (
    <>
      <Wrapper>
        <Form
          onSubmit={onSubmit}
          keepDirtyOnReinitialize
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{
            ...formCustomField,
          }}
          validate={(values: any) => {
            const errors = {} as unknown as any
            if (!values.type.value) {
              errors.type = t('This field is required!')
            }
            if (
              values.type.value === CUSTOM_FIELD_TYPE.CHECK_BOX ||
              values.type.value === CUSTOM_FIELD_TYPE.RADIO_BUTTON ||
              values.type.value === CUSTOM_FIELD_TYPE.SELECT_BOX
            ) {
              errors.options = []

              if (values.options && values.options.length > 0) {
                const options = values.options
                const isDuplicated = checkDuplicated(options)
                if (options.length > 0) {
                  isDuplicated
                    ? (errors.options[ARRAY_ERROR] = DUPLICATE_ERROR_MESSAGE)
                    : null
                }
              } else {
                errors.options[ARRAY_ERROR] = CHOOSE_AT_LEAST_ERROR_MESSAGE
              }
            }
            return errors
          }}
          render={({ handleSubmit, submitting, values, pristine }: any) => {
            return (
              <>
                <FormContainer onSubmit={handleSubmit}>
                  <FormItem>
                    <FormLabel isRequired>{t('Title')}</FormLabel>
                    <Field name='title' component={renderEditor}></Field>
                  </FormItem>

                  <Column>
                    <Row>
                      <Label>
                        {t('Type')} <span>*</span>
                      </Label>
                      <Field
                        name='type'
                        component={SelectAdapter}
                        validate={required}
                        placeholder={t('Select box')}
                        options={customFieldType?.map(type => ({
                          label: type.label,
                          value: type.value,
                        }))}
                        {...selectboxConfig}
                        className='select-custom-field w-52	'
                      />
                    </Row>

                    <Field
                      name='isRequired'
                      type='checkbox'
                      defaultValue={false}
                    >
                      {({ input }: any) => (
                        <CheckboxWrapper>
                          <CheckboxLabel>{t('Required')}</CheckboxLabel>
                          <Checkbox {...input} />
                        </CheckboxWrapper>
                      )}
                    </Field>
                  </Column>
                  {renderOptions(values?.type?.value) && (
                    <Fieldset>
                      <Legend>{t('Options')}</Legend>

                      <FieldArray name='options' validate={optionValidate}>
                        {({ fields, meta: { error } }) => (
                          <>
                            <FieldArrayWrapper>
                              {fields.map((name, index) => (
                                <OptionColumn key={name}>
                                  <Field
                                    name={`${name}.name`}
                                    type='text'
                                    format={trimWhiteSpace}
                                    formatOnBlur
                                  >
                                    {({ input, meta }: any) => (
                                      <Row>
                                        <OptionInput
                                          {...input}
                                          label={t('Name')}
                                          meta={meta}
                                          isRequired
                                          maxLength='255'
                                        />
                                      </Row>
                                    )}
                                  </Field>
                                  <Field
                                    name={`${name}.fee`}
                                    type='number'
                                    defaultValue={0}
                                    parse={parse}
                                    format={formatPrice}
                                  >
                                    {({ input, meta }: any) => (
                                      <Row>
                                        <OptionInput
                                          {...input}
                                          label={t('Fee')}
                                          meta={meta}
                                          isRequired
                                          type='text'
                                          onKeyDown={(e: any) => {
                                            blockTypingNonNumberic(e)
                                          }}
                                          maxLength='15'
                                        />
                                      </Row>
                                    )}
                                  </Field>

                                  <OptionIcon
                                    source={RemoveIcon}
                                    onClick={() => fields.remove(index)}
                                  />
                                </OptionColumn>
                              ))}

                              <div className='flex items-center space-x-2 justify-end'>
                                <OptionIcon
                                  className='add-circle-icon'
                                  source={AddCircleIcon}
                                  onClick={() => fields.push({})}
                                />
                              </div>

                              {(error === DUPLICATE_ERROR_MESSAGE ||
                                error === CHOOSE_AT_LEAST_ERROR_MESSAGE) && (
                                <ValidationText error={error} />
                              )}
                            </FieldArrayWrapper>
                          </>
                        )}
                      </FieldArray>
                    </Fieldset>
                  )}
                  <FieldButton type='submit' disabled={submitting || pristine}>
                    {renderLabelSubmitButton()}
                  </FieldButton>
                </FormContainer>
              </>
            )
          }}
        ></Form>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  padding: 0 4%;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin: 0 auto;
  max-width: 650px;
`

const FieldArrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2%;
`

const Fieldset = styled.fieldset`
  border-radius: 5px;
  border: 1px solid ${COLORS.BLUE};
`

const Legend = styled.legend`
  color: #757575;
`

const Label = styled.label`
  font-size: 12px;
  color: #757575;
  display: flex;

  span {
    padding: 0 5px;
    color: red;
  }
`

const CheckboxLabel = styled.span`
  font-size: 14px;
  color: #757575;
`

const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${responsive.sm`
      width: 100%;
  `}
`

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const Column = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  min-height: 74px;
  flex-wrap: wrap;
`

const OptionColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`

const OptionInput = styled(Input)`
  padding-bottom: 18px;

  input {
    font-size: 14px;
    padding-top: 18px;
    ${responsive.sm`
        font-size: 12px;
  `}
  }
`

const OptionIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin: 10px 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  img {
    filter: none;
  }

  ${responsive.md`
    width: 14px;
    height: 14px;
  `}
`

const FieldButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-self: center;
  width: 100%;
  margin: 10px 0;
`

export default CustomFieldForm
