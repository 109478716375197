import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import Loading from '@/components/atoms/Loading'
import { Separator } from '@/components/ui/separator'
import { InvitationRole, TInvitationList } from '@/models/IInvitationList'
import { Badge } from '@/components/ui/badge'
import {
  getInvitationList,
  useInvitationList,
} from '@/store/zustand/InvitationList'
import GenericTable from '@/components/organisms/GenericTable'

type ParamTypes = {
  eventId: string
}

const InvitationList: FC = () => {
  const { t } = useTranslation()
  const { eventId } = useParams<ParamTypes>()
  const { isLoading, invitationList } = useInvitationList()

  const fetchData = async () => {
    useInvitationList.getState().setIsLoading(true)
    try {
      await getInvitationList(eventId)
    } catch (error) {
      console.error('Error fetching invitations:', error)
    } finally {
      useInvitationList.getState().setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData() // Call fetchData on component mount
  }, [eventId])

  if (isLoading) return <Loading />

  return (
    <div className='h-full flex-1 flex-col p-4 md:p-8 md:flex'>
      <h2 className='text-2xl font-bold tracking-tight'>
        {t('event_invitation_list.title')}
      </h2>
      <p className='text-muted-foreground'>
        {t('event_invitation_list.subtitle')}
      </p>
      <Separator className='my-6' />
      <GenericTable
        columns={[
          {
            accessorKey: 'email',
            header: t('event_invitation_list.email'),
          },
          {
            accessorKey: 'firstName',
            header: t('event_invitation_list.firstname'),
          },
          {
            accessorKey: 'lastName',
            header: t('event_invitation_list.lastname'),
          },
          {
            accessorKey: 'invitationStatus',
            header: t('event_invitation_list.status'),
          },
          {
            accessorKey: 'role',
            header: t('event_invitation_list.role'),
            cell: ({ row }) => (
              <Badge
                color={
                  row.original.role === InvitationRole.Attendee
                    ? 'success'
                    : 'info'
                }
              >
                {row.original.role === InvitationRole.Attendee
                  ? t('Attendee')
                  : t('Guest')}
              </Badge>
            ),
          },
          {
            accessorKey: 'message',
            header: t('event_invitation_list.message'),
          },
        ]}
        data={invitationList}
        noDataText={t('event_invitation_list.no_invitations')}
        fetchData={fetchData}
        pageCount={1}
        pageName='invitationList'
      />
    </div>
  )
}

export default InvitationList
