import { gql } from 'utils/adapters'

export const GET_ATTENDANT_DETAILS = gql`
  query GetAttendantDetails($attendeeId: ID!, $eventId: ID!) {
    attendeeDetail(attendeeId: $attendeeId, eventId: $eventId) {
      _id
      firstName
      lastName
      jobTitle
      company
      workPhone
      mobilePhone
      fax
      email
      status
      webcastParticipantId
      webcastUrlJoinLinks
      address {
        street
        city
        zipCode
        country
      }
      registration {
        customValues {
          eventCustomField {
            _id
            type
            isRequired
            title
            options {
              fee
              name
            }
          }
          value {
            name
            fee
          }
        }
      }
    }
  }
`

export const GET_EVENT_ATTENDEES = gql`
  query getEventAttendees($eventId: ID!) {
    getEventAttendees(eventId: $eventId) {
      data {
        _id
        email
        firstName
        lastName
        address {
          city
        }
        status
      }
    }
  }
`

export const GET_ATTENDEE_BOOKING_DETAIL = gql`
  query GetAttendeeBookingDetail($eventId: ID!) {
    attendeeBooking(eventId: $eventId) {
      _id
      firstName
      salutation
      professional
      lastName
      jobTitle
      company
      workPhone
      mobilePhone
      fax
      email
      status
      webcastParticipantId
      webcastUrlJoinLinks
      address {
        street
        city
        zipCode
        country
      }
      customSelection
      registration {
        registrationCode
        customValues {
          eventCustomField {
            _id
            type
            isRequired
            title
            options {
              fee
              name
            }
          }
          value {
            name
            fee
          }
        }
      }
    }
  }
`
