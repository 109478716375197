import { USER_ROLE } from 'constants/userRole'
import { decryptArray } from './role'

export const isRootAdmin = () => {
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  const decryptedRoles = decryptArray(user?.roles)
  return !!(user && decryptedRoles?.includes(USER_ROLE.ROOT_ADMIN))
}

export const isAdmin = () => {
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  const decryptedRoles = decryptArray(user?.roles)
  return !!(user && decryptedRoles?.includes(USER_ROLE.ADMIN))
}

export const isAttendee = () => {
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  const decryptedRoles = decryptArray(user?.roles)

  return !!decryptedRoles?.includes(USER_ROLE.ATTENDEE)
}

export const hasRole = (role: string) => {
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  const decryptedRoles = decryptArray(user?.roles)
  return !!(user && decryptedRoles?.includes(role))
}
