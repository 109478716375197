import BasicTable from '@/components/organisms/BasicTable'
import { ColumnDef } from '@tanstack/react-table'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from 'recharts'

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import {
  getListEventFeedback,
  getEventFeedbackStatistics,
  useFeedbackEventStore,
} from '@/store/zustand/EventFeedback'
import { useParams } from '@/utils/adapters'
import Loading from '@/components/atoms/Loading'
import moment from 'moment'
import { defaultTimezone } from '@/utils/helpers'
import { FULL_DATE_TIMEZONE_FORMAT } from '@/constants/time'
import { displayTooltipContent } from '../SendMailLog'

type ParamsType = {
  eventId: string
}

const EventFeedbackStatistics = () => {
  const { t } = useTranslation()
  const { eventId } = useParams<ParamsType>()
  const lang = localStorage.getItem('i18nextLng') as string

  const {
    listEventFeedback,
    totalFeedback,
    isLoading,
    eventFeedbackStatistics,
    questionList,
  } = useFeedbackEventStore()

  useEffect(() => {
    const fetchData = async () => {
      await getEventFeedbackStatistics(eventId)
      getListEventFeedback(eventId)
    }
    fetchData()
  }, [eventId])

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: t('No'),
        cell: ({ row }) => <b>{row?.index + 1}</b>,
        size: 50,
      },
      {
        header: t('Email'),
        accessorKey: 'email',
        cell: ({ row }) => <b>{row?.original?.email}</b>,
        size: 100,
      },
      {
        header: t('Last updated'),
        accessorKey: 'createdAt',
        cell: ({ row }) => {
          const displayDate = moment(new Date(row?.original?.createdAt))
            .tz(defaultTimezone)
            .format(FULL_DATE_TIMEZONE_FORMAT)
          return (
            <div>
              {displayTooltipContent(
                String(moment(row?.original?.createdAt).fromNow()),
                `${displayDate} GMT`
              )}
            </div>
          )
        },
        size: 100,
      },
      ...(questionList?.length > 0
        ? questionList?.map(question => ({
            id: question.uniqueKey,
            header: question?.questionLabel,
            cell: ({ row }) => {
              const answer = row?.original?.answers.find(
                answer => answer.questionId === question.questionId
              )
              return (
                <>
                  <b>{answer?.value}</b>
                  {answer?.additionalComment && (
                    <p className='text-muted-foreground'>
                      {displayTooltipContent(
                        answer?.additionalComment,
                        answer?.additionalComment
                      )}
                    </p>
                  )}
                </>
              )
            },
            size: 200,
          }))
        : []),
    ],
    [lang, questionList]
  )

  const chartConfig = {
    average: {
      label: t('event_feedback_statistics.average'),
      color: 'hsl(var(--primary))',
    },
  } satisfies ChartConfig

  const renderCustomizedLabel = props => {
    const { x, y, width, height, value } = props
    const radius = 10

    const getEmoji = value => {
      if (value >= 4.5) return '😍'
      if (value >= 4) return '😊'
      if (value >= 3) return '😐'
      if (value >= 2) return '😕'
      return '😢'
    }

    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius * 2} r={radius} fill='#fff' />
        <text
          x={x + width / 2}
          y={y - radius * 3}
          fill='#fff'
          textAnchor='middle'
          dominantBaseline='middle'
          fontSize='45'
        >
          {getEmoji(value)}
        </text>
      </g>
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className='space-y-6 px-8 py-4'>
      {eventFeedbackStatistics.length > 0 && (
        <>
          <div>
            <h3 className='text-lg font-medium'>
              {t('event_feedback_statistics.title')}
            </h3>
            <p className='text-sm text-muted-foreground mb-4'>
              {t('event_feedback_statistics.chart_desc')}
            </p>
          </div>
          <ChartContainer config={chartConfig} className='max-h-[400px] w-full'>
            <BarChart
              accessibilityLayer
              data={eventFeedbackStatistics}
              barGap='20%'
            >
              <CartesianGrid vertical={false} />
              <YAxis type='number' domain={[0, 6]} ticks={[0, 1, 2, 3, 4, 5]} />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <Bar
                dataKey='average'
                fill='var(--color-average)'
                radius={4}
                maxBarSize={50}
              >
                <LabelList
                  dataKey='average'
                  position='top'
                  offset={12}
                  className='fill-foreground font-bold font-base'
                  fontSize={12}
                  formatter={value => value.toFixed(3)}
                  content={renderCustomizedLabel}
                />
              </Bar>
              <XAxis
                dataKey='questionLabel'
                tickLine={false}
                tickMargin={10}
                axisLine={false}
              />
            </BarChart>
          </ChartContainer>
        </>
      )}

      <div>
        <div>
          <h3 className='text-lg font-medium'>{t('feedback_form.title')}</h3>
          <p className='text-sm text-muted-foreground mb-4'>
            {t('event_feedback_statistics.table_desc')}
          </p>
          <p className='text-base text-muted-foreground font-bold mb-4'>
            {t('event_feedback_statistics.total_feedback')}: {totalFeedback}
          </p>
        </div>
        <BasicTable
          loading={isLoading}
          columns={columns}
          data={listEventFeedback}
          totalItems={totalFeedback}
          isPaginationByClient
          isGlobalSearchable
          globalSearchPlaceholder={t('Search attendee...')}
        />
      </div>
    </div>
  )
}

export default EventFeedbackStatistics
