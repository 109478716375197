import { Separator } from '@/components/ui/separator'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import TwoFactorMethodsForm from './TwoFactorMethodsForm'
import { useUserStore } from '@/store/zustand'

export const TwoFactorMethods: FC = () => {
  const { t } = useTranslation()
  const { currentUser } = useUserStore()

  return (
    <div className='space-y-6'>
      {currentUser?.is2FAEnabled && (
        <>
          <Separator />
          <div>
            <h3 className='text-lg font-medium'>
              {t('Two-Factor Authentication (2FA) Methods')}
            </h3>
            <p className='text-sm text-muted-foreground'>
              {t(
                'You can select your preferred method to receive the two- factor authentication code.'
              )}
            </p>
          </div>
          <TwoFactorMethodsForm />
        </>
      )}
    </div>
  )
}
