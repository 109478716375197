import { FC, useRef, useState } from 'react'
import { z } from 'zod'
import { MESSAGE } from '@/constants/message'
import { EMAIL_REGEX } from '@/constants/regex'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useOrganizationIdVar } from '@/utils/hooks'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import { MessageSquarePlus, X } from 'lucide-react'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'
import axios from 'axios'
import { Environment } from '@/environments'
import { useTranslation } from 'react-i18next'

interface IProps {
  disabled?: boolean
  allowImage?: boolean
  feedbackTypes?: Array<{ label: string; value: string }>
  timeout?: number
}

const UserFeedback: FC<IProps> = props => {
  const { t } = useTranslation()
  const {
    disabled,
    feedbackTypes = [
      { value: 'general', label: t('General') },
      { value: 'bug', label: t('Bug') },
      { value: 'idea', label: t('Idea') },
    ],
    allowImage,
    timeout = 3000,
  } = props
  const { organizationId } = useOrganizationIdVar()
  const menuRef = useRef(null)

  const [open, setOpen] = useState(false)
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState<any>(false)
  const [uploadingImage, setUploadingImage] = useState(false)
  const [type, setType] = useState<string>(feedbackTypes[0].value)
  const [image, setImage] = useState<any>(null)

  const formSchema = z.object({
    email: z
      .string()
      .max(255, {
        message: MESSAGE.TOO_LONG,
      })
      .optional()
      .refine(
        (value: string | undefined) => {
          return !value || EMAIL_REGEX.test(value)
        },
        {
          message: MESSAGE.INVALID_EMAIL,
        }
      ),
    message: z.string(),
    type: z.string(),
  })

  type FormValues = z.infer<typeof formSchema>

  const defaultValues: Partial<FormValues> = {
    email: '',
    message: '',
    type: feedbackTypes.length ? feedbackTypes[0].value : '',
  }

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  })

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    if (form.formState.isDirty) {
      if (values.message) {
        const formData = new FormData()
        formData.append('images', image)
        formData.append('type', values.type)
        formData.append('path', window?.location?.pathname)
        formData.append('message', values.message)
        formData.append('email', values.email || '')
        formData.append('organization', organizationId)

        await axios
          .post(`${Environment.serverUrl}/v1/feedback`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            onSubmitSuccess()
          })
          .catch(error => {
            onSubmitError(error)
          })
      }
    }
  }

  // useClickOutside(menuRef, () => setOpen(false))

  const toggle = () => {
    if (open) {
      close()
    } else {
      setOpen(!open)
    }
  }

  const close = () => {
    setOpen(false)
    resetFormState()
  }

  const selectType = value => () => {
    setType(value)
  }

  const resetFormState = () => {
    form.reset()
    setError(false)
    setSending(false)
    setSent(false)
    setImage(null)
  }

  const onSubmitSuccess = () => {
    setSending(false)
    setSent(true)
    setError(false)
    setTimeout(() => {
      setSent(false)
    }, timeout)
  }

  const onSubmitError = (error: any) => {
    setSending(false)
    setError(determineErrorType(error?.response))
    setTimeout(() => {
      setError(false)
    }, timeout)
  }

  const determineErrorType = err => {
    if (!err) return 'Unexpected'

    if (typeof err === 'string') return err

    switch (err?.status) {
      case 400:
        return 'Bad request'
      case 403:
        return 'Forbidden'
      case 404:
        return 'Not found'
      case 410:
        return 'Archived'
      case 500:
        return 'Internal Server Error'
      default:
        return 'Unexpected'
    }
  }

  const attachImage = event => {
    const { files } = event.target

    const file = files[0]
    file.preview = window.URL.createObjectURL(file)
    setUploadingImage(true)
    setImage(file)
    setUploadingImage(false)
  }

  const RenderImage = () => {
    return (
      <FormItem className='mb-3	relative'>
        <Label className='text-black'>{t('attach.image.text')}</Label>
        {image?.preview ? (
          RenderPreview()
        ) : (
          <Input
            className='bg-background text-black'
            type='file'
            id='imageUpload'
            accept='image/*'
            onChange={attachImage}
          />
        )}
      </FormItem>
    )
  }

  const removeImage = event => {
    if (event) event.preventDefault()
    setImage(null)
    setUploadingImage(false)
  }

  const RenderPreview = () => {
    if (!image?.preview) return null

    return (
      <div
        className='hover:bg-blend-darken flex rounded-md bg-cover bg-center relative w-full h-[140px] mt-3'
        style={{
          backgroundImage: `url(${image?.preview})`,
        }}
      >
        {uploadingImage ? (
          <div className='inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'>
            <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
              Loading...
            </span>
          </div>
        ) : (
          <div className='absolute top-0 right-0 bottom-0 left-0 text-center opacity-0 hover:opacity-100'>
            <span
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 text-[12px] uppercase text-red-600 cursor-pointer hover:bg-blend-overlay'
              onClick={removeImage}
            >
              Remove
            </span>
          </div>
        )}
      </div>
    )
  }

  // Return nothing if the component has been disabled
  if (disabled) return null

  let submitLabel = t('send.feedback.text')

  if (sent) submitLabel = t('Sent')
  if (sending && !sent) submitLabel = t('Sending')
  if (error) submitLabel = error

  return (
    <div
      ref={menuRef}
      className={cn(
        'fixed z-[99999998] bottom-[12px] right-0 m-[1em] text-left font-[400]'
      )}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div
            className={cn(
              'hidden bg-[#f4f4f7] relative z-[999999999] rounded-[4px] w-[380px] bottom-[65px] right-0 shadow-[0_6px_30px_2px_rgba(34,44,79,0.3)] [animationFadeOutDown]',
              open && 'block'
            )}
          >
            <div className='flex text-white	bg-primary py-3 px-4 rounded-t-[3px] text-[14px] items-center'>
              <MessageSquarePlus className='mr-1.5' />
              <span>{t('Feedback')}</span>
              <button
                className='cursor-pointer opacity-70 text-white ml-auto text-[11px] hover:opacity-100'
                onClick={close}
              >
                <X width={'16px'} />
              </button>
            </div>

            <div className='p-3'>
              <FormField
                control={form.control}
                name='email'
                key='email'
                render={({ field }) => (
                  <FormItem className='mb-3 relative'>
                    <Label className='text-black'>{t('Email')}</Label>
                    <FormControl>
                      <Input className='text-black' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormItem className='mb-3 relative'>
                <Label className='text-black'>{t('feedback.type.text')}</Label>
                <ul className='flex list-none p-0 m-0 mb-[0.85em]'>
                  {feedbackTypes?.map(item => (
                    <li
                      key={item.value}
                      className={cn(
                        'text-black w-full bg-background select-none text-center p-3 text-[13px] cursor-pointer whitespace-nowrap overflow-hidden truncate first-of-type:rounded-l-md first-of-type:-mr-[1px] last-of-type:rounded-r-md last-of-type:-ml-[1px] border border-[#d0d8e1]',
                        type === item.value &&
                          'bg-primary text-white border-primary'
                      )}
                      title={item.label}
                      onClick={selectType(item.value)}
                    >
                      {item.label}
                    </li>
                  ))}
                </ul>
                <FormMessage />
              </FormItem>

              <FormField
                control={form.control}
                name='message'
                key='message'
                render={({ field }) => (
                  <FormItem className='mb-3	relative'>
                    <Label className='text-black'>
                      {t('feedback.message.text')}
                    </Label>
                    <FormControl>
                      <Textarea
                        id='message'
                        placeholder={t('feedback.message.placeholder')}
                        className='resize-none text-black'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Only render the image upload if there's callback available  */}
              {allowImage && RenderImage()}

              <Button
                type='submit'
                disabled={
                  sending ||
                  !form.formState.isDirty ||
                  form.formState.isLoading ||
                  form.formState.isSubmitting ||
                  form.formState.isValidating
                }
                variant={error ? 'destructive' : 'default'}
                className={cn(
                  'w-full mt-3 uppercase',
                  sent && 'bg-green-600 hover:bg-green-700'
                )}
              >
                {submitLabel}
              </Button>
            </div>
          </div>

          <Button
            className={cn(
              'flex items-center absolute right-0 bottom-0 whitespace-nowrap cursor-pointer transition hover:shadow-[0_6px_16px_2px_rgba(0,0,0,0.2)] [hover:transform:translateY(-1px)]'
            )}
            onClick={toggle}
          >
            {!open ? <MessageSquarePlus /> : <X />}
          </Button>
        </form>
      </Form>
    </div>
  )
}

export default UserFeedback
