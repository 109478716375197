import React, { FC, useState } from 'react'
import { Field, Form, styled } from 'utils/adapters'

import { useTranslation } from 'react-i18next'
import Modal from 'components/molecules/Modal'
import { Button } from '@/components/ui/button'
import { useMutationNewsletter, useMutationSendTestMail } from './mixins'
import { FileType, IEvent } from 'models'
import { useAppSelector } from 'utils/hooks'
import { useFormState } from 'react-final-form'
import { toast } from '@/components/ui/use-toast'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { Icons } from '@/components/ui/icon'
import {
  composeValidators,
  emailValidation,
  maxLengthValidation,
  requiredValidation,
} from '@/utils/helpers'

type Action = 'newsletter' | 'create-event' | 'update-event'

interface IProps {
  disabled: boolean
  action: Action
  files: FileType[]
  eventId?: string
  event?: IEvent
  organizationId?: string
  sendQrCode?: boolean
  subject?: string
}

export const TestEmailNewsletterContainer: FC<IProps> = ({
  disabled,
  action,
  files,
  eventId,
}) => {
  const { values } = useFormState()
  return (
    <TestEmail
      disabled={disabled}
      action={action}
      files={files}
      eventId={eventId}
      subject={values.subject}
    />
  )
}

const TestEmail: FC<IProps> = ({
  disabled,
  action,
  files,
  eventId,
  event,
  organizationId,
  sendQrCode,
  subject,
}) => {
  const { t } = useTranslation()
  const [isOpenModalSendTest, setIsOpenModalSendTest] = useState(false)

  const handleOpenPopup = () => {
    subject
      ? setIsOpenModalSendTest(true)
      : action === 'newsletter'
      ? toast({
          title: t('Error'),
          description: t('Subject is required'),
          variant: 'destructive',
        })
      : setIsOpenModalSendTest(true)
  }
  return (
    <Wrapper>
      <Button
        className='w-full'
        // inputColor='fill'
        variant='outline'
        name={t('Send Test Email')}
        type={'button'}
        disabled={disabled}
        onClick={handleOpenPopup}
      >
        {t('Send Test Email')}
      </Button>
      <StyledModal
        isShow={isOpenModalSendTest}
        hideFooter
        onClose={() => setIsOpenModalSendTest(false)}
      >
        <ContentSendTest
          action={action}
          files={files}
          eventId={eventId}
          subject={subject}
          event={event}
          organizationId={organizationId}
          sendQrCode={sendQrCode}
          closeModal={() => setIsOpenModalSendTest(false)}
        />
      </StyledModal>
    </Wrapper>
  )
}

type ContentSendTestMailProps = {
  action: Action
  files: FileType[]
  eventId: string | undefined
  subject: string | undefined
  event: IEvent | undefined
  organizationId: string | undefined
  sendQrCode: boolean | undefined
  closeModal: () => void
}
const ContentSendTest: FC<ContentSendTestMailProps> = ({
  action,
  files,
  eventId,
  subject,
  event,
  organizationId,
  sendQrCode,
  closeModal,
}) => {
  const { t } = useTranslation()
  const { selectedRecipents } = useAppSelector(state => state.userReducer)
  const { mutateSendTestEmailNewsletter } = useMutationNewsletter()
  const { mutateSendTestMail } = useMutationSendTestMail()
  const { formData } = useAppSelector(state => state.eventsReducer)
  const { newLetter } = useAppSelector(state => state.eventsReducer)

  const onFinish = (values: any) => {
    switch (action) {
      case 'newsletter':
        mutateSendTestEmailNewsletter({
          variables: {
            eventNewsletterInput: {
              eventId,
              attendees: selectedRecipents,
              content: newLetter,
              subject,
              attachments: files,
            },
            email: values.email,
          },
        })
        closeModal()
        break
      case 'create-event':
        mutateSendTestMail({
          variables: {
            sendTestMailInput: {
              action: 'CreateEvent',
              content: formData.emailContent,
              attachments: files,
              email: values.email,
              event,
              organization: organizationId,
              sendQrCode,
            },
          },
        })
        closeModal()
        break
      case 'update-event':
        mutateSendTestMail({
          variables: {
            sendTestMailInput: {
              eventId,
              action: 'UpdateEvent',
              content: formData.emailContent,
              attachments: files,
              email: values.email,
              organization: organizationId,
              sendQrCode,
            },
          },
        })
        closeModal()
        break
      default:
        console.log('default')
        break
    }
  }

  return (
    <Form
      onSubmit={onFinish}
      initialValues={{
        email: '',
      }}
      subscription={{
        submitting: true,
      }}
      render={({ handleSubmit, pristine, submitting, invalid }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              name='email'
              required
              validate={composeValidators(
                emailValidation,
                maxLengthValidation,
                requiredValidation
              )}
              formatOnBlur
            >
              {({ input, meta }: any) => (
                <FormItem>
                  <FormLabel meta={meta} isRequired>
                    {t('Email')}
                  </FormLabel>
                  <Input {...input} type='email' maxLength='150' />
                </FormItem>
              )}
            </Field>
            <Button
              className='w-full mt-[20px]'
              type='button'
              disabled={submitting || pristine || invalid}
              onClick={event => {
                event.preventDefault()
                handleSubmit()
              }}
            >
              {submitting && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('Submit')}
            </Button>
          </form>
        )
      }}
    />
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledModal = styled(Modal)`
  padding: 13px 5px;
`

export default TestEmail
