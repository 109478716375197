import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Select from 'react-select'
import { CREATE_USER } from 'graphql/mutations'
import { requiredEmailValidation, trimWhiteSpace } from 'utils/helpers'

import { GET_ALL_USERS } from 'graphql/queries'
import {
  getAllRoles,
  getAllScopes,
  getEventOrganizerScopes,
  getSelectedUser,
  transformUserRole,
  updateUser,
} from '../mixins'

import {
  Field,
  Form,
  OnChange,
  useHistory,
  useMutation,
  useParams,
} from 'utils/adapters'
import { useOrganizationIdVar } from 'utils/hooks'

import { IUser } from 'models/IUser'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import Loading from 'components/atoms/Loading'
import {
  ADMIN_DEFAULT_SCOPES,
  USER_ROLE,
  USER_SCOPE,
  USER_SCOPE_HELP_MESSAGE,
} from 'constants/userRole'
import { toast } from '@/components/ui/use-toast'
import { ToastAction } from '@/components/ui/toast'
import { Icons } from '@/components/ui/icon'
import { PATH_NAME } from '@/routes/routesMap'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import HelpText from '@/components/atoms/HelpText'

const SelectAdapter = ({ input, ...rest }: any) => (
  <Select {...input} {...rest} />
)

type SelectProps = {
  value: string
  label: string
}

type ParamsType = {
  id: string
}

const ADMIN_SCOPES = [
  ADMIN_DEFAULT_SCOPES.ATTENDEE_CHECKIN,
  ADMIN_DEFAULT_SCOPES.USER_READ,
  ADMIN_DEFAULT_SCOPES.USER_UPDATE,
  ADMIN_DEFAULT_SCOPES.USER_CREATE,
  ADMIN_DEFAULT_SCOPES.USER_DELETE,
  ADMIN_DEFAULT_SCOPES.USER_ACTIVE,
  // ADMIN_DEFAULT_SCOPES.EVENT_DELETE,
  ADMIN_DEFAULT_SCOPES.EVENT_READ,
  ADMIN_DEFAULT_SCOPES.EVENT_UPDATE,
  ADMIN_DEFAULT_SCOPES.ATTENDEE_REFUND,
  ADMIN_DEFAULT_SCOPES.ATTENDEE_REMOVE,
]

const EVENT_ORGANISER_DEFAULT_SCOPES = [USER_SCOPE.EVENT_READ]

const UserForm: FC = () => {
  const { t } = useTranslation()

  const { organizationId } = useOrganizationIdVar()

  const { id } = useParams<ParamsType>()
  const history = useHistory()

  const [user, setUser] = useState<IUser>()
  const [stateScopes, setStateScopes] = useState<string[]>([])
  const [isShowPassword, setIsShowPassword] = useState(false)
  const { data, loading } = getSelectedUser(id)
  const [finalFormData, setFinalFormData] = useState<
    Partial<IUser> & { password: string }
  >()

  const { data: SCOPES, loading: scopeLoading } = getAllScopes()
  const { data: EVENT_ORGANIZER_SCOPES, loading: eventOrganizerScopeLoading } =
    getEventOrganizerScopes()
  const { data: ROLES, loading: roleLoading } = getAllRoles()
  const { mutateUpdateUser, loading: updateUserLoading } = updateUser()
  const { mutateTransformUserRole, loading: transformUserRoleLoading } =
    transformUserRole(organizationId)

  const required = (value: any) =>
    value ? undefined : t('This field is required')

  useEffect(() => {
    if (data) {
      const { user } = data
      setUser({
        ...user,
        roles: user.roles?.map((role: string) => {
          return {
            label: role,
            value: role,
          }
        }) as [],
      })
    }
  }, [data])

  useEffect(() => {
    if (SCOPES) setStateScopes(SCOPES?.getAllScopes?.data)
    if (user) {
      if (user?.roles?.length > 0 && user.roles[0].value === USER_ROLE.ADMIN) {
        setStateScopes(SCOPES?.getAllScopes?.data)
      } else {
        setStateScopes(EVENT_ORGANIZER_SCOPES?.getEventOrganizerScopes?.data)
      }
    }
  }, [user, SCOPES, EVENT_ORGANIZER_SCOPES])

  const handleClickTransformUserRole = () => {
    mutateTransformUserRole({
      variables: {
        transformUserRole: {
          ...finalFormData,
          organization: organizationId,
        },
      },
    })
  }

  const [mutateCreateUser, { loading: createUserLoading }] = useMutation(
    CREATE_USER,
    {
      onCompleted: () => {
        toast({
          description: t('Create user success!'),
        })
        history.push(PATH_NAME.USERS_MANAGEMENT)
      },

      update: (cache, { data: { createUser } }) => {
        if (organizationId) {
          const existingData = cache.readQuery<any>({
            query: GET_ALL_USERS,
            variables: { organizationId },
          })

          if (existingData && existingData.users) {
            cache.writeQuery({
              query: GET_ALL_USERS,
              data: { users: [...existingData.users, createUser] },
              variables: { organizationId },
            })
          }
        } else {
          const existingData = cache.readQuery<any>({
            query: GET_ALL_USERS,
          })

          if (existingData && existingData.users) {
            cache.writeQuery({
              query: GET_ALL_USERS,
              data: { users: [...existingData.users, createUser] },
            })
          }
        }
      },

      onError: err => {
        if (err.message === 'USER_IS_ATTENDEE') {
          toast({
            title: t('Warning'),
            description: t('users.already_attendee_warn'),
            variant: 'warning',
            action: (
              <ToastAction
                onClick={handleClickTransformUserRole}
                altText='Continue'
                className={
                  'border border-background bg-background hover:bg-accent hover:text-accent-foreground text-orange-700'
                }
              >
                {t('Continue')}
              </ToastAction>
            ),
          })
        } else {
          toast({
            title: t('Error'),
            description: t(err.message),
            variant: 'destructive',
          })
        }
      },
    }
  )

  const onSubmit = (values: any) => {
    const formData = {
      firstName: values.firstName ? values.firstName : '',
      lastName: values.lastName ? values.lastName : '',
      email: values.email,
      scopes: values.scopes ? values.scopes : [],
      company: values.company ? values.company : '',
      roles: values.roles.value
        ? [].concat(values.roles.value)
        : user?.roles[0]['value' as unknown as number],
    }

    setFinalFormData({
      ...formData,
      password: values.password,
    })

    const rootAdminFormData = {
      ...formData,
      organization: organizationId,
    }
    if (id) {
      mutateUpdateUser({
        variables: { user: organizationId ? rootAdminFormData : formData },
      })
    } else {
      mutateCreateUser({
        variables: {
          createUserInput: organizationId
            ? {
                ...rootAdminFormData,
                password: values.password,
              }
            : {
                ...formData,
                password: values.password,
              },
        },
      })
    }
  }

  if (loading || scopeLoading || roleLoading || eventOrganizerScopeLoading) {
    return <Loading />
  }

  const setScopes = (role: SelectProps[], state: any) => {
    const field = state.fields['scopes']
    const adminScopes = SCOPES?.getAllScopes?.data
    const eventOrganizerScopes =
      EVENT_ORGANIZER_SCOPES?.getEventOrganizerScopes?.data

    if (id) {
      if (role && role[0].value === USER_ROLE.ADMIN) {
        setStateScopes(adminScopes)
        field?.change(ADMIN_SCOPES)
      } else if (user && user.roles && !role[1]) {
        if (user.roles[0].value === USER_ROLE.ADMIN) {
          setStateScopes(adminScopes)
          field?.change(user.scopes)
        } else {
          setStateScopes(eventOrganizerScopes)
          field?.change(user.scopes)
        }
      } else if (role && role[0].value !== USER_ROLE.ADMIN) {
        setStateScopes(eventOrganizerScopes)
        field?.change(EVENT_ORGANISER_DEFAULT_SCOPES)
      }
    } else {
      /**
       * case create user
       */
      if (role && role[0].value === USER_ROLE.ADMIN) {
        setStateScopes(adminScopes)
        field?.change(ADMIN_SCOPES)
      } else {
        setStateScopes(eventOrganizerScopes)
        field?.change(EVENT_ORGANISER_DEFAULT_SCOPES)
      }
    }
  }

  const checkDisabledDefaultScopes = (
    item: { value: string },
    values: IUser
  ) => {
    if (values && values.roles) {
      if (values.roles.value === USER_ROLE.ADMIN) {
        return ADMIN_SCOPES.includes(item.value)
      } else if (values.roles.value === USER_ROLE.EVENT_ORGANISER) {
        return EVENT_ORGANISER_DEFAULT_SCOPES.includes(item.value)
      }
    }
    if (user) {
      if (user.roles[0].value === USER_ROLE.ADMIN) {
        return ADMIN_SCOPES.includes(item.value)
      }
      if (user.roles[0].value === USER_ROLE.EVENT_ORGANISER) {
        return EVENT_ORGANISER_DEFAULT_SCOPES.includes(item.value)
      }
    }
  }

  return (
    <div className='mx-auto p-6 space-y-8'>
      <h2 className='text-3xl font-bold tracking-tight'>
        {id ? t('Update') : t('Create')} {t('User Information')}
      </h2>
      <Form
        onSubmit={onSubmit}
        initialValues={user}
        mutators={{ setScopes }}
        render={({ form, handleSubmit, submitting, pristine, values }) => (
          <form
            onSubmit={handleSubmit}
            autoComplete='off'
            className='space-y-8'
          >
            <OnChange name='roles'>
              {(value, previous) => {
                form.mutators.setScopes(value, previous)
              }}
            </OnChange>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <Field
                name='firstName'
                type='text'
                format={trimWhiteSpace}
                formatOnBlur
              >
                {({ input, meta }: any) => (
                  <FormItem>
                    <FormLabel meta={meta}>{t('First Name')}</FormLabel>
                    <Input {...input} maxLength='255' className='w-full' />
                  </FormItem>
                )}
              </Field>

              <Field
                name='lastName'
                type='text'
                format={trimWhiteSpace}
                formatOnBlur
              >
                {({ input, meta }: any) => (
                  <FormItem>
                    <FormLabel meta={meta}>{t('Last Name')}</FormLabel>
                    <Input {...input} maxLength='255' className='w-full' />
                  </FormItem>
                )}
              </Field>

              <Field
                name='email'
                type='text'
                validate={requiredEmailValidation}
                format={trimWhiteSpace}
                formatOnBlur
              >
                {({ input, meta }: any) => (
                  <FormItem>
                    <FormLabel meta={meta} isRequired>
                      {t('Email')}
                    </FormLabel>
                    <Input
                      {...input}
                      disabled={id ? true : false}
                      label={t('Email')}
                      meta={meta}
                      isRequired={true}
                      value={input.value || ''}
                      className='w-full'
                    />
                  </FormItem>
                )}
              </Field>

              {!id && (
                <Field
                  name='password'
                  type={isShowPassword ? 'text' : 'password'}
                  validate={required}
                  format={trimWhiteSpace}
                  formatOnBlur
                >
                  {({ input, meta }: any) => (
                    <FormItem>
                      <FormLabel meta={meta}>{t('Password')}</FormLabel>
                      <Input {...input} meta={meta} isRequired={true} />
                    </FormItem>
                  )}
                </Field>
              )}

              <Field
                name='company'
                type='text'
                format={trimWhiteSpace}
                formatOnBlur
              >
                {({ input, meta }: any) => (
                  <FormItem>
                    <FormLabel meta={meta}>{t('Company')}</FormLabel>
                    <Input {...input} maxLength={255} className='w-full' />
                  </FormItem>
                )}
              </Field>
            </div>

            <div className='space-y-6'>
              <Field name='roles' validate={required}>
                {({ input, meta }) => (
                  <FormItem>
                    <FormLabel meta={meta} isRequired>
                      {t('Role')}
                    </FormLabel>
                    <SelectAdapter
                      input={input}
                      {...input}
                      meta={meta}
                      options={
                        ROLES?.getAllRoles?.data?.map((role: string) => ({
                          value: role,
                          label: role,
                        })) || []
                      }
                    />
                  </FormItem>
                )}
              </Field>

              <fieldset className='space-y-4'>
                <FormLabel className='text-lg font-semibold'>
                  {t('Permissions')}
                </FormLabel>
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6'>
                  {stateScopes?.map((scope: string, index: number) => (
                    <Field
                      key={index}
                      name='scopes'
                      type='checkbox'
                      value={scope}
                    >
                      {({ input }) => (
                        <div className='relative flex items-start'>
                          <div className='flex items-center h-5'>
                            <input
                              {...input}
                              type='checkbox'
                              disabled={checkDisabledDefaultScopes(
                                {
                                  value: input.value,
                                },
                                values
                              )}
                              className='h-5 w-5 rounded border-gray-300 text-primary focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-background'
                            />
                          </div>
                          <div className='ml-3 text-sm'>
                            <HelpText
                              text={scope}
                              helpMessage={USER_SCOPE_HELP_MESSAGE[scope]}
                            />
                          </div>
                        </div>
                      )}
                    </Field>
                  ))}
                </div>
              </fieldset>
            </div>

            <Button
              type='submit'
              disabled={
                submitting ||
                pristine ||
                createUserLoading ||
                transformUserRoleLoading ||
                updateUserLoading
              }
              className='w-full'
            >
              {(createUserLoading ||
                transformUserRoleLoading ||
                updateUserLoading ||
                submitting) && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {id
                ? t('user_management.modify_user_account')
                : t('user_management.add_new_user')}
            </Button>
          </form>
        )}
      />
    </div>
  )
}

export default UserForm
