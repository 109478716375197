import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'

import { FC } from 'react'

import { styled } from 'utils/adapters'

type TabItemProps = {
  itemName: string
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
}

const TabItem: FC<TabItemProps> = ({ itemName, active, onClick }) => {
  return (
    <>
      <Wrapper active={active} onClick={onClick}>
        <span>{itemName}</span>
      </Wrapper>
    </>
  )
}

const activeNavItem = (active?: boolean) => {
  if (active) {
    return `
      background-color: ${COLORS.BLUE};
      color: #fff;
      border: 1px solid ${COLORS.BLUE}
    `
  } else {
    return `
      background-color: #ECEFF0;
      color: #4a4a4a;
      border: 1px solid #ECEFF0
      `
  }
}

const Wrapper = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  max-width: 200px;
  flex: 1 1 auto;
  font-size: 1.5em;
  box-sizing: border-box;
  font-weight: bold;
  cursor: pointer;
  &:first-child {
    border-radius: 50px 0 0 50px;
    ${({ active }) => activeNavItem(active)};
  }
  &:last-child {
    border-radius: 0 50px 50px 0;
    ${({ active }) => activeNavItem(active)}
  }
  ${responsive.sm`
    font-size: 1em;
  `}
`

export default TabItem
