import { CURRENCY } from '@/constants/currency'
import { Currency } from '@/models/IEvent'

export const renderSymbolCurrency = (currency?: string | Currency) => {
  switch (currency) {
    case CURRENCY.USD:
      return '\u0024'
    case CURRENCY.EUR:
      return '\u20AC'
    default:
      return '\u0024'
  }
}
