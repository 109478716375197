import { gql } from 'utils/adapters'

export const LOGIN_MUTATION = gql`
  mutation Login($user: AuthLoginInput!) {
    login(user: $user) {
      _id
      accessToken
      company
      email
      firstName
      lastName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
      organization {
        force2FAEnabled
      }
      refreshToken
      roles
      scopes
      userName
    }
  }
`

export const FETCH_TOKEN = gql`
  mutation FetchToken {
    fetchToken {
      accessToken
    }
  }
`
