import * as React from 'react'
import { FC, useState } from 'react'

import { useAppSelector } from 'utils/hooks'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { DataTableToolbar } from '@/components/organisms/GenericTable/GenericTableToolbar'
import { useTranslation } from 'react-i18next'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  SimplePaginationPrevious,
  SimplePaginationLink,
  SimplePaginationNext,
} from '@/components/ui/pagination'
import { pageSizeOptions } from '@/constants'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { ArrowDownIcon, ArrowUpIcon, ChevronsUpDownIcon } from 'lucide-react'

type PropsType = {
  columns: any
  data: any
  noDataText?: string
  fetchData?: any
  pageCount: number
  pageName: string
  loading?: boolean
  searchColumns?: string[]
  filterColumns?: {
    name: string
    header: string
    options: {
      label: string
      value: string
      icon?: React.ComponentType<{ className?: string }>
    }[]
  }[]
  toolbar?: React.ReactNode
}

const GenericTable: FC<PropsType> = ({
  columns,
  data,
  noDataText,
  fetchData,
  pageCount,
  pageName,
  loading,
  searchColumns,
  filterColumns,
  toolbar,
}) => {
  const { t } = useTranslation()
  const { resetPageFlag } = useAppSelector(state => state.userReducer)
  const _pageIndex = Number(localStorage.getItem(pageName) as string)

  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   prepareRow,
  //   page,
  //   canPreviousPage,
  //   canNextPage,
  //   pageOptions,
  //   gotoPage,
  //   nextPage,
  //   previousPage,
  //   setPageSize,
  //   state: { pageIndex, pageSize }
  // } = useTable(
  //   {
  //     manualPagination: true,
  //     autoResetPage: false,
  //     columns,
  //     data,
  //     pageCount,
  //     initialState: {
  //       pageIndex: _pageIndex
  //     }
  //   },
  //
  //   usePagination
  // )

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      // columnVisibility,
      // rowSelection,
      columnFilters,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  // useEffect(() => {
  //   if (resetPageFlag && _pageIndex === 0) {
  //     gotoPage(0)
  //   }
  // }, [_pageIndex])
  //
  // useEffect(() => {
  //   localStorage.setItem(pageName, pageIndex.toString())
  // }, [pageIndex])
  //

  // const { pageSize, pageIndex } = table.getState().pagination

  // useEffect(() => {
  //   fetchData({ pageIndex, pageSize })
  // }, [fetchData, pageIndex, pageSize])

  return (
    <>
      <div className='space-y-4'>
        <DataTableToolbar
          table={table}
          searchColumns={searchColumns}
          filteredColumns={filterColumns}
          toolbar={toolbar}
        />
        <div className='rounded-md border'>
          <Table>
            <TableHeader className='bg-gray-100 border-b'>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow
                  key={headerGroup.id}
                  className='hover:bg-gray-200 transition-colors'
                >
                  {headerGroup.headers.map((header, i) => (
                    <TableHead
                      key={i}
                      colSpan={header.colSpan}
                      className='py-3 px-4 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider'
                    >
                      {header.isPlaceholder ? null : (
                        <div className='flex items-center space-x-2'>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {header.column.getCanSort() && (
                            <Button
                              variant='ghost'
                              size='sm'
                              className='ml-2 h-8 w-8 p-0'
                              onClick={header.column.getToggleSortingHandler()}
                            >
                              <span className='sr-only'>{t('Sort')}</span>
                              {header.column.getIsSorted() === 'asc' ? (
                                <ArrowUpIcon className='h-4 w-4' />
                              ) : header.column.getIsSorted() === 'desc' ? (
                                <ArrowDownIcon className='h-4 w-4' />
                              ) : (
                                <ChevronsUpDownIcon className='h-4 w-4' />
                              )}
                            </Button>
                          )}
                        </div>
                      )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>

            {loading ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className='h-24 text-center'
                  >
                    <div className='flex justify-center items-center'>
                      <svg
                        className='animate-spin h-5 w-5 mr-3 text-primary'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-25'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                          fill='none'
                        />
                        <path
                          className='opacity-75'
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        />
                      </svg>
                      {t('Loading')}...
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row: any, i: number) => {
                    return (
                      <TableRow className='table-organisms__body__row' key={i}>
                        {row.getVisibleCells().map(cell => {
                          return (
                            <TableCell key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className='h-24 text-center'
                    >
                      <div className='flex flex-col items-center justify-center space-y-2'>
                        <svg
                          className='w-12 h-12 text-gray-400'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={2}
                            d='M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                          />
                        </svg>
                        <p className='font-medium text-gray-500'>
                          {noDataText}
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </div>
      </div>
      {/* pagination */}
      <Pagination className='gap-2 mt-6'>
        <PaginationContent className='mr-2.5'>
          <PaginationItem>
            <SimplePaginationPrevious
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />
          </PaginationItem>
          <PaginationItem>
            <SimplePaginationLink>
              {table.getState().pagination.pageIndex + 1}
            </SimplePaginationLink>
          </PaginationItem>
          <PaginationItem>
            <SimplePaginationNext
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />
          </PaginationItem>
        </PaginationContent>
        <Select
          value={table.getState().pagination.pageSize.toString()}
          onValueChange={value => {
            table.setPageSize(Number(value))
          }}
        >
          <SelectTrigger className='w-[180px]'>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {pageSizeOptions.map(pageSize => (
                <SelectItem key={pageSize} value={pageSize.toString()}>
                  {t('Show')} {pageSize}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </Pagination>
    </>
  )
}

export default GenericTable
