export enum COLORS {
  BLUE = '#2C506C',
  WHITE = '#fff',
  BACKGROUND_DISABLED = 'rgb(227 227 227)',
  COLOR_DISABLED = 'rgb(112 112 112)',
  ERROR = '#FF1B1B',
  SUCCESS = '#00b74a',
  DANGER = '#d9534f',
  INFO = '#0070f3',
  WARNING = '#ff9700',
  ICON_BACKGROUND = '#6c8599',
  BORDER_FIELD = '#d8d8d8',
  PRIMARY = '#050505',
  BORDER_IMAGE = '#d3d3d3',
  FONT_COLOR = '#181818',
  PENDING = '#F29339',
  PAYMENT_FEE_COLOR = '#e4edf4',
  BLACK = '#000000',
}

export enum DnD {
  LIST_BACKGROUND = '#ebecf0',
  LIST_DRAGGINGOVER = '#d5d5d5',
  ITEM_BACKGROUND = '#fff',
  ITEM_SHADOW = '#091e4240',
  ITEM_HOVER = '#f4f5f7',
}

export const PRIMARY_COLOR = [
  {
    label: 'DarkBlue',
    value: '#2C506C',
  },
  {
    label: 'Ocean',
    value: '#1DA2D8',
  },
  {
    label: 'Red',
    value: '#FF0000',
  },
  {
    label: 'Green',
    value: '#4CA64C',
  },
  {
    label: 'Orange',
    value: '#FFB732',
  },
  {
    label: 'Purple',
    value: '#A64CA6',
  },
  {
    label: 'Gray',
    value: '#8C8C8C',
  },
]

export enum ATTENDANT_STATUS {
  INVITED = '#6e8b3d',
  IMPORTED = '#303030',
  PAYMENT_PROCESSING = '#ffb758',
  REGISTERD = '#56f000',
  CHECKED_IN = '#2dccff',
  CANCELLED = '#ff3838',
  CANCELLED_REFUNDED = '#ff7373',
  CANCELLED_WAITING = '#e4c300',
  CANCELLED_REFUSED = '#ff7373',
  INVITATION_SENT_OUT = '#00688b',
}

export enum IMAGES {
  NONE = 'none',
  RED = 'invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%)',
}
