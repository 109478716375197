import CryptoJS from 'crypto-js'
const secretKey = import.meta.env.VITE_ENCRYPT_KEY
export const encryptString = (role: string) => {
  const encrypted = CryptoJS.AES.encrypt(role, secretKey).toString()
  return encrypted
}

export const decryptString = (encryptedRole: string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedRole, secretKey).toString(
    CryptoJS.enc.Utf8
  )
  return decrypted
}

export const encryptArray = (array: string[]) => {
  const encryptedRoles = array?.map((role: string) => {
    const encrypted = encryptString(role)
    return encrypted
  })
  return encryptedRoles
}

export const decryptArray = (array: string[]) => {
  const decryptedRoles = array?.map((role: string) => {
    const decrypted = decryptString(role)
    return decrypted
  })
  return decryptedRoles
}
