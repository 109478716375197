import React from 'react'

import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'
import { styled } from 'utils/adapters'

const FONT_COLOR = '#181818'

const Content: React.FC = () => {
  return (
    <Wrapper>
      <H1>Impressum</H1>
      <span>ConferenceDirect Germany GmbH</span> <br />
      <span>Große Gallusstraße 16-18</span> <br />
      <span>60312, Frankfurt am Main</span>
      <br />
      <Link>E-Mail: okan.serinken@conferencedirect.com</Link> <br />
      <span>Verantwortlich für den Inhalt (gem. § 55 Abs. 2 RStV):</span> <br />
      <span>Geschäftsführer Okan Serinken</span> <br />
      <H3>Disclaimer – rechtliche Hinweise</H3>
      <p>
        <span>§ 1 Haftungsbeschränkung</span> <br />
        <span>
          Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt
          erstellt. Der Anbieter übernimmt jedoch keine Gewähr für die
          Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten
          Inhalte. Die Nutzung der Inhalte der Website erfolgt auf eigene Gefahr
          des Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des
          jeweiligen Autors und nicht immer die Meinung des Anbieters wieder.
          Mit der reinen Nutzung der Website des Anbieters kommt keinerlei
          Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.
        </span>{' '}
        <br />
      </p>{' '}
      <br />
      <p>
        <span>§ 2 Externe Links</span> <br />
        <span>
          Diese Website enthält Verknüpfungen zu Websites Dritter (“externe
          Links”). Diese Websites unterliegen der Haftung der jeweiligen
          Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der
          externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
          Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße
          ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und
          zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das
          Setzen von externen Links bedeutet nicht, dass sich der Anbieter die
          hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht. Eine
          ständige Kontrolle der externen Links ist für den Anbieter ohne
          konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Kenntnis von
          Rechtsverstößen werden jedoch derartige externe Links unverzüglich
          gelöscht.
        </span>{' '}
        <br />
      </p>
      <br />
      <p>
        <span>§ 3 Urheber- und Leistungsschutzrechte</span>
        <br />
        <span>
          Die auf dieser Website veröffentlichten Inhalte unterliegen dem
          deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
          Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf
          der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen
          Rechteinhabers. Dies gilt insbesondere für Vervielfältigung,
          Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe
          von Inhalten in Datenbanken oder anderen elektronischen Medien und
          Systemen. Inhalte und Rechte Dritter sind dabei als solche
          gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
          einzelner Inhalte oder kompletter Seiten ist nicht gestattet und
          strafbar. Lediglich die Herstellung von Kopien und Downloads für den
          persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt
        </span>{' '}
        <br /> <br />
        <span>
          Die Darstellung dieser Website in fremden Frames ist nur mit
          schriftlicher Erlaubnis zulässig.
        </span>
      </p>
      <br />
      <p>
        <span>§ 4 Besondere Nutzungsbedingungen</span>
        <br />
        <span>
          Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von
          den vorgenannten Paragraphen abweichen, wird an entsprechender Stelle
          ausdrücklich darauf hingewiesen. In diesem Falle gelten im jeweiligen
          Einzelfall die besonderen Nutzungsbedingungen.
        </span>
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1140px;
  margin: auto;
  padding: 30px 10px;
  box-sizing: border-box;
  line-height: 1.618 !important;
  word-wrap: break-word;
  color: ${FONT_COLOR};
  ${responsive.sm`
    font-size: 12px;
  `}
`

const H1 = styled.h1`
  font-size: 37px;
  font-weight: 500;
  padding-bottom: 16px;
  ${responsive.sm`
    font-size: 20px;
  `}
`

const H3 = styled.h3`
  font-weight: 500;
  line-height: 1.618;
  padding: 16px 0 8px 0;
`

const Link = styled.span`
  color: ${COLORS.BLUE};
  padding: 10px 0;
`

export default Content
