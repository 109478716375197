import ObjectID from 'bson-objectid'

export * from './checkCapacity'
export * from './convertExternalLink'
export * from './customField'
export * from './dnd'
export * from './general'
export * from './generateObjectID'
export * from './symbolCurrency'
export * from './users'
export * from './validation'
export * from './country'
export * from './omitTypeName'
export * from './time'
export * from './attendee.status'
export * from './address'

export const isObjectID = (id: string) => {
  return ObjectID.isValid(id)
}

export const Step = {
  AttendeeForm: 0,
  AttendeeCustomField: 1,
  DetailCost: 2,
  Payment: 3,
}

// export const AdminEventStep = {
//   EventDetailForm: 0,
//   // FormBuilder: 1,
//   EventSettings: 1,
//   EmailStep: 2,
//   CustomField: 3,
//   AddCustomField: 4,
// }
export const AdminEventStep = {
  EventDetailForm: 0,
  FormBuilder: 1,
  EventSettings: 2,
  EmailStep: 3,
  CustomField: 4,
  AddCustomField: 5,
}

export const locale = 'en'

export const defaultDateFormat = 'dd/MM/yyyy'

export const defaultDateTimeFormat = 'dd/MM/yyyy h:mm aa'

export const defaultToastDisplayTime = 7000

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null
}

export const isActiveRoute = (path: string) => {
  return location.pathname.startsWith(path)
}

export const joinStrings = (...args: string[]) => {
  return args.filter(Boolean).join(', ')
}
