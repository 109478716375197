import { Label } from '@/components/ui/label'
import { Separator } from '@/components/ui/separator'
import { useTranslation } from 'react-i18next'
import TwoFactorMethodsDialog from './TwoFactorMethodsDialog'

export default function TwoFactorAuthForm() {
  const { t } = useTranslation()
  return (
    <>
      <div className='space-y-6'>
        <Separator />
        <div className='flex flex-row items-center justify-between'>
          <div className='space-y-0.5'>
            <Label className='text-base'>
              {t('Enable Two-Factor Authentication (2FA)')}
            </Label>
            <p className='text-sm text-muted-foreground'>
              {t(
                'Two-factor authentication (2FA) requires users to enter a one-time verification code sent using your preferred channel.'
              )}
            </p>
          </div>
          {/* <Switch
            checked={twoFactor}
            onCheckedChange={() => toggle2FA(!twoFactor)}
          /> */}
        </div>
      </div>
      <TwoFactorMethodsDialog />
    </>
  )
}
