import { FC } from 'react'
import { Redirect } from 'utils/adapters'
import { useAppSelector } from 'utils/hooks'
import { PATH_NAME } from '../../routes/routesMap'

const AuthGuard: FC = ({ children }) => {
  const { isAuth } = useAppSelector(state => state.authenticationReducer)

  return isAuth ? <>{children}</> : <Redirect to={PATH_NAME.LOGIN} />
}

export default AuthGuard
