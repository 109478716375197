import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isEqual } from 'lodash'
import moment from 'moment'

import Select from 'react-select'
import { registerLocale } from 'react-datepicker'
import {
  setAttendeeInfo,
  setEventPrice,
  setPaidAmount,
  setStep,
  setTotalPrice,
} from 'store/Events'

import { styled } from 'utils/adapters'
import { defaultDateFormat, renderSymbolCurrency, Step } from 'utils/helpers'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import Loading from 'components/atoms/Loading'
import Input from 'components/atoms/InputNoTransition'
import Checkbox from 'components/atoms/Checkbox'
import RadioButton from 'components/atoms/RadioButton'
import ValidationText from 'components/atoms/ValidationText'
import { CustomField, CustomFieldOption } from 'models'

import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'
import { MESSAGE } from 'constants/message'
import { CUSTOM_FIELD_TYPE } from 'constants/customFieldType'
import {
  checkMissingRequiredField,
  compareDataSelected,
  customFieldDataType,
  mapCheckedObject,
  mapSelectedFields,
  transformSelectedOption,
} from 'components/pages/EventRegister/components/CustomField/customFieldMixin'
import CustomFieldItem from 'components/pages/EventRegister/components/CustomField/CustomFieldItem'
import { PAYMENT_STATUS } from 'constants/paymentMethod'

import tr from 'date-fns/locale/tr'
import de from 'date-fns/locale/de'
import { NewStyledDatePicker } from '@/components/atoms/NewDatePicker/new-datepicker'
import { toast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import {
  getInitialEditCustomField,
  useEditCustomField,
} from '@/store/zustand/Payments'

registerLocale('tr', tr)
registerLocale('de', de)

const AttendeeCustomField: FC = () => {
  const { t } = useTranslation()

  const { isLoading, event, attendeeInfo, price, payments } = useAppSelector(
    state => state.eventsReducer
  )
  const appLanguage = localStorage.getItem('i18nextLng')

  const dispatch = useAppDispatch()
  const initCustomField = attendeeInfo?.registration?.customValues

  const { initialRadio, initialSelectors, initialCheckboxes } =
    getInitialEditCustomField(initCustomField)

  const [missingRequired, setMissingRequired] = useState<CustomField[]>([])

  const inputDate = useEditCustomField(state => state.inputDate)
  const inputField = useEditCustomField(state => state.inputField)
  const selectedFields = useEditCustomField(state => state.selectedFields)
  const checkedOption = useEditCustomField(state => state.checkedOption)
  const selectedRadio = useEditCustomField(state => state.selectedRadio)

  const mapDataToStandard = () => {
    const radioFields = Object.values(selectedRadio)
    const textFields = Object.values(inputField as customFieldDataType).filter(
      item => item.value[0].name !== ''
    )
    const dateFields = Object.values(inputDate)
    const listChecked = mapCheckedObject(checkedOption)

    const checkedOptions = Object.values(
      transformSelectedOption(listChecked)
    ) as customFieldDataType[]

    const selectedOptions = mapSelectedFields(
      transformSelectedOption(selectedFields)
    ) as customFieldDataType[]

    const standardData = [
      ...textFields,
      ...checkedOptions,
      ...dateFields,
      ...selectedOptions,
      ...radioFields,
    ] as customFieldDataType[]

    const _standardData = standardData.filter(item => item.eventCustomField)

    return _standardData
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let newPrice = event?.price || 0

    // if the attendee paid for the event, only charge the new prices added
    // solution payment 1: count all new prices - old price (paid) with the orderId
    //    api get payment by id
    // solution payment 2: only count the new prices

    const customFields = mapDataToStandard()

    const isRequired = validateRequiredField(
      event?.customFields as [],
      customFields
    )

    if (isRequired) {
      customFields?.map(field => {
        const value = field?.value as unknown as CustomFieldOption[]
        value?.map(value => {
          newPrice += value.fee
        })
      })

      sessionStorage.setItem(
        'attendee_info',
        JSON.stringify({
          ...attendeeInfo,
          registration: {
            ...attendeeInfo?.registration,
            customValues: customFields,
          },
        })
      )
      dispatch(
        setAttendeeInfo({
          ...attendeeInfo,
          registration: {
            ...attendeeInfo.registration,
            customValues: customFields,
          },
        })
      )

      dispatch(
        setPaidAmount(
          payments.reduce(
            (acc, currentValue) =>
              currentValue.status === PAYMENT_STATUS.APPROVED
                ? acc + currentValue.amount
                : acc,
            0
          )
        )
      )
      dispatch(setEventPrice(price))
      dispatch(setTotalPrice(newPrice))
      dispatch(setStep(Step.DetailCost))
    } else {
      toast({
        title: t('Error'),
        description: t('Please fill in all the required fields'),
        variant: 'destructive',
      })
    }
  }

  const validateRequiredField = (requiredArray: any[], formArray: any[]) => {
    const requiredArr = requiredArray.filter((requiredField: any) => {
      return requiredField.isRequired
    })

    const formArr = event?.customFields?.filter((eventObject: any) => {
      if (eventObject.isRequired) {
        return formArray.find((formDataObject: any) => {
          return formDataObject.eventCustomField === eventObject._id
        })
      }
    })

    const missingRequiredFields = requiredArr.filter(item => {
      return !formArr?.includes(item)
    })

    setMissingRequired(missingRequiredFields)

    return isEqual(requiredArr, formArr)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { name, value } = event.target

    useEditCustomField.getState().setInputField({
      ...inputField,
      [name]: {
        eventCustomField: id,
        value: [{ name: value, fee: 0 }],
      },
    })
  }

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    option: CustomFieldOption
  ) => {
    useEditCustomField.getState().setCheckedOption({
      ...checkedOption,
      [event.target.name]: {
        id,
        customValues: option,
        value: event.target.checked,
      },
    })
  }

  const handleDateChange = (date: Date, id: string) => {
    const selectedDate = moment(date).format('YYYY-MM-DD')

    useEditCustomField.getState().setDate({
      ...inputDate,
      [id]: {
        eventCustomField: id,
        value: [{ name: selectedDate, fee: 0 }],
      },
    })
  }

  const handleSelectChange = (
    selectedValue: any,
    actionMeta: any,
    id: string
  ) => {
    const cloneSelectedField = [...selectedFields]
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        break
    }

    const value = selectedValue?.map((data: any) => data.value)

    const selectedField = {
      eventCustomField: id,
      value,
    }
    const foundIndex = cloneSelectedField.findIndex(
      (el: any) => el.eventCustomField === id
    )
    if (foundIndex !== -1) {
      if (selectedField?.value?.length) {
        cloneSelectedField[foundIndex].value = selectedField.value
      } else {
        cloneSelectedField.splice(foundIndex, 1)
      }
    } else {
      cloneSelectedField.push(selectedField)
    }
    useEditCustomField.getState().setSelectedFields(cloneSelectedField)
  }

  const handleRadioChange =
    (option: CustomFieldOption, id: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      useEditCustomField.getState().setSelectedRadio({
        ...selectedRadio,
        [event.target.name]: {
          eventCustomField: id,
          value: [option],
        },
      })
    }

  const renderLabel = (value: CustomFieldOption) => {
    if (value?.fee) {
      return `${value?.name} +${value?.fee}${renderSymbolCurrency(
        event?.currency
      )}`
    } else {
      return `${value?.name}`
    }
  }

  const renderErrorMessage = (type: string, customField: CustomField) => {
    const isMissingRequired = checkMissingRequiredField(
      missingRequired,
      customField
    )
    if (isMissingRequired) {
      if (type === CUSTOM_FIELD_TYPE.TEXT)
        return <ErrorMessage error={t(MESSAGE.REQUIRED_FIELD)} />

      if (type === CUSTOM_FIELD_TYPE.CHECK_BOX)
        return <ValidationText error={t(MESSAGE.REQUIRED_MULTI_OPTIONS)} />

      if (type === CUSTOM_FIELD_TYPE.RADIO_BUTTON)
        return <ValidationText error={t(MESSAGE.REQUIRED_OPTION)} />

      if (type === CUSTOM_FIELD_TYPE.SELECT_BOX)
        return <ValidationText error={t(MESSAGE.REQUIRED_MULTI_OPTIONS)} />

      if (type === CUSTOM_FIELD_TYPE.DATE_TIME)
        return <ValidationText error={t(MESSAGE.REQUIRED_FIELD)} />
    }
    return null
  }

  return (
    <>
      {isLoading && <Loading />}
      <Wrapper submitLoading={isLoading}>
        <FormContainer onSubmit={onSubmit}>
          {event?.customFields?.map(
            // @ts-ignore
            (customField: CustomField, index: number) => {
              if (customField.type === CUSTOM_FIELD_TYPE.TEXT) {
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : (event?.eventSetting?.primaryColor as string)
                    }
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    customField={customField}
                    type={customField.type}
                  >
                    <StyledInput
                      type='text'
                      name={customField._id}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(event, customField._id)
                      }
                      value={
                        inputField[customField._id]?.value[0].name
                          ? inputField[customField._id]?.value[0].name
                          : ''
                      }
                      label={
                        <span
                          className='ql-link pointer-events-auto'
                          dangerouslySetInnerHTML={{
                            __html: customField.title,
                          }}
                        />
                      }
                      isRequired={customField.isRequired}
                      isShowSpace={checkMissingRequiredField(
                        missingRequired,
                        customField
                      )}
                    />
                  </CustomFieldItem>
                )
              }
              if (customField.type === CUSTOM_FIELD_TYPE.CHECK_BOX) {
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : (event?.eventSetting?.primaryColor as string)
                    }
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    isDangerouslyTitle
                  >
                    <div>
                      {customField.options.map(
                        (option: CustomFieldOption, i: number) => {
                          const uniqId = `${option.name}${customField._id}`
                          const checked = checkedOption[uniqId]?.value
                          const disabled =
                            checked &&
                            initialCheckboxes[uniqId]?.customValues?.fee
                          return (
                            <div className='flex gap-2' key={i}>
                              <Checkbox
                                name={`${option.name}${customField._id}`}
                                disabled={disabled}
                                checked={checked}
                                onChange={event =>
                                  handleCheckbox(event, customField._id, option)
                                }
                              />
                              <Label>{renderLabel(option)}</Label>
                            </div>
                          )
                        }
                      )}
                    </div>
                  </CustomFieldItem>
                )
              }

              if (customField.type === CUSTOM_FIELD_TYPE.DATE_TIME) {
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : (event?.eventSetting?.primaryColor as string)
                    }
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    isDangerouslyTitle
                  >
                    <NewStyledDatePicker
                      locale={appLanguage || 'en'}
                      dateFormat={defaultDateFormat}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      onChange={(date: Date) =>
                        handleDateChange(date, customField._id)
                      }
                      value={
                        inputDate
                          ? inputDate[customField._id]?.value[0]?.name
                          : new Date()
                      }
                    />
                  </CustomFieldItem>
                )
              }

              if (customField.type === CUSTOM_FIELD_TYPE.SELECT_BOX) {
                const options = customField?.options?.map(
                  (value: CustomFieldOption) => {
                    let isSelected = false
                    initialSelectors?.forEach(selector => {
                      if (selector.eventCustomField === customField._id) {
                        selector?.value?.forEach(selectorValue => {
                          if (selectorValue?.name === value?.name) {
                            isSelected = true
                          }
                        })
                      }
                    })

                    const isDisabled = !!(isSelected && value?.fee)
                    return {
                      id: customField?._id,
                      label: renderLabel(value),
                      value,
                      isDisabled,
                      isFixed: isDisabled,
                    }
                  }
                )
                const value = compareDataSelected(options, selectedFields) || []
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : (event?.eventSetting?.primaryColor as string)
                    }
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    isDangerouslyTitle
                  >
                    <Select
                      isMulti
                      styles={{ ...customStyles }}
                      placeholder='Select box'
                      options={options}
                      value={value}
                      onChange={(selectedValue, actionMeta) =>
                        handleSelectChange(
                          selectedValue,
                          actionMeta,
                          customField._id
                        )
                      }
                      isClearable={false}
                    />
                  </CustomFieldItem>
                )
              }

              if (customField.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON) {
                let disabled = false
                const uniqId = `${customField.title}${customField._id}`
                customField.options.forEach(option => {
                  if (
                    isEqual(initialRadio[uniqId]?.value[0], option) &&
                    initialRadio[uniqId]?.value[0].fee
                  ) {
                    disabled = true
                  }
                })
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : (event?.eventSetting?.primaryColor as string)
                    }
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    isDangerouslyTitle
                  >
                    <BoxContainer>
                      {customField?.options?.map(
                        (option: CustomFieldOption, i: number) => {
                          const checked = isEqual(
                            selectedRadio[uniqId]?.value[0],
                            option
                          )

                          // if there is any fee option chose => disable
                          return (
                            <div key={i}>
                              <RadioButton
                                name={`${customField.title}${customField._id}`}
                                value={JSON.stringify(option)}
                                disabled={disabled}
                                checked={checked}
                                onChange={handleRadioChange(
                                  option,
                                  customField._id
                                )}
                              >
                                {renderLabel(option)}
                              </RadioButton>
                            </div>
                          )
                        }
                      )}
                    </BoxContainer>
                  </CustomFieldItem>
                )
              }
            }
          )}

          <SubmitButton
            primaryColor={event?.eventSetting?.primaryColor}
            color={setTextColorBasedOnBackground(
              event?.eventSetting?.primaryColor
            )}
          >
            {t('Continue')}
          </SubmitButton>
        </FormContainer>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ submitLoading?: boolean }>`
  padding: 2% 4%;
  opacity: ${props => (props.submitLoading ? 0.5 : 1)};
  min-height: 100%;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 10px;
  max-width: 650px;
`

const ErrorMessage = styled(ValidationText)`
  position: absolute;
  bottom: 10px;
`

const Label = styled.span`
  font-size: 16px;
  color: ${COLORS.PRIMARY};
  ${responsive.md`
    font-size: 12px;
  `}
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  box-sizing: border-box;
`

const SubmitButton = styled(Button)<{ primaryColor?: string; color?: string }>`
  margin: 10px 0;
  color: ${props => props.color};
  background-color: ${props => props.primaryColor || COLORS.BLUE};
`

const StyledInput = styled(Input)`
  label {
    font-size: 14px;
  }
`

const customStyles = {
  control: () => ({
    display: 'flex',
    borderBottom: '2px solid #d8d8d8',
    minWidth: '150px',
  }),
  option: (styles: any, { data, isSelected }: any) => {
    return {
      ...styles,
      color: data.value,
      backgroundColor: isSelected ? '#F0F8FF' : 'null',
      ':hover': {
        backgroundColor: '#f8f8f8',
      },
    }
  },
  multiValue: (styles: any, state: any) => ({
    ...styles,
    borderRadius: '50px',
    padding: '5px',
    backgroundColor: state.data.isFixed
      ? COLORS.BACKGROUND_DISABLED
      : COLORS.BLUE,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#fff',
  }),
  multiValueRemove: (styles: any, state: any) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      color: '#DE350B',
      backgroundColor: 'transparent',
    },
    display: state.data.isFixed && 'none',
  }),
}

export default AttendeeCustomField
