import { Language } from 'constants/language'
import moment from 'moment-timezone'

export const GERMAN_DATE_FORMAT = 'DD.MM.YYYY'
export const ENGLISH_DATE_FORMAT = 'DD/MM/YYYY'

export const getEventDateByTimeZone = (
  time: string | undefined,
  lang: string,
  timezone: string
) => {
  let dateFormat = GERMAN_DATE_FORMAT
  if (lang === Language.English || lang?.startsWith('en')) {
    dateFormat = ENGLISH_DATE_FORMAT
  }
  return moment(time).tz(timezone).format(dateFormat)
}

export const getEventDate = (
  time: string | undefined,
  timezone: string
): Date => {
  return moment(time).tz(timezone).toDate()
}

export const getMonthName = (date: Date): string => {
  const month = date.toLocaleString('default', { month: 'long' })
  return month?.slice(0, 3)
}

export const getTimeByTimeZone = (
  time: string | undefined,
  lang: string,
  timezone: string
) => {
  let timeFormat = 'HH:mm'
  if (lang === Language.English || lang?.startsWith('en')) {
    timeFormat = 'hh:mma'
  }

  let utcOffset = '0'
  const utcOffsetHour = moment().tz(timezone).utcOffset() / 60
  if (utcOffsetHour >= 0) {
    utcOffset = '+' + utcOffsetHour
  }

  return {
    utcOffset,
    time: moment(time).tz(timezone).format(timeFormat),
  }
}

export const getEventTime = (
  mainLanguage: string | undefined,
  timezone: string,
  startEvent: Date | string | undefined,
  endEvent: Date | string | undefined
) => {
  const startMoment = moment(startEvent, 'DD-MM-YYYY')
  const endMoment = moment(endEvent, 'DD-MM-YYYY')
  const isTheSameDay = startMoment.isSame(endMoment, 'day')

  let startTime = moment(startEvent).tz(timezone).format('HH:mm')
  if (mainLanguage === Language.English) {
    startTime = moment(startEvent).tz(timezone).format('hh:mma')
  }

  let endTime = moment(endEvent).tz(timezone).format('HH:mm')
  if (mainLanguage === Language.English) {
    endTime = moment(endEvent).tz(timezone).format('hh:mma')
  }

  const isTheSameTime = moment(startEvent).isSame(endEvent)

  let utcOffset = '0'
  const utcOffsetHour = moment().tz(timezone).utcOffset() / 60
  if (utcOffsetHour >= 0) {
    utcOffset = '+' + utcOffsetHour
  }

  return {
    isTheSameDay,
    startTime,
    endTime,
    utcOffset,
    isTheSameTime,
  }
}
