import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory } from 'utils/adapters'

import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { COLORS } from 'constants/colors'

type FooterProps = {
  bgColor?: string
}

const Footer: FC<FooterProps> = ({ bgColor = COLORS.BLUE }) => {
  const { t } = useTranslation()

  const history = useHistory()
  return (
    <div
      className='mt-auto text-center text-sm h-12'
      style={{
        backgroundColor: bgColor,
        color: setTextColorBasedOnBackground(bgColor),
      }}
    >
      <div className='flex flex-wrap justify-between mx-auto gap-4 px-3 md:px-32 h-full items-center'>
        <ul className='flex list-none m-0 p-0 justify-between'>
          <li className='flex items-center'>
            <span
              className='px-1 sm:px-4 text-xs transition-colors duration-250 ease-in uppercase opacity-90 cursor-pointer sm:text-sm'
              onClick={() => history.push('/imprint')}
            >
              {t('Imprint')}
            </span>
          </li>

          <li className='flex items-center'>
            <span
              className='px-1 sm:px-4 text-xs transition-colors duration-250 ease-in uppercase opacity-90 cursor-pointer sm:text-sm'
              onClick={() => history.push('/privacy')}
            >
              {t('Privacy')}
            </span>
          </li>
        </ul>
        <div className='text-xs sm:text-sm'>
          ©{new Date().getFullYear()} ConferenceDirect
        </div>
      </div>
    </div>
  )
}

export default Footer
