import { FC } from 'react'
import { styled } from 'utils/adapters'
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'
import { DnD } from 'constants/colors'

type DragnDropType = {
  onDragEnd(result: DropResult, provided: ResponderProvided): void
}

const DragnDrop: FC<DragnDropType> = ({ onDragEnd, children }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <List
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {children}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const List = styled.div<{ isDraggingOver: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${props =>
    props.isDraggingOver ? DnD.LIST_DRAGGINGOVER : DnD.LIST_BACKGROUND};
  border-radius: 4px;
  padding: 8px;
`

export default DragnDrop
