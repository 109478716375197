import { FC, useRef, useState } from 'react'

import { styled } from 'utils/adapters'
import { useClickOutside } from 'utils/hooks'
import LanguageList from './components/LanguageList'

import Icon from 'components/atoms/Icon'
import { LANGUAGE, SUPPORT_LANGUAGE } from 'constants/language'

import { FlagIcon as England, GermanyIcon, TurkeyIcon } from 'assets/images'
import { responsive } from 'assets/scss/mixin'

type LanguageProps = {
  language: string
  onSelectLanguage: (language: string) => void
}

const SupportLanguage: FC<LanguageProps> = ({ language, onSelectLanguage }) => {
  const [toggleLanguage, setToggleLanguage] = useState(false)

  const languageRef = useRef(null)
  useClickOutside(languageRef, () => setToggleLanguage(!toggleLanguage))

  const renderFlag = (language: string) => {
    switch (language) {
      case LANGUAGE.ENGLISH:
        return <FlagIcon source={England} />
      case LANGUAGE.GERMAN:
        return <FlagIcon source={GermanyIcon} />
      case LANGUAGE.TURKISH:
        return <FlagIcon source={TurkeyIcon} />
      default:
        return <FlagIcon source={England} />
    }
  }

  const handleToggleLanguage = () => {
    setToggleLanguage(!toggleLanguage)
  }

  return (
    <IconWrapper onClick={handleToggleLanguage}>
      {renderFlag(language)}
      {toggleLanguage && (
        <LanguageContainer ref={languageRef}>
          <LanguageList
            languages={SUPPORT_LANGUAGE}
            selectedLanguage={language}
            onSelectLanguage={onSelectLanguage}
          />
        </LanguageContainer>
      )}
    </IconWrapper>
  )
}

const LanguageContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-70%);
  padding: 6px;
  border-radius: 8px;
  z-index: 999;
`

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`

const FlagIcon = styled(Icon)`
  z-index: 3;
  img {
    width: 24px;
    height: 24px;
    filter: none;
  }
`

export default SupportLanguage
