import { FC } from 'react'
import { useParams } from 'utils/adapters'
import Loading from 'components/atoms/Loading'
import { useAdminEventDetail } from './mixins'
import Attendees from '@/components/pages/EventAttendeeList/Attendees'

const EventAttendeeList: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useAdminEventDetail(id)

  if (loading) {
    return <Loading />
  }

  return <Attendees event={data?.event} />
}

export default EventAttendeeList
