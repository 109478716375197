import { LANGUAGE } from '../../constants/language'

export const hideIconByPath = (endString: string) => {
  const url = window.location.pathname
  return url.endsWith(endString)
}

export const defaultTimezone = 'Europe/Berlin'

export const priceFormat = (price: number | undefined, lang: string) => {
  switch (lang) {
    case LANGUAGE.GERMAN:
      return price?.toLocaleString('de-DE')
    case LANGUAGE.TURKISH:
      return price?.toLocaleString('tr-TR')
    default:
      return price?.toLocaleString('en-US')
  }
}

export const isJSON = (str: string) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export const safelyParseJSON = (json: any) => {
  // This function cannot be optimised, it's best to
  // keep it small!
  let parsed

  try {
    parsed = JSON.parse(json)
  } catch (e) {
    console.log('error while parsing json', json)
    // Oh well, but whatever...
  }

  return parsed // Could be undefined!
}
