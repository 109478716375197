import { FC } from 'react'

import { Attendee, IEvent } from 'models'
import CustomField from './DetailCost/CustomField'
import EventInformation from './DetailCost/EventInformation'
import UserDetails from './DetailCost/UserDetails'

const RegistrationOverview: FC<{
  attendee: Attendee
  options: any
  event: IEvent
}> = ({ attendee, options, event }) => {
  return (
    <>
      <div className='space-y-4 w-full mx-auto'>
        <EventInformation event={event} />
        <UserDetails attendee={attendee} event={event} />
        {options?.length !== 0 && <CustomField options={options} />}
      </div>
    </>
  )
}

export default RegistrationOverview
