import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GET_ORGANIZATION } from 'graphql/queries'
import { useParams, useQuery } from 'utils/adapters'
import { UserRole } from 'constants/userRole'
import { useAppDispatch } from 'utils/hooks'
import OrganizationForm from './OrganizationForm'
import Loading from '../../../atoms/Loading'
import { toast } from '@/components/ui/use-toast'

interface RouteParams {
  id: string
}

const OrganizationFormWrapper: FC<{ userRole: UserRole }> = ({ userRole }) => {
  const params = useParams<RouteParams>()
  const id = params.id
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)

  const { data: organizationData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATION,
    {
      onCompleted: ({ organization }) => {
        setLoading(false)
      },
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
      variables: {
        id,
      },
      skip: !id,
      fetchPolicy: 'no-cache',
    }
  )

  useEffect(() => {
    if (!id) {
      setLoading(false)
    }
  }, [])

  if (organizationLoading || loading) {
    return <Loading />
  }

  return (
    <OrganizationForm
      id={id}
      userRole={userRole}
      organizationData={organizationData}
    />
  )
}

export default OrganizationFormWrapper
