import { gql } from 'utils/adapters'

export const UPDATE_BOOKING_INFO = gql`
  mutation UpdateBooking(
    $attendee: UpdateAttendeeInput!
    $eventId: ID!
    $orderId: ID
  ) {
    updateBookingInfo(
      attendee: $attendee
      eventId: $eventId
      orderId: $orderId
    ) {
      status
      message
    }
  }
`

export const CANCEL_BOOKING = gql`
  mutation cancelBooking($reason: String, $eventId: ID!) {
    cancelBooking(cancellationReason: $reason, eventId: $eventId) {
      message
      status
    }
  }
`

export const ACTION_REFUND_CANCEL_BOOKING = gql`
  mutation ActionRefundCancelBooking(
    $action: ActionRefundCancelBookingStatus!
    $attendeeEmail: String!
    $eventId: ID!
  ) {
    actionRefundCancelBooking(
      action: $action
      attendeeEmail: $attendeeEmail
      eventId: $eventId
    ) {
      message
      status
    }
  }
`

export const MANUAL_REFUND_CANCEL_BOOKING = gql`
  mutation ManuallyRefundedCancelBooking(
    $attendeeEmail: String!
    $eventId: ID!
  ) {
    manuallyRefundedCancelBooking(
      attendeeEmail: $attendeeEmail
      eventId: $eventId
    ) {
      message
      status
    }
  }
`
