export const Currency = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
]

export enum CURRENCY {
  EUR = 'EUR',
  USD = 'USD',
}
