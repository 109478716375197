import { Card, CardContent, CardHeader } from '@/components/ui/card'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CustomField: React.FC<any> = ({ options }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <h2 className='text-xl font-semibold'>
          {t('registration_overview.custom_fields')}
        </h2>
      </CardHeader>
      <CardContent className='grid gap-6'>
        <div className='grid gap-4'>
          {options.map((field: any, index: number) => {
            return (
              <div key={index} className='space-y-1.5'>
                <div
                  dangerouslySetInnerHTML={{ __html: field.title || '' }}
                  className='ql-link text-sm text-muted-foreground'
                />
                {field.options &&
                  field.options.map((option, index: number) => {
                    return <div key={index}> {option.name}</div>
                  })}
              </div>
            )
          })}
        </div>
      </CardContent>
    </Card>
  )
}

export default CustomField
