import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  destination: string | undefined
  otpValue: string
  setOtpValue: (value: string) => void
}

export const TwoFactorAuthCode: FC<IProps> = ({
  otpValue: value,
  setOtpValue,
  destination,
}) => {
  const { t } = useTranslation()
  const fields = 6
  const dataRef = useRef(Array(fields).fill(''))
  const firstInputEl = useRef(null)

  useEffect(() => {
    if (value !== null) {
      dataRef.current = String(value)
        .split('')
        .map(char => char)
    } else {
      dataRef.current = Array(fields).fill('')
    }
  }, [value, fields])

  const handleOtpInput = (e, index: number) => {
    const char = e.target.value

    if (char) {
      dataRef.current[index] = char
      dataRef.current[index + 1] = ''
      if (index < fields - 1) {
        // @ts-ignore
        firstInputEl.current[index + 1].focus()
      }
    } else {
      dataRef.current[index] = ''
      if (index > 0) {
        // @ts-ignore
        firstInputEl.current[index - 1].focus()
      }
    }

    setOtpValue(dataRef.current.join(''))
  }

  const handlePaste = e => {
    const pasteData = e.clipboardData.getData('text').split('')

    for (let i = 0; i < pasteData.length && i < fields; i++) {
      dataRef.current[i] = pasteData[i]
    }

    setOtpValue(dataRef.current.join(''))
  }

  return (
    <div className='space-y-6'>
      <p className='text-sm text-muted-foreground text-center'>
        {t('Enter the authentication code below we sent to')} {destination}
      </p>
      <div className='flex flex-row items-center justify-center'>
        <div className='space-y-0.5'>
          <div className=' h-12 flex flex-row items-center justify-center mx-auto w-full max-w-xs'>
            {Array.from({ length: fields }, (_, index) => {
              return (
                <input
                  key={index}
                  className='w-12 h-12 mx-1 w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none sm:rounded-lg border border-gray-200 text-lg bg-white focus:ring-1 focus:ring-sky-700'
                  type='text'
                  maxLength={1}
                  value={dataRef.current[index]}
                  ref={el => {
                    if (index === 0) {
                      // @ts-ignore
                      firstInputEl.current = []
                    }
                    // @ts-ignore
                    firstInputEl.current[index] = el
                  }}
                  onPaste={e => index === 0 && handlePaste(e)}
                  onChange={e => handleOtpInput(e, index)}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
