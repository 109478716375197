import { FC, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { COLORS } from 'constants/colors'
import { styled } from 'utils/adapters'
import { responsive } from 'assets/scss/mixin'
import { toast } from '@/components/ui/use-toast'

const CopyToClipBoard: FC<{ value: string; maxWidth?: string }> = ({
  value,
  maxWidth,
}) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const onCopy = () => {
    setIsCopied(true)
    toast({
      description: t('Copied to clipboard!'),
    })
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }

  return (
    <CopyContainer maxWidth={maxWidth}>
      <Text>{value}</Text>
      <CopyToClipboard text={value} onCopy={onCopy}>
        <CopyButton>{isCopied ? t('Copied!') : t('Copy')}</CopyButton>
      </CopyToClipboard>
    </CopyContainer>
  )
}

const CopyContainer = styled.div<{ maxWidth?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  border-radius: 5px;
  padding: 8px 10px;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '400px')};
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
`

const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b9c1cf;
  ${responsive.md`
    font-size: 12px;
  `}
`

const CopyButton = styled.button`
  padding: 8px 10px;
  border-radius: 5px;
  background-color: ${COLORS.BLUE};
  color: #fff;
  //width: 70px;
  cursor: pointer;
  border: none;
  //max-width: 66px;

  &:focus {
    outline: 0;
  }

  ${responsive.md`
    font-size: 12px;
  `}
`

export default CopyToClipBoard
