import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import Loading from 'components/atoms/Loading'
import {
  arrayMutators,
  FieldArray,
  Form,
  styled,
  useMutation,
  useParams,
} from 'utils/adapters'
import { AddCircleIcon, RemoveIcon } from 'assets/images'
import Icon from 'components/atoms/Icon'
import { useAdminEventDetail } from '../EventDetail/mixins'
import PolicyFormContainer from './components/PolicyFormContainer'

import { ActionIcon } from 'components/styles'
import { responsive } from 'assets/scss'

import { ADD_OR_UPDATE_CANCELATION_POLICIES } from 'graphql/mutations'
import { FIXED_RANGES_TYPE, RANGES_TYPE } from 'constants/cancellationPolicy'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'

type CancellationPolicyProps = {
  values: any
}

const CancellationPolicy: FC<CancellationPolicyProps> = ({ values }) => {
  const { t } = useTranslation()

  const { id } = useParams<{ id: string }>()
  const { data, loading } = useAdminEventDetail(id)
  const event = data?.event

  const [mutateAddCancelationPolices] = useMutation(
    ADD_OR_UPDATE_CANCELATION_POLICIES,
    {
      onCompleted: data => {
        if (data) {
          toast({
            description: t('Update event policies success!'),
          })
        }
      },

      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  if (loading) {
    return <Loading />
  }

  const onSubmit = (values: any) => {
    const formattedData = values.data.map((item: any) => {
      switch (item.type) {
        case RANGES_TYPE.DATE_RANGES:
          // eslint-disable-next-line no-case-declarations
          const dateRangesObj: any = {
            startDate: moment(item.startDate).toDate(),
            endDate: moment(item.endDate).toDate(),
            feeNumber: Number(item.feeNumber),
            feeType: item.feeType.value,
          }
          if (item._id) {
            dateRangesObj['_id'] = item._id
          }
          return dateRangesObj
        case RANGES_TYPE.FIXED_RANGES:
          // eslint-disable-next-line no-case-declarations
          let fixedRangesObj: any
          if (item.dateType.value === FIXED_RANGES_TYPE.BEFORE) {
            fixedRangesObj = {
              endDate: moment(item.date).toDate(),
              feeNumber: Number(item.feeNumber),
              feeType: item.feeType.value,
            }
          } else {
            fixedRangesObj = {
              startDate: moment(item.date).toDate(),
              feeNumber: Number(item.feeNumber),
              feeType: item.feeType.value,
            }
          }
          if (item._id) {
            fixedRangesObj['_id'] = item._id
          }
          return fixedRangesObj
      }
    })

    mutateAddCancelationPolices({
      variables: {
        eventId: event._id,
        cancellationPolicies: [...formattedData],
      },
    })
  }

  return (
    <Form
      keepDirtyOnReinitialize
      mutators={{
        ...arrayMutators,
      }}
      onSubmit={onSubmit}
      initialValues={values}
      render={({ handleSubmit, submitting, pristine }) => (
        <FormContainer onSubmit={handleSubmit}>
          <FieldArray name='data'>
            {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
            {({ fields, meta: { error } }) => (
              <>
                {fields.map((name, index) => {
                  return (
                    <div key={name}>
                      <StyledPolicyFormWrapper>
                        <PolicyFormContainer name={name} index={index} />
                        <ActionIcon
                          source={RemoveIcon}
                          onClick={() => fields.remove(index)}
                        />
                      </StyledPolicyFormWrapper>
                    </div>
                  )
                })}

                <StyledActionWrapper>
                  <ButtonAddNew
                    variant={'outline'}
                    onClick={() =>
                      fields.push({
                        feeNumber: null,
                        feeType: '',
                        startDate: '',
                        endDate: '',
                        date: '',
                        type: RANGES_TYPE.FIXED_RANGES,
                      })
                    }
                  >
                    {t('Add new')}
                    <StyledActionIconAdd source={AddCircleIcon} />
                  </ButtonAddNew>
                </StyledActionWrapper>
              </>
            )}
          </FieldArray>
          <StyledSubmitButtonWrapper>
            <Button type='submit' disabled={submitting || pristine}>
              {t('Submit')}
            </Button>
          </StyledSubmitButtonWrapper>
        </FormContainer>
      )}
    />
  )
}

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${responsive.md`
      gap: 15px;
  `}
`

const StyledPolicyFormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 50px;
`

const StyledActionWrapper = styled.span``

const StyledActionIconAdd = styled(Icon)`
  margin-left: 5px;

  img {
    filter: none;
  }
`

const ButtonAddNew = styled(Button)`
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: right;
`

const StyledSubmitButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
`

export default CancellationPolicy
