export const CountryTr = [
  {
    code: 'AF',
    name: 'Afganistan',
  },
  {
    code: 'AX',
    name: 'Aland adaları',
  },
  {
    code: 'AL',
    name: 'Arnavutluk',
  },
  {
    code: 'DZ',
    name: 'Cezayir',
  },
  {
    code: 'AS',
    name: 'Amerikan Samoası',
  },
  {
    code: 'AD',
    name: 'Andorra',
  },
  {
    code: 'AO',
    name: 'Angola',
  },
  {
    code: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'AQ',
    name: 'Antarktika',
  },
  {
    code: 'AG',
    name: 'Antigua ve Barbuda',
  },
  {
    code: 'AR',
    name: 'Arjantin',
  },
  {
    code: 'AM',
    name: 'Ermenistan',
  },
  {
    code: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AU',
    name: 'Avustralya',
  },
  {
    code: 'AT',
    name: 'Avusturya',
  },
  {
    code: 'AZ',
    name: 'Azerbaycan',
  },
  {
    code: 'BS',
    name: 'Bahamalar',
  },
  {
    code: 'BH',
    name: 'Bahreyn',
  },
  {
    code: 'BD',
    name: 'Bangladeş',
  },
  {
    code: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BY',
    name: 'Belarus',
  },
  {
    code: 'BE',
    name: 'Belçika',
  },
  {
    code: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BT',
    name: 'Butan',
  },
  {
    code: 'BO',
    name: 'Bolivya',
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius ve Saba',
  },
  {
    code: 'BA',
    name: 'Bosna Hersek',
  },
  {
    code: 'BW',
    name: 'Botsvana',
  },
  {
    code: 'BV',
    name: 'Bouvet Adası',
  },
  {
    code: 'BR',
    name: 'Brezilya',
  },
  {
    code: 'IO',
    name: 'İngiliz Hint Okyanusu Bölgesi',
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    code: 'BG',
    name: 'Bulgaristan',
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BI',
    name: 'Burundi',
  },
  {
    code: 'KH',
    name: 'Kamboçya',
  },
  {
    code: 'CM',
    name: 'Kamerun',
  },
  {
    code: 'CA',
    name: 'Kanada',
  },
  {
    code: 'CV',
    name: 'Cape Verde',
  },
  {
    code: 'KY',
    name: 'Cayman Adaları',
  },
  {
    code: 'CF',
    name: 'Orta Afrika Cumhuriyeti',
  },
  {
    code: 'TD',
    name: 'Çad',
  },
  {
    code: 'CL',
    name: 'Şili',
  },
  {
    code: 'CN',
    name: 'Çin',
  },
  {
    code: 'CX',
    name: 'Noel Adası',
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Adaları',
  },
  {
    code: 'CO',
    name: 'Kolombiya',
  },
  {
    code: 'KM',
    name: 'Komorlar',
  },
  {
    code: 'CG',
    name: 'Kongo',
  },
  {
    code: 'CD',
    name: 'Kongo, Demokratik Kongo Cumhuriyeti',
  },
  {
    code: 'CK',
    name: 'Cook Adaları',
  },
  {
    code: 'CR',
    name: 'Kosta Rika',
  },
  {
    code: 'CI',
    name: 'Fildişi Sahili',
  },
  {
    code: 'HR',
    name: 'Hırvatistan',
  },
  {
    code: 'CU',
    name: 'Küba',
  },
  {
    code: 'CW',
    name: 'Curacao',
  },
  {
    code: 'CY',
    name: 'Kıbrıs',
  },
  {
    code: 'CZ',
    name: 'Çek Cumhuriyeti',
  },
  {
    code: 'DK',
    name: 'Danimarka',
  },
  {
    code: 'DJ',
    name: 'Cibuti',
  },
  {
    code: 'DM',
    name: 'Dominika',
  },
  {
    code: 'DO',
    name: 'Dominik Cumhuriyeti',
  },
  {
    code: 'EC',
    name: 'Ekvador',
  },
  {
    code: 'EG',
    name: 'Mısır',
  },
  {
    code: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'GQ',
    name: 'Ekvator Ginesi',
  },
  {
    code: 'ER',
    name: 'Eritre',
  },
  {
    code: 'EE',
    name: 'Estonya',
  },
  {
    code: 'ET',
    name: 'Etiyopya',
  },
  {
    code: 'FK',
    name: 'Falkland Adaları (Malvinas)',
  },
  {
    code: 'FO',
    name: 'Faroe Adaları',
  },
  {
    code: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FI',
    name: 'Finlandiya',
  },
  {
    code: 'FR',
    name: 'Fransa',
  },
  {
    code: 'GF',
    name: 'Fransız Guyanası',
  },
  {
    code: 'PF',
    name: 'Fransız Polinezyası',
  },
  {
    code: 'TF',
    name: 'Fransız Güney Toprakları',
  },
  {
    code: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GM',
    name: 'Gambiya',
  },
  {
    code: 'GE',
    name: 'Gürcistan',
  },
  {
    code: 'DE',
    name: 'Almanya',
  },
  {
    code: 'GH',
    name: 'Gana',
  },
  {
    code: 'GI',
    name: 'Cebelitarık',
  },
  {
    code: 'GR',
    name: 'Yunanistan',
  },
  {
    code: 'GL',
    name: 'Grönland',
  },
  {
    code: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'GU',
    name: 'Guam',
  },
  {
    code: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GN',
    name: 'Gine',
  },
  {
    code: 'GW',
    name: 'Gine-Bissau',
  },
  {
    code: 'GY',
    name: 'Guyana',
  },
  {
    code: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HM',
    name: 'Heard Adası ve McDonald Adaları',
  },
  {
    code: 'VA',
    name: 'Holy See (Vatikan Şehir Devleti)',
  },
  {
    code: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HU',
    name: 'Macaristan',
  },
  {
    code: 'IS',
    name: 'İzlanda',
  },
  {
    code: 'IN',
    name: 'Hindistan',
  },
  {
    code: 'ID',
    name: 'Endonezya',
  },
  {
    code: 'IR',
    name: 'İran İslam Cumhuriyeti',
  },
  {
    code: 'IQ',
    name: 'Irak',
  },
  {
    code: 'IE',
    name: 'İrlanda',
  },
  {
    code: 'IM',
    name: 'Man Adası',
  },
  {
    code: 'IL',
    name: 'İsrail',
  },
  {
    code: 'IT',
    name: 'İtalya',
  },
  {
    code: 'JM',
    name: 'Jamaika',
  },
  {
    code: 'JP',
    name: 'Japonya',
  },
  {
    code: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JO',
    name: 'Ürdün',
  },
  {
    code: 'KZ',
    name: 'Kazakistan',
  },
  {
    code: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'KP',
    name: 'Kore Demokratik Halk Cumhuriyeti',
  },
  {
    code: 'KR',
    name: 'Kore Cumhuriyeti',
  },
  {
    code: 'XK',
    name: 'Kosova',
  },
  {
    code: 'KW',
    name: 'Kuveyt',
  },
  {
    code: 'KG',
    name: 'Kırgızistan',
  },
  {
    code: 'LA',
    name: 'Lao Demokratik Halk Cumhuriyeti',
  },
  {
    code: 'LV',
    name: 'Letonya',
  },
  {
    code: 'LB',
    name: 'Lübnan',
  },
  {
    code: 'LS',
    name: 'Lesoto',
  },
  {
    code: 'LR',
    name: 'Liberya',
  },
  {
    code: 'LY',
    name: 'Libya Arap Cemahiriyası',
  },
  {
    code: 'LI',
    name: 'Lihtenştayn',
  },
  {
    code: 'LT',
    name: 'Litvanya',
  },
  {
    code: 'LU',
    name: 'Lüksemburg',
  },
  {
    code: 'MO',
    name: 'Makao',
  },
  {
    code: 'MK',
    name: 'Makedonya Eski Yugoslav Cumhuriyeti',
  },
  {
    code: 'MG',
    name: 'Madagaskar',
  },
  {
    code: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MY',
    name: 'Malezya',
  },
  {
    code: 'MV',
    name: 'Maldivler',
  },
  {
    code: 'ML',
    name: 'Mali',
  },
  {
    code: 'MT',
    name: 'Malta',
  },
  {
    code: 'MH',
    name: 'Marşal Adaları',
  },
  {
    code: 'MQ',
    name: 'Martinik',
  },
  {
    code: 'MR',
    name: 'Moritanya',
  },
  {
    code: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'MX',
    name: 'Meksika',
  },
  {
    code: 'FM',
    name: 'Mikronezya Federal Devletleri',
  },
  {
    code: 'MD',
    name: 'Moldova Cumhuriyeti',
  },
  {
    code: 'MC',
    name: 'Monako',
  },
  {
    code: 'MN',
    name: 'Moğolistan',
  },
  {
    code: 'ME',
    name: 'Karadağ',
  },
  {
    code: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MA',
    name: 'Fas',
  },
  {
    code: 'MZ',
    name: 'Mozambik',
  },
  {
    code: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'NA',
    name: 'Namibya',
  },
  {
    code: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NL',
    name: 'Hollanda',
  },
  {
    code: 'AN',
    name: 'Hollanda Antilleri',
  },
  {
    code: 'NC',
    name: 'Yeni Kaledonya',
  },
  {
    code: 'NZ',
    name: 'Yeni Zelanda',
  },
  {
    code: 'NI',
    name: 'Nikaragua',
  },
  {
    code: 'NE',
    name: 'Nijer',
  },
  {
    code: 'NG',
    name: 'Nijerya',
  },
  {
    code: 'NU',
    name: 'Niue',
  },
  {
    code: 'NF',
    name: 'Norfolk Adası',
  },
  {
    code: 'MP',
    name: 'Kuzey Mariana Adaları',
  },
  {
    code: 'NO',
    name: 'Norveç',
  },
  {
    code: 'OM',
    name: 'Umman',
  },
  {
    code: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PW',
    name: 'Palau',
  },
  {
    code: 'PS',
    name: 'Filistin Arazisi, İşgal altında',
  },
  {
    code: 'PA',
    name: 'Panama',
  },
  {
    code: 'PG',
    name: 'Papua Yeni Gine',
  },
  {
    code: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PE',
    name: 'Peru',
  },
  {
    code: 'PH',
    name: 'Filipinler',
  },
  {
    code: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PL',
    name: 'Polonya',
  },
  {
    code: 'PT',
    name: 'Portekiz',
  },
  {
    code: 'PR',
    name: 'Porto Riko',
  },
  {
    code: 'QA',
    name: 'Katar',
  },
  {
    code: 'RE',
    name: 'Reunion',
  },
  {
    code: 'RO',
    name: 'Romanya',
  },
  {
    code: 'RU',
    name: 'Rusya Federasyonu',
  },
  {
    code: 'RW',
    name: 'Ruanda',
  },
  {
    code: 'BL',
    name: 'Saint Barthelemy',
  },
  {
    code: 'SH',
    name: 'Saint Helena',
  },
  {
    code: 'KN',
    name: 'Saint Kitts ve Nevis',
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'MF',
    name: 'Aziz Martin',
  },
  {
    code: 'PM',
    name: 'Saint Pierre ve Miquelon',
  },
  {
    code: 'VC',
    name: 'Saint Vincent ve Grenadinler',
  },
  {
    code: 'WS',
    name: 'Samoa',
  },
  {
    code: 'SM',
    name: 'San Marino',
  },
  {
    code: 'ST',
    name: 'Sao Tome ve Principe',
  },
  {
    code: 'SA',
    name: 'Suudi Arabistan',
  },
  {
    code: 'SN',
    name: 'Senegal',
  },
  {
    code: 'RS',
    name: 'Sırbistan',
  },
  {
    code: 'CS',
    name: 'Sırbistan ve Karadağ',
  },
  {
    code: 'SC',
    name: 'Seyşeller',
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SG',
    name: 'Singapur',
  },
  {
    code: 'SX',
    name: 'St Martin',
  },
  {
    code: 'SK',
    name: 'Slovakya',
  },
  {
    code: 'SI',
    name: 'Slovenya',
  },
  {
    code: 'SB',
    name: 'Solomon Adaları',
  },
  {
    code: 'SO',
    name: 'Somali',
  },
  {
    code: 'ZA',
    name: 'Güney Afrika',
  },
  {
    code: 'GS',
    name: 'Güney Georgia ve Güney Sandwich Adaları',
  },
  {
    code: 'SS',
    name: 'Güney Sudan',
  },
  {
    code: 'ES',
    name: 'ispanya',
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SR',
    name: 'Surinam',
  },
  {
    code: 'SJ',
    name: 'Svalbard ve Jan Mayen',
  },
  {
    code: 'SZ',
    name: 'Svaziland',
  },
  {
    code: 'SE',
    name: 'İsveç',
  },
  {
    code: 'CH',
    name: 'İsviçre',
  },
  {
    code: 'SY',
    name: 'Suriye Arap Cumhuriyeti',
  },
  {
    code: 'TW',
    name: "Tayvan, Çin'in bölgesi",
  },
  {
    code: 'TJ',
    name: 'Tacikistan',
  },
  {
    code: 'TZ',
    name: 'Tanzanya Birleşik Cumhuriyeti',
  },
  {
    code: 'TH',
    name: 'Tayland',
  },
  {
    code: 'TL',
    name: 'Doğu Timor',
  },
  {
    code: 'TG',
    name: 'Gitmek',
  },
  {
    code: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TT',
    name: 'Trinidad ve Tobago',
  },
  {
    code: 'TN',
    name: 'Tunus',
  },
  {
    code: 'TR',
    name: 'Türkiye',
  },
  {
    code: 'TM',
    name: 'Türkmenistan',
  },
  {
    code: 'TC',
    name: 'Turks ve Caicos Adaları',
  },
  {
    code: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UA',
    name: 'Ukrayna',
  },
  {
    code: 'AE',
    name: 'Birleşik Arap Emirlikleri',
  },
  {
    code: 'GB',
    name: 'Birleşik Krallık',
  },
  {
    code: 'US',
    name: 'Amerika Birleşik Devletleri',
  },
  {
    code: 'UM',
    name: 'Amerika Birleşik Devletleri Küçük Dış Adaları',
  },
  {
    code: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'UZ',
    name: 'Özbekistan',
  },
  {
    code: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'VE',
    name: 'Venezuela',
  },
  {
    code: 'VN',
    name: 'Vietnam',
  },
  {
    code: 'VG',
    name: 'Virgin Adaları, İngiliz',
  },
  {
    code: 'VI',
    name: 'Virgin Adaları, U.s.',
  },
  {
    code: 'WF',
    name: 'Wallis ve Futuna',
  },
  {
    code: 'EH',
    name: 'Batı Sahra',
  },
  {
    code: 'YE',
    name: 'Yemen',
  },
  {
    code: 'ZM',
    name: 'Zambiya',
  },
  {
    code: 'ZW',
    name: 'Zimbabve',
  },
]
