import { ATTENDANT_STATUS, COLORS } from 'constants/colors'
import { responsive } from 'assets/scss/mixin'

import { FC } from 'react'
import { styled } from 'utils/adapters'

type ButtonType = {
  name: string | React.ReactNode
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  className?: string
  inputColor?: string
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  tagLabel?: string
}

const Button: FC<ButtonType> = ({
  name,
  onClick,
  className,
  inputColor,
  type,
  disabled,
  tagLabel,
}) => {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      inputColor={inputColor}
      type={type}
      disabled={disabled}
    >
      <ButtonName>{name}</ButtonName>
      {!!tagLabel && <TagLabel>{tagLabel}</TagLabel>}
    </StyledButton>
  )
}

const renderColor = (inputColor?: string) => {
  switch (inputColor) {
    case 'primary':
      return `
      background: ${COLORS.WHITE};
      color: ${COLORS.BLUE};
      border: none;
      font-weight: bold;
      `

    case 'stroke':
      return `
        background: transparent;
        color: ${COLORS.WHITE};
        border: 2px solid ${COLORS.WHITE};
      `

    case 'fill':
      return `
      background:${COLORS.BLUE};
      color: ${COLORS.WHITE};
      border: none
      `
    case 'secondary':
      return `
        background:${COLORS.WHITE};
        color: ${COLORS.BLUE};
        border: 1px solid ${COLORS.BLUE};
      `
    case 'imported':
      return `
          background:${COLORS.PAYMENT_FEE_COLOR};
          color: ${COLORS.BLUE};
          border: 1px solid ${COLORS.BLUE};
        `
    case 'registered':
      return `
        background:${ATTENDANT_STATUS.REGISTERD};
        color: ${COLORS.BLUE};
        border: 1px solid ${COLORS.BLUE};
      `
    case 'checkin':
      return `
          background:${ATTENDANT_STATUS.CHECKED_IN};
          color: ${COLORS.BLUE};
          border: 1px solid ${COLORS.BLUE};
        `

    default:
      return `${COLORS.WHITE}`
  }
}

const StyledButton = styled.button<{ inputColor?: string }>`
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 24px 0;
  border-radius: 50px;
  cursor: pointer;
  max-width: 650px;
  transition: opacity 0.25s ease;
  ${({ inputColor }) => renderColor(inputColor)};

  &:focus {
    outline: 0;
  }

  &:disabled,
  &[disabled] {
    background-color: ${COLORS.BACKGROUND_DISABLED};
    color: ${COLORS.COLOR_DISABLED};
  }

  ${responsive.sm`
    padding: 20px
  `}
  &:hover {
    opacity: 0.8;
  }
`

const ButtonName = styled.span`
  font-size: 24px;
  white-space: nowrap;
  ${responsive.sm`
    font-size: 16px;
  `}
`

const TagLabel = styled.span`
  color: white;
  background: rgb(255, 27, 27);
  padding: 4px 20px;
  border-radius: 0px;
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  top: 16px;
  right: -16px;
  transform: rotate(36deg);
`

export default Button
