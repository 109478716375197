import {
  IChangeLog,
  IChangeLogFilter,
  ISendMailLog,
  ISendMailLogFilter,
} from 'models/IChangesLog'
import { getChangesLog, getSendMailLogs } from 'services/changesLogServices'
import { create } from 'zustand'
import { ChangesLogState, SendMailLogState } from './ChangesLogState'
import { LIMIT_PAGINATION } from '@/constants/changesLog'

export const useChangesLogStore = create<ChangesLogState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  changesLogData: [],
  setChangesLogData: (changesLogData: IChangeLog[]) =>
    set({
      changesLogData,
    }),
  filters: {
    organizationId: '',
    page: 1,
    limit: LIMIT_PAGINATION[0],
    timeFrame: '1',
  },
  setFilters: (filters: IChangeLogFilter) =>
    set({
      filters,
    }),
  totalItems: 0,
  setTotalItems: (totalItems: number) =>
    set({
      totalItems,
    }),
}))

export const getChangesLogData = async (orgId: string, params) => {
  try {
    const { data } = await getChangesLog(orgId, params)
    if (data) {
      useChangesLogStore.getState().setChangesLogData(data.data)
      useChangesLogStore.getState().setTotalItems(data.pagination.total)
    }
  } catch (error) {
    useChangesLogStore.getState().setErrorMessage('')
  }
}

export const resetFilters = (organizationId: string) => {
  useChangesLogStore.getState().setFilters({
    organizationId,
    page: 1,
    limit: LIMIT_PAGINATION[0],
    timeFrame: '1',
  })
}

export const useSendMailLogStore = create<SendMailLogState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),

  filters: {
    page: 1,
    limit: LIMIT_PAGINATION[0],
    timeFrame: '1',
  },
  setFilters: (filters: ISendMailLogFilter) =>
    set({
      filters,
    }),
  sendMailLogData: [],
  setSendMailLogData: (sendMailLogData: ISendMailLog[]) =>
    set({
      sendMailLogData,
    }),
  totalItems: 0,
  setTotalItems: (totalItems: number) =>
    set({
      totalItems,
    }),
}))

export const resetFiltersSendMailLog = () => {
  useSendMailLogStore.getState().setFilters({
    page: 1,
    limit: LIMIT_PAGINATION[0],
    timeFrame: '1',
  })
}

export const getMailLogsData = async params => {
  try {
    const { data } = await getSendMailLogs(params)
    if (data) {
      useSendMailLogStore.getState().setSendMailLogData(data.data)
      useSendMailLogStore.getState().setTotalItems(data.pagination.total)
    }
  } catch (error) {
    useSendMailLogStore.getState().setErrorMessage('')
  }
}
