import { Button } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import { Field, Form, arrayMutators, useParams } from 'utils/adapters'
import { ClientFeedbackFormItem } from './ClientFeedbackItem'
import { Input } from '@/components/ui/input'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { trimWhiteSpace } from '@/utils/helpers'
import {
  getFeedbackEventData,
  useFeedbackEventStore,
  getAttendeeFeedback,
  submitAttendeeFeedback,
} from '@/store/zustand/EventFeedback'
import { useEffect, useState } from 'react'
import Loading from '@/components/atoms/Loading'
import { IFeedbackAnswer, IFeedbackItem } from '@/models/IFeedback'
import { Icons } from '@/components/ui/icon'
import { CircleCheckBig } from 'lucide-react'
type ParamsType = {
  id: string
  attendeeId: string
}
const ClientFeedback = ({ previewData }: { previewData: any }) => {
  const { t } = useTranslation()
  const { id, attendeeId } = useParams<ParamsType>()

  const { feedbackEventData, attendeeFeedbackInfo, isLoading } =
    useFeedbackEventStore()

  const [feedbacksData, setFeedbacksData] = useState<any>(previewData)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (!previewData) {
        await getFeedbackEventData(id)
        if (attendeeId) {
          await getAttendeeFeedback(id, attendeeId)
        }
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (!previewData && feedbackEventData) {
      setFeedbacksData(feedbackEventData)
    }
  }, [feedbackEventData])

  useEffect(() => {
    if (!previewData && attendeeFeedbackInfo) {
      setFeedbacksData({
        ...feedbacksData,
        email: attendeeFeedbackInfo?.email,
        questions: feedbacksData?.questions?.map((question: IFeedbackItem) => ({
          ...question,
          value: attendeeFeedbackInfo?.answers?.find(
            (answer: IFeedbackAnswer) => answer.questionId === question.uid
          )?.value,
        })),
      })
    }
    if (attendeeFeedbackInfo?.answers) {
      setIsSubmitted(true)
    }
  }, [attendeeFeedbackInfo])

  const onSubmit = async (values: any) => {
    setIsSubmitting(true)
    const data = {
      email: values.email,
      answers: values?.questions?.map(item => ({
        questionId: item.uid,
        value: item.value,
        additionalComment: item.additional_comment,
      })),
    }
    await submitAttendeeFeedback(id, data)
    setIsSubmitted(true)
    setIsSubmitting(false)
  }

  const required = (value: any) =>
    value ? undefined : t('This field is required!')

  if (isLoading) return <Loading />
  if (!feedbacksData) return null

  return isSubmitted ? (
    <>
      <div className='bg-slate-100 w-full h-screen flex items-center'>
        <div className='space-y-6 px-8 py-8 focus-visible:ring-0 max-w-[1200px] mx-auto w-full'>
          <div className='flex flex-col gap-4 justify-between bg-white px-4 py-10 mb-4 w-full'>
            <div className='mx-auto'>
              <CircleCheckBig
                className='text-[#2db92f] w-[80px] h-[80px]'
                strokeWidth='2'
              />
            </div>
            <p className='text-3xl font-bold text-center text-primary'>
              {t('Thank you for your feedback!')}
            </p>
            {attendeeId && (
              <Button
                onClick={() => setIsSubmitted(false)}
                className='w-full md:w-auto mx-auto mt-4'
              >
                {t('Edit your feedback')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className='bg-slate-100 min-h-screen'>
      <div className='space-y-6 px-8 py-8 focus-visible:ring-0 max-w-[1200px] mx-auto'>
        <Form
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          keepDirtyOnReinitialize
          initialValues={{ ...feedbacksData }}
          render={({
            handleSubmit,
            values,
            submitting,
            invalid,
            form: { getState },
          }: any) => (
            <form onSubmit={handleSubmit}>
              <div className='mb-3'>
                <div className='flex flex-col gap-4 justify-between bg-white px-4 py-6 mb-4'>
                  <p
                    className='text-3xl font-bold border-b border-gray-300 px-0 rounded-none border-t-0 border-x-0 py-2 focus-visible:ring-0 
												focus-visible:ring-offset-0 focus:border-b-2 focus:border-primary'
                  >
                    {feedbacksData.title}
                  </p>
                  <p className='text-base'>{feedbacksData.content}</p>
                </div>
                <div className='flex flex-col gap-4 justify-between bg-white px-4 py-6 mb-4'>
                  <Field
                    name={'email'}
                    type='text'
                    validate={required}
                    format={trimWhiteSpace}
                    formatOnBlur
                  >
                    {({ input, meta }: any) => (
                      <FormItem className='w-full'>
                        <FormLabel
                          meta={meta}
                          isRequired={!attendeeFeedbackInfo?.email}
                          className='font-bold text-lg'
                        >
                          {t('Email')}
                        </FormLabel>
                        <Input
                          {...input}
                          meta={meta}
                          disabled={attendeeFeedbackInfo?.email}
                          className='font-bold border-b border-gray-300 px-0 rounded-none border-t-0 border-x-0 py-6 focus-visible:ring-0 
												focus-visible:ring-offset-0 focus:border-b-2 focus:border-primary'
                        />
                      </FormItem>
                    )}
                  </Field>
                </div>

                {feedbacksData?.questions?.map(
                  (feedback: any, index: number) => (
                    <ClientFeedbackFormItem
                      key={feedback.uid}
                      index={index}
                      label={feedback.label}
                      type={feedback.type}
                      isRequired={feedback.required}
                    />
                  )
                )}
              </div>

              {!previewData && (
                <div className='flex flex-col md:flex-row gap-4'>
                  <Button
                    type='submit'
                    className='w-full rounded-none'
                    disabled={submitting}
                  >
                    {isSubmitting && (
                      <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                    )}
                    {t('client_feedback.submit_btn')}
                  </Button>
                </div>
              )}
            </form>
          )}
        />
      </div>
    </div>
  )
}

export default ClientFeedback
