import { create } from 'zustand'
import { InvitationListState } from './InvitationList'
import { getRespondedInvitationListReq } from 'services/invitationListService'
import { InvitationRole, TInvitationList } from '@/models/IInvitationList'

export const useInvitationList = create<InvitationListState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) =>
    set({
      isLoading,
    }),
  invitationList: [],
  setInvitationList: (invitationList: TInvitationList[]) =>
    set({
      invitationList,
    }),
}))

export const getInvitationList = async (eventId: string) => {
  try {
    useInvitationList.getState().setIsLoading(true)

    const { data } = await getRespondedInvitationListReq(eventId)
    if (data) {
      const remappedInvitationList = remapInvitationList(data.data)
      useInvitationList.getState().setInvitationList(remappedInvitationList)
    }
  } catch (error: any) {
    useInvitationList.getState().setErrorMessage(error.message) // Set error message
  } finally {
    useInvitationList.getState().setIsLoading(false) // Ensure loading state is reset
  }
}

const remapInvitationList = (invitationList: {
  attendees: TInvitationList[]
  guests: TInvitationList[]
}): TInvitationList[] => {
  const { attendees, guests } = invitationList
  const remappedInvitationList = [
    ...attendees?.map(attendee => ({
      ...attendee,
      role: InvitationRole.Attendee,
    })),
    ...guests?.map(guest => ({
      ...guest,
      role: InvitationRole.Guest,
    })),
  ]
  return remappedInvitationList
}
