import { updateAttendeeDetailReq } from '@/services/attendeesService'

// export const useReleaseStore =

export const updateAttendeeDetail = async (eventId, attendeeId, data) => {
  try {
    const result = await updateAttendeeDetailReq(eventId, attendeeId, data)
    if (result) return result.data
  } catch (err: any) {}
}
