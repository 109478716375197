import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { CustomFieldType } from '@/models'
import { PATH_NAME } from '@/routes/routesMap'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { ChevronLeft, Plus, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { setFormData, setStep } from 'store/Events'
import { FieldArray, Form, arrayMutators, useParams } from 'utils/adapters'
import { FormBuilderInputItem } from './FormBuilderInputItem'
import { ToggleDefaultFields } from './ToggleDefaulFields'
import { SaveChangesNowButton } from '@/components/molecules/SaveChangesNow'
import DragnDrop from '@/components/molecules/DragnDrop'
import DragableItem from '@/components/molecules/DragnDrop/DragableItem'
import { reorder } from '@/utils/helpers'
import HelpText from '@/components/atoms/HelpText'

type ParamsType = {
  id: string
}

export const FormBuilder = () => {
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()
  const dispatch = useAppDispatch()
  const matchEditRoute = useRouteMatch(PATH_NAME.EDIT_EVENT)

  const { step, formData } = useAppSelector(state => state.eventsReducer)

  const onSubmit = (values: any) => {
    // format options
    const formattedValue = values?.customSelections?.map(question => ({
      ...question,
      options: question?.options?.map(option => ({
        ...option,
        label: option?.value,
      })),
    }))

    delete values.toggleDefaultFields.email

    dispatch(
      setFormData({
        ...formData,
        customSelections: formattedValue,
        toggleDefaultFields: values.toggleDefaultFields,
      })
    )
    dispatch(setStep(step + 1))
  }

  const handleGoBack = () => {
    dispatch(setStep(step - 1))
  }

  const formValue: any = {
    customSelections: formData?.customSelections,
    toggleDefaultFields: formData?.toggleDefaultFields,
  }

  return (
    <div className='space-y-6 px-8 py-4'>
      <div>
        <h3 className='text-lg font-medium'>{t('Form Builder')}</h3>
        <p className='text-sm text-muted-foreground'>
          {t('Customize event form inputs.')}
        </p>
      </div>
      <Separator />
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        keepDirtyOnReinitialize
        initialValues={{ ...formValue }}
        render={({
          handleSubmit,
          values,
          submitting,
          form: { getState, change },
        }: any) => (
          <form onSubmit={handleSubmit}>
            <ChevronLeft
              onClick={() => handleGoBack()}
              className={cn(
                'absolute top-[15px] left-14 cursor-pointer md:top-[28px] md:left-24',
                !matchEditRoute && 'text-white left-5 md:left-8'
              )}
            />
            <ToggleDefaultFields />

            <div className='mb-3'>
              <FieldArray name='customSelections'>
                {({ fields, meta: { error } }) => (
                  <>
                    {values?.customSelections?.length > 0 && (
                      <DragnDrop
                        onDragEnd={result => {
                          if (!result.destination) {
                            return
                          }
                          const _items = reorder(
                            values.customSelections,
                            result.source.index,
                            result.destination.index
                          )
                          dispatch(
                            setFormData({
                              ...formData,
                              customSelections: _items,
                            })
                          )
                          change('customSelections', _items)
                        }}
                      >
                        {fields.map((name, index) => {
                          return (
                            <DragableItem
                              className='mb-2'
                              key={index}
                              index={index}
                              item={{ _id: `${index}` }}
                            >
                              <div
                                key={name}
                                className='flex gap-4 justify-between bg-slate-100 px-4 py-6'
                              >
                                <div className='font-bold'>
                                  <HelpText
                                    text={`Q${index + 15}`}
                                    helpMessage={t(
                                      'custom_selections.help_message_custom_question'
                                    )}
                                  />
                                </div>
                                <div className='w-[85%]'>
                                  <FormBuilderInputItem
                                    name={name}
                                    index={index}
                                  />
                                </div>

                                <Trash2
                                  onClick={() => fields.remove(index)}
                                  className='w-[20px] text-gray-600 cursor-pointer'
                                />
                              </div>
                            </DragableItem>
                          )
                        })}
                      </DragnDrop>
                    )}
                    <button
                      className='-z-1 flex items-center cursor-pointer space-x-2 mt-6 text-primary font-bold'
                      onClick={() =>
                        fields.push({
                          label: '',
                          type: CustomFieldType.TEXT,
                          required: false,
                          enabled: true,
                        })
                      }
                    >
                      <Plus className='w-[20px] mr-1' />
                      {t('Add new questions')}
                    </button>
                  </>
                )}
              </FieldArray>
            </div>

            <div className='flex flex-col md:flex-row gap-4'>
              {matchEditRoute && (
                <SaveChangesNowButton
                  id={id}
                  handleGetFormValues={() => {
                    delete getState().values.toggleDefaultFields.email
                    return {
                      ...formData,
                      ...getState().values,
                    }
                  }}
                />
              )}
              <Button type='submit' className='w-full' disabled={submitting}>
                {t('Continue')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}
