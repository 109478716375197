import { createSlice } from '@reduxjs/toolkit'

const CustomFieldReducer = createSlice({
  name: 'customField',
  initialState: {
    selectedCustomField: {} as any,
    isSelected: true,
    step: 0,
  },
  reducers: {
    setSelectedCustomField: (state, action) => {
      state.selectedCustomField = action.payload
    },
    setIsSelected: (state, action) => {
      state.isSelected = action.payload
    },
    setCustomFieldStep: (state, action) => {
      state.step = action.payload
    },
  },
})

export const { setSelectedCustomField, setIsSelected, setCustomFieldStep } =
  CustomFieldReducer.actions
export default CustomFieldReducer.reducer
