import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GET_ORGANIZATIONS } from 'graphql/queries'
import { ACTIVE_ORGANIZATION, REMOVE_ORGANIZATION } from 'graphql/mutations'

import { styled, useHistory, useMutation, useQuery } from 'utils/adapters'
import { useAppDispatch, useModal } from 'utils/hooks'

import GenericTable from 'components/organisms/GenericTable'
import Modal from 'components/molecules/Modal'

import { CheckIcon, EditIcon, RemoveIcon } from 'assets/images'

import { IMAGES } from 'constants/colors'
import { MESSAGE } from 'constants/message'
import Loading from '@/components/atoms/Loading'
import Icon from '@/components/atoms/Icon'
import { cn } from '@/lib/utils'
import { UserStatuses } from '@/components/pages/Users/components/UsersTable'
import { Badge } from '@/components/ui/badge'
import { PATH_NAME } from '@/routes/routesMap'
import { Plus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'

const OrganizationTable: FC = () => {
  const { t } = useTranslation()

  const fetchIdRef = useRef(0)
  const { isShow, showModal, hideModal } = useModal()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [tableData, setTableData] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [selectedRow, setSelectedRow] = useState<any>()

  const { data: OrganizationData, loading: OrganizationLoading } = useQuery(
    GET_ORGANIZATIONS,
    {
      onCompleted: ({ organizations }) => {
        setTableData(organizations)
      },
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  const [mutateRemoveOrganization, { loading: removeOrganizationLoading }] =
    useMutation(REMOVE_ORGANIZATION, {
      onCompleted() {
        toast({
          description: t('Deactivate organization success'),
        })
      },
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    })

  const [mutateActiveOrganization, { loading: activeOrganizationLoading }] =
    useMutation(ACTIVE_ORGANIZATION, {
      onCompleted() {
        toast({
          description: t('Active organization success'),
        })
      },

      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    })

  const columns = useMemo(
    () => [
      {
        header: t('Name'),
        accessor: 'name',
        id: 'name',
        accessorFn: row => `${row?.name}`,
      },

      {
        header: t('Email'),
        accessor: 'email',
        id: 'email',
        accessorFn: row => `${row?.email}`,
      },
      {
        header: t('Allowed amount of Users'),
        accessor: 'capacity',
        id: 'capacity',
        accessorFn: row => row?.capacity,
      },

      {
        header: t('Status'),
        accessor: (d: any) => d.isRemoved,
        id: 'status',
        cell: ({ row }: any) => {
          const status = UserStatuses.find(
            status =>
              status.value === (!row.original.isRemoved ? 'active' : 'inactive')
          )

          if (!status) {
            return null
          }

          return (
            <div className='flex w-[100px] items-center'>
              <Badge variant='secondary'>
                {status.icon && (
                  <status.icon
                    className={cn(
                      'mr-2 h-4 w-4 text-muted-foreground',
                      status.value === 'active'
                        ? 'text-green-500'
                        : 'text-red-500'
                    )}
                  />
                )}
                <span>{status.label}</span>
              </Badge>
            </div>
          )
        },
      },

      {
        header: t('Actions'),
        id: 'actions',
        cell: ({ row }: any) => (
          <ActionContainer>
            <ActionIcon
              onClick={() => {
                history.push(`/organizations/edit/${row.original._id}`)
              }}
              source={EditIcon}
            />

            {row.original.isRemoved ? (
              <>
                <ActionIcon
                  source={CheckIcon}
                  onClick={() => {
                    setSelectedRow(row)
                    showModal()
                  }}
                />
              </>
            ) : (
              <>
                <StyleRemoveIcon
                  onClick={() => {
                    setSelectedRow(row)
                    showModal()
                  }}
                  source={RemoveIcon}
                />
              </>
            )}
          </ActionContainer>
        ),
      },
    ],
    []
  )

  const onConfirm = () => {
    if (selectedRow.original.isRemoved) {
      mutateActiveOrganization({
        variables: {
          activeOrganizationId: selectedRow.original._id,
        },
      })
    } else {
      mutateRemoveOrganization({
        variables: {
          removeOrganizationId: selectedRow.original._id,
        },
      })
    }

    hideModal()
  }

  return OrganizationLoading ||
    removeOrganizationLoading ||
    activeOrganizationLoading ? (
    <Loading />
  ) : (
    <>
      <div className='text-primary right-16 flex justify-end mb-2'>
        <Button onClick={() => history.push(PATH_NAME.CREATE_ORGANIZATION)}>
          <Plus className='cursor-pointer mr-2 h-5' />
          {t('Add new')}
        </Button>
      </div>
      <GenericTable
        columns={columns}
        data={tableData}
        noDataText={t('There are no organizations yet')}
        pageCount={pageCount}
        pageName='organizationPageIndex'
      />
      <Modal
        isShow={isShow}
        title={t('Are you sure to {{action}} this organization?', {
          action: selectedRow?.original?.isRemoved ? 'active' : 'remove',
        })}
        onClose={hideModal}
        onConfirm={onConfirm}
      >
        <Content>
          <span>
            {selectedRow?.original?.isRemoved
              ? t(MESSAGE.CONFIRM_ACTIVE)
              : t(MESSAGE.CONFIRM_DEACTIVE)}
          </span>
        </Content>
      </Modal>
    </>
  )
}

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`

const ActionIcon = styled(Icon)`
  cursor: pointer;

  img {
    filter: none;

    &:hover {
      opacity: 0.8;
    }
  }
`

const StyleRemoveIcon = styled(ActionIcon)`
  img {
    filter: ${IMAGES.NONE};
  }
`

const StatusText = styled.span<{ isRemoved: boolean }>`
  color: ${props => (props.isRemoved ? 'red' : 'green')};
`

const Content = styled.div`
  padding: 10px;
`

export default OrganizationTable
