import { BadgeCurrency } from '@/components/atoms/BadgeCurrency'
import Loading from '@/components/atoms/Loading'
import { toast } from '@/components/ui/use-toast'
import { EEventType } from '@/constants/events'
import { LANGUAGE } from '@/constants/language'
import { PUBLIC_GET_EVENT_BY_ID } from '@/graphql/queries/event'
import { CustomFieldOption, EventCustomValue } from '@/models/IEvent'
import { getQRInfo, useQRInfo } from '@/store/zustand/QRManagement'
import { useParams, useQuery } from '@/utils/adapters'
import {
  defaultTimezone,
  getEventDateByTimeZone,
  getEventTime,
  joinStrings,
  priceFormat,
} from '@/utils/helpers'
import {
  CalendarDays,
  ChevronDown,
  ChevronUp,
  Clock,
  MapPin,
} from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const TRUNCATE_POSITION = 1000

const QRAttendeeInfo: FC = () => {
  const { eventId, attendeeId } = useParams<{
    eventId: string
    attendeeId: string
  }>()
  const { t } = useTranslation()
  const info = useQRInfo(state => state.qrInfo)
  const isLoading = useQRInfo(state => state.isLoading)

  const [isExpanded, setIsExpanded] = useState(false)
  const [agendaURL, setAgendaURL] = useState(undefined)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  const { data: eventData, loading: eventLoading } = useQuery(
    PUBLIC_GET_EVENT_BY_ID,
    {
      onCompleted() {
        setAgendaURL(eventData?.event?.agendaUrl)
      },
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
      variables: {
        id: eventId,
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (eventData && agendaURL !== undefined) {
      getQRInfo(eventId, attendeeId, agendaURL)
    }
  }, [eventData?.event, agendaURL])

  const event = info?.event
  const attendee = info?.attendee
  const isDigitalEvent = event?.type === EEventType.DIGITAL
  const tz = event?.timezone || defaultTimezone
  const lang = LANGUAGE.ENGLISH
  const { isTheSameDay, startTime, endTime, utcOffset, isTheSameTime } =
    getEventTime(event?.mainLanguage, tz, event?.startEvent, event?.endEvent)

  if (isLoading || eventLoading) return <Loading />

  return (
    <div className='p-4 md:p-20 bg-[#F3F2F4] md:py-4 md:px-32'>
      <div className='max-w-[500px]'>
        {event?.eventSetting?.logo && (
          <img src={event.eventSetting.logo} className='rounded-[25px]' />
        )}
      </div>

      <div className='flex gap-2 mt-3 md:mt-5 mb-3 md:mb-5 p-4 md:p-6 rounded-[25px] text-gray-600 shadow-lg bg-white items-start justify-between'>
        <div className='flex flex-col gap-2'>
          <div className='text-[22px] font-bold text-primary'>
            {event?.title}
          </div>
          {!isDigitalEvent && (
            <div className='flex gap-2 items-center text-sm'>
              <MapPin width='16' height='16' className='text-gray-500' />
              {joinStrings(
                event?.hotelName || '',
                event?.address?.street || '',
                event?.address?.city || '',
                event?.address?.country || ''
              )}
            </div>
          )}
          <div className='flex gap-2 items-center text-sm'>
            <CalendarDays width='16' height='16' className='text-gray-500' />
            {getEventDateByTimeZone(event?.startEvent, lang, tz)}{' '}
            {!isTheSameDay &&
              ` - ${getEventDateByTimeZone(event?.endEvent, lang, tz)}`}
          </div>
          {isTheSameDay && (
            <div className='flex items-center gap-2 justify-start text-sm'>
              <Clock className='text-gray-500' width='16' height='16' />
              <p className=''>
                {isTheSameTime
                  ? `${startTime} (GMT${utcOffset})`
                  : `${startTime} - ${endTime} (GMT${utcOffset})`}
              </p>
            </div>
          )}
        </div>

        {!event?.isBookable ||
          (event?.price !== 0 && (
            <div className='bg-[#F3F2F4] rounded-[15px] p-3 w-[100px] text-center'>
              <div className='flex items-center justify-center font-extrabold text-lg'>
                <BadgeCurrency currency={event?.currency} strokeWidth={3} />
                <p className=''>{priceFormat(event?.price, lang)}</p>
              </div>
              <p className='text-gray-400 text-sm'>/ {t('person')}</p>
            </div>
          ))}
      </div>
      {event?.description && (
        <div className='mb-3 md:mb-5 rounded-[25px] bg-white p-4 md:p-6'>
          <div className='font-bold mb-2 text-gray-500'>
            {t('qr_info.event_section.title')}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: isExpanded
                ? event.description
                : `${event.description.slice(0, TRUNCATE_POSITION)}${
                    event.description.length > TRUNCATE_POSITION ? '...' : ''
                  }`,
            }}
          />
          {event.description.length > TRUNCATE_POSITION && (
            <span
              onClick={toggleExpand}
              className='w-full mt-2 text-gray-500 flex items-center gap-1 justify-center'
            >
              {isExpanded
                ? t('qr_info.see_less_btn')
                : t('qr_info.see_more_btn')}
              {isExpanded ? (
                <ChevronUp width='16' height='16' />
              ) : (
                <ChevronDown width='16' height='16' />
              )}
            </span>
          )}
        </div>
      )}

      <div className='mb-3 md:mb-5 rounded-[25px] bg-white p-4 md:p-6'>
        <div className='font-bold mb-2 text-gray-500'>
          {t('qr_info.attendee_section.title')}
        </div>
        <div className='grid grid-cols-1 gap-4'>
          <div className='flex flex-wrap justify-between'>
            <p className='font-medium text-gray-500 text-base'>
              {t('Full name')}
            </p>
            <p className='text-gray-500 text-base break-words overflow-wrap'>
              {attendee?.firstName} {attendee?.lastName}
            </p>
          </div>
          <div className='flex flex-wrap justify-between'>
            <p className='font-medium text-gray-500 text-base text-pretty break-words'>
              {t('Email')}
            </p>
            <p className='text-gray-500 text-base break-words overflow-wrap'>
              {attendee?.email}
            </p>
          </div>
          {attendee?.jobTitle && (
            <div className='flex flex-wrap justify-between'>
              <p className='font-medium text-gray-500 text-base'>
                {t('Job title')}
              </p>
              <p className='text-gray-500 text-base break-words overflow-wrap'>
                {attendee.jobTitle}
              </p>
            </div>
          )}
          {attendee?.company && (
            <div className='flex flex-wrap justify-between'>
              <p className='font-medium text-gray-500 text-base'>
                {t('Company')}
              </p>
              <p className='text-gray-500 text-base break-words overflow-wrap'>
                {attendee.company}
              </p>
            </div>
          )}
          <div className='flex flex-wrap justify-between'>
            <p className='font-medium text-gray-500 text-base'>
              {t('Address')}
            </p>
            <p className='text-gray-500 text-base break-words overflow-wrap'>
              {joinStrings(
                attendee?.address?.street || '',
                attendee?.address?.city || '',
                attendee?.address?.country || ''
              )}
            </p>
          </div>
          {attendee?.workPhone && attendee?.workPhone !== '_' && (
            <div className='flex flex-wrap justify-between'>
              <p className='font-medium text-gray-500 text-base'>
                {t('Work phone')}
              </p>
              <p className='text-gray-500 text-base break-words overflow-wrap'>
                {attendee.workPhone}
              </p>
            </div>
          )}
          {attendee?.mobilePhone && attendee?.mobilePhone !== '_' && (
            <div className='flex flex-wrap justify-between'>
              <p className='font-medium text-gray-500 text-base'>
                {t('Mobile phone')}
              </p>
              <p className='text-gray-500 text-base break-words overflow-wrap'>
                {attendee.mobilePhone}
              </p>
            </div>
          )}
          {attendee?.fax && attendee?.fax !== '_' && (
            <div className='flex flex-wrap justify-between'>
              <p className='font-medium text-gray-500 text-base'>{t('Fax')}</p>
              <p className='text-gray-500 text-base break-words overflow-wrap'>
                {attendee.fax}
              </p>
            </div>
          )}
        </div>
      </div>
      {attendee?.registration?.customValues &&
        attendee?.registration?.customValues?.length > 0 && (
          <div className='mb-3 md:mb-5 rounded-[25px] bg-white p-4 md:p-6'>
            <div className='font-bold mb-2 text-gray-500'>
              {t('Custom Fields')}
            </div>
            <div className='grid grid-cols-1 gap-4'>
              {attendee?.registration?.customValues?.map(
                (customField: EventCustomValue) => {
                  return (
                    <div
                      className='flex flex-wrap justify-between'
                      key={customField?.eventCustomField?._id}
                    >
                      <p className='font-medium text-gray-500 text-base'>
                        {customField?.eventCustomField?.title}
                      </p>
                      <p className='text-gray-500 text-base break-words overflow-wrap'>
                        {customField?.value
                          .map((option: CustomFieldOption) => option?.name)
                          .join(', ')}
                      </p>
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )}
      {event?.isBookable && (
        <div className='mb-3 md:mb-5 rounded-[25px] bg-white p-4 md:p-6'>
          <div className='font-bold mb-2 text-gray-500'>
            {t('qr_info.total_section.title')}
          </div>
          <>
            <div className='grid grid-cols-[2fr_1fr] gap-4 mb-6'>
              <p className='font-medium text-gray-500 text-base'>
                {t('Ticket')}
              </p>
              <p className='text-gray-500 text-base flex items-center justify-center'>
                <BadgeCurrency currency={event?.currency} />
                <p className=''>{priceFormat(event?.price, lang)}</p>
              </p>
            </div>
            {attendee?.registration.customValues.map(
              (customField: EventCustomValue) => {
                const optionsWithFees = customField.value.filter(
                  (option: CustomFieldOption) => option.fee > 0
                )
                if (optionsWithFees.length) {
                  return (
                    <div
                      className='mb-6'
                      key={customField?.eventCustomField?._id}
                    >
                      <p className='font-medium text-gray-500 text-base col-span-3'>
                        {customField.eventCustomField.title}
                      </p>
                      {optionsWithFees.map((option: CustomFieldOption) => (
                        <div
                          className='grid grid-cols-[2fr_1fr] gap-4 mt-2'
                          key={option.name}
                        >
                          <p className='font-medium text-gray-500 text-base ml-5'>
                            {option.name}
                          </p>
                          <p className='text-gray-500 text-base flex items-center justify-center'>
                            <BadgeCurrency currency={event?.currency} />
                            <p className=''>{priceFormat(option.fee, lang)}</p>
                          </p>
                        </div>
                      ))}
                    </div>
                  )
                }
              }
            )}
          </>
          <div className='grid grid-cols-[2fr_1fr] gap-4 bg-[#E4EDF4] py-2'>
            <p className='font-medium text-gray-500 text-base ml-2'>
              {t('qr_info.total_section.total')}
            </p>
            <p className='text-gray-500 text-base flex items-center justify-center'>
              <BadgeCurrency currency={event?.currency} />
              <p className=''>{priceFormat(attendee?.totalPrice, lang)}</p>
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default QRAttendeeInfo
