import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery } from 'utils/adapters'

import BasicTable from '@/components/organisms/BasicTable'
import { Badge } from '@/components/ui/badge'
import { COLORS } from '@/constants/colors'
import { GET_ORGANIZATIONS } from '@/graphql/queries'
import { isRootAdmin } from '@/utils/helpers'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { ColumnDef } from '@tanstack/react-table'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { PencilLine, Plus, Trash2 } from 'lucide-react'
import {
  deleteStatus,
  getListStatus,
  useStatusManagement,
} from 'store/zustand/StatusManagement'
import { Button } from '@/components/ui/button'
import AddStatusModal from './AddStatusModal'
import { IStatus } from '@/models/IStatus'
import { MESSAGE } from '@/constants/message'
import Modal from '@/components/molecules/Modal'

const StatusManagement: FC = () => {
  const { t } = useTranslation()

  const user = JSON.parse(sessionStorage.getItem('user') as string)

  const listStatus = useStatusManagement(state => state.listStatus)
  const isLoading = useStatusManagement(state => state.isLoading)

  const [selectedOrg, setSelectedOrg] = useState<string>('')
  const [openModal, setOpenModal] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedDeleteStatusId, setSelectedDeleteStatusId] = useState('')
  const [selectedStatus, setSelectedStatus] = useState<IStatus | null>(null)

  const { data: organizationsData } = useQuery(GET_ORGANIZATIONS)

  const organizationsOption = organizationsData?.organizations.map(
    organization => ({
      value: organization._id,
      label: organization.name,
    })
  )

  useEffect(() => {
    const organizationId = isRootAdmin()
      ? organizationsOption && organizationsOption[0].value
      : user.organization._id

    if (organizationId) setSelectedOrg(organizationId)
  }, [organizationsData])

  useEffect(() => {
    if (selectedOrg) getListStatus(selectedOrg)
  }, [selectedOrg])

  const handleDeleteStatus = async (id: string) => {
    await deleteStatus(selectedOrg, id)
    setOpenModalConfirm(false)
  }

  const columns: ColumnDef<any, any>[] = [
    {
      header: t('No'),
      cell: ({ row }) => <b>{row?.index + 1}</b>,
    },
    {
      header: t('status_management.column.status'),
      accessorKey: 'name',
      cell: ({ row }) => (
        <Badge
          style={{
            color: setTextColorBasedOnBackground(
              row.original.bgColor || COLORS.BLUE
            ),
            backgroundColor: row.original.bgColor || COLORS.BLUE,
          }}
        >
          {row.original.name}
        </Badge>
      ),
    },
    {
      header: t('status_management.column.desc'),
      accessorKey: 'description',
    },

    {
      header: t('status_management.column.action'),
      cell: ({ row }) => (
        <div className='flex gap-4'>
          <PencilLine
            className='cursor-pointer'
            width={20}
            onClick={() => {
              setOpenModal(true)
              setIsEditMode(true)
              setSelectedStatus(row.original)
            }}
          />
          <Trash2
            className='cursor-pointer'
            width={20}
            onClick={() => {
              setOpenModalConfirm(true)
              setSelectedDeleteStatusId(row.original._id)
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <>
      <div className='flex flex-col'>
        <div className='mx-auto overflow-x-auto p-[16px] max-w-full md:p-[40px] w-full'>
          <div className='flex justify-between'>
            {isRootAdmin() && organizationsOption && (
              <Select
                defaultValue={organizationsOption[0].value}
                onValueChange={value => setSelectedOrg(value)}
              >
                <SelectTrigger className='w-[180px]'>
                  <SelectValue placeholder='Select organization' />
                </SelectTrigger>
                <SelectContent>
                  {organizationsOption?.map(option => (
                    <SelectItem key={option.value} value={String(option.value)}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
            <div className='text-primary right-16 flex justify-end mb-2'>
              <Button onClick={() => setOpenModal(true)}>
                <Plus className='cursor-pointer mr-2 h-5' />
                {t('status_management.add_btn')}
              </Button>
            </div>
          </div>
          <BasicTable
            columns={columns}
            data={listStatus}
            totalItems={0}
            hasPagination={false}
            loading={isLoading}
          />
        </div>
      </div>
      <AddStatusModal
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        open={openModal}
        onClose={() => {
          setOpenModal(false)
          setSelectedStatus(null)
          setIsEditMode(false)
        }}
        selectedOrg={selectedOrg}
        title={
          isEditMode
            ? t('status_management.modal.edit_title')
            : t('status_management.modal.add_title')
        }
      />
      <Modal
        isShow={openModalConfirm}
        title={t(MESSAGE.WARNING)}
        description={t('status_management.modal.confirm_delete_msg')}
        onClose={() => setOpenModalConfirm(false)}
        onConfirm={() => handleDeleteStatus(selectedDeleteStatusId)}
      />
    </>
  )
}

export default StatusManagement
