import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/molecules/Modal'
import { styled, useMutation } from 'utils/adapters'
import {
  ActionRefundCancelBookingStatus,
  defaultToastDisplayTime,
  getEventDateByTimeZone,
  renderSymbolCurrency,
} from 'utils/helpers'
import {
  ACTION_REFUND_CANCEL_BOOKING,
  MANUAL_REFUND_CANCEL_BOOKING,
} from 'graphql/mutations'
import { setToast } from 'store/Toast'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { Cancellation, Currency } from 'models'
import { COLORS } from 'constants/colors'
import { responsive } from 'assets/scss'

interface IProps {
  isShow: boolean
  setIsShow: React.Dispatch<React.SetStateAction<boolean>>
  _id: string
  actionRefundWaitingInfo: {
    action: ActionRefundCancelBookingStatus
    attendeeEmail: string
    cancellation: Cancellation
    registrationCodes: string[]
  }
  tz: string
  currency: Currency | undefined
}

const ActionRefundCancelBookingModal: FC<IProps> = ({
  _id,
  isShow,
  setIsShow,
  actionRefundWaitingInfo,
  tz,
  currency,
}) => {
  const { t } = useTranslation()
  const { lang } = useAppSelector(state => state.appReducer)
  const dispatch = useAppDispatch()
  const { action, attendeeEmail, cancellation, registrationCodes } =
    actionRefundWaitingInfo

  const handleErrorMessageRefundCancelBooking = (message: string) => {
    switch (message) {
      case 'CANNOT_PERFORM_THIS_ACTION':
        dispatch(
          setToast({
            message: t('Cannot perform this action'),
            variant: 'danger',
            displayTime: defaultToastDisplayTime,
          })
        )
        return null
      case 'CANNOT_REFUND_THIS_REQUEST':
        dispatch(
          setToast({
            message: t(
              'Cannot refund this request, please go to Stripe and do it manually'
            ),
            variant: 'danger',
            displayTime: defaultToastDisplayTime,
          })
        )
        return null
      default:
        dispatch(
          setToast({
            message,
            variant: 'danger',
            displayTime: defaultToastDisplayTime,
          })
        )
        return null
    }
  }

  const [mutateActionRefundCancelBooking] = useMutation(
    ACTION_REFUND_CANCEL_BOOKING,
    {
      onCompleted({ actionRefundCancelBooking }) {
        // ACTION_REQUEST_REFUND
        if (actionRefundCancelBooking.message) {
          dispatch(
            setToast({
              message: t('Success!'),
              variant: 'success',
              displayTime: defaultToastDisplayTime,
            })
          )
        }
      },
      update(cache) {
        const normalizedId = cache.identify({
          _id,
          __typename: 'Event',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      onError: err => {
        err.graphQLErrors.map(({ message }) =>
          handleErrorMessageRefundCancelBooking(message)
        )
      },
    }
  )

  const [mutateManualRefundCancelBooking] = useMutation(
    MANUAL_REFUND_CANCEL_BOOKING,
    {
      onCompleted({ manuallyRefundedCancelBooking }) {
        if (manuallyRefundedCancelBooking.message) {
          dispatch(
            setToast({
              message: t('Success!'),
              variant: 'success',
              displayTime: defaultToastDisplayTime,
            })
          )
        }
      },
      update(cache) {
        const normalizedId = cache.identify({
          _id,
          __typename: 'Event',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
      onError: err => {
        err.graphQLErrors.map(({ message }) =>
          handleErrorMessageRefundCancelBooking(message)
        )
      },
    }
  )

  const onConfirm = () => {
    mutateActionRefundCancelBooking({
      variables: {
        action,
        attendeeEmail,
        eventId: _id,
      },
    })
    setIsShow(false)
  }

  const onClickManualRefund = () => {
    mutateManualRefundCancelBooking({
      variables: {
        attendeeEmail,
        eventId: _id,
      },
    })
    setIsShow(false)
  }

  if (!isShow) {
    return null
  }

  return (
    <Modal
      isShow={isShow}
      title={t('Are you sure you want to {{action}} this refund request?', {
        action: t(action),
      })}
      onClose={() => setIsShow(false)}
      onConfirm={onConfirm}
    >
      <Content>
        <p>
          {t('Stripe Transaction(s)')}: {registrationCodes?.toString()}
        </p>
        <p>
          {t('Reason')}: {cancellation.cancellationReason}
        </p>
        <p>
          {t('Date')}:{' '}
          {getEventDateByTimeZone(
            cancellation.cancellationDate.toString(),
            lang,
            tz
          )}
        </p>
        <p>
          {t('Fee')}: {cancellation.cancellationFee}
          {renderSymbolCurrency(currency)}
        </p>
        <p>
          {t('Refund Fee')}: {cancellation.refundFee}
          {renderSymbolCurrency(currency)}
        </p>
        <StyledButton name={'refunded-manually'} onClick={onClickManualRefund}>
          {t('Manually Refunded?')}
        </StyledButton>
      </Content>
    </Modal>
  )
}

const Content = styled.div`
  padding: 10px;
`

const StyledButton = styled.button`
  margin-top: 10px;
  padding: 8px 10px;
  border-radius: 5px;
  background-color: ${COLORS.WARNING};
  color: #fff;
  cursor: pointer;
  border: none;

  &:focus {
    outline: 0;
  }

  &:hover {
    filter: brightness(85%);
  }

  ${responsive.md`
    font-size: 12px;
  `}
`
export default ActionRefundCancelBookingModal
