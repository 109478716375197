import React from 'react'
import { useTranslation } from 'react-i18next'
import { registerLocale } from 'react-datepicker'
import de from 'date-fns/locale/de'
import tr from 'date-fns/locale/tr'
import moment from 'moment/moment'

import Input from 'components/atoms/Input'
import SelectAdapter from 'components/atoms/Select'
import ValidationText from 'components/atoms/ValidationText'
import { Field, styled } from 'utils/adapters'
import { defaultDateFormat } from 'utils/helpers'

import { DATE_OPTIONS, FEE_OPTIONS, RANGES_TYPE } from 'constants/cancellationPolicy'

import { Label, Row } from 'components/pages/EventInformation/EventInformation.style'
import { NewStyledDatePicker } from '@/components/atoms/NewDatePicker/new-datepicker'

registerLocale('tr', tr)
registerLocale('de', de)

type PolicyFormProps = {
  name: string
  type: string
}

const PolicyForm: React.FC<PolicyFormProps> = function({ type, name }) {
  const { t } = useTranslation()
  const appLanguage = localStorage.getItem('i18nextLng')

  const required = (value: any) =>
    value ? undefined : t('This field is required!')

  return (
    <Wrapper>
      {type === RANGES_TYPE.FIXED_RANGES ? (
        <>
          <StyledRow>
            <Label>
              {t('Date type')} <span>*</span>
            </Label>
            <Field
              name={`${name}.dateType`}
              validate={required}
              component={SelectAdapter}
              placeholder={t('Choose date type')}
              options={DATE_OPTIONS}
            />
          </StyledRow>
          <StyledRow>
            <Label>
              {t('Date')} <span>*</span>
            </Label>
            <Field name={`${name}.date`} validate={required}>
              {({ input, meta }: any) => {
                return (
                  <>
                    <NewStyledDatePicker
                      locale={appLanguage || 'en'}
                      selected={input.value}
                      timeCaption={`${t('Date')}:`}
                      dateFormat={defaultDateFormat}
                      showMonthDropdown
                      dropdownMode='select'
                      onKeyDown={event => {
                        event.preventDefault()
                      }}
                      onChange={(date: Date) => {
                        input.onChange(moment(date).toDate())
                      }}
                      onBlur={() => {
                        input.onBlur()
                      }}
                    />
                    {meta.error && meta.touched && (
                      <ValidationText error={meta.error} />
                    )}
                  </>
                )
              }}
            </Field>
          </StyledRow>
        </>
      ) : (
        <>
          <StyledRowHidden>
            <StyledRow>
              <Label>
                {t('Date type')} <span>*</span>
              </Label>
              <Field
                name={`${name}.dateType`}
                component={SelectAdapter}
                placeholder={t('Choose date type')}
                options={DATE_OPTIONS}
              />
            </StyledRow>
          </StyledRowHidden>

          <StyledRow>
            <Label>
              {t('Start date')} <span>*</span>
            </Label>
            <Field name={`${name}.startDate`} validate={required}>
              {({ input, meta }: any) => {
                console.log('meta', meta.error)
                return (
                  <>
                    <NewStyledDatePicker
                      locale={appLanguage || 'en'}
                      selected={input.value}
                      timeCaption={t('Time:')}
                      dateFormat={defaultDateFormat}
                      showMonthDropdown
                      dropdownMode='select'
                      onKeyDown={event => {
                        event.preventDefault()
                      }}
                      onChange={(date: Date) => {
                        input.onChange(moment(date).toDate())
                      }}
                      onBlur={() => {
                        input.onBlur()
                      }}
                    />
                    {meta.error && meta.touched && (
                      <ValidationText error={meta.error} />
                    )}
                  </>
                )
              }}
            </Field>
          </StyledRow>
          <StyledRow>
            <Label>
              {t('End date')} <span>*</span>
            </Label>
            <Field name={`${name}.endDate`} validate={required}>
              {({ input, meta }: any) => (
                <>
                  <NewStyledDatePicker
                    locale={appLanguage || 'en'}
                    selected={input.value}
                    timeCaption={t('End:')}
                    dateFormat={defaultDateFormat}
                    // filterTime={filterStartTime}
                    showMonthDropdown
                    dropdownMode='select'
                    onKeyDown={event => {
                      event.preventDefault()
                    }}
                    onChange={(date: Date) => {
                      input.onChange(moment(date).toDate())
                    }}
                    onBlur={() => {
                      input.onBlur()
                    }}
                  />
                  {meta.error && meta.touched && (
                    <ValidationText error={meta.error} />
                  )}
                </>
              )}
            </Field>
          </StyledRow>
        </>
      )}
      <StyledRow>
        <Label>
          {t('Fee type')} <span>*</span>
        </Label>
        <Field
          name={`${name}.feeType`}
          validate={required}
          component={SelectAdapter}
          placeholder={t('Choose fee type')}
          options={FEE_OPTIONS}
        />
      </StyledRow>
      <StyledRow>
        <Label>
          {t('Fee')} <span>*</span>
        </Label>
        <Field
          name={`${name}.feeNumber`}
          type='number'
          formatOnBlur
          validate={required}
        >
          {({ input, meta }: any) => (
            <StyledRow>
              <StyledInput {...input} meta={meta} />
            </StyledRow>
          )}
        </Field>
      </StyledRow>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
  gap: 30px;
`

const StyledInput = styled(Input)`
  padding-bottom: 0;

  input {
    padding-top: 2px;
  }

  span {
    bottom: -20px;
    position: absolute;
  }
`

const StyledRow = styled(Row)`
  flex: 1 1 auto;

  > span {
    position: absolute;
    bottom: -20px;
  }
`

export const StyledRowHidden = styled.div`
  display: none;
`

export default PolicyForm
