import React from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from 'utils/adapters'
import Modal from 'components/molecules/Modal'
import { MESSAGE } from 'constants/message'

type GoBackModalType = {
  isShow: boolean
  onClose: () => void
  onConfirm: () => void
}

const GoBackModal: React.FC<GoBackModalType> = ({
  isShow,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isShow={isShow}
      title={t('Warning!')}
      onClose={onClose}
      onConfirm={onConfirm}
      description={t(MESSAGE.CONFIRM_BACK)}
    />
  )
}

export default GoBackModal
