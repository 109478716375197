import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { UserState } from './zustand/user'
import { IUser } from '@/models'
import { TwoFactorMethod } from '@/constants/user'

const initialState = {
  currentUser: null,
  isTwoFaAuthenticated: false,
}

// export const useUserStore = create<UserState>()(
//   devtools(
//     persist(
//       set => ({
//         ...initialState,
//         twoFactorMethod: TwoFactorMethod.NONE,
//         setCurrentUser: (currentUser: IUser) =>
//           set({
//             currentUser,
//           }),
//         setTwoFaAuthenticated: (isTwoFaAuthenticated: boolean) =>
//           set({
//             isTwoFaAuthenticated,
//           }),
//         resetStore: () => set(initialState),
//       }),
//       {
//         name: 'user-storage',
//       }
//     )
//   )
// )
export const useUserStore = create<UserState>(set => ({
  currentUser: null,
  setCurrentUser: (user: IUser) =>
    set({
      currentUser: user,
    }),
  isTwoFaAuthenticated: false,
  setTwoFaAuthenticated: (isTwoFaAuthenticated: boolean) =>
    set({
      isTwoFaAuthenticated: isTwoFaAuthenticated,
    }),
  resetStore: () => set({ ...initialState }),
}))
