import { gql } from 'utils/adapters'

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    organizations {
      _id
      email
      name
      isRemoved
      capacity
      eventSourceId
      userCreated
    }
  }
`

export const GET_ORGANIZATION = gql`
  query GetOrganization($id: ID!) {
    organization(id: $id) {
      _id
      email
      name
      capacity
      eventSourceId
      userCreated
      sendDailyRegistrationReport
      dailyReportConfig {
        language
        timezone {
          offset
          value
          label
        }
      }
      emailCredentials {
        host
        port
        username
        secure
      }
      terms
      defaultConfirmationEmail
      force2FAEnabled
      logo
    }
  }
`
