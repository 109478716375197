import { FC } from 'react'
import { styled } from 'utils/adapters'
import { COLORS } from 'constants/colors'
import { responsive } from 'assets/scss/mixin'

type RadioButtonType = {
  name?: string
  value?: any
  disabled?: boolean
  checked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  color?: string
}

const RadioButton: FC<RadioButtonType> = ({
  name,
  value,
  disabled,
  onChange,
  checked,
  children,
  color,
  ...props
}) => {
  return (
    <LabelRadio disabled={disabled}>
      <Radio
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <RadioMark color={color} />
      {children}
    </LabelRadio>
  )
}

const LabelRadio = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px 5px 0;
  position: relative;
  color: ${COLORS.PRIMARY};
  ${props =>
    props.disabled &&
    `
        cursor: not-allowed;
        opacity: 0.4;
    `};
  ${responsive.md`
      font-size: 12px;
    `}
`

const RadioMark = styled.span<{ color?: string }>`
  display: inline-block;
  position: relative;
  border: 1px solid #777777;
  width: 16px;
  height: 16px;
  left: 0;
  top: 2px;
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: ${props => props.color};
    opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    transition: all 110ms;
  }
`

const Radio = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  visibility: hidden;
  display: none;
  &:checked + ${RadioMark} {
    &::after {
      width: 12px;
      height: 12px;
      opacity: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`

export default RadioButton
