import { COLORS } from 'constants/colors'
import { FC } from 'react'
import { FieldMetaState } from 'react-final-form'
import { styled } from 'utils/adapters'
import Validation from 'components/atoms/ValidationText'
import { responsive } from 'assets/scss/mixin'

type InputType = {
  id?: string
  name?: any
  label: string | any
  type: string
  meta?: FieldMetaState<any>
  value?: string | number | readonly string[] | undefined
  isRequired?: boolean
  className?: string
  isShowSpace?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  onBlur?: any
  placeholder?: string
}

const Input: FC<InputType> = ({
  id,
  name,
  label,
  type,
  value,
  isRequired,
  isShowSpace,
  meta,
  onChange,
  onBlur,
  placeholder,
  className,
  ...props
}) => {
  return (
    <>
      <Wrapper className={className} isShowSpace={isShowSpace}>
        <Label htmlFor='name'>
          {label ? label : 'Label'}{' '}
          {isRequired && <span className='font-bold text-[#d9534f]'>*</span>}
        </Label>
        <InputField
          id={id}
          name={name}
          error={meta?.error && meta.touched}
          type={type || 'text'}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          {...props}
        />

        {meta?.error && meta?.touched && <ValidationText error={meta?.error} />}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ isShowSpace?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${props => (props.isShowSpace ? '1.5em' : '4px')};
`

const Label = styled.label`
  top: 1em;
  left: 8px;
  user-select: none;
  color: #999;
  pointer-events: none;
  transition: 0.25s ease;
  font-size: 12px;
`

const InputField = styled.input<{ error?: string; isRequired?: boolean }>`
  padding: 0 4px;
  border: 0;
  border-bottom: 1px solid
    ${props => (props.error ? COLORS.ERROR : COLORS.BORDER_FIELD)};
  height: 30px;
  transition: 0.25s ease;
  outline: none;
  font-size: 16px;

  &:focus {
    border-color: ${COLORS.BLUE};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${COLORS.BLUE} !important;
  }
  ${responsive.md`
    font-size: 14px;
  `}
`

const ValidationText = styled(Validation)`
  position: absolute;
  bottom: 0;
  left: 8px;
`

export default Input
