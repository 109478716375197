import { FC } from 'react'
import { styled } from 'utils/adapters'
import { renderSymbolCurrency } from 'utils/helpers'
import { CustomFieldOption } from 'models'
import { responsive } from 'assets/scss/mixin'

type QuestionWitType = {
  title: string
  options: CustomFieldOption[]
  currency?: string
}

const QuestionWit: FC<QuestionWitType> = ({ title, options, currency }) => {
  return (
    <Wrapper>
      <span
        className='ql-link'
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      {options &&
        options.map((option, index: number) => {
          return (
            <Option key={index}>
              <span>{option.name}</span>
              <span>
                {option.fee} {renderSymbolCurrency(currency)}
              </span>
            </Option>
          )
        })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-bottom: 8px;
  font-weight: bold;
  max-width: 600px;
  ${responsive.sm`
    font-size: 12px;
    padding: 4px;
  `}
`

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  span {
    font-weight: 500;
  }
  ${responsive.sm`
    font-size: 12px;
  `}
`

export default QuestionWit
