import { request } from './request'

export const getChangesLog = (orgId: string, params) => {
  return request.request({
    method: 'GET',
    url: `/v1/changelogs/${orgId}`,
    params,
  })
}

export const getSendMailLogs = params => {
  return request.request({
    method: 'GET',
    url: '/v1/maillogs',
    params,
  })
}
