import GlobalStyle from './global.style'
import { FC } from 'react'
import * as Sentry from '@sentry/react'
import 'regenerator-runtime'

import { BrowserRouter as Router } from 'utils/adapters'
import Routes from 'routes/Routes'

import Auth from 'components/containers/Auth'
import AppContainer from './components/containers/App'

import './global.css'

const App: FC = () => {
  return (
    <Router>
      <Auth>
        <AppContainer>
          <GlobalStyle />
          <Routes />
        </AppContainer>
      </Auth>
    </Router>
  )
}

export default Sentry.withProfiler(App)
