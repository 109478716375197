import { Button } from '@/components/ui/button'
import { Column, Label } from '../EventInformation.style'

import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import { GET_LANGUAGES } from 'graphql/queries'
import { clearFormData, setFormData, setStep } from 'store/Events'

import SelectAdapter from 'components/atoms/Select'
import Validation from 'components/atoms/ValidationText'
import Modal from 'components/molecules/Modal'
import {
  Field,
  Form,
  FormApi,
  OnChange,
  styled,
  useHistory,
  useParams,
  useQuery,
} from 'utils/adapters'
import {
  composeValidators,
  defaultDateTimeFormat,
  getListCountriesByLanguage,
  maxLengthValidation,
  postCodeValidation,
  requiredValidation,
  trimWhiteSpace,
  validateDescription,
} from 'utils/helpers'
import { useAppDispatch, useAppSelector, useModal } from 'utils/hooks'

import { NewStyledDatePicker } from '@/components/atoms/NewDatePicker/new-datepicker'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { EventType } from 'constants/events'
import { MESSAGE } from 'constants/message'
import de from 'date-fns/locale/de'
import tr from 'date-fns/locale/tr'
import { ChevronLeft } from 'lucide-react'
import { registerLocale } from 'react-datepicker'
import { useRouteMatch } from 'react-router'
import Editor from '@/components/atoms/Editor'
import { PATH_NAME } from '@/routes/routesMap'
import { cn } from '@/lib/utils'
import { SaveChangesNowButton } from '@/components/molecules/SaveChangesNow'

registerLocale('tr', tr)
registerLocale('de', de)

type ParamsType = {
  id: string
}

const EventForm: FC = () => {
  const { t } = useTranslation()

  const matchEditRoute = useRouteMatch(PATH_NAME.EDIT_EVENT)

  const { id } = useParams<ParamsType>()
  const appLanguage = localStorage.getItem('i18nextLng')
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { isShow, showModal, hideModal } = useModal()
  const formRef: React.MutableRefObject<FormApi | any> = useRef(null)

  const { step, formData } = useAppSelector(state => state.eventsReducer)
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [isDigitalEvent, setIsDigitalEvent] = useState(false)

  const { data: mainLanguages, loading: loadingMainLanguages } = useQuery(
    GET_LANGUAGES,
    {
      onError: ({ message }) => {
        console.log(message)
      },
    }
  )

  useEffect(() => {
    if (formData.type) setIsDigitalEvent(formData.type === EventType.DIGITAL)
  }, [formData.type])

  useEffect(() => {
    if (formData.startEvent && formData.endEvent) {
      setStartDate(moment(formData.startEvent).toDate())
      setEndDate(moment(formData.endEvent).toDate())
    }
  }, [formData.startEvent, formData.endEvent])

  useEffect(() => {
    if (!id) {
      if (!formData.type) {
        dispatch(
          setFormData({
            ...formData,
            type: EventType.CLASSIC,
          })
        )
      } else {
        dispatch(
          setFormData({
            ...formData,
          })
        )
      }
    }
  }, [])

  const onSubmit = (values: any) => {
    dispatch(setFormData(values))
    dispatch(setStep(step + 1))
  }

  const startTimeValidation = (value: string | any) => {
    const formState = formRef.current.getState().values

    if (!value) {
      return MESSAGE.REQUIRED_FIELD
    } else if (formState.startEvent && formState.endEvent) {
      const startTime = new Date(formState.startEvent)
      const endTime = new Date(formState.endEvent)
      if (startTime.getTime() > endTime.getTime()) {
        return MESSAGE.INVALID_TIME
      }
    }
  }

  const endTimeValidation = (value: string | any) => {
    const formState = formRef.current.getState().values

    if (!value) {
      return MESSAGE.REQUIRED_FIELD
    } else if (formState.startEvent && formState.endEvent) {
      const startTime = new Date(formState.startEvent)
      const endTime = new Date(formState.endEvent)
      if (startTime.getTime() > endTime.getTime()) {
        return MESSAGE.INVALID_TIME
      }
    }
  }

  const handleGoBack = () => {
    showModal()
  }

  const onConfirm = () => {
    hideModal()
    dispatch(clearFormData())
    history.push('/')
  }

  const filterStartTime = (time: Date) => {
    const selectedDate = new Date(time)
    if (startDate && endDate) {
      return selectedDate.getTime() < endDate.getTime()
    } else return true
  }

  const filterEndTime = (time: Date) => {
    const selectedDate = new Date(time)

    if (startDate) {
      return selectedDate.getTime() > startDate.getTime()
    }
    return true
  }
  const setEventType = (eventType: string) => {
    if (eventType[0] === EventType.DIGITAL) {
      setIsDigitalEvent(true)
    } else {
      setIsDigitalEvent(false)
    }
  }

  const requiredTextField = composeValidators(
    requiredValidation,
    maxLengthValidation
  )
  if (id && !formData._id) return null
  return (
    <>
      <div className='space-y-6 p-10'>
        <div>
          <h3 className='text-lg font-medium'>{t('Event Detail')}</h3>
          <p className='text-sm text-muted-foreground'>
            {t('Customize event information.')}
          </p>
          <ChevronLeft
            onClick={handleGoBack}
            className={cn(
              'absolute top-[15px] left-14 cursor-pointer md:top-[28px] md:left-24',
              !matchEditRoute && 'text-white left-5 md:left-8'
            )}
          />
        </div>
        <Separator />
        <Form
          onSubmit={onSubmit}
          mutators={{ setEventType }}
          initialValues={{ ...formData }}
          subscription={{
            submitting: true,
          }}
          render={({ handleSubmit, form }) => {
            formRef.current = form
            return (
              <form
                onSubmit={handleSubmit}
                className='mx-auto flex-wrap justify-around gap-4 md:gap-6'
              >
                <OnChange name='type'>
                  {value => {
                    form.mutators.setEventType(value)
                  }}
                </OnChange>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                  <div className='col-span-1 lg:w-5/5 w-full space-y-4 lg:mx-auto'>
                    <Field
                      name={t('title')}
                      type='text'
                      validate={requiredTextField}
                      format={trimWhiteSpace}
                      formatOnBlur
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta} isRequired>
                            {t('Title')}
                          </FormLabel>
                          <Input
                            {...input}
                            meta={meta}
                            isRequired={true}
                            maxLength='256'
                          />
                        </FormItem>
                      )}
                    </Field>
                    <Column>
                      <FormItem>
                        <Label>{t('Event Type')}</Label>
                        <EventTypeContent>
                          <LabelOption>
                            <Field
                              name='type'
                              component='input'
                              type='radio'
                              value={EventType.CLASSIC}
                            />
                            {t('Classic')}
                          </LabelOption>
                          <LabelOption>
                            <Field
                              name='type'
                              component='input'
                              type='radio'
                              value={EventType.DIGITAL}
                            />
                            {t('Digital')}
                          </LabelOption>
                        </EventTypeContent>
                      </FormItem>
                      <FormItem>
                        <FormLabel>{t('Choose main language')}</FormLabel>
                        <SelectLanguage
                          name='mainLanguage'
                          component='select'
                          placeholder={t('Select main language')}
                        >
                          {!loadingMainLanguages &&
                            mainLanguages?.fetchLanguages?.data.map(
                              (language: string, index: number) => (
                                <option key={index} value={language}>
                                  {language}
                                </option>
                              )
                            )}
                        </SelectLanguage>
                      </FormItem>
                    </Column>

                    <Column>
                      <FormItem>
                        <FormLabel>
                          {t('Start of event')} <span>*</span>
                        </FormLabel>
                        <Field name='startEvent' validate={startTimeValidation}>
                          {({ input, meta }: any) => (
                            <>
                              <NewStyledDatePicker
                                locale={appLanguage || 'en'}
                                selected={startDate}
                                timeCaption={t('Time:')}
                                timeFormat='HH:mm'
                                showTimeSelect
                                timeIntervals={30}
                                minDate={new Date()}
                                maxDate={endDate}
                                dateFormat={defaultDateTimeFormat}
                                filterTime={filterStartTime}
                                showMonthDropdown
                                dropdownMode='select'
                                onKeyDown={event => {
                                  event.preventDefault()
                                }}
                                onChange={(date: Date) => {
                                  if (date) {
                                    setStartDate(date)
                                    input.onChange(
                                      moment(date).format('YYYY-MM-DD HH:mm')
                                    )
                                  } else {
                                    setStartDate(date)
                                    input.onChange(date)
                                  }
                                }}
                              />
                              {meta.error && meta.touched && (
                                <Validation error={meta.error} />
                              )}
                            </>
                          )}
                        </Field>
                      </FormItem>

                      <FormItem>
                        <FormLabel>
                          {t('End of event')} <span>*</span>
                        </FormLabel>

                        <Field name='endEvent' validate={endTimeValidation}>
                          {({ input, meta }: any) => (
                            <>
                              <NewStyledDatePicker
                                locale={appLanguage || 'en'}
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate || new Date()}
                                selected={endDate}
                                timeCaption={t('Time:')}
                                timeFormat='HH:mm'
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode='select'
                                showTimeSelect
                                timeIntervals={30}
                                onKeyDown={event => {
                                  event.preventDefault()
                                }}
                                dateFormat={defaultDateTimeFormat}
                                filterTime={filterEndTime}
                                onChange={(date: Date) => {
                                  if (date) {
                                    setEndDate(date)
                                    input.onChange(
                                      moment(date).format('YYYY-MM-DD HH:mm')
                                    )
                                  } else {
                                    setEndDate(date)
                                    input.onChange(date)
                                  }
                                }}
                              />
                              {meta.error && meta.touched && (
                                <Validation error={meta.error} />
                              )}
                            </>
                          )}
                        </Field>
                      </FormItem>
                    </Column>
                    {!isDigitalEvent && (
                      <>
                        <Field
                          name='hotelName'
                          type='text'
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel meta={meta}>
                                {t('Hotel name')}
                              </FormLabel>
                              <Input {...input} maxLength='256' />
                            </FormItem>
                          )}
                        </Field>

                        <Field
                          name='address.street'
                          type='text'
                          validate={requiredTextField}
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel meta={meta} isRequired>
                                {t('Street')}
                              </FormLabel>
                              <Input
                                {...input}
                                type='text'
                                meta={meta}
                                isRequired={true}
                                maxLength='256'
                              />
                            </FormItem>
                          )}
                        </Field>

                        <Column>
                          <Field
                            name='address.city'
                            type='text'
                            validate={requiredTextField}
                            format={trimWhiteSpace}
                            formatOnBlur
                          >
                            {({ input, meta }: any) => (
                              <FormItem>
                                <FormLabel meta={meta} isRequired>
                                  {t('City')}
                                </FormLabel>
                                <Input
                                  {...input}
                                  type='text'
                                  meta={meta}
                                  isRequired={true}
                                  maxLength='256'
                                />
                              </FormItem>
                            )}
                          </Field>

                          <Field
                            name='address.zipCode'
                            type='text'
                            validate={composeValidators(
                              requiredValidation,
                              postCodeValidation
                            )}
                            format={trimWhiteSpace}
                            formatOnBlur
                          >
                            {({ input, meta }: any) => (
                              <FormItem>
                                <FormLabel meta={meta} isRequired>
                                  {t('Zip')}
                                </FormLabel>
                                <Input
                                  {...input}
                                  type='text'
                                  meta={meta}
                                  isRequired={true}
                                  maxLength='51'
                                />
                              </FormItem>
                            )}
                          </Field>
                        </Column>
                      </>
                    )}
                  </div>

                  <div className='col-span-1 lg:w-5/5 w-full space-y-6 lg:mx-auto'>
                    {!isDigitalEvent && (
                      <FormItem>
                        <FormLabel>
                          {t('Country')} <span>*</span>
                        </FormLabel>
                        <Field
                          className='select-country'
                          name='address.country'
                          validate={requiredTextField}
                          component={SelectAdapter}
                          placeholder={t('Select box')}
                          value={{
                            value: formData?.address?.country,
                            label: formData?.address?.country,
                          }}
                          options={getListCountriesByLanguage(appLanguage).map(
                            country => ({
                              label: country.name,
                              value: country.name,
                            })
                          )}
                        />
                      </FormItem>
                    )}

                    <Field
                      name='description'
                      validate={validateDescription}
                      type='text'
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta}>
                            {t('Event description')}
                          </FormLabel>
                          <Editor
                            className='content-editor'
                            value={formData.description}
                            isMention={false}
                            onChange={value => {
                              form.change('description', value)
                            }}
                          />
                        </FormItem>
                      )}
                    </Field>

                    <Button type='submit' className='w-full'>
                      {t('Continue')}
                    </Button>
                    {matchEditRoute && (
                      <SaveChangesNowButton
                        id={id}
                        handleGetFormValues={() => form.getState().values}
                      />
                    )}
                  </div>
                </div>
              </form>
            )
          }}
        />
      </div>

      <Modal
        isShow={isShow}
        title={t('Are you sure to go back?')}
        onClose={hideModal}
        onConfirm={onConfirm}
        description={t(MESSAGE.CONFIRM_BACK)}
      />
    </>
  )
}

const EventTypeContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
`

const LabelOption = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`

const SelectLanguage = styled(Field)`
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #e2eded;

  &:focus {
    outline: none;
  }

  option {
    padding: 8px 0;
  }
`

export default EventForm
