import { FC } from 'react'

export const PAYMENT_FEE_COLOR = '#e4edf4'

const ListChosenItem: FC = ({ children }) => {
  return (
    <div className='flex flex-col space-y-2 rounded-md border p-[10px]'>
      {children}
    </div>
  )
}

export default ListChosenItem
