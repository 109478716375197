import { DefaultImage, Template1, Template2, Template3 } from 'assets/images'

export const INVITATION_TEMPLATE = [
  {
    title: 'Template 1',
    description: 'This is the description for template 1.',
    imageUrl: Template1,
  },
  {
    title: 'Template 2',
    description: 'This is the description for template 2.',
    imageUrl: Template2,
  },
  {
    title: 'Template 3',
    description: 'This is the description for template 3.',
    imageUrl: Template3,
  },
]
export enum INVITATION_STATUS {
  ATTEND = 0,
  NOT_ATTEND = 1,
}

export const INVITATION_TEMPLATE_IDS = {
  template1: 'e98d171e-2e8a-4491-ad1c-78e5e42d96fe',
  template2: '83cab306-cf15-490f-bd10-f3326523217d',
  template3: '8aa5b0c1-2b72-48e6-ab41-3063a0f22d48',
}
