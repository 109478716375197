import { configureStore } from '@reduxjs/toolkit'

import { authenticationReducer } from './Authentication'
import { toastReducer } from './Toast'
import { eventsReducer } from './Events'
import { customFieldReducer } from './CustomField'
import { userReducer } from './User'
import { appReducer } from './App'

export const store = configureStore({
  reducer: {
    authenticationReducer,
    toastReducer,
    eventsReducer,
    customFieldReducer,
    userReducer,
    appReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
