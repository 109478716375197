export enum LANGUAGE {
  ENGLISH = 'en',
  GERMAN = 'de',
  TURKISH = 'tr',
}

export const SUPPORT_LANGUAGE = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'German',
    value: 'de',
  },
  {
    name: 'Turkish',
    value: 'tr',
  },
]

const SupportLanguageArray = ['en', 'de', 'tr']
export { SupportLanguageArray as SUPPORT_LANG }

export const Language = {
  English: 'English',
  German: 'German',
  Turkish: 'Turkish',
}

export const getLanguage = (lang: string | null) => {
  for (const key in LANGUAGE) {
    if (LANGUAGE[key] === lang) {
      return key
    }
  }
}
