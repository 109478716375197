import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CreatableSelect from 'react-select/creatable'

import { setFormData, setIsCreateCustomField, setStep } from 'store/Events'
import { CUSTOM_FIELDS } from 'graphql/queries'
import {
  AdminEventStep,
  blockTypingNonNumberic,
  formatPrice,
  generateObjectID,
  trimWhiteSpace,
} from 'utils/helpers'

import {
  arrayMutators,
  Field,
  FieldArray,
  Form,
  styled,
  useQuery,
} from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks' // import Input from 'components/atoms/Input'
import SelectAdapter from 'components/atoms/Select'
import { Button } from '@/components/ui/button'
import Icon from 'components/atoms/Icon'
import Checkbox from 'components/atoms/Checkbox'
import BookingWithPaymentForm from './BookingWithPaymentForm'
import Modal from 'components/molecules/Modal'
import Validation from 'components/atoms/ValidationText'

import { COLORS } from 'constants/colors'
import { CUSTOM_FIELD_TYPE, customFieldType } from 'constants/customFieldType'

import { responsive } from 'assets/scss/mixin'
import { AddCircleIcon, RemoveIcon } from 'assets/images'
import { useRouteMatch } from 'react-router'
import { PATH_NAME } from '@/routes/routesMap'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { ChevronLeft } from 'lucide-react'
import { cn } from '@/lib/utils'
import HyperLinkInput from '@/components/atoms/HyperLinkInput'

interface selectValue {
  value: string
  label: string
}

const ReactCreatableSelect = ({ input, ...rest }: any) => (
  <CreatableSelect {...input} {...rest} />
)

const EventCustomFieldForm: FC = () => {
  const { t } = useTranslation()
  const matchEditRoute = useRouteMatch(PATH_NAME.EDIT_EVENT)

  const { step, formData, isCreateCustomField } = useAppSelector(
    state => state.eventsReducer
  )

  const [selectedValue, setSelectedValue] = useState<selectValue>()
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false)
  const [selectedCustomField, setSelectedCustomField] = useState(
    JSON.parse(localStorage.getItem('selected_custom_field') as string)
  )
  const selectedCustomFieldIndex = JSON.parse(
    localStorage.getItem('selected_custom_field_index') as string
  )

  const organizationId = localStorage.getItem('organizationId')

  const dispatch = useAppDispatch()

  const { data } = useQuery(CUSTOM_FIELDS, {
    onError: ({ message }) => {
      toast({
        title: t('Error'),
        description: t(message),
        variant: 'destructive',
      })
    },
    variables: {
      organizationId,
    },
  })

  const formCustomField = {
    ...selectedCustomField,
    type: {
      value: selectedCustomField?.type || '',
      label: selectedCustomField?.type || '',
    },
    options:
      selectedCustomField?.options?.map((item: any) => ({
        ...item,
      })) ?? [],
  }

  const renderOptions = (type: string) => {
    switch (type) {
      case CUSTOM_FIELD_TYPE.CHECK_BOX:
        return true
      case CUSTOM_FIELD_TYPE.SELECT_BOX:
        return true
      case CUSTOM_FIELD_TYPE.RADIO_BUTTON:
        return true
      default:
        return false
    }
  }

  const required = (value: any) =>
    value ? undefined : t('This field is required!')

  const parse = (value: any) => {
    return !parseFloat(value) || !Number(value) || value.endsWith('.')
      ? value
      : parseFloat(value.replace(/,/g, '.'))
  }

  const requiredPrice = (value: number) =>
    value?.toString() ? undefined : t('This field is required!')

  const handleGoBack = () => {
    if (step > 0) {
      dispatch(setStep(step - 1))
      dispatch(setIsCreateCustomField(false))
    }
    localStorage.setItem('selected_custom_field', JSON.stringify({}))
  }

  const onSubmit = (values: any, form: any) => {
    if (isCreateCustomField) {
      const createdField = {
        _id: generateObjectID(),
        title: values.title,
        isRequired: values.isRequired,
        type: values.type.value,
        options: values.options.map((item: any) => ({
          ...item,
          fee: +item.fee,
        })),
      }
      setSelectedCustomField(createdField)

      if (
        (createdField.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON ||
          createdField.type === CUSTOM_FIELD_TYPE.SELECT_BOX ||
          createdField.type === CUSTOM_FIELD_TYPE.CHECK_BOX) &&
        createdField.options.length === 0
      ) {
        return toast({
          title: t('Error'),
          description: t('Please choose at least one option!'),
          variant: 'destructive',
        })
      } else {
        if (checkDuplicated(createdField.options)) {
          return toast({
            title: t('Error'),
            description: t('Duplicate name of the option!'),
            variant: 'destructive',
          })
        } else {
          const totalCustomFieldFee = sumCustomFieldFee(createdField.options)
          if (
            formData.isBookable ||
            (!formData.isBookable && totalCustomFieldFee === 0) ||
            ((createdField.type === CUSTOM_FIELD_TYPE.TEXT ||
              createdField.type === CUSTOM_FIELD_TYPE.DATE_TIME) &&
              (totalCustomFieldFee === 0 || totalCustomFieldFee !== 0))
          ) {
            dispatch(
              setFormData({
                ...formData,
                customFields: formData.customFields.concat(createdField),
              })
            )

            toast({
              description: t('Create custom field success'),
            })
          } else {
            return setShowConfirmModal(true)
          }
        }
      }
    } else {
      const updatedFields = formData.customFields.map((item: any, index: any) =>
        index === selectedCustomFieldIndex ? values : item
      )
      const updatedField = {
        _id: values._id,
        title: values.title,
        isRequired: values.isRequired,
        type: values.type.value,
        options: values.options.map((item: any) => ({
          ...item,
          fee: +item.fee,
        })),
      }

      const customFields = updatedFields.map((field: any) => ({
        _id: field._id ? field._id : generateObjectID(),
        title: field.title,
        isRequired: field.isRequired,
        type: field.type.value ? field.type.value : field.type,
        options: field?.options?.map((item: any) => ({
          ...item,
          fee: +item.fee,
        })),
      }))
      setSelectedCustomField(updatedField)

      if (
        (updatedField.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON ||
          updatedField.type === CUSTOM_FIELD_TYPE.SELECT_BOX ||
          updatedField.type === CUSTOM_FIELD_TYPE.CHECK_BOX) &&
        updatedField.options.length === 0
      ) {
        return toast({
          title: t('Error'),
          description: t('Please choose at least one option!'),
          variant: 'destructive',
        })
      } else {
        const totalCustomFieldFee = sumCustomFieldFee(updatedField.options)

        if (
          formData.isBookable ||
          (!formData.isBookable && totalCustomFieldFee === 0)
        ) {
          dispatch(
            setFormData({
              ...formData,
              customFields,
            })
          )

          if (checkDuplicated(updatedField.options)) {
            return toast({
              title: t('Error'),
              description: t('Duplicate name of the option!'),
              variant: 'destructive',
            })
          } else {
            localStorage.setItem('selected_custom_field', JSON.stringify({}))
            form.restart()
            toast({
              description: t('Update custom field success'),
            })
          }
        } else {
          return setShowConfirmModal(true)
        }
      }
    }
    dispatch(setStep(AdminEventStep.CustomField))
    dispatch(setIsCreateCustomField(false))
  }

  const sumCustomFieldFee = (options: { name: string; fee: number }[]) => {
    let totalCustomFieldFee = 0
    options?.forEach((field: { name: string; fee: number }) => {
      return (totalCustomFieldFee += field?.fee)
    })

    return +totalCustomFieldFee
  }

  const checkDuplicated = (options: any) => {
    const optionsNames = options?.map((item: any) => {
      return item.name
    })
    return optionsNames?.some(
      (option: any, index: number) => optionsNames?.indexOf(option) !== index
    )
  }

  const handleChange = (input: any) => {
    setSelectedValue(input)
    const selectedField = data?.customFields?.find(
      (field: any) => field?.title === input?.value
    )
    setSelectedCustomField(selectedField)
  }

  const handleCreate = (input: any) => {
    setSelectedValue({ value: input, label: input })
    setSelectedCustomField({
      title: input,
      isRequired: false,
    })
  }

  const maxCharactersLength = 3000

  const renderEditor: FC<{ input: any }> = ({ input }) => {
    return (
      <HyperLinkInput
        value={input.value}
        onChange={content => {
          input.onChange(content)
        }}
        className='text-base h-10 rounded-none flex flex-row-reverse w-full'
      />
    )
  }

  return (
    <Wrapper>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{ ...formCustomField }}
        validate={values => {
          const error = {} as unknown as any
          if (!values.type.value) {
            error.type = t('This field is required!')
          }
          if (values.title?.length > maxCharactersLength) {
            error.title = t(
              'Max length validation, accept {{number}} characters',
              { number: maxCharactersLength }
            )
          }
          return error
        }}
        render={({ handleSubmit, submitting, values }: any) => (
          <>
            <FormContainer onSubmit={handleSubmit}>
              {isCreateCustomField ? (
                <Field
                  name='title'
                  validate={required}
                  format={trimWhiteSpace}
                  formatOnBlur
                >
                  {({ input, meta }) => (
                    <FormItem>
                      <FormLabel>
                        {t('Title')} <span>*</span>
                      </FormLabel>
                      <ReactCreatableSelect
                        {...input}
                        placeholder={t('Select box')}
                        value={selectedValue}
                        onChange={handleChange}
                        onCreateOption={handleCreate}
                        options={data?.customFields.map((customField: any) => ({
                          label: (
                            <div
                              className='ql-link'
                              dangerouslySetInnerHTML={{
                                __html: customField.title,
                              }}
                            />
                          ),
                          value: customField.title,
                        }))}
                      />
                      {meta?.error && meta?.touched && (
                        <Validation error={meta?.error} />
                      )}
                    </FormItem>
                  )}
                </Field>
              ) : (
                <Field
                  name='title'
                  type='text'
                  validate={required}
                  format={trimWhiteSpace}
                  formatOnBlur
                >
                  {({ input, meta }: any) => (
                    <FormItem>
                      <FormLabel isRequired meta={meta}>
                        {t('Title')}
                      </FormLabel>
                      <Field name='title' component={renderEditor}></Field>
                    </FormItem>
                  )}
                </Field>
              )}

              <Column>
                <FormItem>
                  <FormLabel>
                    Type <span>*</span>
                  </FormLabel>
                  <Field
                    name='type'
                    component={SelectAdapter}
                    validate={required}
                    placeholder={t('Select box')}
                    options={customFieldType.map(type => ({
                      label: type.label,
                      value: type.value,
                    }))}
                    className='w-44'
                  />
                </FormItem>

                <Field name='isRequired' type='checkbox' defaultValue={false}>
                  {({ input }: any) => (
                    <CheckboxWrapper>
                      <CheckboxLabel>{t('Required')}</CheckboxLabel>
                      <Checkbox {...input} />
                    </CheckboxWrapper>
                  )}
                </Field>
              </Column>
              {renderOptions(values.type.value) && (
                <Fieldset>
                  <Legend>{t('Options')}</Legend>

                  <FieldArray name='options'>
                    {({ fields }) => (
                      <FieldArrayWrapper>
                        {fields.map((name, index) => (
                          <OptionColumn key={name}>
                            <Field
                              name={`${name}.name`}
                              type='text'
                              validate={required}
                              format={trimWhiteSpace}
                              formatOnBlur
                            >
                              {({ input, meta }: any) => (
                                <FormItem>
                                  <FormLabel isRequired meta={meta}>
                                    {t('Name')}
                                  </FormLabel>
                                  <Input
                                    {...input}
                                    meta={meta}
                                    isRequired={true}
                                    maxLength={maxCharactersLength}
                                  />
                                </FormItem>
                              )}
                            </Field>

                            <Field
                              name={`${name}.fee`}
                              type='number'
                              defaultValue={0}
                              parse={parse}
                              validate={requiredPrice}
                              format={formatPrice}
                            >
                              {({ input, meta }: any) => (
                                <FormItem>
                                  <FormLabel isRequired meta={meta}>
                                    {t('Fee')}
                                  </FormLabel>
                                  <Input
                                    {...input}
                                    meta={meta}
                                    isRequired
                                    type='text'
                                    onKeyDown={(e: any) => {
                                      blockTypingNonNumberic(e)
                                    }}
                                    maxLength='15'
                                  />
                                </FormItem>
                              )}
                            </Field>

                            <OptionIcon
                              source={RemoveIcon}
                              onClick={() => fields.remove(index)}
                            />
                          </OptionColumn>
                        ))}

                        <OptionIcon
                          source={AddCircleIcon}
                          onClick={() => fields.push({})}
                        />
                      </FieldArrayWrapper>
                    )}
                  </FieldArray>
                </Fieldset>
              )}

              <FieldButton
                name={
                  isCreateCustomField
                    ? t('Create Custom Field')
                    : t('Update Custom Field')
                }
                type='submit'
                disabled={submitting}
              >
                {isCreateCustomField
                  ? t('Create Custom Field')
                  : t('Update Custom Field')}
              </FieldButton>
              <ChevronLeft
                onClick={handleGoBack}
                className={cn(
                  'absolute top-[15px] left-14 cursor-pointer md:top-[28px] md:left-24',
                  !matchEditRoute && 'text-white left-5 md:left-8'
                )}
              />
            </FormContainer>
          </>
        )}
      ></Form>
      <StyledModal
        isShow={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={() => {
          setShowPaymentModal(true)
          setShowConfirmModal(false)
        }}
      >
        <p style={{ maxWidth: '400px', padding: '10px' }}>
          You are choosing customfield with fee, which need to activate payment
          option of the event. Do you want to activate the payment option?
        </p>
      </StyledModal>

      <StyledModal
        isShow={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        hideFooter
      >
        <div style={{ padding: '10px' }}>
          <BookingWithPaymentForm
            onClose={() => setShowPaymentModal(false)}
            triggerCloseModal={() => setShowPaymentModal(false)}
          />
        </div>
      </StyledModal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2%;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  margin: auto;
  max-width: 650px;
`

const FieldArrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Fieldset = styled.fieldset`
  border-radius: 5px;
  border: 1px solid ${COLORS.BLUE};
`

const Legend = styled.legend`
  color: #757575;
`

const CheckboxLabel = styled.span`
  font-size: 14px;
  color: #757575;
`

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-self: center;
`

const Column = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  min-height: 74px;
  flex-wrap: wrap;
`

const OptionColumn = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`

const OptionIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  margin: 10px 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  img {
    filter: none;
  }

  ${responsive.md`
    width: 14px;
    height: 14px;
  `}
`

const FieldButton = styled(Button)`
  align-self: center;
  width: 100%;
  margin: 10px 0;
`

const StyledModal = styled(Modal)`
  max-width: 400px;
`

export default EventCustomFieldForm
