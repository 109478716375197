import { FC } from 'react'
import ErrorBoundary from 'components/containers/ErrorBoundary'

const DashboardLayout: FC = ({ children }) => (
  <>
    <ErrorBoundary>{children}</ErrorBoundary>
  </>
)

export default DashboardLayout
