import { FC } from 'react'
import ContentLoader from 'react-content-loader'

const OrganizationSkeleton: FC = props => {
  return (
    <ContentLoader
      speed={2}
      width={200}
      height={40}
      viewBox='0 0 200 40'
      backgroundColor='#d9d9d9'
      foregroundColor='#ededed'
      {...props}
    >
      <rect x='0' y='0' rx='0' ry='0' width='200' height='40' />
    </ContentLoader>
  )
}

export default OrganizationSkeleton
