import React, { FC, lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useHistory, useParams, useQuery } from 'utils/adapters'
import { useAppDispatch, useAppSelector, useModal } from 'utils/hooks'
import { defaultToastDisplayTime, Step } from 'utils/helpers'

import {
  ATTENDEE_GET_EVENT_BY_ID,
  GET_ATTENDEE_BOOKING_DETAIL,
} from 'graphql/queries'
import {
  setAttendeeInfo,
  setEventPrice,
  setPaidAmount,
  setRegisteredCustomFields,
  setSelectedEvent,
  setStep,
  setTotalPrice,
} from 'store/Events'
import { setToast } from 'store/Toast'

import Header from 'components/organisms/Header'
import AttendeeCustomField from './components/AttendeeCustomField'
import DetailCost from './components/DetailCost'
import AttendeeForm from './components/AttendeeInfo'
import { toast } from '@/components/ui/use-toast'
import GoBackModal from '@/components/pages/EventRegister/components/AttendeeInfo/GoBackModal'
import {
  getPaymentInfo,
  setInitialEditCustomField,
  usePaymentInfo,
} from 'store/zustand/Payments'
import { PATH_NAME } from '@/routes/routesMap'

const Footer = lazy(() => import('components/organisms/Footer'))
const Loading = lazy(() => import('components/atoms/Loading'))
const Stripe = React.lazy(() => import('components/pages/Payments/Stripe'))

interface ParamsType {
  id: string
}

const EditBooking: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { id } = useParams<ParamsType>()

  const { isShow, showModal, hideModal } = useModal()

  const { step, totalPrice, event, attendeeInfo } = useAppSelector(
    state => state.eventsReducer
  )

  const paid = usePaymentInfo(state => state.amount)

  const { loading: attendeeLoading, data: attendeeBookingData } = useQuery(
    GET_ATTENDEE_BOOKING_DETAIL,
    {
      onCompleted() {
        if (attendeeBookingData) {
          dispatch(setAttendeeInfo(attendeeBookingData.attendeeBooking))
          dispatch(
            setRegisteredCustomFields(
              attendeeBookingData?.attendeeBooking?.registration?.customValues
            )
          )
          setInitialEditCustomField(
            attendeeBookingData?.attendeeBooking?.registration?.customValues
          )
        }
      },
      onError: err => {
        err.graphQLErrors.map(({ message }) => {
          toast({
            title: t(message),
            description: t(err.message),
            variant: 'destructive',
          })
        })
      },

      variables: {
        eventId: id,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const { data: eventData, loading: eventLoading } = useQuery(
    ATTENDEE_GET_EVENT_BY_ID,
    {
      onCompleted() {
        if (eventData) {
          dispatch(setSelectedEvent(eventData.event))
          if (eventData.event?.isBookable) {
            dispatch(setEventPrice(eventData.event?.price))
          }
        }
      },
      onError: err => {
        err.graphQLErrors.map(
          (error: any) =>
            error?.statusCode === 404 &&
            dispatch(
              setToast({
                message: 'Cannot find event',
                variant: 'danger',
                displayTime: defaultToastDisplayTime,
              })
            )
        )
      },
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    if (attendeeInfo) {
      const orderIds = attendeeInfo?.registration?.registrationCode?.split('|')
      const queryParams = orderIds
        ?.map(str => `orderId=${encodeURIComponent(str)}`)
        .join('&')
      if (queryParams) {
        getPaymentInfo(queryParams)
      }
    }
  }, [attendeeInfo])
  const paymentLoading = false

  const language = (localStorage.getItem('i18nextLng') as string) || 'en'

  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  )

  const renderComponent = (step: number) => {
    switch (step) {
      case Step.AttendeeForm:
        return <AttendeeForm />
      case Step.AttendeeCustomField:
        return <AttendeeCustomField />
      case Step.DetailCost:
        return <DetailCost />
      case Step.Payment:
        return (
          <>
            <Stripe
              mode='payment'
              value={totalPrice - paid}
              eventId={event?._id}
              attendee={attendeeInfo}
              currency={event?.currency?.toString()}
              eventSetting={event?.eventSetting}
              action='edit-booking'
            />
          </>
        )

      default:
        return null
    }
  }

  const onConfirm = () => {
    history.push('/dashboard')
    localStorage.removeItem('inputDate')
    localStorage.removeItem('inputField')
    localStorage.removeItem('selectedFields')
    localStorage.removeItem('selectedRadio')
    localStorage.removeItem('isChecked')
    sessionStorage.removeItem('attendee_info')
    sessionStorage.removeItem('eventPrice')
  }

  const handleGoBack = (step: number) => {
    switch (step) {
      case Step.AttendeeForm:
        showModal()
        break
      case Step.AttendeeCustomField:
        dispatch(setStep(Step.AttendeeForm))
        break
      case Step.DetailCost:
        dispatch(setTotalPrice(0))
        dispatch(setEventPrice(0))
        dispatch(setPaidAmount(0))
        if (event?.customFields?.length === 0) {
          dispatch(setStep(Step.AttendeeForm))
        } else {
          dispatch(setStep(Step.AttendeeCustomField))
        }
        break
      case Step.Payment:
        dispatch(setStep(Step.DetailCost))
        break
      default:
        break
    }
  }

  if (eventLoading || attendeeLoading || paymentLoading) {
    return <Loading />
  }

  if (clientSecret) {
    return <Stripe mode='result' action='edit-booking' />
  }

  // TODO: remove it
  if (paid > 0) {
    history.push(PATH_NAME.ERROR_404)
  }

  return (
    <>
      <div className='w-full h-full'>
        <Header
          bgColor={event?.eventSetting?.primaryColor}
          title={event?.title as string}
          showLanguageSelector={true}
          defaultLanguage={language}
          displayBackIcon
          handleBack={() => handleGoBack(step)}
        />
        <div className='min-h-[calc(100vh-54px-47px)] md:min-h-[calc(100vh-80px-47px)]'>
          {renderComponent(step)}
        </div>

        <Footer bgColor={event?.eventSetting?.primaryColor} />
        <GoBackModal
          isShow={isShow}
          onClose={hideModal}
          onConfirm={onConfirm}
        />
      </div>
    </>
  )
}

export default EditBooking
