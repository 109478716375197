import { FC } from 'react'
import { keyframes, styled } from 'utils/adapters'

const DotsLoading: FC<{ className?: string }> = ({ className }) => {
  return (
    <Wrapper className={className}>
      <Bounce1 />
      <Bounce2 />
      <Bounce />
    </Wrapper>
  )
}

const bounceDelay = keyframes`
    0% { transform: scale(0) }
    80% { transform: scale(0) }
    100% { transform: scale(0) }
    40% { transform: scale(0.8) }
`

const Wrapper = styled.div`
  width: 70px;
  text-align: center;
`

const Bounce = styled.div`
  width: 18px;
  height: 18px;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;
`

const Bounce1 = styled(Bounce)`
  animation-delay: -0.32s;
  background-color: #333;
`

const Bounce2 = styled(Bounce)`
  animation-delay: -0.16s;
  background-color: #333;
`

export default DotsLoading
