import { t } from 'i18next'

export enum SORT_BY_TYPE {
  CREATED_AT = 'CreateEvent',
  START_DATE = 'StartEvent',
  END_DATE = 'EndEvent',
}

export enum SORT_TYPE {
  DESC = 'DESC',
  ASC = 'ASC',
}

export type Option<T> = {
  value: T
  label: string
}

export const getOrderByOptions = () => [
  { value: SORT_BY_TYPE.CREATED_AT, label: t('dashboard_filter.create_date') },
  { value: SORT_BY_TYPE.START_DATE, label: t('dashboard_filter.start_date') },
  { value: SORT_BY_TYPE.END_DATE, label: t('dashboard_filter.end_date') },
]

export const getDirectionOptions = () => [
  { value: SORT_TYPE.DESC, label: t('DESC') },
  { value: SORT_TYPE.ASC, label: t('ASC') },
]
