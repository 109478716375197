import { Separator } from '@/components/ui/separator'
import { INVITATION_TEMPLATE } from '@/constants/invitationTemplate'
import {
  getEventInvitationTemplate,
  getInvitationTemplateList,
  updateEventInvitationTemplate,
  useInvitationTemplate,
} from '@/store/zustand/InvitationTemplateManagement'
import { cn } from 'lib/utils'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import InvitationTemplateForm from './InvitationTemplateForm'
import Loading from '@/components/atoms/Loading'
import { toast } from '@/components/ui/use-toast'
import CopyToClipBoard from '@/components/molecules/CopyToClipBoard'
import { Environment } from '@/environments'

type ParamTypes = {
  eventId: string
}

const InvitationTemplateManagement: FC = () => {
  const { t } = useTranslation()
  const { eventId } = useParams<ParamTypes>()
  const [displayErrorMsg, setDisplayErrorMsg] = useState(false)
  const invitationTemplateList = useInvitationTemplate(
    state => state.invitationTemplateList
  )
  const eventInvitationTemplate = useInvitationTemplate(
    state => state.eventInvitationTemplate
  )

  const selectedTemplate = useInvitationTemplate(
    state => state.selectedTemplate
  )

  const loading = useInvitationTemplate(state => state.isLoading)

  const invitationList = INVITATION_TEMPLATE.map((card, index) => ({
    ...card,
    id: invitationTemplateList[index]?.id || `${index}`,
    description:
      invitationTemplateList[index]?.description ||
      INVITATION_TEMPLATE[index].description,
    isPublish: Boolean(invitationTemplateList[index]),
  })).filter(card => card.isPublish)

  const fetchData = async () => {
    await getInvitationTemplateList(eventId)
    await getEventInvitationTemplate(eventId)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (eventInvitationTemplate?.template)
      useInvitationTemplate
        .getState()
        .setSelectedTemplate(eventInvitationTemplate?.template)
  }, [eventInvitationTemplate])

  const handleSelectTemplate = (isPublish: boolean, card) => {
    if (!isPublish) return
    if (selectedTemplate === card.id) {
      useInvitationTemplate.getState().setSelectedTemplate(null)
      setDisplayErrorMsg(true)
    } else {
      useInvitationTemplate.getState().setSelectedTemplate(card.id)
      setDisplayErrorMsg(false)
    }
  }

  const handleUpdateTemplate = async values => {
    if (!selectedTemplate) {
      setDisplayErrorMsg(true)
      return
    }
    const res = await updateEventInvitationTemplate(eventId, {
      templateId: selectedTemplate,
      fields: values,
    })

    if (res) {
      toast({
        description: t(
          'event_invitation.template_form.update_template_success'
        ),
      })
    }
  }

  if (loading) return <Loading />

  return (
    <div>
      <div className='h-full flex-1 flex-col p-4 md:p-8 md:flex'>
        <h2 className='text-2xl font-bold tracking-tight'>
          {t('event_invitation.title')}
        </h2>
        <p className='text-muted-foreground'>
          {t('event_invitation.subtitle')}
        </p>
        <Separator className='my-6' />
        <h5 className='text-lg font-bold mb-4'>
          {t('event_invitation.template_subtitle')}
        </h5>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-4'>
          {invitationList.map((card, index) => (
            <div
              key={card.id}
              className={cn(
                'bg-white relative bg-cover bg-no-repeat bg-center h-[350px] hover:border-primary object-cover rounded-lg shadow-md overflow-hidden transform transition-transform duration-300 hover:scale-105 cursor-pointer',
                {
                  'border-4 border-primary scale-105':
                    selectedTemplate === card.id,
                  'cursor-not-allowed': !card.isPublish,
                }
              )}
              style={{ backgroundImage: `url(${card.imageUrl})` }}
              onClick={() => handleSelectTemplate(card.isPublish, card)}
            >
              {!card.isPublish && (
                <div
                  className='absolute transform rotate-45 -translate-x-1/2 -translate-y-1/2 bg-muted text-primary text-xs font-bold py-1 px-3 rounded shadow-md'
                  style={{
                    top: '33px',
                    right: '-120px',
                    width: '150px',
                    textAlign: 'center',
                  }}
                >
                  {t('event_invitation.coming_soon_tag')}
                </div>
              )}
              <div className='p-4 pt-10 absolute w-full bottom-0'>
                <div className='absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-80 -z-50' />
                <div className='flex gap-4 items-center'>
                  <h1 className='font-bold text-white text-6xl'>{`0${
                    index + 1
                  }`}</h1>
                  <div className=''>
                    <h3 className='text-xl font-black text-white'>
                      {card.title}
                    </h3>
                    <p className='font-semibold text-white font-base'>
                      {card.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {displayErrorMsg && (
          <p className={cn('text-sm font-medium text-destructive mt-4')}>
            {t('event_invitation.template_form.error_msg')}
          </p>
        )}

        {!!selectedTemplate && (
          <div className='mt-10'>
            <h5 className='text-lg font-bold mb-2'>
              {t('event_invitation.guest_invitation_link_title')}
            </h5>
            <CopyToClipBoard
              value={`${Environment.clientBaseUrl}/events/${eventId}/invitation/guest`}
            />
          </div>
        )}

        <div className='mt-10'>
          <h5 className='text-lg font-bold mb-2'>
            {t('event_invitation.custom_template_title')}
          </h5>
          <p className='text-muted-foreground mb-4'>
            {t('event_invitation.custom_template_subtitle')}
          </p>
          <InvitationTemplateForm handleUpdateTemplate={handleUpdateTemplate} />
        </div>
      </div>
    </div>
  )
}

export default InvitationTemplateManagement
