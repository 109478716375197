import React from 'react'
import { styled } from 'utils/adapters'
import { Trans } from 'react-i18next'
import Checkbox from 'components/atoms/Checkbox'
import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'

type TermType = {
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onClick: React.MouseEventHandler<HTMLSpanElement>
}
const Term: React.FC<TermType> = ({ checked, onChange, onClick }) => {
  return (
    <TermBlock>
      <Checkbox checked={checked} onChange={onChange} />
      <div>
        <Trans i18nKey='terms'>
          I accept the registration data use {''}
          <UnderlineText onClick={onClick}>terms</UnderlineText>
        </Trans>
      </div>
    </TermBlock>
  )
}

const TermBlock = styled.div`
  display: flex;
  gap: 10px;
  color: #757575;
  ${responsive.md`
    font-size: 14px;
  `}
`

const UnderlineText = styled.span`
  color: ${COLORS.BLUE};
  text-decoration: underline;
  cursor: pointer;
`

export default Term
