import React from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from 'utils/adapters'
import { responsive } from 'assets/scss/mixin'

const DescriptionPendingPayment = () => {
  const { t } = useTranslation()

  return (
    <Text>
      <Title>{t('In Progress')}...</Title>
      <span>{t('Your payment is in progress')}.</span>
      <span>{t('Your booking is confirmed now')}.</span>
    </Text>
  )
}

const DescriptionSuccessRegister = () => {
  const { t } = useTranslation()

  return (
    <Text>
      <Title>{t('Congratulations')}</Title>
      <span>{t('Your registration is successful')}.</span>
      <span>{t('Your booking is confirmed now')}.</span>
    </Text>
  )
}

const Description: React.FC<{ isPendingPayment?: boolean }> = ({
  isPendingPayment,
}) => {
  return isPendingPayment ? (
    <DescriptionPendingPayment />
  ) : (
    <DescriptionSuccessRegister />
  )
}

const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin: 15% 0;
  ${responsive.md`
    margin: 10% 0;
  `}
`

const Title = styled.h1`
  font-size: 24px;
  text-transform: uppercase;
  padding-bottom: 20px;
  ${responsive.md`
    font-size: 16px;
  `}
`
export default Description
