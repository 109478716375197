import { Input } from 'components/ui/input'
import { X } from 'lucide-react'
import { useEffect, useState } from 'react'

export function BasicTableSearchInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  const handleClear = () => {
    setValue('')
    onChange('')
  }

  return (
    <div className='relative'>
      <Input
        {...props}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      {value && (
        <X
          className='absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center justify-center bg-transparent border-none cursor-pointer p-0 m-0 text-gray-500'
          width='16'
          height='16'
          onClick={handleClear}
        />
      )}
    </div>
  )
}
