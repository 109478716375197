import Checkbox from '@/components/atoms/Checkbox'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { SelectAdapter } from '@/components/ui/select-adapter'
import { Switch } from '@/components/ui/switch'
import { CUSTOM_FIELD_TYPE, customFieldType } from '@/constants/customFieldType'
import { cn } from '@/lib/utils'
import { Field, FieldArray } from '@/utils/adapters'
import { trimWhiteSpace } from '@/utils/helpers'
import { t } from 'i18next'
import { filter, flatMap, groupBy } from 'lodash'
import { Plus, Trash2 } from 'lucide-react'
import { FC } from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

interface FormBuilderInputItemProps {
  name: string
  index: number
}

const required = (value: any) =>
  value ? undefined : t('This field is required!')

const isDisplayOptions = (type: string) => {
  return (
    type === CUSTOM_FIELD_TYPE.CHECK_BOX ||
    type === CUSTOM_FIELD_TYPE.SELECT_BOX ||
    type === CUSTOM_FIELD_TYPE.RADIO_BUTTON
  )
}

export const FormBuilderInputItem: FC<FormBuilderInputItemProps> = ({
  name,
  index,
}) => {
  const { t } = useTranslation()

  const form = useForm()

  return (
    <div className=''>
      <div className='flex gap-6 flex-col md:flex-row'>
        <div className='z-999'>
          <Field
            name={`${name}.type`}
            validate={required}
            placeholder={t('Select box')}
          >
            {({ input, meta }: any) => (
              <FormItem>
                <div className='w-[200px] md-w-[170px]'>
                  <SelectAdapter
                    input={input}
                    meta={meta}
                    options={customFieldType}
                    title={t('Type')}
                    isRequired
                  />
                </div>
              </FormItem>
            )}
          </Field>
        </div>
        <div className='w-[80%] min-w-[200px]'>
          <Field
            name={`${name}.label`}
            type='text'
            validate={required}
            format={trimWhiteSpace}
            formatOnBlur
          >
            {({ input, meta }: any) => (
              <FormItem>
                <FormLabel meta={meta} isRequired>
                  {t('Title')}
                </FormLabel>
                <Input
                  {...input}
                  meta={meta}
                  maxLength='256'
                  className='bg-white'
                />
              </FormItem>
            )}
          </Field>
        </div>
      </div>
      <div className='flex gap-10 mt-6 items-center'>
        <Field name={`${name}.required`} type='checkbox' defaultValue={false}>
          {({ input, meta }: any) => (
            <div className='flex gap-2'>
              <Checkbox {...input} />
              <div className=''>{t('Is required?')}</div>
            </div>
          )}
        </Field>
        <Field name={`${name}.enabled`} formatOnBlur className='h-full'>
          {({ input, meta }: any) => {
            return (
              <FormItem className='flex flex-row gap-4'>
                <FormLabel meta={meta} className='text-base'>
                  {t('custom_selections.enable_label')}
                </FormLabel>
                <div className=''>
                  <Switch
                    id={name}
                    checked={input.value}
                    onCheckedChange={value => input.onChange(value)}
                  />
                </div>
              </FormItem>
            )
          }}
        </Field>
      </div>
      <Options
        type={form.getState().values?.customSelections[index]?.type}
        index={index}
      />
    </div>
  )
}

type OptionsProps = {
  type: string
  index: number
}

const Options = ({ type, index }: OptionsProps) => {
  if (!isDisplayOptions(type)) {
    return null
  }

  const { t } = useTranslation()

  const validateOption = (value, allValues, index) => {
    if (!value) {
      return t('This field is required!')
    }

    if (allValues?.[index]?.options) {
      const groupedByValue = groupBy(allValues?.[index].options, 'value')

      const duplicates = flatMap(
        filter(groupedByValue, group => group.length > 1)
      ) as any

      if (duplicates.length && value === duplicates[0]?.value) {
        return t('custom_selections.error.duplicate')
      }
    }

    return undefined
  }

  return (
    <FieldArray
      name={`customSelections[${index}].options`}
      validate={undefined}
    >
      {({ fields, meta: { error } }) => {
        if (fields.length === 0) {
          fields.push({})
        }
        return (
          <div>
            <fieldset className='rounded-2 border p-4 mt-6'>
              <legend>{t('Options')}</legend>
              <div>
                {fields?.map((name, optionIndex) => {
                  return (
                    <div key={name} className='flex justify-center mb-8'>
                      <div className='flex flex-col md:flex-row gap-4 md:gap-10 w-[100%] items-center'>
                        <div className='w-[30%] min-w-[200px]'>
                          <Field
                            name={`${name}.value`}
                            type='text'
                            format={trimWhiteSpace}
                            formatOnBlur
                            validate={(value, allValues: any) =>
                              validateOption(
                                value,
                                allValues?.customSelections,
                                index
                              )
                            }
                          >
                            {({ input, meta }: any) => (
                              <FormItem>
                                <FormLabel
                                  meta={meta}
                                  isRequired
                                  className='font-bold'
                                >
                                  {t('custom_selections.option', {
                                    number: optionIndex + 1,
                                  })}
                                </FormLabel>
                                <Input
                                  {...input}
                                  meta={meta}
                                  maxLength='256'
                                  className='bg-white'
                                />
                              </FormItem>
                            )}
                          </Field>
                        </div>
                      </div>

                      <Trash2
                        // don't allow options array is  empty
                        onClick={() => {
                          console.log('fields.length', fields.length)
                          return (
                            (fields.length ?? 0) > 1 &&
                            fields.remove(optionIndex)
                          )
                        }}
                        className={cn(
                          'w-[20px] text-gray-600 cursor-pointer',
                          (fields.length ?? 0) <= 1
                            ? 'pointer-events-none opacity-50'
                            : ''
                        )}
                      />
                    </div>
                  )
                })}

                <button
                  className='flex items-center space-x-2 cursor-pointer mt-6 text-primary font-bold'
                  onClick={() => fields.push({})}
                >
                  <Plus className='w-[20px] mr-1' /> {t('Add option')}
                </button>

                {/* <ValidationText error={error} /> */}
              </div>
            </fieldset>
          </div>
        )
      }}
    </FieldArray>
  )
}
