import { FC } from 'react'
import Select from 'react-select'
import ValidationText from '../ValidationText'
import { cn } from '@/lib/utils'

const SelectAdapter: FC<{ input: any; styles?: any; meta: any }> = ({
  input,
  styles,
  meta,
  ...rest
}) => {
  return (
    <>
      <Select
        {...input}
        isSearchable
        unstyled
        classNames={{
          clearIndicator: ({ isFocused }) =>
            cn(
              isFocused ? 'text-neutral-600' : 'text-neutral-200',
              'p-2',
              isFocused ? 'hover:text-neutral-800' : 'hover:text-neutral-400'
            ),
          // container: () => cn(),
          control: ({ isDisabled, isFocused }) =>
            cn(
              isDisabled ? 'bg-neutral-50' : 'bg-white',
              isDisabled
                ? 'border-neutral-100'
                : isFocused
                ? 'bg-accent'
                : 'border-neutral-200',
              'rounded-md',
              'border-input',
              'border',
              'h-10',
              isFocused && 'shadow-[0_0_0_2px] shadow-primary-800',
              isFocused ? 'hover:bg-accent' : 'hover:border-neutral-300 cursor-pointer',
              meta?.error && meta?.touched && 'border-rose-500'
            ),
          dropdownIndicator: ({ isFocused }) =>
            cn(
              isFocused ? 'text-neutral-600' : 'text-neutral-200',
              'p-2',
              isFocused ? 'hover:text-neutral-800' : 'hover:text-neutral-400'
            ),
          group: () => cn('py-2'),
          groupHeading: () =>
            cn(
              'text-neutral-400',
              'text-xs',
              'font-medium',
              'mb-1',
              'px-3',
              'uppercase'
            ),
          // indicatorsContainer: () => cn(),
          indicatorSeparator: ({ isDisabled }) =>
            cn(isDisabled ? 'bg-neutral-100' : 'bg-neutral-200', 'my-2'),
          input: () => cn('m-0.5', 'py-0.5', 'text-neutral-800'),
          loadingIndicator: ({ isFocused }) =>
            cn(isFocused ? 'text-neutral-600' : 'text-neutral-200', 'p-2'),
          loadingMessage: () => cn('text-neutral-400', 'py-2', 'px-3'),
          menu: () =>
            cn(
              'bg-white',
              'rounded',
              'shadow-[0_0_0_1px_rgba(0,0,0,0.1)]',
              'my-1',
            ),
          menuList: () => cn('py-1'),
          // menuPortal: () => cn(),
          multiValue: () => cn('bg-neutral-100', 'rounded-sm', 'm-0.5'),
          multiValueLabel: () =>
            cn('rounded-sm', 'text-neutral-800', 'text-sm', 'p-[3]', 'pl-[6]'),
          multiValueRemove: ({ isFocused }) =>
            cn(
              'rounded-sm',
              isFocused && 'bg-red-500',
              'px-1',
              'hover:bg-red-500',
              'hover:text-red-800'
            ),
          noOptionsMessage: () => cn('text-neutral-400', 'py-2', 'px-3'),
          option: ({ isDisabled, isFocused, isSelected }) =>
            cn(
              isSelected
                ? 'bg-primary'
                : isFocused
                ? 'bg-accent'
                : 'bg-transparent',
              isDisabled
                ? 'text-neutral-200'
                : isSelected
                ? 'text-white'
                : 'text-inherit',
              'py-2',
              'px-3',
              !isDisabled &&
                (isSelected ? 'active:bg-primary' : 'active:bg-primary-500')
            ),
          placeholder: () => cn('text-neutral-500', 'mx-0.5', 'cursor-pointer'),
          singleValue: ({ isDisabled }) =>
            cn(isDisabled ? 'text-neutral-400' : 'text-neutral-800', 'mx-0.5', 'cursor-pointer'),
          valueContainer: () => cn('py-0.5', 'px-2'),
        }}
        onChange={value => input.onChange(value)}
        {...rest}
      />
      {meta.error && meta.touched && <ValidationText error={meta?.error} />}
    </>
  )
}

export default SelectAdapter
