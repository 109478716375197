import { FC } from 'react'

import Searchbar from 'components/pages/Dashboard/components/Searchbar'
import { SORT_BY_TYPE, SORT_TYPE } from 'constants/sortEvent'
import FilterEvent from './Filter'

interface IProps {
  orderBy: SORT_BY_TYPE
  direction: SORT_TYPE
  setOrderBy: (value: SORT_BY_TYPE) => void
  setDirection: (value: SORT_TYPE) => void
}

const EventActions: FC<IProps> = ({
  orderBy,
  direction,
  setOrderBy,
  setDirection,
}) => {
  return (
    <div className='w-full flex flex-col gap-4 z-1'>
      <Searchbar />
      <FilterEvent
        orderBy={orderBy}
        direction={direction}
        setDirection={setDirection}
        setOrderBy={setOrderBy}
      />
    </div>
  )
}

export default EventActions
