import { gql } from 'utils/adapters'

export const CHANGE_ATTENDANT_STATUS = gql`
  mutation changeAttendeeStatus(
    $eventUID: ID!
    $attendeeUID: ID!
    $status: String!
  ) {
    changeAttendeeStatus(
      eventUID: $eventUID
      attendeeUID: $attendeeUID
      status: $status
    ) {
      status
      message
    }
  }
`

export const IMPORT_ATTENDEES = gql`
  mutation importAttendees(
    $attendees: [ImportAttendeeInput!]!
    $eventId: ID!
    $sendMailAfterImport: Boolean!
  ) {
    importAttendees(
      eventId: $eventId
      importAttendeesInput: $attendees
      sendMailAfterImport: $sendMailAfterImport
    ) {
      createdAttendees {
        email
        firstName
        lastName
      }
      errorAttendees
    }
  }
`

export const REMOVE_ATTENDEE = gql`
  mutation deleteAttendee($attendeeUID: String!, $eventUID: ID!) {
    deleteAttendee(attendeeUID: $attendeeUID, eventUID: $eventUID) {
      message
      status
    }
  }
`
