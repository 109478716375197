export const RANGES_TYPE = {
  FIXED_RANGES: 'fixedRanges',
  DATE_RANGES: 'dateRanges',
}

export const FIXED_RANGES_TYPE = {
  BEFORE: 'Before',
  AFTER: 'After',
}

export const FEE_TYPE = {
  PERCENTAGE: 'Percentage',
  FIXED_AMOUNT: 'FixedAmount',
}

export const DATE_OPTIONS = [
  {
    value: FIXED_RANGES_TYPE.BEFORE,
    label: FIXED_RANGES_TYPE.BEFORE,
  },
  {
    value: FIXED_RANGES_TYPE.AFTER,
    label: FIXED_RANGES_TYPE.AFTER,
  },
]

export const FEE_OPTIONS = [
  {
    value: FEE_TYPE.PERCENTAGE,
    label: 'Percentage',
  },
  {
    value: FEE_TYPE.FIXED_AMOUNT,
    label: 'Fixed amount',
  },
]

export const DATE_LIMIT = {
  START: '01/01/1970',
  END: '31/12/9999',
}
