import { create } from 'zustand'
import { TwoFAState } from './TwoFAState'
import {
  disable2FA,
  enable2FA,
  getEncryptedTokenReq,
  receive2FA,
  update2FAMethod,
  updatePhoneNumberOnboardingReq,
  verify2FA,
} from '@/services/authServices'
import { TVerify2FAResponse } from '@/models/IAuth'
import { TResponseSuccessfully } from '@/models/common'
import { TwoFactorMethod } from '@/constants/user'

export const use2FAStore = create<TwoFAState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  method: TwoFactorMethod.NONE,
  setMethod: (method: string) =>
    set({
      method: method,
    }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) =>
    set({
      isLoading,
    }),
  hasError: false,
  setHasError: (hasError: boolean) =>
    set({
      hasError,
    }),
}))

export const receiveMessage = async (method: string) => {
  try {
    const result: any = await receive2FA(method)
    if (result.data) return result.data
  } catch (err: any) {
    use2FAStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}

export const verifyCode = async (code: string) => {
  try {
    const result: { data: TVerify2FAResponse } = await verify2FA(code)
    return result.data
  } catch (err: any) {
    use2FAStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}

export const enableTwoFA = async (code: string, method: string) => {
  try {
    const refreshToken = localStorage.getItem('REFRESH_TOKEN')
    const result: { data: TResponseSuccessfully } = await enable2FA(
      code,
      method,
      refreshToken as string
    )
    if (result) return result.data
  } catch (err: any) {
    use2FAStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}

export const disableTwoFA = async (password: string) => {
  try {
    const refreshToken = localStorage.getItem('REFRESH_TOKEN')
    const result: { data: TResponseSuccessfully } = await disable2FA(
      password,
      refreshToken as string
    )
    if (result) return result.data
  } catch (err: any) {
    use2FAStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}

export const updateTwoFAMethod = async (code: string, method: string) => {
  try {
    const result = await update2FAMethod(code, method)
    if (result) return result.data
  } catch (err: any) {
    use2FAStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}

export const getEncryptedToken = async (returnUrl: string) => {
  try {
    const result = await getEncryptedTokenReq(returnUrl)
    if (result) return result.data.encryptedToken
  } catch (err: any) {
    use2FAStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}

export const updatePhoneNumberOnboarding = async (data: {
  email: string
  phoneNumber: string
}) => {
  try {
    const result = await updatePhoneNumberOnboardingReq(data)
    if (result) return result.data
  } catch (err: any) {
    use2FAStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}
