import { createSlice } from '@reduxjs/toolkit'
import {
  Attendee,
  EventAction,
  EventCustomValue,
  IEvent,
  ImportAttendeeType,
  IPayment,
} from 'models'

const eventsReducer = createSlice({
  name: 'events',
  initialState: {
    emailPlaceHolders: [],
    tabState: false,
    events: [] as IEvent[],
    activeEvents: [] as IEvent[],
    expiredEvents: [] as IEvent[],
    isPendingEvent: false,
    event: {} as IEvent,
    isLoading: false,
    formData: {} as unknown as any,
    newLetter: '',
    attendeeInfo: {} as Attendee,
    price: 0,
    payments: [] as IPayment[],
    paid: 0,
    defaultLanguage: 'ENGLISH',
    registeredCustomFields: [] as EventCustomValue[],
    isUpdated: false,
    action: EventAction.CREATE,

    step: 0,
    isCreateCustomField: false,
    totalPrice: 0,
    optionWidth: 0,
    isPendingPayment: false,
    isFullyBooked: false,
    eventLang: 'en',
    importAttendeesData: [] as ImportAttendeeType[],
    eventDetail: {} as IEvent,
    eventTerms: '',
  },
  reducers: {
    setEventDetail: (state, action) => {
      state.eventDetail = action.payload
    },
    setTabState: (state, action) => {
      state.tabState = action.payload
    },

    setIsPendingPayment: (state, action) => {
      state.isPendingPayment = action.payload
    },
    setEmailPlaceHolders: (state, action) => {
      state.emailPlaceHolders = action.payload
    },
    setOptionWidth: (state, action) => {
      state.optionWidth = action.payload
    },

    setEvents: (state, action) => {
      state.events = action.payload

      state.isPendingEvent = false
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSelectedEvent: (state, action) => {
      state.event = action.payload
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setFormData: (state, action) => {
      state.formData = action.payload
    },
    setNewLetter: (state, action) => {
      state.newLetter = action.payload
    },
    setEventAction: (state, action) => {
      state.action = action.payload
    },
    setIsCreateCustomField: (state, action) => {
      state.isCreateCustomField = action.payload
    },
    clearFormData: state => {
      state.formData = {}
    },

    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload
    },
    setFullyBooked: (state, action) => {
      state.isFullyBooked = action.payload
    },
    setIsPendingEvent: (state, action) => {
      state.isPendingEvent = action.payload
    },
    setAttendeeInfo: (state, action) => {
      state.attendeeInfo = action.payload
    },
    setEventPrice: (state, action) => {
      state.price = action.payload
    },
    setPayments: (state, action) => {
      state.payments = action.payload
    },
    setPaidAmount: (state, action) => {
      state.paid = action.payload
    },
    setEventLang: (state, action) => {
      state.eventLang = action.payload
    },
    setRegisteredCustomFields: (state, action) => {
      state.registeredCustomFields = action.payload
    },
    setIsUpdated: (state, action) => {
      state.isUpdated = action.payload
    },
    setImportAttendees: (state, action) => {
      state.importAttendeesData = action.payload
    },
    editImportAttendee: (state, action) => {
      const index = state.importAttendeesData.findIndex(
        attendee => attendee.id === action.payload.id
      )
      state.importAttendeesData[index] = action.payload
    },
    removeImportAttendee: (state, action) => {
      console.log(action.payload)
      state.importAttendeesData = state.importAttendeesData.filter(
        attendee => attendee.id !== action.payload
      )
    },

    setEventTerms: (state, action) => {
      state.eventTerms = action.payload
    },
  },
})

export const {
  setEmailPlaceHolders,
  setIsLoading,
  setSelectedEvent,
  setStep,
  setFormData,
  setEventAction,
  clearFormData,

  setIsCreateCustomField,
  setTotalPrice,
  setOptionWidth,
  setIsPendingPayment,
  setFullyBooked,
  setIsPendingEvent,
  setEvents,
  setTabState,
  setAttendeeInfo,
  setEventPrice,
  setPayments,
  setPaidAmount,
  setEventLang,
  setRegisteredCustomFields,
  setIsUpdated,
  setImportAttendees,
  editImportAttendee,
  removeImportAttendee,
  setNewLetter,
  setEventDetail,
  setEventTerms,
} = eventsReducer.actions
export default eventsReducer.reducer
