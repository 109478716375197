import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { CheckIcon, PlusCircle } from 'lucide-react'
import { Separator } from '@/components/ui/separator'
import { Badge } from '@/components/ui/badge'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { getAllRoles } from '@/components/pages/Users/mixins'
import Loading from '@/components/atoms/Loading'
import { cn } from '@/lib/utils'
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command'

export const UserTableFilter = ({ onFilterStatus, onFilterRole }) => {
  const { t } = useTranslation()

  const { data: rolesData, loading: roleLoading } = getAllRoles()
  if (roleLoading) {
    return <Loading />
  }

  const statusOptions = [
    { label: t('Active'), value: 'false' },
    { label: t('Inactive'), value: 'true' },
  ]

  const roleOptions = rolesData?.getAllRoles?.data?.map((role: string) => ({
    label: role,
    value: role,
  }))

  return (
    <div className='flex space-x-2'>
      <Filter
        title={t('Status')}
        options={statusOptions}
        onFilter={onFilterStatus}
      />
      <Filter title={t('Role')} options={roleOptions} onFilter={onFilterRole} />
    </div>
  )
}

const Filter = ({ title, options, onFilter }) => {
  const { t } = useTranslation()

  const [selectedValues, setSelectedValues] = React.useState(new Set())

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant='outline' size='sm' className='h-8 border-dashed'>
          <PlusCircle className='mr-2 h-4 w-4' />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <Separator orientation='vertical' className='mx-2 h-4' />
              <Badge
                variant='secondary'
                className='rounded-sm px-1 font-normal lg:hidden'
              >
                {selectedValues.size}
              </Badge>
              <div className='hidden space-x-1 lg:flex'>
                {selectedValues.size > 2 ? (
                  <Badge
                    variant='secondary'
                    className='rounded-sm px-1 font-normal'
                  >
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  options
                    .filter(option => selectedValues.has(option.value))
                    .map(option => (
                      <Badge
                        variant='secondary'
                        key={option.value}
                        className='rounded-sm px-1 font-normal'
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[200px] p-0' align='start'>
        <Command>
          <CommandList>
            <CommandGroup>
              {options.map(option => {
                const isSelected = selectedValues.has(option.value)
                return (
                  <CommandItem
                    key={option.value}
                    onClick={() => {
                      if (selectedValues.size > 0) {
                        selectedValues.clear()
                      }
                      if (isSelected) {
                        selectedValues.delete(option.value)
                      } else {
                        selectedValues.add(option.value)
                      }
                      const filterValues = Array.from(selectedValues)
                      setSelectedValues(new Set(selectedValues))
                      onFilter(filterValues)
                    }}
                  >
                    <div
                      className={cn(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                        isSelected
                          ? 'bg-primary text-primary-foreground'
                          : 'opacity-50 [&_svg]:invisible'
                      )}
                    >
                      <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                    {option.icon && (
                      <option.icon className='mr-2 h-4 w-4 text-muted-foreground' />
                    )}
                    <span>{option.label}</span>
                  </CommandItem>
                )
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandGroup>
                  <CommandItem
                    onClick={() => {
                      onFilter(undefined)
                      setSelectedValues(new Set())
                    }}
                    className='justify-center text-center cursor-pointer'
                  >
                    {t('Clear filters')}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
