import { FC } from 'react'
import CopyToClipBoard from '../CopyToClipBoard'

const WebcastJoinLink: FC<{ webcastJoinLinks: string[] }> = ({
  webcastJoinLinks,
}) => {
  return (
    <>
      {webcastJoinLinks?.map((link, index: number) => (
        <div key={index}>
          <CopyToClipBoard value={link} />
        </div>
      ))}
    </>
  )
}

export default WebcastJoinLink
