import { useReactiveVar } from '@apollo/client'
import { organizationIdVar } from 'graphql/variables'

const useOrganizationIdVar = () => {
  const organizationId = useReactiveVar(organizationIdVar)
  const setOrganizationId = (organizationId: string) => {
    organizationIdVar(organizationId)
  }
  return {
    organizationId,
    setOrganizationId,
  }
}

export default useOrganizationIdVar
