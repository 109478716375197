import { isEqual, uniqWith } from 'lodash'
import { CustomField, CustomFieldOption } from 'models'

export type customFieldDataType = {
  [name in string | number]: {
    eventCustomField: string
    value: CustomFieldOption[]
  }
}

type checkedOjectType = {
  [name in string | number]: {
    id: string
    value: boolean
    customValues: CustomFieldOption
  }
}

export const compareDataSelected = (options: any, selectedFields: any) => {
  const result: any = []
  options?.forEach((option: any) => {
    const foundIndex = selectedFields?.findIndex(
      (el: any) => el?.eventCustomField === option?.id
    )

    if (foundIndex !== -1) {
      selectedFields[foundIndex]?.value?.forEach((el: any) => {
        if (isEqual(el, option?.value)) {
          result.push(option)
        }
      })
    } else {
      return []
    }
  })
  return result
}

export const mapCheckedObject = (checkedObject: checkedOjectType) => {
  return Object.keys(checkedObject)
    .map(key => {
      if (checkedObject[key].value === true) {
        return {
          eventCustomField: checkedObject[key].id,
          value: [
            {
              name: checkedObject[key].customValues.name,
              fee: checkedObject[key].customValues.fee,
            },
          ],
        }
      }
    })
    .filter(item => item)
}

export const transformSelectedOption = (selectedFields: any[]) => {
  return selectedFields.reduce((obj: any, item: any) => {
    obj[item?.eventCustomField]
      ? obj[item?.eventCustomField].value.push(...item?.value)
      : (obj[item?.eventCustomField] = { ...item })
    return obj
  }, {})
}

export const mapSelectedFields = (selectedFields: any[]) => {
  return Object.values(selectedFields).map((selectedItem: any) => ({
    ...selectedItem,
    value: uniqWith(selectedItem.value, isEqual),
  }))
}

export const checkMissingRequiredField = (
  requiredFields: CustomField[],
  customField: CustomField
) => {
  const index = requiredFields.findIndex(
    (item: CustomField) => item._id === customField._id
  )

  return index !== -1
}
