import { useTranslation } from 'react-i18next'
import { Separator } from '@/components/ui/separator'
import { SidebarNav } from './components/SidebarNav'
import Header from '@/components/organisms/Header'
import { getCurrentUser } from '../Users/mixins'
import Loading from '@/components/atoms/Loading'
import { useUserStore } from '@/store/zustand'
import { useHistory } from 'react-router'

const SettingsLayout: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const sidebarNavItems = [
    {
      title: t('Account'),
      href: '/settings',
    },
    {
      title: t('Password and authentication'),
      href: '/settings/auth',
    },
  ]

  const { data: user, loading } = getCurrentUser()

  useUserStore.getState().setCurrentUser(user?.currentUser)

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Header
        displayBackIcon
        handleBack={() => history.push('/')}
        title={t('Settings')}
        showLanguageSelector={true}
      />
      <div className='space-y-6 p-10 pb-16 md:block'>
        <div className='space-y-0.5'>
          <h2 className='text-2xl font-bold tracking-tight'>{t('Settings')}</h2>
          <p className='text-muted-foreground'>
            {t('Manage your account settings and set e-mail preferences.')}
          </p>
        </div>
        <Separator className='my-6' />
        <div className='flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0'>
          <aside className='-mx-4 lg:w-1/5'>
            <SidebarNav items={sidebarNavItems} />
          </aside>
          {children}
        </div>
      </div>
    </>
  )
}

export default SettingsLayout
