import { useQuery } from '@apollo/client'
import {
  ADMIN_GET_EVENT_BY_ID,
  ATTENDEE_GET_EVENT_BY_ID,
  GET_ATTENDEE_BOOKING_DETAIL,
} from 'graphql/queries'
import { useAppDispatch } from 'utils/hooks'
import { setAttendeeInfo } from 'store/Events'
import { t } from 'i18next'
import { toast } from '@/components/ui/use-toast'

export const useAdminEventDetail = (id: string) => {
  const { data, loading } = useQuery(ADMIN_GET_EVENT_BY_ID, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
    variables: {
      id,
    },
  })

  return {
    data,
    loading,
  }
}

export const useAttendeeEventDetail = (id: string) => {
  const { data, loading } = useQuery(ATTENDEE_GET_EVENT_BY_ID, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
    variables: {
      id,
    },
  })

  return {
    data,
    loading,
  }
}

export const getAttendeeBookingDetail = (eventId: string) => {
  const dispatch = useAppDispatch()

  const { loading, data: attendeeBookingData } = useQuery(
    GET_ATTENDEE_BOOKING_DETAIL,
    {
      onCompleted() {
        if (attendeeBookingData) {
          dispatch(setAttendeeInfo(attendeeBookingData?.attendeeBooking))
        }
      },
      onError: err => {
        err.graphQLErrors.map(({ message }) => {
          toast({
            title: t('Error'),
            description: t(message),
            variant: 'destructive',
          })
        })
      },

      variables: {
        eventId,
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  return {
    data: attendeeBookingData?.attendeeBooking,
    loading,
  }
}
