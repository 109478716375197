import { cn } from '../../../lib/utils'

interface IProps {
  title: string
}

const EventAdminMainNav = ({
  title,
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) => {
  return (
    <nav
      className={cn('flex items-center space-x-4 lg:space-x-6', className)}
      {...props}
    >
      <span className='text-palette-800 w-full text-lg font-medium uppercase'>
        {title}
      </span>
    </nav>
  )
}

export default EventAdminMainNav
