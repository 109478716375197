import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { IMAGE_MAX_SIZE } from '@/constants'
import { uploadImageReq } from '@/services/uploadServices'
import { useInvitationTemplate } from '@/store/zustand/InvitationTemplateManagement'
import {
  defaultTimezone,
  getEventDateByTimeZone,
  getEventTime,
} from '@/utils/helpers'
import { useAppSelector } from '@/utils/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as z from 'zod'
import { Label } from '@radix-ui/react-label'
import DotsLoading from '@/components/atoms/DotsLoading'
import { INVITATION_TEMPLATE_IDS } from '@/constants/invitationTemplate'
import { XCircle } from 'lucide-react'

const InvitationTemplateForm = ({ handleUpdateTemplate }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const lang = localStorage.getItem('i18nextLng') as string
  const [updateLoading, setUpdateLoading] = useState(false)
  const [logoPreview, setLogoPreview] = useState('')
  const [updateLogoLoading, setUpdateLogoLoading] = useState(false)
  const [logoErrMsg, setLogoErrMsg] = useState('')

  const event = useAppSelector(state => state.eventsReducer.eventDetail)
  const eventInvitationTemplate = useInvitationTemplate(
    state => state.eventInvitationTemplate
  )

  const selectedTemplate = useInvitationTemplate(
    state => state.selectedTemplate
  )

  const formSchema = z.object({
    title: z.string(),
    host: z.string(),
    eventTime: z.string(),
    cardTopContent: z.string(),
    cardBottomContent: z.string(),
    logo: z.string(),
    copyRight: z.string(),
  })

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      host: '',
      eventTime: '',
      cardTopContent: '',
      cardBottomContent: '',
      logo: '',
      copyRight: '',
    },
    mode: 'onChange',
  })

  useEffect(() => {
    if (eventInvitationTemplate) {
      const {
        title,
        host,
        eventTime,
        cardTopContent,
        cardBottomContent,
        logo,
        copyRight,
      } = eventInvitationTemplate.fields
      form.reset({
        title,
        host,
        eventTime,
        cardTopContent,
        cardBottomContent,
        logo,
        copyRight,
      })
      setLogoPreview(logo)
    }
  }, [eventInvitationTemplate])

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setUpdateLoading(true)
    await handleUpdateTemplate(values)
    setUpdateLoading(false)
  }

  const getCopyRightPlaceholder = () => {
    switch (selectedTemplate) {
      case INVITATION_TEMPLATE_IDS.template1:
        return '©2024 ConferenceDirect'
      case INVITATION_TEMPLATE_IDS.template2:
        return '© 2024 wineBANK Franchise GmbH & Co. KG'
      case INVITATION_TEMPLATE_IDS.template3:
        return '©2024 Liechtensteinische Landesbank AG'
      default:
        return '©2024 ConferenceDirect'
    }
  }

  const getTextAreaPlaceholder = () => {
    switch (selectedTemplate) {
      case INVITATION_TEMPLATE_IDS.template1:
        return 'ConferenceDirect'
      case INVITATION_TEMPLATE_IDS.template2:
        return 'wineBANK'
      case INVITATION_TEMPLATE_IDS.template3:
        return 'Liechtensteinische Landesbank AG'
      default:
        return 'ConferenceDirect'
    }
  }

  const handleUploadLogo = async event => {
    const file = event.target.files[0]
    if (file) {
      if (file.size > IMAGE_MAX_SIZE) {
        setLogoErrMsg(
          t('event_invitation.template_form.error.logo_size', {
            max: 5,
          })
        )
      } else {
        setLogoErrMsg('')
        const formData = new FormData()
        formData.append('file', file)
        setUpdateLogoLoading(true)
        const res = await uploadImageReq(formData)
        setUpdateLogoLoading(false)
        if (res) {
          form.setValue('logo', res.data.uri)
          setLogoPreview(res.data.uri)
        }
      }
    }
  }

  const handlePreviewTemplate = () => {
    const previewData = {
      cardTopContent:
        form.getValues().cardTopContent || `You are invited \n ${event.title}`,
      cardBottomContent:
        form.getValues().cardBottomContent || defaultCardBottomContent(),
      title: form.getValues().title || event.title,
      host: form.getValues().host || getTextAreaPlaceholder(),
      eventTime: form.getValues().eventTime || defaultCardBottomContent(),
      logo: form.getValues().logo || eventInvitationTemplate?.fields?.logo,
      copyRight: form.getValues().copyRight || getCopyRightPlaceholder(),
    }
    useInvitationTemplate.getState().setTemplateDetailPreview(previewData)
    history.push(
      `/admin/events/${event._id}/invitation-template/${selectedTemplate}`
    )
  }
  const tz = event?.timezone || defaultTimezone
  const { isTheSameDay, startTime, endTime, utcOffset } = getEventTime(
    event?.mainLanguage,
    tz,
    event?.startEvent,
    event?.endEvent
  )
  const startEventTime = getEventDateByTimeZone(event?.startEvent, lang, tz)
  const endEventTime = getEventDateByTimeZone(event?.endEvent, lang, tz)

  const defaultCardBottomContent = () => {
    if (isTheSameDay) {
      return `${startTime} - ${endTime} (GMT${utcOffset})`
    } else {
      return `${startEventTime} - ${endEventTime}`
    }
  }

  return (
    <div className=''>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
          <div className='flex gap-[80px]'>
            <div className='flex-1'>
              <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
                <FormField
                  control={form.control}
                  name='cardTopContent'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className='text-base'>
                        {t('event_invitation.template_form.card_top_content')}
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder={`You are invited \n ${event.title}`}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='cardBottomContent'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className='text-base'>
                        {t(
                          'event_invitation.template_form.card_bottom_content'
                        )}
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder={defaultCardBottomContent()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='title'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className='text-base'>
                        {t('event_invitation.template_form.title')}
                      </FormLabel>
                      <FormControl>
                        <Textarea {...field} placeholder={event.title} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='host'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className='text-base'>
                        {t('event_invitation.template_form.host')}
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder={getTextAreaPlaceholder()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name='eventTime'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className='text-base'>
                        {t('event_invitation.template_form.event_time')}
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder={defaultCardBottomContent()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='copyRight'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className='text-base'>
                        {t('event_invitation.template_form.copy_right')}
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          placeholder={getCopyRightPlaceholder()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='logo'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className='text-base'>
                        {t('event_invitation.template_form.logo')}
                      </FormLabel>
                      <FormControl>
                        <div className='p-4 border-2 rounded-md border-dashed flex flex-col items-center'>
                          <Input
                            id='upload'
                            type='file'
                            accept='.png, .jpeg, .jpg'
                            className='cursor-pointer hidden'
                            onChange={handleUploadLogo}
                          />
                          <Label
                            htmlFor='upload'
                            className='bg-accent rounded-sm px-4 py-2'
                          >
                            {t('event_invitation.template_form.choose_logo')}
                          </Label>
                          {logoErrMsg && (
                            <p className='text-sm font-medium text-destructive'>
                              {logoErrMsg}
                            </p>
                          )}
                          <div className='relative min-h-[30px]'>
                            <div className='absolute mt-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                              {updateLogoLoading && <DotsLoading />}
                            </div>
                            {!updateLogoLoading && logoPreview && (
                              <div className='border border-dashed border-primary p-1 cursor-pointer mt-4'>
                                <img
                                  className='max-h-[50px] max-w-[200px] mx-auto'
                                  src={logoPreview}
                                />
                                <div
                                  className='absolute top-[10px] right-[5px] w-[5px] z-100 cursor-pointer'
                                  onClick={() => {
                                    setLogoPreview('')
                                    form.setValue('logo', '')
                                  }}
                                >
                                  <XCircle
                                    color='#665252'
                                    size='20px'
                                    className='cursor-pointer'
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
          <div className='flex justify-center gap-4'>
            <Button
              type='button'
              variant='outline'
              className='bg-accent w-full'
              onClick={handlePreviewTemplate}
              disabled={updateLogoLoading || updateLoading}
            >
              {t('event_invitation.preview_btn')}
            </Button>
            <Button
              type='submit'
              className='w-full'
              disabled={updateLogoLoading || updateLoading}
            >
              {updateLoading && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('event_invitation.template_form.submit_btn')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}

export default InvitationTemplateForm
