import { FC } from 'react'
import moment from 'moment'

import Loading from 'components/atoms/Loading'
import { styled, useParams } from 'utils/adapters'
import { useAdminEventDetail } from '../EventDetail/mixins'
import CancellationPolicy from './CancellationPolicy'

import {
  DATE_LIMIT,
  FIXED_RANGES_TYPE,
  RANGES_TYPE,
} from 'constants/cancellationPolicy'

const CancellationPolicyContainer: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useAdminEventDetail(id)
  const event = data?.event
  const formData = event?.eventSetting.cancellationPolicies

  console.log('formData', formData)

  const formValue: any = {
    data: [
      {
        feeNumber: null,
        feeType: '',
        startDate: '',
        endDate: '',
        date: '',
        type: RANGES_TYPE.FIXED_RANGES,
      },
    ],
  }

  const isLimitedStartDate = (startDate: string) => {
    return moment(startDate).format('DD/MM/YYYY') === DATE_LIMIT.START
  }

  const isLimitedEndDate = (endDate: string) => {
    return moment(endDate).format('DD/MM/YYYY') === DATE_LIMIT.END
  }

  const getFormType = (item: any) => {
    if (isLimitedStartDate(item.startDate) || isLimitedEndDate(item.endDate)) {
      return RANGES_TYPE.FIXED_RANGES
    } else {
      return RANGES_TYPE.DATE_RANGES
    }
  }

  const getDateType = (item: any) => {
    if (isLimitedStartDate(item.startDate)) {
      return {
        value: FIXED_RANGES_TYPE.BEFORE,
        label: FIXED_RANGES_TYPE.BEFORE,
      }
    } else if (isLimitedEndDate(item.endDate)) {
      return {
        value: FIXED_RANGES_TYPE.AFTER,
        label: FIXED_RANGES_TYPE.AFTER,
      }
    } else return null
  }

  const formattedDataForm = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        feeType: {
          value: item.feeType,
          label: item.feeType,
        },
        type: getFormType(item),
        dateType: getDateType(item),
        date: isLimitedStartDate(item.startDate)
          ? moment(item.endDate).toDate()
          : isLimitedEndDate(item.endDate)
          ? moment(item.startDate).toDate()
          : new Date(),
        startDate: moment(item.startDate).toDate(),
        endDate: moment(item.endDate).toDate(),
      }
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Wrapper>
        <CancellationPolicy
          values={
            formData.length ? { data: formattedDataForm(formData) } : formValue
          }
        />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  padding: 4% 8%;
`

export default CancellationPolicyContainer
