import { gql } from 'utils/adapters'

export const CREATE_CUSTOM_FIELD = gql`
  mutation CreateCustomField($createCustomFieldInput: CreateCustomFieldInput!) {
    createCustomField(createCustomFieldInput: $createCustomFieldInput) {
      _id
      title
      options {
        name
        fee
      }
      isRequired
      type
    }
  }
`

export const EDIT_CUSTOM_FIELD = gql`
  mutation UpdateCustomField($updateCustomFieldInput: UpdateCustomFieldInput!) {
    updateCustomField(updateCustomFieldInput: $updateCustomFieldInput) {
      _id
      title
      isRequired
      type
      options {
        name
        fee
      }
    }
  }
`

export const DELETE_CUSTOM_FIELD = gql`
  mutation RemoveCustomField($id: ID!) {
    removeCustomField(id: $id) {
      status
      message
    }
  }
`
