import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { Textarea } from '@/components/ui/textarea'
import { toast } from '@/components/ui/use-toast'
import { MESSAGE } from '@/constants/message'
import { cn } from '@/lib/utils'
import { createNewRelease } from '@/store/zustand/Releases'
import { zodResolver } from '@hookform/resolvers/zod'
import { addDays, format } from 'date-fns'
import { CalendarIcon, Plus } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

export const CreateRelease = () => {
  const { t } = useTranslation()

  const formSchema = z.object({
    version: z.string().nonempty(t(MESSAGE.REQUIRED_FIELD)),
    title: z.string().nonempty(t(MESSAGE.REQUIRED_FIELD)),
    description: z.string(),
    detail: z.string(),
    date: z.date({
      required_error: t(MESSAGE.REQUIRED_FIELD),
    }),
  })
  type FormValues = z.infer<typeof formSchema>
  const defaultValues: Partial<FormValues> = {
    version: '',
    title: '',
    description: '',
    detail: '',
    date: new Date(),
  }
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  })
  const { formState } = form

  async function onSubmit(values: any) {
    const res = await createNewRelease(values)

    if (res) {
      toast({
        description: t('Create release success'),
      })
    } else {
      toast({
        description: t('Create release failed'),
        variant: 'destructive',
      })
    }
  }
  return (
    <Sheet>
      <SheetTrigger>
        <Button>
          <Plus className='cursor-pointer mr-2 h-5' />
          {t('releases.create.title')}
        </Button>
      </SheetTrigger>

      <SheetContent className='w-[400px] sm:w-[540px] sm:max-w-[540px]'>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
            <SheetHeader>
              <SheetTitle>{t('releases.create.title')}</SheetTitle>
              <SheetDescription>
                <div className='grid space-y-6'>
                  <FormField
                    control={form.control}
                    name='version'
                    key='version'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          {t('Version')} <span className='text-red-600'>*</span>
                        </FormLabel>
                        <FormControl>
                          <Input placeholder='v1.0.0' {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='title'
                    key='title'
                    render={({ field }) => (
                      <FormItem className='relative'>
                        <FormLabel>
                          {t('Title')} <span className='text-red-600'>*</span>
                        </FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='description'
                    render={({ field }) => (
                      <FormItem className='relative'>
                        <FormLabel>{t('Description')}</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='detail'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t('Detail')}</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder='...'
                            // className='resize-none'
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='date'
                    render={({ field }) => (
                      <div className='space-y-2'>
                        <FormLabel>{t('Date')}</FormLabel>
                        <DatePickerWithPresets {...field} />
                      </div>
                    )}
                  />
                </div>
              </SheetDescription>
            </SheetHeader>
            <SheetFooter>
              <SheetClose>
                <Button variant='ghost'>{t('Cancel')}</Button>
              </SheetClose>
              <Button
                disabled={formState.isLoading}
                type='submit'
                className='px-12'
              >
                {formState.isLoading && (
                  <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                )}
                {t('Create')}
              </Button>
            </SheetFooter>
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}

function DatePickerWithPresets({ value, onChange, ...props }) {
  const { t } = useTranslation()

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal',
            !value && 'text-muted-foreground'
          )}
        >
          <CalendarIcon className='mr-2 h-4 w-4' />
          {value ? (
            format(value, 'dd/MM/yyyy')
          ) : (
            <span>{t('Pick a date')}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='flex w-auto flex-col space-y-2 p-2'>
        <Select
          onValueChange={value =>
            onChange(addDays(new Date(), parseInt(value)))
          }
        >
          <SelectTrigger>
            <SelectValue placeholder={t('Select')} />
          </SelectTrigger>
          <SelectContent position='popper'>
            <SelectItem value='0'>{t('Today')}</SelectItem>
            <SelectItem value='1'>{t('Tomorrow')}</SelectItem>
            <SelectItem value='3'>{t('In 3 days')}</SelectItem>
            <SelectItem value='7'>{t('In a week')}</SelectItem>
          </SelectContent>
        </Select>
        <div className='rounded-md border'>
          <Calendar
            mode='single'
            selected={value}
            onSelect={onChange}
            disabled={date =>
              date < new Date() || date < new Date('1900-01-01')
            }
            initialFocus
          />
        </div>
      </PopoverContent>
    </Popover>
  )
}
