import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { toast } from '@/components/ui/use-toast'
import { MESSAGE } from '@/constants/message'
import { UPDATE_PHONE_NUMBER } from '@/graphql/mutations'
import { useUserStore } from '@/store/zustand'
import { useMutation } from '@/utils/adapters'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import * as z from 'zod'

import './styles.css'

export default function PhoneNumberForm() {
  const { t } = useTranslation()
  const { currentUser, setCurrentUser } = useUserStore()

  const [mutateUpdateUser, { loading }] = useMutation(UPDATE_PHONE_NUMBER, {
    onCompleted: data => {
      const phoneNumber = data.updateCurrentUser.phoneNumber as string
      if (currentUser)
        setCurrentUser({
          ...currentUser,
          phoneNumber,
        })
      toast({
        title: t('Profile successfully updated'),
      })
    },

    onError: err => {
      toast({
        title: t('Error: Internal Server Error'),
        variant: 'destructive',
      })
      console.error(err)
    },
  })

  const profileFormSchema = z.object({
    phoneNumber: z.string().nonempty({ message: t(MESSAGE.REQUIRED_FIELD) }),
  })

  type ProfileFormValues = z.infer<typeof profileFormSchema>

  useEffect(() => {
    if (currentUser?.phoneNumber)
      form.reset({
        phoneNumber: currentUser?.phoneNumber,
      })
  }, [])

  const defaultValues: Partial<ProfileFormValues> = {
    phoneNumber: '',
  }
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues,
    mode: 'onChange',
  })

  function onSubmit(data: ProfileFormValues) {
    mutateUpdateUser({
      variables: {
        user: {
          ...data,
          email: currentUser?.email,
        },
      },
    })
  }

  const disabled = loading

  return (
    <div className='space-y-6'>
      <div>
        <h3 className='text-lg font-medium'>
          {t('Phone Number')} {''}
          <span className='text-red-600'>*</span>
        </h3>
        <p className='text-sm text-muted-foreground'>
          {t(
            'You will need this number to access two factor authentication enabled accounts.'
          )}
        </p>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='flex space-x-2 w-full'
        >
          <FormField
            control={form.control}
            name='phoneNumber'
            render={({ field }) => (
              <FormItem className='flex-auto'>
                <FormControl>
                  <PhoneInput
                    className='flex h-10 w-full rounded-md border border-input bg-transparent pl-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
                    international
                    defaultCountry='DE'
                    placeholder={t('Enter phone number')}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button disabled={disabled} className='mt-0' type='submit'>
            {disabled && (
              <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
            )}
            {t('Update phone number')}
          </Button>
        </form>
      </Form>
    </div>
  )
}
