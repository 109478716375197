import { FC } from 'react'
import { styled } from 'utils/adapters'
import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'

const Content: FC = () => {
  return (
    <Wrapper>
      <H1>Datenschutzerklärung</H1>
      <p>
        Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden
        von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der
        Bereitstellung eines funktionsfähigen und nutzerfreundlichen
        Internetauftritts, inklusive seiner Inhalte und der dort angebotenen
        Leistungen, verarbeitet.
      </p>
      <p>
        Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der
        Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als
        „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten, wie das Erheben, das Erfassen, die
        Organisation, das Ordnen, die Speicherung, die Anpassung oder
        Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung
        durch Übermittlung, Verbreitung oder eine andere Form der
        Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung,
        das Löschen oder die Vernichtung.
      </p>
      <p>
        Mit der nachfolgenden Datenschutzerklärung informieren wir Sie
        insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der
        Verarbeitung personenbezogener Daten, soweit wir entweder allein oder
        gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
        entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu
        Optimierungszwecken sowie zur Steigerung der Nutzungsqualität
        eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum
        eigener Verantwortung verarbeiten.
      </p>
      <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
      <p>
        <span>I. Informationen über uns als Verantwortliche</span> <br />
        <span>II. Rechte der Nutzer und Betroffenen</span> <br />
        <span>III. Informationen zur Datenverarbeitung</span>
      </p>
      <H4>I. Informationen über uns als Verantwortliche</H4>
      <p>
        Verantwortlicher Anbieter dieses Internetauftritts im
        datenschutzrechtlichen Sinne ist:
      </p>
      <p>
        <span>ConferenceDirect Germany GmbH</span>
        <br />
        <span>Große Gallusstraße 16-18</span>
        <br />
        <span>60312, Frankfurt am Main</span>
        <br />
        <span>Deutschland</span> <br />
        <Link>E-Mail: okan.serinken@conferencedirect.com</Link>
      </p>
      <H4>II. Rechte der Nutzer und Betroffenen</H4>
      <p>
        Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung
        haben die Nutzer und Betroffenen das Recht
      </p>
      <ul>
        <li>
          auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf
          Auskunft über die verarbeiteten Daten, auf weitere Informationen über
          die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15
          DSGVO);
        </li>

        <li>
          auf Berichtigung oder Vervollständigung unrichtiger bzw.
          unvollständiger Daten (vgl. auch Art. 16 DSGVO);
        </li>

        <li>
          auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art.
          17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß
          Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der
          Verarbeitung nach Maßgabe von Art. 18 DSGVO;
        </li>

        <li>
          auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten
          und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche
          (vgl. auch Art. 20 DSGVO);
        </li>

        <li>
          auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht
          sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß
          gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch
          Art. 77 DSGVO).
        </li>
      </ul>
      <p>
        Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen
        gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede
        Berichtigung oder Löschung von Daten oder die Einschränkung der
        Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt,
        zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese
        Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand
        verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft
        über diese Empfänger.
      </p>
      <p>
        <strong>
          Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das
          Recht auf Widerspruch gegen die künftige Verarbeitung der sie
          betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe
          von Art. 6 Abs. 1 lit. {'f)'} DSGVO verarbeitet werden. Insbesondere
          ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der
          Direktwerbung statthaft.
        </strong>
      </p>
      <H4>III. Informationen zur Datenverarbeitung</H4>
      <p>
        Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden
        gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der
        Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen und nachfolgend keine anderslautenden Angaben zu
        einzelnen Verarbeitungsverfahren gemacht werden.
      </p>
      <H4>Serverdaten</H4>
      <p>
        Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren
        und stabilen Internetauftritts, werden Daten durch Ihren
        Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt.
        Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres
        Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf
        unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s)
        unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des
        jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von
        dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.
      </p>
      <p>
        Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch
        nicht gemeinsam mit anderen Daten von Ihnen.
      </p>
      <p>
        Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit.
        {'f)'} DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung,
        Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.
      </p>
      <p>
        Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit
        keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist.
        Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls
        ganz oder teilweise von der Löschung ausgenommen.
      </p>
      <H4>Cookies</H4>
      <H5>{'a)'} Sitzungs-Cookies/Session-Cookies</H5>
      <p>
        Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind
        kleine Textdateien oder andere Speichertechnologien, die durch den von
        Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und
        gespeichert werden. Durch diese Cookies werden im individuellen Umfang
        bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder
        Standortdaten oder Ihre IP-Adresse, verarbeitet.
      </p>{' '}
      <p>
        Durch diese Verarbeitung wird unser Internetauftritt
        benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw.
        die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen
        oder das Angebot einer Warenkorbfunktion ermöglicht.
      </p>{' '}
      <p>
        Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit {'b.) DSGVO'},
        sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung
        verarbeitet werden.
      </p>{' '}
      <p>
        Falls die Verarbeitung nicht der Vertragsanbahnung oder
        Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der
        Verbesserung der Funktionalität unseres Internetauftritts.
        Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. {'f) DSGVO.'}
      </p>{' '}
      <p>
        Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies
        gelöscht.
      </p>
      <H5>
        {`
              b) Drittanbieter-Cookies
          `}
      </H5>
      <p>
        Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von
        Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse
        oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten,
        verwendet.
      </p>{' '}
      <p>
        Die Einzelheiten hierzu, insbesondere zu den Zwecken und den
        Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies,
        entnehmen Sie bitte den nachfolgenden Informationen.
      </p>
      <H5>
        {`
          c) Beseitigungsmöglichkeit
          `}
      </H5>
      <p>
        Sie können die Installation der Cookies durch eine Einstellung Ihres
        Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie
        bereits gespeicherte Cookies jederzeit löschen. Die hierfür
        erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret
        genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die
        Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden
        sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die
        Verarbeitung allerdings nicht über die Einstellungen des Browsers
        unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung
        Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und
        Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen
        benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation
        Ihres Flash-Players oder wenden sich an den Hersteller bzw.
        Benutzer-Support.
      </p>
      <p>
        Sollten Sie die Installation der Cookies verhindern oder einschränken,
        kann dies allerdings dazu führen, dass nicht sämtliche Funktionen
        unseres Internetauftritts vollumfänglich nutzbar sind.
      </p>
      <H5>Kontaktanfragen / Kontaktmöglichkeit</H5>
      <p>
        Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten,
        werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer
        Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und
        Beantwortung Ihre Anfrage erforderlich – ohne deren Bereitstellung
        können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.
      </p>
      <p>
        {`
               Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.
              `}
      </p>{' '}
      <p>
        Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet
        worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen, wie bspw. bei einer sich etwaig anschließenden
        Vertragsabwicklung.
      </p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1140px;
  margin: auto;
  padding: 30px 10px;
  box-sizing: border-box;
  word-wrap: break-word;
  text-align: justify;
  color: ${COLORS.FONT_COLOR};
  p,
  span,
  li {
    margin: 10px 0;
    line-height: 1.6;
  }
  ${responsive.sm`
    font-size: 12px;
  `}
  ul {
    padding-left: 30px;
  }
`

const H1 = styled.h1`
  font-size: 37px;
  font-weight: 500;
  padding-bottom: 16px;
  ${responsive.sm`
    font-size: 20px;
  `}
`

const H4 = styled.h4`
  font-weight: 500;
  line-height: 1.618;
  padding: 4px 0;
  margin: 0;
`

const H5 = styled(H4)`
  font-size: 1em;
`

const Link = styled.span`
  color: ${COLORS.BLUE};
  padding: 10px 0;
`

const LinkDirect = styled.a`
  color: #2190ff;
  padding: 10px 0;
  &:hover {
    opacity: 0.7;
  }
`
export default Content
