import { FC } from 'react'
import { useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import RadioButton from 'components/atoms/RadioButton'
import { RANGES_TYPE } from 'constants/cancellationPolicy'
import { Field, styled } from 'utils/adapters'
import PolicyForm from './PolicyForm'

interface IProps {
  name: string
  index: number
}

const PolicyFormContainer: FC<IProps> = ({ name, index }) => {
  const { t } = useTranslation()
  const { values } = useFormState()

  return (
    <StyledPolicyFormContainer>
      <StyledRadioWrapper>
        <Field
          name={`${name}.type`}
          type='radio'
          formatOnBlur
          value={RANGES_TYPE.FIXED_RANGES}
        >
          {({ input }: any) => (
            <RadioButton
              {...input}
              onChange={event => {
                input.onChange(event.target.value)
              }}
            >
              {t('Select by fixed ranges')}
            </RadioButton>
          )}
        </Field>
      </StyledRadioWrapper>

      <StyledRadioWrapper>
        <Field
          name={`${name}.type`}
          type='radio'
          formatOnBlur
          value={RANGES_TYPE.DATE_RANGES}
        >
          {({ input }: any) => (
            <RadioButton
              {...input}
              onChange={event => {
                input.onChange(event.target.value)
              }}
            >
              {t('Select by date ranges')}
            </RadioButton>
          )}
        </Field>
      </StyledRadioWrapper>
      <PolicyForm name={name} type={values.data[index].type} />
    </StyledPolicyFormContainer>
  )
}

const StyledPolicyFormContainer = styled.div`
  flex: 8;
  margin-bottom: 20px;
`

const StyledRadioWrapper = styled.div`
  > label {
    display: inline-flex;
  }
`

export default PolicyFormContainer
