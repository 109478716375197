import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { useSearch } from 'utils/hooks'

import { GET_ATTENDEE_EVENTS } from 'graphql/queries'
import { EventList } from './EventList'
import { SORT_BY_TYPE, SORT_TYPE } from 'constants/sortEvent'
import useDateRange from '@/utils/hooks/useDateRange'

type AttendeeEventListListType = {
  tabState: boolean
  orderBy: SORT_BY_TYPE
  direction: SORT_TYPE
}
const AttendeeEventList: FC<AttendeeEventListListType> = ({
  tabState,
  orderBy,
  direction,
}) => {
  const { searchKey } = useSearch()
  const { filterByDate } = useDateRange()

  const {
    data: eventData,
    loading: eventDataLoading,
    networkStatus,
  } = useQuery(GET_ATTENDEE_EVENTS, {
    variables: {
      filter: {
        title: searchKey.length > 0 ? searchKey : '',
        isExpired: tabState,
        filterBy: orderBy,
        direction: direction,
        ...(filterByDate.fromTime &&
          filterByDate.toTime && {
            fromTime: filterByDate.fromTime.toISOString(),
            toTime: filterByDate.toTime.toISOString(),
          }),
      },
    },
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-first',
  })

  return (
    <EventList
      data={eventData}
      loading={eventDataLoading}
      networkStatus={networkStatus}
    />
  )
}

export default AttendeeEventList
