'use client'

import { format } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'
import * as React from 'react'
import { DateRange } from 'react-day-picker'

import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { useTranslation } from 'react-i18next'
import useDateRange from 'utils/hooks/useDateRange'

export function DatePickerWithRange({
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  })

  const { setFilterByDate } = useDateRange()

  const { t } = useTranslation()

  const handleClearDateRage = () => {
    setDate({
      from: undefined,
      to: undefined,
    })
    setFilterByDate(undefined, undefined)
  }

  const handleSetDateRage = (date: DateRange | undefined) => {
    setDate(date)
    if (date?.from && date?.to) {
      setFilterByDate(date.from, date.to)
    }

    if (!date?.from && !date?.to) handleClearDateRage()
  }

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'w-[260px] justify-start text-left font-normal rounded-md',
              !date && 'text-muted-foreground',
              'h-[38px]'
            )}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {date?.from && date.to ? (
              <div className='text-[14px]'>
                {format(date.from, 'LLL dd, y')} -{' '}
                {format(date.to, 'LLL dd, y')}
              </div>
            ) : (
              <span className='text-[14px]'>{t('Pick range date')}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          <Calendar
            initialFocus
            mode='range'
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSetDateRage}
            numberOfMonths={2}
          />
          <div className='text-end mr-[20px]'>
            <Button
              id='date'
              variant={'outline'}
              className='mb-[20px]'
              onClick={handleClearDateRage}
            >
              {t('Clear')}
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}
