import './global.style.ts'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'utils/adapters'
import * as Sentry from '@sentry/react'

import { store } from './store'

import reportWebVitals from './reportWebVitals'
import './config/i18n'
import { client } from 'config'

import App from './App'
import Toast from 'components/molecules/Toast'
import { Environment } from './environments'
import { Toaster } from './components/ui/toaster'
import UserFeedback from './components/molecules/user-feedback'

if (Environment.nodeEnv !== 'development') {
  Sentry.init({
    dsn: 'https://e3950442bdac4f92aaf4bd6ff36ae3dd@o4504757998190592.ingest.sentry.io/4504758500786176',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: Environment.nodeEnv,
  })
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Toast />
      <Toaster />
      <UserFeedback allowImage={true} />
      <App />
    </Provider>
  </ApolloProvider>,

  document.getElementById('root')
)

reportWebVitals()
