import { Input } from '@/components/ui/input'
import { DataTableFacetedFilter } from '@/components/organisms/GenericTable/GenericTableFacetFilter'
import { Button } from '@/components/ui/button'
import { X } from 'lucide-react'
import { Table } from '@tanstack/react-table'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export interface FilteredColumnOptions {
  label: string
  value: string
  icon?: React.ComponentType<{ className?: string }>
}

export interface FilteredColumn {
  name: string
  header: string
  options: FilteredColumnOptions[]
}

interface DataTableToolbarProps<TData> {
  table: Table<TData>
  filteredColumns?: FilteredColumn[]
  searchColumns?: string[]
  toolbar?: React.ReactNode
}

export function DataTableToolbar<TData>({
  table,
  filteredColumns,
  searchColumns,
  toolbar,
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation()
  const isFiltered = table.getState().columnFilters.length > 0

  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-1 items-center space-x-2'>
        {searchColumns?.map(column => (
          <Input
            key={column}
            placeholder={`${t('Search')} ${t(column)}`}
            value={(table.getColumn(column)?.getFilterValue() as string) ?? ''}
            onChange={event =>
              table.getColumn(column)?.setFilterValue(event.target.value)
            }
            className='h-8 w-[150px] lg:w-[250px]'
          />
        ))}
        {filteredColumns?.map(
          (column, index) =>
            table.getColumn(column.name) && (
              <DataTableFacetedFilter
                key={index}
                column={table.getColumn(column.name)}
                title={column.header}
                options={column.options}
              />
            )
        )}
        {toolbar}
        {isFiltered && (
          <Button
            variant='ghost'
            onClick={() => table.resetColumnFilters()}
            className='h-8 px-2 lg:px-3'
          >
            Reset
            <X className='ml-2 h-4 w-4' />
          </Button>
        )}
      </div>
      {/*<DataTableViewOptions table={table} />*/}
    </div>
  )
}
