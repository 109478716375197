import { request } from './request'

export const uploadImageReq = formData => {
  return request.request({
    method: 'POST',
    url: '/upload',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
