import { Turnstile } from '@marsidev/react-turnstile'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import { EMAIL_REGEX, MAX_LENGTH } from '@/constants/regex'
import { MESSAGE } from '@/constants/message'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { useState } from 'react'

interface ForgotPasswordRequestFormProps {
  onSubmit: (values: any) => void
  onBackToLogin: () => void
  isLoading: boolean
  siteKey: string
}

export const ForgotPasswordRequestForm: React.FC<
  ForgotPasswordRequestFormProps
> = ({ onSubmit, onBackToLogin, isLoading, siteKey }) => {
  const { t } = useTranslation()
  const language = (localStorage.getItem('i18nextLng') as string) || 'en'
  const [turnstileToken, setTurnstileToken] = useState<string | null>(null)

  const formSchema = z.object({
    email: z
      .string()
      .min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
      .max(MAX_LENGTH.COMMON_FIELD, {
        message: t(MESSAGE.TOO_LONG, {
          max: MAX_LENGTH.COMMON_FIELD,
        }),
      })
      .regex(EMAIL_REGEX, { message: t(MESSAGE.INVALID_EMAIL) }),
  })

  type FormValues = z.infer<typeof formSchema>

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })

  const handleSubmit = (values: FormValues) => {
    if (!turnstileToken) {
      return
    }
    onSubmit({ ...values })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-8'>
        <div className='grid space-y-6'>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('Email')}</FormLabel>
                <FormControl>
                  <Input placeholder='email@example.com' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Turnstile
            options={{
              action: 'forgot-password',
              theme: 'light',
              language,
            }}
            siteKey={siteKey}
            onSuccess={token => setTurnstileToken(token)}
            onError={() => setTurnstileToken(null)}
            onExpire={() => setTurnstileToken(null)}
          />
          <div className='flex space-x-4'>
            <Button variant='outline' onClick={onBackToLogin} type='button'>
              {t('Back to login')}
            </Button>
            <Button
              type='submit'
              disabled={isLoading || !turnstileToken}
              className='flex-1'
            >
              {isLoading && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('Reset password')}
            </Button>
          </div>
        </div>
      </form>
    </Form>
  )
}
