import { FC, useEffect, useRef, useState } from 'react'

import { IEvent } from 'models'

import { Background } from 'assets/images'
import { cn } from '@/lib/utils'

type EventDetailType = {
  event: IEvent
  isAdmin?: boolean
}
const EventDetailContainer: FC<EventDetailType> = ({
  children,
  event,
  isAdmin = false,
}) => {
  const updatedImgRef = useRef<any>()

  const [imgSrc, setImgSrc] = useState(Background)

  useEffect(() => {
    if (event.eventSetting?.backgroundPicture) {
      setImgSrc(event.eventSetting.backgroundPicture as string)
    } else {
      setImgSrc(Background)
    }
  }, [event.eventSetting?.backgroundPicture])

  useEffect(() => {
    if (imgSrc === Background) {
      return
    }

    updatedImgRef.current.animate(
      {
        opacity: [0, event?.eventSetting?.backgroundTransparent],
      },
      1500
    )
  }, [imgSrc])

  return (
    <div
      className={cn(
        isAdmin ? 'min-h-[calc(100vh-80px)]' : 'min-h-[calc(100vh-54px-47px)]',
        'md:min-h-[calc(100vh-80px-47px)]',
        'relative'
      )}
    >
      <img
        ref={updatedImgRef}
        src={imgSrc}
        loading='lazy'
        className={cn(
          isAdmin
            ? 'min-h-[calc(100vh-54px)] md:min-h-[calc(100vh-80px)]'
            : 'min-h-[calc(100vh-54px-47px) md:min-h-[calc(100vh-80px-47px)]]',
          'absolute z-[-1] w-screen object-cover h-full'
        )}
        style={{
          opacity: event?.eventSetting?.backgroundTransparent
            ? event?.eventSetting?.backgroundTransparent
            : '0.3',
        }}
      />
      {children}
    </div>
  )
}

export default EventDetailContainer
