import { FC } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './select'
import { useTranslation } from 'react-i18next'
import { cn } from '@/lib/utils'
import { FormLabel } from '@/components/ui/final-form'

export const SelectAdapter: FC<{
  input: any
  styles?: any
  meta: any
  options: any
  title?: string
  isRequired?: boolean
  disabled?: boolean
  className?: string
}> = ({
  input,
  styles,
  meta,
  options,
  title,
  isRequired,
  disabled = false,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      {title && (
        <FormLabel meta={meta} className={cn(className, 'mb-2')}>
          {t(title)} {isRequired && '*'}
        </FormLabel>
      )}
      <Select onValueChange={input.onChange} {...input} disabled={disabled}>
        <SelectTrigger
          className={cn(
            meta?.error && meta?.touched && 'border-rose-500',
            'border focus:ring-transparent'
          )}
        >
          <SelectValue placeholder={t('Select')} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {options.map(
              (item: { label: string; value: string }, index: number) => (
                <SelectItem key={index} value={item.value}>
                  <p className='font-medium'>{item.label}</p>
                </SelectItem>
              )
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  )
}
