import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { toast } from '@/components/ui/use-toast'
import { TwoFactorMethod } from '@/constants/user'
import { useUserStore } from '@/store/zustand'
import PhoneInput from 'react-phone-number-input'
import {
  enableTwoFA,
  receiveMessage,
  updatePhoneNumberOnboarding,
  use2FAStore,
} from '@/store/zustand/TowFA'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { TwoFactorAuthCode } from './TwoFactorAuthCode'
import { MESSAGE } from '@/constants/message'
import { UPDATE_PHONE_NUMBER } from '@/graphql/mutations'
import { useMutation } from '@apollo/client'
import { cn } from '@/lib/utils'
import { setLoggedIn } from '@/store/Authentication'
import { useAppDispatch } from '@/utils/hooks'

interface IProps {
  handleBack?: any
  email?: string
}

enum STEP {
  CHOOSE_METHOD = 'CHOOSE_METHOD',
  SEND_CODE = 'SEND_CODE',
  VERIFY = 'VERIFY',
}

const ForceTwoFAForm: React.FC<IProps> = ({ handleBack, email }) => {
  const { t } = useTranslation()
  const { currentUser, setCurrentUser } = useUserStore.getState()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [otpValue, setOtpValue] = useState('')
  const [step, setStep] = useState(STEP.CHOOSE_METHOD)

  const { method, setMethod } = use2FAStore.getState()

  const formSchema = z.object({
    method: z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) }),
    phoneNumber: z.string().optional(),
  })

  type FormValues = z.infer<typeof formSchema>

  const defaultValues: Partial<FormValues> = {
    method: '',
    phoneNumber: '',
  }

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  })

  const onSendVerifyCode = async (data: FormValues) => {
    setLoading(true)
    setMethod(data.method)

    const res = await updatePhoneNumberOnboarding({
      email: email ?? '',
      phoneNumber: data.phoneNumber ?? '',
    })
    if (res) {
      await receiveMessage(form.getValues().method)
      setStep(STEP.VERIFY)
    }
    setLoading(false)
  }

  const onSubmitOTP = async () => {
    setLoading(true)
    const res = await enableTwoFA(otpValue, method)
    if (!res)
      toast({
        title: t(use2FAStore.getState().errorMessage),
        variant: 'destructive',
      })
    else {
      localStorage.setItem('ACCESS_TOKEN', res.data.accessToken as string)
      localStorage.setItem('REFRESH_TOKEN', res.data.refreshToken as string)
      dispatch(setLoggedIn(true))
      // setAuthSecret(res.message)
      if (currentUser)
        setCurrentUser({
          ...currentUser,
          is2FAEnabled: true,
        })
    }
    setLoading(false)
  }

  const onChooseMethod = () => {
    setStep(STEP.SEND_CODE)
  }

  const METHOD = [
    { value: 'sms', label: t('force2FA.method.sms') },
    { value: 'phone', label: t('force2FA.method.voice') },
    {
      value: 'totp',
      label: t('force2FA.method.totp'),
      disabled: true,
    },
  ]

  // const {
  //   formState: { errors },
  // } = form
  // console.log('errors', errors, 'form', form.getValues())

  const renderChooseMethod = () => (
    <FormField
      control={form.control}
      name='method'
      render={({ field }) => (
        <FormItem className='space-y-3'>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className='flex flex-col'
            >
              {METHOD.map(({ value, label, disabled }) => (
                <FormItem
                  key={value}
                  className='flex items-center space-x-3 space-y-0 rounded-lg transition-all'
                >
                  <FormControl>
                    <RadioGroupItem
                      value={value}
                      id={value}
                      disabled={disabled}
                      className='w-5 h-5'
                    />
                  </FormControl>
                  <FormLabel
                    className='font-medium text-base cursor-pointer flex-grow'
                    htmlFor={value}
                  >
                    {t(label)}
                  </FormLabel>
                  {disabled && (
                    <span className='text-sm text-gray-500 italic'>
                      {t('Coming soon')}
                    </span>
                  )}
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage className='text-red-500' />
        </FormItem>
      )}
    />
  )

  const renderSendCode = () => (
    <FormField
      control={form.control}
      name='phoneNumber'
      render={({ field }) => (
        <FormItem className='flex-auto'>
          <FormControl>
            <PhoneInput
              className='flex h-10 w-full rounded-md border border-input bg-transparent pl-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
              international
              placeholder={t('Enter phone number')}
              {...field}
              value={field.value || ''}
              onChange={value => field.onChange(value || '')}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )

  const renderVerify = () => (
    <div className='space-y-8'>
      <TwoFactorAuthCode
        otpValue={otpValue}
        setOtpValue={setOtpValue}
        destination={form.getValues().phoneNumber}
      />
      <div className='grid grid-cols-3 grid-flow-col space-x-4'>
        <Button
          variant='outline'
          type='submit'
          disabled={loading}
          onClick={() => setStep(STEP.SEND_CODE)}
        >
          {t('Back')}
        </Button>
        <Button onClick={onSubmitOTP} disabled={loading} className='col-span-2'>
          {loading && <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />}
          {t('Verify')}
        </Button>
      </div>
    </div>
  )

  if (step === STEP.VERIFY) return renderVerify()
  return (
    <>
      <div className='text-lg font-bold text-center'>
        {t('Two-Factor Authentication (2FA)')}
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSendVerifyCode)}
          className='space-y-8'
        >
          {step === STEP.CHOOSE_METHOD
            ? renderChooseMethod()
            : renderSendCode()}
          <div
            className={cn('grid grid-cols-3 grid-flow-col space-x-4', {
              hidden: step === STEP.SEND_CODE,
            })}
          >
            <Button
              variant='outline'
              type='button'
              disabled={loading}
              onClick={handleBack}
            >
              {t('Back')}
            </Button>
            <Button
              type='button'
              className='col-span-2'
              onClick={onChooseMethod}
              disabled={loading || !form.getValues().method}
            >
              {loading && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('Choose 2FA Method')}
            </Button>
          </div>

          <div
            className={cn('grid grid-cols-3 grid-flow-col space-x-4', {
              hidden: step === STEP.CHOOSE_METHOD,
            })}
          >
            <Button
              variant='outline'
              type='button'
              onClick={() => setStep(STEP.CHOOSE_METHOD)}
            >
              {t('Back')}
            </Button>
            <Button
              type='submit'
              disabled={loading || !form.getValues().phoneNumber}
              className='col-span-2'
            >
              {loading && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('force2FA.send_code_btn')}
            </Button>
          </div>
        </form>
      </Form>
    </>
  )
}

export default ForceTwoFAForm
