import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import BasicTable from '@/components/organisms/BasicTable'
import { ColumnDef } from '@tanstack/react-table'
import { getReleases, useReleaseStore } from '@/store/zustand/Releases'
import Permission from '@/components/atoms/Permission'
import { USER_ROLE } from '@/constants/userRole'
import format from 'date-fns/format'
import { defaultDateFormat } from '@/utils/helpers'
import { CreateRelease } from './CreateRelease'

const Releases: FC = () => {
  const { t } = useTranslation()
  const lang = localStorage.getItem('i18nextLng') as string

  const { releases, loading } = useReleaseStore()

  useEffect(() => {
    getReleases()
  }, [])

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: t('Version'),
        cell: ({ row }) => <b>{row?.original?.version}</b>,
        size: 50,
      },
      {
        header: t('Title'),
        cell: ({ row }) => <b>{row?.original?.title}</b>,
        size: 100,
      },
      {
        header: t('Description'),
        cell: ({ row }) => <b>{row?.original?.description}</b>,
        size: 300,
      },
      {
        header: t('Date'),
        cell: ({ row }) => (
          <b>{format(new Date(row?.original?.date), defaultDateFormat)}</b>
        ),
        size: 50,
      },
    ],
    [lang]
  )

  return (
    <div className='flex flex-col'>
      <div className='mx-auto overflow-x-auto p-[16px] max-w-full md:p-[40px] w-full'>
        <Permission role={USER_ROLE.ROOT_ADMIN}>
          <div className='mb-[40px] flex justify-end'>
            <CreateRelease />
          </div>
        </Permission>
        <BasicTable
          columns={columns}
          data={releases}
          loading={loading}
          filters={{ page: 1 }}
          totalItems={releases.length}
        />
      </div>
    </div>
  )
}

export default Releases
