import * as React from 'react'
import * as LabelPrimitive from '@radix-ui/react-label'
import { Slot } from '@radix-ui/react-slot'
import { cn } from 'lib/utils'
import { Label } from './label'
import { FieldMetaState } from '@/utils/adapters'
import ValidationText from '@/components/atoms/ValidationText'
import HelpText from '../atoms/HelpText'

type FormItemContextValue = {
  id: string
}

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
)

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  // const id = React.useId()

  const id = props?.id as string

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('space-y-2', className)} {...props} />
    </FormItemContext.Provider>
  )
})
FormItem.displayName = 'FormItem'

interface FormLabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  meta?: FieldMetaState<any>
  isRequired?: boolean
  helpMessage?: string
}

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  FormLabelProps
>(({ className, isRequired, meta, children, helpMessage, ...props }, ref) => {
  return (
    <div className='flex justify-between items-center text-sm'>
      <Label
        ref={ref}
        className={cn(
          meta?.error && meta?.touched && 'text-destructive',
          className
        )}
        {...props}
      >
        {helpMessage ? (
          <HelpText
            text={isRequired ? `${children} *` : (children as string)}
            helpMessage={helpMessage}
          />
        ) : isRequired && children ? (
          `${children} *`
        ) : (
          (children as string)
        )}
      </Label>
      {meta?.error && meta?.touched && <ValidationText error={meta?.error} />}
    </div>
  )
})
FormLabel.displayName = 'FormLabel'

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  return (
    <Slot
      ref={ref}
      // id={formItemId}
      // aria-describedby={
      //   !error
      //     ? `${formDescriptionId}`
      //     : `${formDescriptionId} ${formMessageId}`
      // }
      // aria-invalid={!!error}
      {...props}
    />
  )
})
FormControl.displayName = 'FormControl'

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  return (
    <p
      ref={ref}
      className={cn('text-sm text-muted-foreground', className)}
      {...props}
    />
  )
})
FormDescription.displayName = 'FormDescription'

export { FormItem, FormLabel, FormControl, FormDescription }
