import { Letter2, Template2Logo } from '@/assets/images'
import { Dialog, DialogContent, DialogFooter } from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { Textarea } from '@/components/ui/textarea'
import { INVITATION_STATUS } from '@/constants/invitationTemplate'
import { cn } from '@/lib/utils'
import { useInvitationTemplate } from '@/store/zustand/InvitationTemplateManagement'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/ui/button'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'utils/adapters'
import { z } from 'zod'
import './style.scss'

function EventInvitationTemplate2() {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [isFlip, setIsFlip] = useState(false)
  const [displayActionAnimation, setDisplayActionAnimation] = useState(false)
  const templateDetailPreview = useInvitationTemplate(
    state => state.templateDetailPreview
  )

  const [openRsvpForm, setOpenRsvpForm] = useState(false)
  const { eventId } = useParams<{ eventId: string }>()

  useEffect(() => {
    if (templateDetailPreview === null) {
      history.goBack()
    }
  }, [templateDetailPreview])

  const handleReplay = () => {
    setDisplayActionAnimation(false)
    setIsOpen(false)
    setIsFlip(false)
    setTimeout(() => setIsOpen(true), 1000)
  }

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 500)
  }, [])

  useEffect(() => {
    setTimeout(() => setDisplayActionAnimation(true), 3000)
  }, [isOpen])

  const handleUpdateInvitation = async (status: number) => {
    if (status === INVITATION_STATUS.ATTEND)
      window.location.href = `${
        import.meta.env.VITE_REGISTER_EVENT
      }/events/${eventId}`
  }

  const formSchema = z.object({
    message: z.string().max(500, {
      message:
        'Please ensure that your message does not exceed 500 characters.',
    }),
  })
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
  })

  const onSubmit = async (values: { message: string }) => {
    form.reset({ message: '' })
    setOpenRsvpForm(false)
  }

  const topContent =
    templateDetailPreview?.cardTopContent ||
    'You are invited \n annual drinks \n party \n 2024'
  const bottomContent =
    templateDetailPreview?.cardBottomContent ||
    'Wednesday \n 18th \n September \n 7PM'
  const logo = templateDetailPreview?.logo || Template2Logo
  const copyRight = templateDetailPreview?.copyRight || '©2024 ConferenceDirect'

  return (
    <div className='template-2 font-base'>
      <div
        // style={{
        //   // backgroundImage: `url('/images/invitation/template2/template-bg.webp')`,
        //   // background: `linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.5)), url('/images/invitation/template2/template-bg.webp') no-repeat center`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
        className='bg-center bg-cover h-[400px] md:h-[800px] overflow-hidden background-img relative'
      >
        <div className='mt-[20px]'>
          <div className={isOpen ? 'envelope open' : 'envelope close'}>
            <div
              className={cn(
                'relative flip-card card h-full flex justify-center items-center w-[100%] -rotate-90'
              )}
            >
              <div
                className={cn(
                  'flip-card-inner w-[90%] h-full relative text-center',
                  isFlip && 'flip-card-rotate'
                )}
              >
                <div className='flip-card-front flex justify-center items-center italic'>
                  <img
                    src={Letter2}
                    className='letter relative'
                    // onLoad={() => setIsImageLoaded(true)}
                  />
                  <div className='max-w-[120px] md:max-w-[300px]  absolute text-[#BB5B2A] font-black text-xs md:text-xl lg:text-2xl top-[20px] md:top-[42px] lg:top-[63px] right-[100px] md:right-[220px] lg:right-[265px] uppercase whitespace-pre-line leading-[1rem] md:leading-6 lg:leading-[1.8rem]'>
                    {topContent}
                  </div>
                  <div className='max-w-[100px] md:max-w-[160px]  absolute text-[#BB5B2A] font-bold text-xs md:text-base lg:text-xl top-[248px] md:top-[485px] lg:top-[555px] right-[10px] md:right-[25px] lg:right-[35px] uppercase whitespace-pre-line md:leading-5 leading-[0.8rem] lg:leading-[1.5rem]'>
                    {bottomContent}
                  </div>
                </div>
                <div className='flip-card-back'></div>
              </div>
            </div>
            <div className='flap-outside flap-container'>
              <div className='flap flap-top'>
                <div className='lining' />
              </div>
            </div>
            <div className='flap-inside flap-container'>
              <div className='flap flap-bottom' />
            </div>
          </div>
          {displayActionAnimation && (
            <div className='reset flex gap-2 flex-col absolute top-[20%] md:top-[40%] left-[10%] md:left-[20%] transform -translate-x-1/2 -translate-y-1/2'>
              <button
                id='open'
                onClick={() => setIsFlip(!isFlip)}
                className='bg-[#ECE9E4] p-2 rounded-full'
              >
                <Icons.flip />
              </button>
              <button
                id='reset'
                onClick={handleReplay}
                className='bg-[#ECE9E4] p-2 rounded-full'
              >
                <Icons.replay />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='py-10 md:py-20 px-10 md:px-20 lg:px-40 bg-[#ECE9E4]'>
        <h1 className=' text-2xl md:text-5xl text-center uppercase text-[#575751]'>
          {templateDetailPreview?.title}
        </h1>
        <div className='flex flex-col gap-8 md:flex-row pt-10 md:pt-20 lg:pt-40 justify-between text-center  text-[#575751] '>
          <div className='tracking-[2.5px] leading-tight'>
            <div className='mb-4 uppercase font-bold'>HOSTED BY</div>
            <div className='text-xl'>{templateDetailPreview?.host}</div>
          </div>
          <div className='tracking-[2.5px] leading-tight'>
            <div className='mb-4 uppercase font-bold'>Date</div>
            <div className='text-xl'>{templateDetailPreview?.eventTime}</div>
          </div>
        </div>
      </div>
      <div className='py-10 md:py-20 px-10 md:px-20 lg:px-40 bg-[#575751] text-center '>
        <div className='uppercase text-large font-bold text-white text-lg'>
          PLEASE REGISTER HERE
        </div>
        <div className='flex flex-col items-center md:flex-row md:gap-40 justify-center'>
          <Button
            className='w-[250px] md:w-[300px] py-6 mt-10 rounded-none uppercase text-white bg-[#bb5b29] tracking-[0.3em] leading-[17px] hover:scale-105 transform transition-transform duration-300 hover:bg-[#bb5b29]'
            onClick={() => handleUpdateInvitation(INVITATION_STATUS.ATTEND)}
          >
            Will attend
          </Button>
          <Button
            className='w-[250px] md:w-[300px] py-6 mt-10 rounded-none uppercase text-white bg-[#bb5b29] tracking-[0.3em] leading-[17px] hover:scale-105 transform transition-transform duration-300 hover:bg-[#bb5b29]'
            onClick={() => setOpenRsvpForm(true)}
          >
            Will not attend
          </Button>
        </div>
      </div>
      <div className='py-10 md:py-20 px-10 md:px-20 lg:px-40 bg-[#ECE9E4] flex justify-center flex-col items-center'>
        <img src={logo} className='w-[150px] md:w-[200px]' />
        <div className='flex justify-center mt-4 items-center gap-4 text-sm'>
          {/* <a href={import.meta.env.VITE_IMPRINT_URL}>Imprint</a>
        <a href={import.meta.env.VITE_PRIVACY_URL}>Privacy</a> */}
          <div>{copyRight}</div>
        </div>
      </div>
      {openRsvpForm && (
        <Dialog open={openRsvpForm} onOpenChange={() => setOpenRsvpForm(false)}>
          <DialogContent className='sm:max-w-[425px]'>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className='space-y-8'
              >
                <FormField
                  control={form.control}
                  name='message'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Your private message to the host (optional)
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          id='message'
                          placeholder='Enter your message'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <Button
                    className='w-full bg-[#bb5b29] hover:bg-[#bb5b29]  '
                    type='submit'
                  >
                    Confirm
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
export default EventInvitationTemplate2
