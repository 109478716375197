type IConfig = {
  serverUrl: string | undefined
  stripeApiKey: string | undefined
  rootUrl: string | undefined
  nodeEnv: string | undefined
  registerEventPage: string | undefined
  clientBaseUrl: string
}

const config: IConfig = {
  serverUrl: import.meta.env.VITE_SERVER_URL,
  stripeApiKey: import.meta.env.VITE_STRIPE_API_KEY,
  rootUrl: import.meta.env.VITE_REGISTER_EVENT,
  nodeEnv: import.meta.env.VITE_NODE_ENV,
  registerEventPage: import.meta.env.VITE_REGISTER_EVENT,
  clientBaseUrl: import.meta.env.VITE_CLIENT_BASE_URL,
}

const Environment: IConfig = config
export { Environment }
