import { Template3LetterBg, Template3Bg } from '@/assets/images'
import { Icons } from '@/components/ui/icon'
import { cn } from '@/lib/utils'
import { Template3Logo } from 'assets/images'
import { Button } from 'components/ui/button'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'utils/adapters'
import './style.scss'
import { INVITATION_STATUS } from '@/constants/invitationTemplate'
import { useInvitationTemplate } from '@/store/zustand/InvitationTemplateManagement'
import { Dialog, DialogContent, DialogFooter } from '@/components/ui/dialog'
import { z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Textarea } from '@/components/ui/textarea'

function EventInvitationTemplate3() {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [isFlip, setIsFlip] = useState(false)
  const [displayActionAnimation, setDisplayActionAnimation] = useState(false)
  const templateDetailPreview = useInvitationTemplate(
    state => state.templateDetailPreview
  )

  const [openRsvpForm, setOpenRsvpForm] = useState(false)
  const { eventId } = useParams<{ eventId: string }>()

  const sectionRefs = useRef<any>([]) // Create a reference array to store all sections

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const target = entry.target

          if (entry.isIntersecting) {
            target.classList.add('observed-animation-fade-in-active')
          } else {
            target.classList.remove('observed-animation-fade-in-active')
          }
        })
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    )

    sectionRefs.current.forEach(section => {
      if (section) observer.observe(section) // Observe each section
    })

    return () => {
      // Cleanup observer on component unmount
      if (sectionRefs.current) {
        sectionRefs.current.forEach(section => {
          if (section) observer.unobserve(section)
        })
      }
    }
  }, [])

  useEffect(() => {
    if (templateDetailPreview === null) {
      history.goBack()
    }
  }, [templateDetailPreview])

  const handleReplay = () => {
    setDisplayActionAnimation(false)
    setIsOpen(false)
    setIsFlip(false)
    setTimeout(() => setIsOpen(true), 1000)
  }

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 500)
  }, [])

  useEffect(() => {
    setTimeout(() => setDisplayActionAnimation(true), 3000)
  }, [isOpen])

  const handleUpdateInvitation = async (status: number) => {
    if (status === INVITATION_STATUS.ATTEND)
      window.location.href = `${
        import.meta.env.VITE_REGISTER_EVENT
      }/events/${eventId}`
  }

  const formSchema = z.object({
    message: z.string().max(500, {
      message:
        'Please ensure that your message does not exceed 500 characters.',
    }),
  })
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
  })

  const onSubmit = async (values: { message: string }) => {
    form.reset({ message: '' })
    setOpenRsvpForm(false)
  }

  const topContent =
    templateDetailPreview?.cardTopContent ||
    'You are invited \n annual drinks \n party \n 2024'
  const bottomContent =
    templateDetailPreview?.cardBottomContent ||
    'Wednesday \n 18th \n September \n 7PM'
  const logo = templateDetailPreview?.logo || Template3Logo
  const copyRight =
    templateDetailPreview?.copyRight || '©2024 Liechtensteinische Landesbank AG'

  return (
    <div className='template-3'>
      <div
        style={{
          backgroundImage: `url(${Template3Bg})`,
        }}
        className='bg-center bg-cover h-[400px] md:h-[800px] overflow-hidden'
      >
        <div className='mt-[20px]'>
          <div className={isOpen ? 'envelope open' : 'envelope close'}>
            <div
              className={cn(
                'relative flip-card card h-full flex justify-center items-center w-[100%] -rotate-90'
              )}
            >
              <div
                className={cn(
                  'flip-card-inner w-[90%] h-full relative text-center',
                  isFlip && 'flip-card-rotate'
                )}
              >
                <div className='flip-card-front flex justify-center items-center'>
                  <img src={Template3LetterBg} className='letter'></img>
                  <div className='max-w-[120px] md:max-w-[300px] font-grotesque absolute text-white font-black text-sm md:text-2xl lg:text-3xl top-[40px] md:top-[67px] lg:top-[70px] right-[70px] md:right-[150px] lg:right-[220px] uppercase whitespace-pre-line leading-4 md:leading-8 lg:leading-[2.25rem]'>
                    {topContent}
                  </div>
                  <div className='max-w-[100px] md:max-w-[160px] font-grotesque absolute text-white font-bold text-sm md:text-xl lg:text-2xl top-[213px] md:top-[405px] lg:top-[560px] right-[10px] md:right-[25px] lg:right-[35px] uppercase whitespace-pre-line md:leading-normal leading-4'>
                    {bottomContent}
                  </div>
                </div>
                <div className='flip-card-back'></div>
              </div>
            </div>
            <div className='flap-outside flap-container'>
              <div className='flap flap-top'>
                <div className='lining' />
              </div>
            </div>
            <div className='flap-inside flap-container'>
              <div className='flap flap-bottom' />
            </div>
          </div>
          {displayActionAnimation && (
            <div className='reset flex gap-2 flex-col absolute top-[20%] md:top-[40%] left-[10%] md:left-[20%] transform -translate-x-1/2 -translate-y-1/2'>
              <button
                id='open'
                onClick={() => setIsFlip(!isFlip)}
                className='bg-white p-2 rounded-full'
              >
                <Icons.flip />
              </button>
              <button
                id='reset'
                onClick={handleReplay}
                className='bg-white p-2 rounded-full'
              >
                <Icons.replay />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className=' py-10 md:py-20 px-10 md:px-20 lg:px-40 bg-[#E6E6E6]'>
        <h1 className='font-plantin text-2xl md:text-5xl text-center uppercase observed-animation-fade-in-active'>
          {templateDetailPreview?.title}
        </h1>
        <div className='observed-animation-fade-in flex flex-col gap-8 md:flex-row mt-10 md:mt-20 lg:mt-40 justify-between text-center font-grotesque text-[#333333 '>
          <div
            className='tracking-[2.5px] leading-tight'
            ref={el => (sectionRefs.current[0] = el)}
          >
            <div className='mb-4 uppercase font-bold'>HOSTED BY</div>
            <div className='text-xl'>{templateDetailPreview?.host}</div>
          </div>
          <div
            className='tracking-[2.5px] leading-tight'
            ref={el => (sectionRefs.current[1] = el)}
          >
            <div className='mb-4 uppercase font-bold'>Date</div>
            <div className='text-xl whitespace-pre-line'>
              {templateDetailPreview?.eventTime}
            </div>
          </div>
        </div>
      </div>

      <div
        ref={el => (sectionRefs.current[2] = el)}
        className='py-10 md:py-20 px-10 md:px-20 lg:px-40 bg-white text-center font-grotesque'
      >
        <div className='uppercase text-large font-bold'>
          PLEASE REGISTER HERE
        </div>
        <div
          className={cn(
            'flex flex-col items-center md:flex-row md:gap-40 justify-center'
          )}
        >
          <Button
            variant='outline'
            className='w-[250px] md:w-[300px] mt-10 rounded-lg bg-[#c6ae70] border-none py-[16px] text-white font-bold'
            onClick={() => handleUpdateInvitation(INVITATION_STATUS.ATTEND)}
          >
            Will attend
          </Button>
          <Button
            variant='outline'
            className='w-[250px] md:w-[300px] mt-10 rounded-lg border-2 border-black py-[16px] font-bold'
            onClick={() => setOpenRsvpForm(true)}
          >
            Will not attend
          </Button>
        </div>
      </div>
      <div className='py-10 md:py-20 px-10 md:px-20 lg:px-40 bg-[#E6E6E6] flex justify-center flex-col items-center'>
        <img src={logo} className='w-[86px]' />
        <div className='flex justify-center mt-4 items-center gap-4 text-sm'>
          <a href={import.meta.env.VITE_IMPRINT_URL}>Imprint</a>
          <a href={import.meta.env.VITE_PRIVACY_URL}>Privacy</a>
          <div>{copyRight}</div>
        </div>
      </div>

      {openRsvpForm && (
        <Dialog open={openRsvpForm} onOpenChange={() => setOpenRsvpForm(false)}>
          <DialogContent className='sm:max-w-[425px]'>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className='space-y-8'
              >
                <FormField
                  control={form.control}
                  name='message'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Your private message to the host (optional)
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          {...field}
                          id='message'
                          placeholder='Enter your message'
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <Button className='w-full bg-[#38452E]' type='submit'>
                    Confirm
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
export default EventInvitationTemplate3

//<img class="lory__image object-fill fit-center-top " srcset="/-/media/leadimages/bildwelt-2023/gebaeude/leadimage-vaduz-7064-3000x1280.png?mw=2560&amp;version=1&amp;hash=8B7CDE8CED631F5E18FFBF864AB3C795 2560w,/-/media/leadimages/bildwelt-2023/gebaeude/leadimage-vaduz-7064-3000x1280.png?mw=1920&amp;version=1&amp;hash=E80215DD77EA28E3CEB17AE183A64B75 1920w,/-/media/leadimages/bildwelt-2023/gebaeude/leadimage-vaduz-7064-3000x1280.png?mw=1280&amp;version=1&amp;hash=F3CD48FB02311EA8F5CFD30C960A958E 1280w,/-/media/leadimages/bildwelt-2023/gebaeude/leadimage-vaduz-7064-3000x1280.png?mw=640&amp;version=1&amp;hash=EFAC516A7048FF86EC6A4A31762BC7D1 640w,/-/media/leadimages/bildwelt-2023/gebaeude/leadimage-vaduz-7064-3000x1280.png?mw=360&amp;version=1&amp;hash=C84EC9B621A023A418EE9D9D2027A77A 360w,/-/media/leadimages/bildwelt-2023/gebaeude/leadimage-vaduz-7064-3000x1280.png?mw=3000&amp;version=1&amp;hash=C816EC508567E00E22325BC7944D6BC6 3000w" sizes="100vw" src="/-/media/leadimages/bildwelt-2023/gebaeude/leadimage-vaduz-7064-3000x1280.png?mw=2560&amp;version=1&amp;hash=8B7CDE8CED631F5E18FFBF864AB3C795" alt="Vaduz">
