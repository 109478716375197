import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Briefcase, MapPinned, PenLine, Phone, Printer } from 'lucide-react'
import moment from 'moment'

import { checkCustomFieldType, transformAddress } from '@/utils/helpers'
import { Field, Form, styled } from 'utils/adapters'
import { Attendee, CustomFieldType, ICustomSelection } from '@/models'
import { Input } from '@/components/ui/input'
import QuestionWit from '@/components/molecules/AttendantDetails/QuestionWit'
import WebcastJoinLink from '@/components/molecules/AttendantDetails/WebcastJoinLink'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { responsive } from '@/assets/scss/mixin'
import { Sheet, SheetContent, SheetHeader } from '@/components/ui/sheet'
import { cn } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Icons } from '@/components/ui/icon'

interface ModalProps {
  eventCustomSelections: ICustomSelection[] | undefined
  attendee: Attendee
  handleUpdateAttendee: any
  open: boolean
  onClose: any
  loading: boolean
}

const AttendeeSheet: FC<ModalProps> = ({
  eventCustomSelections,
  attendee,
  handleUpdateAttendee,
  open,
  onClose,
  loading,
}) => {
  const { t } = useTranslation()

  const initialValues = {
    additionalFields: {
      hotel: attendee?.additionalFields?.hotel || '',
    },
  }

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent className='overflow-auto'>
        <SheetHeader>
          {/*  <div className='flex justify-between items-center mb-4'>*/}
          {/*    <PenLine className='h-6 w-6 text-muted-foreground' />*/}
          {/*    <div className='flex items-center space-x-2'>*/}
          {/*      <PenLine className='h-6 w-6 text-muted-foreground' />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          <div className='text-center my-4'>
            <h2 className='text-xl font-semibold mb-1'>
              {attendee?.firstName} {attendee?.lastName}
            </h2>
            <p className='text-muted-foreground mb-1'>{attendee?.email}</p>
            {(attendee?.jobTitle || attendee?.company) && (
              <p className='text-muted-foreground mb-1'>
                {attendee?.jobTitle}
                {attendee?.company ? ` · ${attendee?.company}` : ''}
              </p>
            )}
            <p className='text-muted-foreground flex items-center mb-1 justify-center'>
              <MapPinned className='h-4 w-4 text-muted-foreground mr-1' />
              {transformAddress(
                attendee?.address?.street,
                attendee?.address?.zipCode,
                attendee?.address?.city,
                attendee?.address?.country
              )}
            </p>
            {attendee?.mobilePhone && (
              <p className='text-muted-foreground flex items-center mb-1 justify-center'>
                <Phone className='h-4 w-4 text-muted-foreground mr-1' />
                {attendee?.mobilePhone}
              </p>
            )}
            {attendee?.workPhone && (
              <p className='text-muted-foreground flex items-center mb-1 justify-center'>
                <Briefcase className='h-4 w-4 text-muted-foreground mr-1' />
                {attendee?.workPhone}
              </p>
            )}
            {attendee?.fax && (
              <p className='text-muted-foreground flex items-center mb-1 justify-center'>
                <Printer className='h-4 w-4 text-muted-foreground mr-1' />
                {attendee?.fax}
              </p>
            )}
            {attendee?.invitationStatus && (
              <Badge
                variant='default'
                className={cn(
                  'text-white mt-2',
                  attendee?.invitationStatus === 'Attending'
                    ? 'bg-green-500'
                    : 'bg-red-500'
                )}
              >
                {attendee?.invitationStatus}
              </Badge>
            )}
            {eventCustomSelections?.length ? (
              <div className='mt-6 space-y-3'>
                {eventCustomSelections?.map((customSelection, index) => {
                  const value = attendee?.customSelection?.[customSelection.id]
                  if (!value) return null

                  let displayValue = value

                  // Handle different field types
                  switch (customSelection.type) {
                    case CustomFieldType.CHECK_BOX:
                      displayValue = Array.isArray(value) ? (
                        <div className='flex flex-wrap gap-1'>
                          {value.map((item, i) => (
                            <Badge
                              key={i}
                              variant='secondary'
                              className='text-xs'
                            >
                              {item}
                            </Badge>
                          ))}
                        </div>
                      ) : (
                        value
                      )
                      break
                    case CustomFieldType.DATE_TIME:
                      displayValue = value ? (
                        <span className='text-muted-foreground'>
                          {moment(value).format('DD/MM/YYYY')}
                        </span>
                      ) : (
                        value
                      )
                      break
                    case CustomFieldType.SELECT_BOX:
                    case CustomFieldType.RADIO_BUTTON:
                      displayValue = Array.isArray(value) ? (
                        <div className='flex flex-wrap gap-1'>
                          {value.map((item, i) => (
                            <Badge
                              key={i}
                              variant='secondary'
                              className='text-xs'
                            >
                              {item}
                            </Badge>
                          ))}
                        </div>
                      ) : (
                        value
                      )
                      break
                    default:
                      displayValue = (
                        <span className='text-muted-foreground'>{value}</span>
                      )
                  }

                  return (
                    <div key={index} className='flex flex-col'>
                      <span className='text-sm font-medium mb-1'>
                        {customSelection.label}
                      </span>
                      <div className='text-sm'>{displayValue}</div>
                    </div>
                  )
                })}
              </div>
            ) : null}
            {/* <div className='flex justify-center items-center mt-2 cursor-pointer'>
              <PenLine className='h-4 w-4 text-muted-foreground mr-1' />
              <span className='text'>
                {t('attendee.edit_details')} <Badge>{t('Coming soon')}</Badge>
              </span>
            </div> */}
          </div>
        </SheetHeader>
        <Form
          onSubmit={handleUpdateAttendee}
          initialValues={initialValues}
          subscription={{
            submitting: true,
          }}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field name='additionalFields.hotel' type='text' formatOnBlur>
                  {({ input, meta }: any) => (
                    <FormItem className='mb-4'>
                      <FormLabel meta={meta}>{t('Hotel')}</FormLabel>
                      <Input
                        {...input}
                        meta={meta}
                        isRequired={true}
                        maxLength='256'
                      />
                    </FormItem>
                  )}
                </Field>

                {attendee?.webcastUrlJoinLinks?.length > 0 && (
                  <div>
                    <h4>{t('Webcast join links')}:</h4>
                    <WebcastJoinLink
                      webcastJoinLinks={attendee?.webcastUrlJoinLinks}
                    />
                  </div>
                )}
                {attendee?.registration?.customValues?.length ? (
                  <QuestionWitWrapper>
                    {attendee?.registration?.customValues?.map(
                      (item, index) => (
                        <QuestionWit
                          key={index}
                          title={item?.eventCustomField?.title}
                          options={checkCustomFieldType(item)}
                        />
                      )
                    )}
                  </QuestionWitWrapper>
                ) : (
                  ''
                )}
                <Button type='submit' className='w-full mt-2'>
                  {loading && (
                    <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                  )}
                  {t('Update')}
                </Button>
              </form>
            )
          }}
        ></Form>
        <div className='mb-4'></div>
        {/*<div className='text-center text-muted-foreground mb-4'>*/}
        {/*  Sunday, Jul 14*/}
        {/*</div>*/}
        <div className='space-y-2'>
          {/*<div className='flex justify-start'>*/}
          {/*  <div className='bg-yellow-100 text-muted-foreground px-3 py-1 rounded-full'>*/}
          {/*    Sent · 6:02 PM*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='flex justify-start'>*/}
          {/*  <div className='bg-yellow-100 text-muted-foreground px-3 py-1 rounded-full'>*/}
          {/*    Opened · 6:04 PM*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='flex justify-start'>*/}
          {/*  <div className='bg-gray-100 text-muted-foreground px-3 py-1 rounded-full'>*/}
          {/*    hihi · 6:08 PM*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='flex justify-start'>*/}
          {/*  <div className='bg-purple-100 text-muted-foreground px-3 py-1 rounded-full'>*/}
          {/*    The Anh Ng cannot attend · 6:08 PM*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='flex justify-start'>*/}
          {/*  <div className='bg-gray-100 text-muted-foreground px-3 py-1 rounded-full'>*/}
          {/*    atteneeifje · 6:09 PM*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='flex justify-start'>*/}
          {/*  <div className='bg-green-100 text-muted-foreground px-3 py-1 rounded-full'>*/}
          {/*    The Anh Ng will attend · 6:09 PM*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='flex justify-start'>*/}
          {/*  <div className='bg-gray-100 text-muted-foreground px-3 py-1 rounded-full'>*/}
          {/*    hello · 6:15 PM*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </SheetContent>
    </Sheet>
  )
}

const QuestionWitWrapper = styled.div`
  ${responsive.sm`
    font-size: 12px;
    padding: 8px;
  `}
`

export default AttendeeSheet
