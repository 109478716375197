import Quill from 'quill'
import QuillMention from 'quill-mention'
import { FC, useEffect, useRef, useState } from 'react'

import 'quill-mention/dist/quill.mention.css'
import 'quill/dist/quill.snow.css'
import './styles.css'
import './customLineHeight'
import './customFont'
import './customBlockquote'
import './customBlock'
// import './customCodeBlock'
import './customList'
import './customIndent'
import './customFontSize'
import { customColorList } from './customColor'

import { Label } from '@/components/ui/label'
import { Switch } from '@/components/ui/switch'
import axios from 'axios'
import DOMPurify from 'dompurify'
import { Environment } from 'environments'
import { IPlaceHolders } from 'models'
// resize image
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter'
import { useTranslation } from 'react-i18next'

import { quillFonts } from './customFont'
import { lineHeightConfig } from './customLineHeight'
import { fontSizes } from './customFontSize'

Quill.register(
  {
    'modules/mention': QuillMention,
    'modules/blotFormatter': BlotFormatter,
  },
  true
)

const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)
const BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)
const ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)

type EditorType = {
  value: string
  onChange: (value: string) => void
  isMention: boolean
  className: string
  emailPlaceHolders?: IPlaceHolders[]
  displayInsertAsHtml?: boolean
  isRawContent?: boolean
  setIsRawContent?: (by: boolean) => void
}

const Editor: FC<EditorType> = ({
  value,
  onChange,
  isMention,
  className,
  emailPlaceHolders,
  displayInsertAsHtml,
  isRawContent,
  setIsRawContent,
}) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['link', 'blockquote', 'image'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

    [{ size: fontSizes }], // custom dropdown

    [{ color: customColorList }, { background: [] }],
    [{ font: quillFonts }],
    [{ align: [] }],

    [
      {
        lineheight: lineHeightConfig.whitelist,
      },
    ],
    ['clean'], // remove formatting button
  ]

  // Quill modules
  const modules = {
    toolbar: toolbarOptions,
    blotFormatter: {},
  }

  const editorRef = useRef(null)

  function selectLocalImage(editor: any) {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/png, image/jpeg')
    input.click()

    // Listen upload local image and save to server
    input.onchange = () => {
      // TODO: Quill update code
      // @ts-ignore
      const file = input?.files[0]
      saveImageToServer(editor, file)
    }
  }

  async function saveImageToServer(editor: any, file: any) {
    try {
      const accessToken = localStorage.getItem('ACCESS_TOKEN')
      const formData = new FormData()
      formData.append('file', file)
      const response = await axios.post(
        `${Environment.serverUrl}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      insertToEditor(editor, response?.data?.uri)
    } catch (error) {
      console.log('err', error)
    }
  }

  function insertToEditor(editor: any, url: string) {
    // push image url to rich editor.
    const range = editor.getSelection()
    editor.insertEmbed(range.index, 'image', url.toString())
  }

  const [rawHtmlContent, setRawHtmlContent] = useState('')
  const [content, setContent] = useState('')

  const { t } = useTranslation()

  const [pasteAsHTML, setPasteAsHTML] = useState(false)

  useEffect(() => {
    if (isRawContent) setPasteAsHTML(isRawContent)
  }, [])

  useEffect(() => {
    if (editorRef.current) {
      // Initialize Quill editor with mention module
      const quill = new Quill(editorRef.current, {
        theme: 'snow',
        modules: isMention
          ? {
              toolbar: toolbarOptions,
              blotFormatter: {},
              mention: {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                mentionDenotationChars: ['$'],
                showDenotationChar: false,
                source: (searchTerm, renderList, mentionChar) => {
                  let values: any
                  if (mentionChar === '$') {
                    values = emailPlaceHolders?.map(item => ({
                      value: item.name,
                    }))
                  }

                  if (searchTerm.length === 0) {
                    renderList(values, searchTerm)
                  } else {
                    const matches: any = []
                    for (let i = 0; i < values.length; i++)
                      if (
                        ~values[i].value
                          .toLowerCase()
                          .indexOf(searchTerm.toLowerCase())
                      )
                        matches.push(values[i])
                    renderList(matches, searchTerm)
                  }
                },
                onSelect: function (item) {
                  appendTextToEditor(item.value)
                },
              },
            }
          : modules,
      })

      // document.querySelector('.ql-editor')?.classList.remove('ql-editor')
      const appendTextToEditor = text => {
        const mentionModule = quill.getModule('mention')
        const insertAtPos = mentionModule.mentionCharPos
        //delete search key
        quill.deleteText(
          mentionModule.mentionCharPos,
          mentionModule.cursorPos - mentionModule.mentionCharPos,
          Quill.sources.USER
        )
        quill.insertText(insertAtPos, text + ' ')
        quill.formatText(insertAtPos, text.length, {
          // 'background-color': '#d3e1eb',
          'padding-top': '10px',
          'margin-right': '2px',
          'border-radius': '6px',
        })
        quill.setSelection(insertAtPos + text.length + 1)
      }

      // onChange editor
      quill.on('text-change', () => {
        const mentionElements = document.querySelectorAll('#editor .mention')
        // Loop through each mention element and remove the 'data-value' attribute
        mentionElements.forEach(function (mentionElement) {
          mentionElement.removeAttribute('data-value')
        })

        // if editor is empty
        if (quill.getLength() === 1 && quill.getText().trim() === '') {
          const element = document.querySelectorAll(`.${className} .ql-editor`)
          if (element[0]) element[0].innerHTML = ''
        }

        const content = DOMPurify.sanitize(quill.root?.innerHTML)

        const rawHTML = quill.getText()
        setRawHtmlContent(rawHTML)
        setContent(content)

        if (displayInsertAsHtml) {
          setPasteAsHTML(prevPasteAsHTML => {
            if (prevPasteAsHTML) {
              onChange(rawHTML)
            } else {
              onChange(content)
            }
            return prevPasteAsHTML // Return the previous value to maintain the current state
          })
        } else {
          onChange(content)
        }
      })

      quill.getModule('toolbar').addHandler('image', () => {
        selectLocalImage(quill)
      })

      // set initial value for editor

      if (value && displayInsertAsHtml && isRawContent) {
        quill.setText(value)
      } else if (value) {
        quill.root.innerHTML = DOMPurify.sanitize(value)
      }

      return () => {
        editorRef.current = null
      }
    }
  }, [editorRef.current, value, emailPlaceHolders])

  useEffect(() => {
    if (displayInsertAsHtml) {
      if (pasteAsHTML) {
        onChange(rawHtmlContent)
      } else {
        onChange(content)
      }
    }
  }, [pasteAsHTML])

  if (!emailPlaceHolders?.length && isMention) return null

  return (
    <>
      {displayInsertAsHtml && (
        <div className='flex items-center space-x-2 justify-end'>
          <Switch
            id='airplane-mode'
            checked={pasteAsHTML}
            onCheckedChange={value => {
              setPasteAsHTML(!pasteAsHTML)
              if (setIsRawContent) setIsRawContent(value)
            }}
          />
          <Label htmlFor='airplane-mode'>{t('Insert as HTML/Preview')}</Label>
        </div>
      )}
      <div className='flex gap-4 max-h-[670px]'>
        <div
          className={pasteAsHTML && displayInsertAsHtml ? 'w-1/2' : 'w-full'}
        >
          <div ref={editorRef} id='editor' className={className} />
        </div>

        {pasteAsHTML && displayInsertAsHtml && (
          <div
            className='w-1/2 border border-solid border-1 border-gray-300 overflow-auto p-3 rounded-md'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(rawHtmlContent),
            }}
          />
        )}
      </div>
    </>
  )
}

export default Editor
