import React from 'react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useForm } from 'react-hook-form'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useTranslation } from 'react-i18next'
import { Separator } from '@/components/ui/separator'
import { useMutation } from '@apollo/client'
import { CHANGE_PASSWORD } from '@/graphql/mutations'
import { MESSAGE } from '@/constants/message'
import { PASSWORD_REGEX } from '@/constants/regex'

export function ChangePasswordForm() {
  const { t } = useTranslation()

  const formSchema = z
    .object({
      currentPassword: z
        .string()
        .nonempty({ message: t(MESSAGE.REQUIRED_FIELD) })
        .regex(PASSWORD_REGEX, { message: t(MESSAGE.INVALID_PASSWORD) }),
      newPassword: z
        .string()
        .nonempty({ message: t(MESSAGE.REQUIRED_FIELD) })
        .regex(PASSWORD_REGEX, { message: t(MESSAGE.INVALID_PASSWORD) }),
      retypeNewPassword: z
        .string()
        .nonempty({ message: t(MESSAGE.REQUIRED_FIELD) })
        .regex(PASSWORD_REGEX, { message: t(MESSAGE.INVALID_PASSWORD) }),
    })
    .refine(data => data.newPassword === data.retypeNewPassword, {
      message: t('Passwords do not match'),
      path: ['retypeNewPassword'],
    })

  type ProfileFormValues = z.infer<typeof formSchema>

  const defaultValues: Partial<ProfileFormValues> = {
    currentPassword: '',
    newPassword: '',
    retypeNewPassword: '',
  }
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  })

  const [mutateChangePassword] = useMutation(CHANGE_PASSWORD, {
    onCompleted: data => {
      toast({
        title: t('Updated successfully'),
      })
    },

    onError: ({ message }) => {
      toast({
        title: t(message),
        variant: 'destructive',
      })
    },
  })

  function onSubmit(data: ProfileFormValues) {
    mutateChangePassword({
      variables: {
        oldPassword: data.currentPassword,
        newPassword: data.newPassword,
      },
    })
  }

  return (
    <div className='space-y-6'>
      <Separator />
      <div>
        <h3 className='text-lg font-medium'>{t('Change Password')}</h3>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
          <FormField
            control={form.control}
            name='currentPassword'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('Current password')}{' '}
                  <span className='text-red-600'>*</span>
                </FormLabel>
                <FormControl>
                  <Input type='password' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='newPassword'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('New password')} <span className='text-red-600'>*</span>
                </FormLabel>
                <FormControl>
                  <Input type='password' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='retypeNewPassword'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('Retype new password')}{' '}
                  <span className='text-red-600'>*</span>
                </FormLabel>
                <FormControl>
                  <Input type='password' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type='submit'>{t('Change password')}</Button>
        </form>
      </Form>
    </div>
  )
}
