import { Button } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { FormItem } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { Separator } from '@/components/ui/separator'
import { Textarea } from '@/components/ui/textarea'
import { FEEDBACK_FORM_TYPE } from '@/constants'
import { cn } from '@/lib/utils'
import {
  createFeedbackEvent,
  getFeedbackEventData,
  useFeedbackEventStore,
} from '@/store/zustand/EventFeedback'
import { Field } from '@/utils/adapters'
import { trimWhiteSpace } from '@/utils/helpers'
import { Plus, Share2, Trash2 } from 'lucide-react'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldArray, Form, arrayMutators, useParams } from 'utils/adapters'
import ClientFeedback from '../ClientFeedback'
import { FeedbackFormItem } from './FeedbackFormItem'
import { Icons } from '@/components/ui/icon'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from '@/components/ui/use-toast'
import { Environment } from '@/environments'

type ParamsType = {
  eventId: string
}

const CreateFeedBackForm: FC = () => {
  const { t } = useTranslation()
  const { eventId } = useParams<ParamsType>()
  const { feedbackEventData } = useFeedbackEventStore()

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await getFeedbackEventData(eventId)
      setLoading(false)
    }
    fetchData()
  }, [eventId])

  const [activeSection, setActiveSection] = useState<number>(-1)
  const [isPreview, setIsPreview] = useState<boolean>(false)

  const onSubmit = async (values: any, form: any) => {
    setLoading(true)
    const questionsWithoutUid = values.questions.map(question => {
      if (question.uid === '') {
        const { uid, ...rest } = question
        return rest
      }
      return question
    })
    const updatedFeedback = await createFeedbackEvent(eventId, {
      ...values,
      questions: questionsWithoutUid,
    })

    setLoading(false)
    form.reset(updatedFeedback)
    useFeedbackEventStore.getState().setFeedbackEventData(updatedFeedback)
  }

  const formInitialValue: any = {
    questions: feedbackEventData?.questions || [],
    title: feedbackEventData?.title || t('feedback_form.feedback_title'),
    content: feedbackEventData?.content || t('feedback_form.overview'),
  }

  const onCopy = () => {
    toast({
      description: t('Copied to clipboard!'),
    })
  }

  return (
    <div className={cn('space-y-6 px-8 py-4 focus-visible:ring-0')}>
      {loading ? (
        <div className='flex items-center justify-center h-[50vh]'>
          <Icons.spinner className='h-8 w-8 animate-spin' />
        </div>
      ) : (
        <>
          {!feedbackEventData?.questions?.length && (
            <div className='p-4 mb-4 text-yellow-800 border-l-4 border-yellow-300 bg-yellow-50'>
              <p className='font-medium'>
                {t(
                  'feedback_form.no_questions_warning',
                  'No feedback questions found.'
                )}
              </p>
              <p className='text-sm'>
                {t(
                  'feedback_form.add_questions_prompt',
                  'Please add some questions to create your feedback form.'
                )}
              </p>
            </div>
          )}

          <div>
            <h3 className='text-lg font-medium'>{t('feedback_form.title')}</h3>
            <p className='text-sm text-muted-foreground'>
              {t('feedback_form.content')}
            </p>
            <p className='text-sm text-muted-foreground'>
              {t('feedback_form.help_text')}
            </p>
          </div>
          <Separator />

          <Form
            onSubmit={onSubmit}
            mutators={{
              ...arrayMutators,
            }}
            keepDirtyOnReinitialize
            initialValues={{ ...formInitialValue }}
            render={({
              handleSubmit,
              values,
              submitting,
              form: { getState, reset },
            }: any) => {
              useEffect(() => {
                return () => {
                  reset(formInitialValue)
                }
              }, [])
              return (
                <>
                  <form onSubmit={handleSubmit}>
                    <div className='mb-3'>
                      {activeSection === -1 ? (
                        <div
                          className={cn(
                            'flex flex-col gap-4 justify-between bg-slate-100 px-4 py-6 mb-4 border-l-4',
                            activeSection === -1
                              ? 'border-primary'
                              : 'border-transparent'
                          )}
                          onClick={() => setActiveSection(-1)}
                        >
                          <Field
                            name={'title'}
                            type='text'
                            format={trimWhiteSpace}
                            formatOnBlur
                          >
                            {({ input, meta }: any) => (
                              <FormItem className='w-full'>
                                <Input
                                  {...input}
                                  meta={meta}
                                  autoFocus
                                  className='text-3xl font-bold border-b border-gray-300 px-0 rounded-none border-t-0 border-x-0 py-6 focus-visible:ring-0 
                        focus-visible:ring-offset-0 focus:border-b-2 focus:border-primary'
                                />
                              </FormItem>
                            )}
                          </Field>
                          <Field
                            name={'content'}
                            type='text'
                            format={trimWhiteSpace}
                            formatOnBlur
                          >
                            {({ input, meta }: any) => (
                              <FormItem className='w-full'>
                                <Textarea
                                  {...input}
                                  meta={meta}
                                  className='mt-2 text-base border-b border-gray-300 bg-transparent focus-visible:ring-0 
                        focus-visible:ring-offset-0 focus:border-2 focus:border-primary'
                                />
                              </FormItem>
                            )}
                          </Field>
                        </div>
                      ) : (
                        <div
                          className='flex flex-col gap-4 justify-between px-4 py-6 mb-4 bg-slate-100'
                          onClick={() => setActiveSection(-1)}
                        >
                          <p
                            className='text-3xl font-bold border-b border-gray-300 px-0 rounded-none border-t-0 border-x-0 py-2 focus-visible:ring-0 
                        focus-visible:ring-offset-0 focus:border-b-2 focus:border-primary '
                          >
                            {values.title}
                          </p>
                          <p className='text-base whitespace-pre-wrap'>
                            {values.content}
                          </p>
                        </div>
                      )}
                      <div className='flex gap-6 flex-col md:flex-row justify-between px-4 py-6 mb-4 bg-slate-100'>
                        <div className='w-full'>
                          <p className='font-bold text-lg'>{t('Email')} *</p>
                          <Input
                            className='font-bold border-b border-gray-300 px-0 rounded-none border-t-0 border-x-0 py-6 focus-visible:ring-0 
                          focus-visible:ring-offset-0 focus:border-b-2 focus:border-primary'
                          />
                        </div>
                      </div>

                      <FieldArray name='questions'>
                        {({ fields, meta: { error } }) => (
                          <>
                            {fields.map((name, index) => {
                              return (
                                <div
                                  key={name}
                                  className={cn(
                                    'flex gap-4 justify-between bg-slate-100 px-4 py-6 mb-4 items-center border-l-4',
                                    activeSection === index
                                      ? 'border-primary'
                                      : 'border-transparent'
                                  )}
                                  onClick={() => setActiveSection(index)}
                                >
                                  <div className='w-[85%]'>
                                    <FeedbackFormItem
                                      name={name}
                                      index={index}
                                      type={
                                        getState().values?.questions[index]
                                          ?.type
                                      }
                                      activeSection={activeSection}
                                    />
                                  </div>

                                  <Trash2
                                    onClick={() => fields.remove(index)}
                                    className='w-[20px] text-gray-600 cursor-pointer'
                                  />
                                </div>
                              )
                            })}

                            <button
                              type='button'
                              className='-z-1 flex items-center cursor-pointer space-x-2 mt-6 text-primary font-bold'
                              onClick={() => {
                                fields.push({
                                  label: t('feedback_form.title_placeholder'),
                                  type: FEEDBACK_FORM_TYPE.RATING,
                                  required: false,
                                  uid: '',
                                })
                                if (fields.length) {
                                  setActiveSection(fields.length)
                                }
                              }}
                            >
                              <Plus className='w-[20px] mr-1' />
                              {t('feedback_form.add_btn')}
                            </button>
                          </>
                        )}
                      </FieldArray>
                    </div>

                    <div className='flex flex-col md:flex-row gap-4'>
                      <CopyToClipboard
                        text={`${Environment.registerEventPage}/events/${eventId}/feedback`}
                        onCopy={onCopy}
                      >
                        <Button variant='outline' type='button'>
                          <Share2 className='text-[#8e8e8e]' />
                        </Button>
                      </CopyToClipboard>
                      <Button
                        type='button'
                        className='w-full'
                        variant='outline'
                        onClick={() => setIsPreview(true)}
                      >
                        {t('feedback_form.preview_btn')}
                      </Button>

                      <Button
                        type='submit'
                        className='w-full'
                        disabled={submitting}
                      >
                        {loading && (
                          <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                        )}
                        {t('feedback_form.save_btn')}
                      </Button>
                    </div>
                  </form>
                  {isPreview && (
                    <Dialog
                      open={isPreview}
                      onOpenChange={() => setIsPreview(false)}
                    >
                      <DialogContent className='max-h-[90vh] overflow-y-auto w-full max-w-[80%] p-0'>
                        <ClientFeedback previewData={values} />
                      </DialogContent>
                    </Dialog>
                  )}
                </>
              )
            }}
          />
        </>
      )}
    </div>
  )
}

export default CreateFeedBackForm
