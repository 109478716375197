import { Redirect } from 'utils/adapters'

import { USER_ROLE } from 'constants/userRole'
import AttendeeGuard from 'guards/AttendeeGuard'
import AuthGuard from 'guards/AuthGuard'
import { IRoute } from 'models/IRoute'

import DashboardLayout from 'components/layouts/DashboardLayout'
import SettingsProfilePage from '@/components/pages/OwnAccount/components/SettingProfilePage'
import SettingsAuthPage from '@/components/pages/OwnAccount/components/SettingAuthPage'
import UserForm from '@/components/pages/Users/components/UserForm'
import AdminEventDetail from '@/components/pages/EventDetail/AdminEventDetail'
import Dashboard from '@/components/pages/Dashboard'
import AccountSettingsLayout from '@/components/pages/OwnAccount'
import CustomFieldList from '@/components/pages/CustomField/components/CustomFieldList'
import Imprint from '@/components/pages/Imprint'
import Privacy from '@/components/pages/Privacy'
import UsersTable from '@/components/pages/Users/components/UsersTable'
import OrganizationTable from '@/components/pages/Organization/components/OrganizationTable'
import AdminOrganization from '@/components/pages/Organization/AdminOrganization'
import OrganizationWrapper from '@/components/pages/Organization/OrganizationWrapper'
import Error404View from '@/components/pages/Error404View'
import AuthenticationPage from '@/components/pages/Login'
import EmailNewsLetter from '@/components/pages/EmailNewsLetter'
import ClientEventDetail from '@/components/pages/ClientEventDetail'
import AttendeeEventDetail from '@/components/pages/EventDetail/AttendeeEventDetail'
import EventInformation from '@/components/pages/EventInformation'
import EventRegister from 'components/pages/EventRegister'
import EditBooking from '@/components/pages/EventRegister/EditBooking'
import CancellationPolicy from '@/components/pages/CancellationPolicy'
import ImportAttendees from '@/components/pages/ImportAttendees'
import SuccessRegisterContainer from 'components/pages/EventRegister/SuccessRegisterContainer'
import EventAttendeeList from '@/components/pages/EventAttendeeList'
import AdminEventLayout from '../components/layouts/event/event.admin.layout'
import CustomFieldForm from '@/components/pages/CustomField/components/CustomFieldForm'
import SendMailLog from 'components/pages/SendMailLog'
import ChangesLog from '@/components/pages/ChangesLog'
import Feedbacks from '@/components/pages/Feedbacks'
import DuplicateEventPage from '@/components/pages/DuplicateEvent'
import AdminLayout from 'components/layouts/AdminLayout'
import { t } from 'i18next'
import Releases from '@/components/pages/Releases'
import QRManagement from '@/components/pages/QRManagement'
import QRAttendeeInfo from '@/components/pages/QRAttendeeInfo'
import StatusManagement from '@/components/pages/StatusManagement'
import InvitationTemplateManagement from 'components/pages/InvitationTemplate'
import EventInvitationPreview from '@/components/pages/InvitationTemplate/InvitationTemplatePreview'
import LoginGuard from '@/guards/LoginGuard'
import InvitationList from '@/components/pages/InvitationList'
import CreateFeedBackForm from '@/components/pages/CreateFeedBackForm'
import ClientFeedback from '@/components/pages/ClientFeedback'
import EventFeedbackStatistics from '@/components/pages/EventFeedbackStatistics'

export const PATH_NAME = {
  ROOT: '/',
  ERROR_404: '/404',
  DASHBOARD: '/dashboard',
  USERS_MANAGEMENT: '/admin/users',
  USERS_TABLE: '/admin/users',
  CREATE_USER: '/admin/users/create',
  EDIT_EXISTING_USER: '/admin/users/edit/:id',
  ORGANIZATION_MANAGEMENT: '/organizations',
  ORGANIZATION_TABLE: '/organizations',
  CREATE_ORGANIZATION: '/organizations/root-admin/create',
  EDIT_ORGANIZATION: '/organizations/edit/:id',
  STATUS_MANAGEMENT: '/admin/statuses',
  LOGIN: '/login',
  SETTINGS: '/settings',
  SETTINGS_GENERAL: '/settings',
  SETTINGS_AUTH: '/settings/auth',
  EDIT_USER: '/settings/edit/:id',
  RESET_NEW_PASSWORD: '/reset/:id',
  ATTENDEE_EVENT_DETAIL: '/attendee/events/:id',
  CLIENT_EVENT_DETAIL: '/events/:id',
  CUSTOM_FIELDS: '/admin/custom-field',
  CUSTOM_FIELDS_LIST: '/admin/custom-field',
  CREATE_CUSTOM_FIELD: '/admin/custom-field/create',
  EDIT_CUSTOM_FIELD: '/admin/custom-field/edit/:id',
  ADMIN_ORGANIZATION: '/organizations/admin/:id',
  ATTENDEE_INFORMATION: '/attendee/register/:id',
  EVENT_REGISTER_SUCCESS: '/attendee/register/:id/success',
  IMPRINT: '/imprint',
  PRIVACY: '/privacy',
  ATTENDANT_DETAILS: '/:eventId/:attendantId',
  // Attendee Edit booking
  EDIT_BOOKING: '/events/:id/edit-booking',
  EVENT_FEEDBACK: {
    ATTENDEE: '/events/:id/feedback/:attendeeId',
    CLIENT: '/events/:id/feedback',
  },
  // Admin Manage Events
  CREATE_EVENT: '/admin/events/create',
  EDIT_EVENT: '/admin/events/:id/edit',
  DUPLICATE_EVENT: '/admin/events/:id/duplicate',
  ADMIN_EVENT_DETAIL: '/admin/events/:id',
  CANCELLATION_POLICY: '/admin/events/:id/cancellation-policy',
  EVENT_ATTENDEE_LIST: '/admin/events/:id/attendees',
  IMPORT_ATTENDEES: '/admin/events/:id/import',
  EMAIL_NEWSLETTER: '/admin/events/:id/email',
  QR_MANAGEMENT: '/admin/events/:id/qr',
  EVENT_INVITATION_TEMPLATE: '/admin/events/:eventId/invitation-template',
  EVENT_INVITATION_LIST: '/admin/events/:eventId/invitations',
  EVENT_INVITATION_TEMPLATE_DETAIL:
    '/admin/events/:eventId/invitation-template/:id',
  EVENT_INVITATION_TEMPLATE_PREVIEW:
    '/admin/events/:eventId/invitation-template/:id',
  ADMIN_EVENT_FEEDBACK: '/admin/events/:eventId/feedback',
  ADMIN_EVENT_FEEDBACK_STATISTICS: '/admin/events/:eventId/feedback/statistics',
  SEND_MAIL_LOG: '/admin/send-mail-log',
  CHANGES_LOG: '/admin/changes-log',
  FEEDBACKS: '/admin/feedbacks',
  RELEASES: '/admin/releases',
  RELEASES_CREATE: '/admin/releases/create',
  RELEASES_ANNOUNCEMENT: '/releases',
  QR_ATTENDEE_INFO: '/events/:eventId/qr/attendees/:attendeeId',
}

const routesMap: IRoute[] = [
  {
    exact: true,
    path: PATH_NAME.ROOT,
    component: () => <Redirect to={PATH_NAME.DASHBOARD} />,
  },

  {
    exact: true,
    path: PATH_NAME.ERROR_404,
    component: Error404View,
  },
  {
    exact: true,
    path: PATH_NAME.LOGIN,
    guard: LoginGuard,
    component: AuthenticationPage,
  },

  {
    exact: true,
    path: PATH_NAME.CLIENT_EVENT_DETAIL,
    component: ClientEventDetail,
  },

  {
    exact: true,
    path: PATH_NAME.EVENT_FEEDBACK.ATTENDEE,
    component: ClientFeedback,
  },
  {
    exact: true,
    path: PATH_NAME.EVENT_FEEDBACK.CLIENT,
    component: ClientFeedback,
  },

  {
    path: PATH_NAME.SEND_MAIL_LOG,
    guard: AuthGuard,
    layout: AdminLayout,
    props: { title: t('Send mail log') },
    component: SendMailLog,
  },
  {
    path: PATH_NAME.CHANGES_LOG,
    guard: AuthGuard,
    layout: AdminLayout,
    props: { title: t('Changes Log') },
    component: ChangesLog,
  },
  {
    path: PATH_NAME.FEEDBACKS,
    guard: AuthGuard,
    layout: AdminLayout,
    props: { title: t('Feedbacks') },
    component: Feedbacks,
  },
  {
    path: PATH_NAME.RELEASES,
    guard: AuthGuard,
    layout: AdminLayout,
    props: { title: t('releases.title') },
    component: Releases,
    requireRoles: [USER_ROLE.ROOT_ADMIN],
  },

  {
    exact: true,
    path: PATH_NAME.QR_ATTENDEE_INFO,
    component: QRAttendeeInfo,
  },

  // user management
  {
    path: PATH_NAME.USERS_MANAGEMENT,
    layout: AdminLayout,
    guard: AuthGuard,
    props: { title: t('Users Management') },
    exact: true,
    component: UsersTable,
    requireRoles: [
      USER_ROLE.ROOT_ADMIN,
      USER_ROLE.ADMIN,
      USER_ROLE.EVENT_ORGANISER,
    ],
  },
  {
    path: PATH_NAME.CREATE_USER,
    layout: AdminLayout,
    guard: AuthGuard,
    props: { title: t('user_management.add_new_user'), displayBackIcon: true },
    exact: true,
    component: UserForm,
    requireRoles: [
      USER_ROLE.ROOT_ADMIN,
      USER_ROLE.ADMIN,
      USER_ROLE.EVENT_ORGANISER,
    ],
  },
  {
    path: PATH_NAME.EDIT_EXISTING_USER,
    layout: AdminLayout,
    guard: AuthGuard,
    props: {
      title: t('user_management.modify_user_account'),
      displayBackIcon: true,
    },
    exact: true,
    component: UserForm,
    requireRoles: [
      USER_ROLE.ROOT_ADMIN,
      USER_ROLE.ADMIN,
      USER_ROLE.EVENT_ORGANISER,
    ],
  },

  // organization management
  {
    path: PATH_NAME.ORGANIZATION_MANAGEMENT,
    layout: AdminLayout,
    guard: AuthGuard,
    exact: true,
    props: { title: t('Organizations Management') },
    component: OrganizationTable,
    requireRoles: [USER_ROLE.ROOT_ADMIN],
  },
  {
    path: PATH_NAME.CREATE_ORGANIZATION,
    layout: AdminLayout,
    guard: AuthGuard,
    props: { title: t('Create Organization'), displayBackIcon: true },
    component: OrganizationWrapper,
    requireRoles: [USER_ROLE.ROOT_ADMIN],
    exact: true,
  },
  {
    path: PATH_NAME.EDIT_ORGANIZATION,
    layout: AdminLayout,
    guard: AuthGuard,
    props: { title: t('Update Organization'), displayBackIcon: true },
    component: OrganizationWrapper,
    requireRoles: [USER_ROLE.ROOT_ADMIN],
    exact: true,
  },
  {
    path: PATH_NAME.ADMIN_ORGANIZATION,
    layout: AdminLayout,
    guard: AuthGuard,
    props: { title: t('Organization Management') },
    component: AdminOrganization,
    requireRoles: [USER_ROLE.ADMIN],
    exact: true,
  },
  {
    path: PATH_NAME.STATUS_MANAGEMENT,
    layout: AdminLayout,
    guard: AuthGuard,
    exact: true,
    props: { title: t('Status Management') },
    component: StatusManagement,
    requireRoles: [
      USER_ROLE.ROOT_ADMIN,
      USER_ROLE.ADMIN,
      USER_ROLE.EVENT_ORGANISER,
    ],
  },

  // Attendee
  {
    exact: true,
    path: PATH_NAME.ATTENDEE_EVENT_DETAIL,
    component: AttendeeEventDetail,
    guard: AuthGuard,
  },

  {
    exact: true,
    path: PATH_NAME.ATTENDEE_INFORMATION,
    component: EventRegister,
  },

  {
    exact: true,
    path: PATH_NAME.EVENT_REGISTER_SUCCESS,
    component: SuccessRegisterContainer,
  },

  {
    exact: true,
    path: PATH_NAME.EDIT_BOOKING,
    component: EditBooking,
    guard: AttendeeGuard,
  },

  {
    exact: true,
    path: PATH_NAME.RESET_NEW_PASSWORD,
    component: AuthenticationPage,
  },

  {
    exact: true,
    path: PATH_NAME.IMPRINT,
    component: Imprint,
  },
  {
    exact: true,
    path: PATH_NAME.PRIVACY,
    component: Privacy,
  },
  {
    exact: true,
    path: PATH_NAME.EVENT_INVITATION_TEMPLATE_DETAIL,
    component: EventInvitationPreview,
  },

  {
    path: PATH_NAME.SETTINGS,
    layout: AccountSettingsLayout,
    routes: [
      {
        exact: true,
        path: PATH_NAME.SETTINGS_GENERAL,
        component: SettingsProfilePage,
      },
      {
        exact: true,
        path: PATH_NAME.SETTINGS_AUTH,
        component: SettingsAuthPage,
      },
    ],
  },

  // custom field management
  {
    path: PATH_NAME.CUSTOM_FIELDS,
    layout: AdminLayout,
    guard: AuthGuard,
    props: { title: t('library_label') },
    component: CustomFieldList,
    exact: true,
  },

  {
    path: PATH_NAME.CREATE_CUSTOM_FIELD,
    layout: AdminLayout,
    guard: AuthGuard,
    props: {
      title: t('library.create_library_question'),
      displayBackIcon: true,
    },
    component: CustomFieldForm,
  },
  {
    path: PATH_NAME.EDIT_CUSTOM_FIELD,
    layout: AdminLayout,
    guard: AuthGuard,
    props: {
      title: t('library.update_library_question'),
      displayBackIcon: true,
    },
    component: CustomFieldForm,
  },

  // {
  //   exact: true,
  //   path: PATH_NAME.ATTENDANT_DETAILS,
  //   component: AttendantDetails,
  //   guard: AttendeeGuard,
  // },

  {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: PATH_NAME.CREATE_EVENT,
    requireRoles: [USER_ROLE.ADMIN, USER_ROLE.EVENT_ORGANISER],
    component: EventInformation,
  },

  {
    path: '/admin/events/:id',
    guard: AuthGuard,
    layout: AdminEventLayout,
    routes: [
      {
        exact: true,
        path: PATH_NAME.EDIT_EVENT,
        component: EventInformation,
      },
      {
        exact: true,
        path: PATH_NAME.DUPLICATE_EVENT,
        component: DuplicateEventPage,
      },
      {
        exact: true,
        path: PATH_NAME.ADMIN_EVENT_DETAIL,
        component: AdminEventDetail,
      },
      {
        exact: true,
        path: PATH_NAME.CANCELLATION_POLICY,
        component: CancellationPolicy,
      },
      {
        exact: true,
        path: PATH_NAME.EVENT_ATTENDEE_LIST,
        component: EventAttendeeList,
      },
      {
        exact: true,
        path: PATH_NAME.IMPORT_ATTENDEES,
        component: ImportAttendees,
      },
      {
        exact: true,
        path: PATH_NAME.EMAIL_NEWSLETTER,
        component: EmailNewsLetter,
      },
      {
        exact: true,
        path: PATH_NAME.QR_MANAGEMENT,
        component: QRManagement,
      },
      {
        exact: true,
        path: PATH_NAME.EVENT_INVITATION_TEMPLATE,
        component: InvitationTemplateManagement,
      },
      {
        exact: true,
        path: PATH_NAME.EVENT_INVITATION_LIST,
        component: InvitationList,
      },
      {
        exact: true,
        path: PATH_NAME.ADMIN_EVENT_FEEDBACK,
        component: CreateFeedBackForm,
      },
      {
        exact: true,
        path: PATH_NAME.ADMIN_EVENT_FEEDBACK_STATISTICS,
        component: EventFeedbackStatistics,
      },
    ],
  },

  {
    path: PATH_NAME.ROOT,
    guard: AuthGuard,
    layout: AdminLayout,
    props: { title: t('Events'), displayAddEventButton: true },
    routes: [
      {
        path: PATH_NAME.CREATE_EVENT,
        requireRoles: [USER_ROLE.ROOT_ADMIN],
        component: EventInformation,
      },

      {
        exact: true,
        path: PATH_NAME.DASHBOARD,
        component: Dashboard,
      },

      {
        component: () => <Redirect to={PATH_NAME.ERROR_404} />,
      },
    ],
  },

  {
    path: '*',
    routes: [
      {
        component: () => <Redirect to={PATH_NAME.ERROR_404} />,
      },
    ],
  },
]

export default routesMap
