import Quill from 'quill'

const Parchment = Quill.import('parchment')
export const lineHeightConfig = {
  scope: Parchment.Scope.INLINE,
  whitelist: ['0.8', '1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '2.0'],
}
const lineHeightClass = new Parchment.Attributor.Class(
  'lineheight',
  'ql-line-height',
  lineHeightConfig
)
const lineHeightStyle = new Parchment.Attributor.Style(
  'lineheight',
  'line-height',
  lineHeightConfig
)
Parchment.register(lineHeightClass)
Parchment.register(lineHeightStyle)
