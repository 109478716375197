import { FC, useState } from 'react'
import { styled } from 'utils/adapters'

import { SUPPORT_LANGUAGE } from 'constants/language'

interface ILanguage {
  name: string
  value: string
}

type LanguageListType = {
  languages: ILanguage[]
  onSelectLanguage: (language: string) => void
  selectedLanguage: string
}

const LanguageList: FC<LanguageListType> = ({
  languages,
  onSelectLanguage,
  selectedLanguage,
}) => {
  const checkLangIndex = () => {
    return SUPPORT_LANGUAGE.findIndex(item => item.value === selectedLanguage)
  }

  const [langIndex, setLangIndex] = useState<number>(checkLangIndex())

  return (
    <ListWrapper>
      {languages.map((lang, index) => (
        <Item
          active={langIndex === index}
          key={index}
          onClick={() => {
            setLangIndex(index)
            onSelectLanguage(lang.value)
          }}
        >
          {lang.name}
        </Item>
      ))}
    </ListWrapper>
  )
}

const ListWrapper = styled.ul`
  padding: 10px 0;
  list-style: none;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  border-radius: 8px;
  max-height: 500px;
  overflow-y: auto;
`

const Item = styled.li<{ active: boolean }>`
  padding: 4px 20px;
  background: ${props => (props.active ? '#4ebcf1' : 'inherit')};
  color: ${props => (props.active ? '#fff' : '#333')};
  &:hover {
    background: #4ebcf1;
    color: #fff;
  }
`

export default LanguageList
