import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from 'utils/adapters'
import { COLORS } from 'constants/colors'
import { responsive } from 'assets/scss/mixin'

type AccommodationButtonType = {
  href: string
  primaryColor: string
}

const AccommodationButton: FC<AccommodationButtonType> = ({
  href,
  primaryColor,
}) => {
  const { t } = useTranslation()

  return (
    <div className='block'>
      <StyledButton
        target='_blank'
        href={href}
        primaryColor={primaryColor}
        rel='noreferrer noopener'
        className='rounded-md py-2 px-4 text-sm font-medium '
      >
        {t('Accommodation')}
      </StyledButton>
    </div>
  )
}

const StyledButton = styled.a<{ primaryColor?: string }>`
  text-align: center;
  cursor: pointer;
  color: #fff;
  width: 100%;
  background-color: ${props => props.primaryColor || COLORS.BLUE};

  &:hover {
    opacity: 0.8;
  }

  ${responsive.sm`
    font-size: 16px;
  `};
`

export default AccommodationButton
