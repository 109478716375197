import { useState } from 'react'

const useModal = () => {
  const [isShow, setIsShow] = useState(false)

  const showModal = () => {
    setIsShow(true)
  }

  const hideModal = () => {
    setIsShow(false)
  }

  const toggle = () => {
    setIsShow(!isShow)
  }

  return {
    isShow,
    showModal,
    hideModal,
    toggle,
  }
}

export default useModal
