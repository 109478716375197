import Quill from 'quill'

const List = Quill.import('formats/list')

class ListStyle extends List {
  static create(value) {
    const node = super.create(value)
    node.style.paddingLeft = '1.5em'
    node.style.margin = '0'
    return node
  }
}

Quill.register(ListStyle, true)

const Parchment = Quill.import('parchment')

const customFontFamilyAttributor = new Parchment.Attributor.Style(
  'custom-family-attributor',
  'font-family'
)
const customSizeAttributor = new Parchment.Attributor.Style(
  'custom-size-attributor',
  'font-size'
)
const customColorAttributor = new Parchment.Attributor.Style(
  'custom-color-attributor',
  'color'
)
const ListItemBlot = Quill.import('formats/list/item')

class CustomListItem extends ListItemBlot {
  optimize(context) {
    super.optimize(context)

    if (this.children.length >= 1) {
      const child = this.children.head
      const attributes = child?.attributes?.attributes

      if (attributes) {
        for (const key in attributes) {
          const element = attributes[key]
          const name = element.keyName
          const value = element.value(child.domNode)

          if (name === 'color') {
            super.format('custom-color-attributor', value)
          } else if (name === 'font-family')
            super.format('custom-family-attributor', value)
          else if (name === 'font-size')
            super.format('custom-size-attributor', value)
        }
      }
    }
  }
}

Quill.register(customColorAttributor, true)
Quill.register(customFontFamilyAttributor, true)
Quill.register(customSizeAttributor, true)
Quill.register(CustomListItem, true)
