import { UserAuthForm } from './components/UserAuthForm'
import { styled } from '@/utils/adapters'
import { LoginBackgroundJPG as LoginDestopBackground } from 'assets/images'
import * as React from 'react'
import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { useTranslation } from 'react-i18next'
import LanguageDropdown from '../../organisms/language/language-dropdown'

const AuthenticationPage: React.FC = () => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = React.useState<
    'login' | 'forgotPassword' | 'resetPassword'
  >('login')

  const [title, setTitle] = React.useState(t('Login'))

  React.useEffect(() => {
    const getTitle = () => {
      switch (currentPage) {
        case 'login':
          return t('Login')
        case 'forgotPassword':
          return t('Forgot Password')
        case 'resetPassword':
          return t('Reset Password')
        default:
          return t('Login')
      }
    }
    setTitle(getTitle())
  }, [currentPage])

  return (
    <>
      <div className='container relative h-full flex-col items-center justify-center py-20 md:grid lg:max-w-none lg:grid-cols-2 lg:py-0 lg:px-0'>
        <div
          className={cn(
            buttonVariants({ variant: 'ghost' }),
            'absolute right-4 top-4 md:right-8 md:top-8'
          )}
        >
          <LanguageDropdown />
        </div>
        <LeftBlock className='relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex'>
          <div className='relative z-20 flex items-center text-lg font-medium'>
            ConferenceDirect
          </div>
          <div className='relative z-20 mt-auto'>
            <blockquote className='space-y-2'></blockquote>
          </div>
        </LeftBlock>
        <div className='lg:p-8'>
          <div className='mx-auto flex flex-col justify-center space-y-6 w-[300px]'>
            <div className='flex flex-col space-y-2 text-center'>
              <h1 className='text-2xl font-semibold tracking-tight'>{title}</h1>
            </div>
            <UserAuthForm onPageChange={setCurrentPage} />
            <p className='px-8 text-center text-sm text-muted-foreground'>
              {t('By clicking continue, you agree to our')}{' '}
              <a
                href='/terms'
                className='underline underline-offset-4 hover:text-primary'
              >
                {t('Terms of Service')}
              </a>{' '}
              {t('and')}{' '}
              <a
                href='/privacy'
                className='underline underline-offset-4 hover:text-primary'
              >
                {t('Privacy Policy')}
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

const LeftBlock = styled.div`
  background-image: url(${LoginDestopBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: auto;
`

export default AuthenticationPage
