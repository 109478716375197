import { FC } from 'react'
import { IOrganization } from 'models'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

type PropsType = {
  onChange: (organizationId: string) => void
  defaultValue?: string
  value: string
  organizationsData: IOrganization[]
}
const SelectOrganization: FC<PropsType> = ({
  onChange,
  organizationsData,
  value,
  defaultValue
}) => {
  return (
    <Select onValueChange={onChange} value={value} defaultValue={defaultValue}>
      <SelectTrigger className='w-44 fborder focus:ring-transparent'>
        <SelectValue placeholder='Select a organization' />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {organizationsData.map(
            (organization: IOrganization, index: number) => (
              <SelectItem key={index} value={organization._id}>
                <p className='font-medium'>{organization.name}</p>
              </SelectItem>
            )
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}

export default SelectOrganization
