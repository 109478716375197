import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GET_CURRENT_USER } from 'graphql/queries'

import { useQuery } from 'utils/adapters'
import { useAppDispatch } from 'utils/hooks'

import Events from 'components/organisms/Events'
import Loading from 'components/atoms/Loading'
import { USER_ROLE } from 'constants/userRole'
import { setStep } from '@/store/Events'
import { toast } from '@/components/ui/use-toast'
import { isAdmin, isRootAdmin } from '@/utils/helpers'
import { getActiveRelease, useReleaseStore } from '@/store/zustand/Releases'
import { getCurrentUser } from '../Users/mixins'
// import { NotiBanner } from '@/components/atoms/NotiBanner/noti-banner'

const Dashboard: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { release, loading } = useReleaseStore()

  // const [hasNewVersion, setHasNewVersion] = useState(false)
  // const turnOffNewReleaseVersionNotify = localStorage.getItem('showNewRelease')
  // const hasCheckNewVersionReleasePermission = isRootAdmin() || isAdmin()

  const { data: userData, loading: userLoading } = getCurrentUser()

  // useEffect(() => {
  //   if (hasCheckNewVersionReleasePermission) getActiveRelease()
  // }, [hasCheckNewVersionReleasePermission])

  // const checkHasNewVersion = () => {
  //   const currentVersion = localStorage.getItem('version')
  //   const newVersion = release.version
  //   return String(currentVersion).trim() !== String(newVersion).trim()
  // }

  // useEffect(() => {
  //   setHasNewVersion(checkHasNewVersion())
  // }, [release.version])

  // reset step to 0 when redirect /
  useEffect(() => {
    dispatch(setStep(0))
  }, [])

  const isAttendee = userData?.currentUser?.roles.includes(USER_ROLE.ATTENDEE)

  // const isDisplayNotification =
  //   hasCheckNewVersionReleasePermission &&
  //   (hasNewVersion || !turnOffNewReleaseVersionNotify)

  if (userLoading || loading) return <Loading />

  return (
    <>
      {/*  TODO: uncomment to display notify */}
      {/* {isDisplayNotification && (
        <NotiBanner
          title={release?.title}
          description={release?.description}
          version={release?.version}
        />
      )} */}
      <div className='max-w-screen-lg mx-auto flex flex-col gap-5 p-4'>
        {!userLoading && <Events isAttendee={isAttendee} />}
      </div>
    </>
  )
}

export default Dashboard
