import { create } from 'zustand'
import { ReleaseState } from './Release'
import { IRelease } from '@/models/IRelease'
import {
  apiGetActiveRelease,
  createRelease,
  getReleasesList,
} from '@/services/releasesServices'

export const useReleaseStore = create<ReleaseState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  releases: [],
  setReleases: (releases: IRelease[]) =>
    set({
      releases,
    }),
  loading: false,
  setLoading: (loading: boolean) => set({ loading }),
  release: {} as IRelease,
  setRelease: (release: IRelease) => set({ release }),
}))

export const getReleases = async () => {
  try {
    useReleaseStore.getState().setLoading(true)
    const { data } = await getReleasesList()
    if (data) {
      useReleaseStore.getState().setReleases(data.data)
      useReleaseStore.getState().setLoading(false)
    }
  } catch (error) {
    useReleaseStore.getState().setErrorMessage('')
  }
}

export const getActiveRelease = async () => {
  try {
    useReleaseStore.getState().setLoading(true)
    const { data } = await apiGetActiveRelease()
    if (data) {
      useReleaseStore.getState().setRelease(data.data)
      useReleaseStore.getState().setLoading(false)
    }
  } catch (error) {
    useReleaseStore.getState().setErrorMessage('')
  }
}

export const createNewRelease = async data => {
  try {
    const result = await createRelease(data)
    if (result) return result.data
  } catch (err: any) {
    useReleaseStore.getState().setErrorMessage(err?.response?.data?.message)
  }
}
