import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import TabItem from './TabItem'

type TabType = {
  isExpired: boolean
  activeTab: (index: number) => void
}

const TabList: FC<TabType> = ({ isExpired, activeTab }) => {
  const { t } = useTranslation()

  const TabItems = [
    {
      name: t('Active')
    },
    {
      name: t('Expired')
    }
  ]

  return (
    <div className=''>
      <div className='flex justify-center items-center'>
        {TabItems.map((item, index) => (
          <TabItem
            itemName={item.name}
            key={index}
            onClick={() => activeTab(index)}
            active={isExpired === !!index}
          />
        ))}
      </div>
    </div>
  )
}

export default TabList
