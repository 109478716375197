import { FC } from 'react'
import { styled } from 'utils/adapters'
import ContentLoader from 'react-content-loader'
import { responsive } from 'assets/scss/mixin'

const EventItemLoader: FC = props => {
  return (
    <ContentLoader
      speed={2}
      width={650}
      height={160}
      viewBox='0 0 650 160'
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="26" rx="3" ry="3" width="100%" height="16" />
      <rect x="0" y="56" rx="3" ry="3" width="100%" height="6" />
      <rect x="0" y="88" rx="3" ry="3" width="100%" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="100%" height="6" />
    </ContentLoader>
  )
}

const EventListLoader: FC = () => {
  const listLoader = new Array(4).fill(1)
  return (
    <Wrapper>
      {listLoader.map((_, index) => (
        <EventItemLoader key={index} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  width: 100%;
  ${responsive.md`
      gap: 0;
  `}
`

export default EventListLoader
