import { useMutation } from 'utils/adapters'
import { REGISTER_EVENT, UPDATE_BOOKING_INFO } from 'graphql/mutations'
import { setIsLoading, setStep, setTotalPrice } from 'store/Events'
import { useAppDispatch } from 'utils/hooks'
import { t } from 'i18next'
import { toast } from '@/components/ui/use-toast'

export const useMutationRegisterEvent = () => {
  const dispatch = useAppDispatch()
  const [mutateRegisterEvent] = useMutation(REGISTER_EVENT, {
    onCompleted: () => {
      dispatch(setStep(-1))
      dispatch(setTotalPrice(-1))
      sessionStorage.clear()
      console.log('registered successfully')
    },

    // onError: () => {
    //   toast({
    //     title: t('Error'),
    //     description: t('Your payment cannot be completed at this time'),
    //     variant: 'destructive',
    //   })
    // },
  })
  return {
    mutateRegisterEvent,
  }
}

export const useMutationEditBooking = () => {
  const dispatch = useAppDispatch()
  const [mutateUpdateBooking] = useMutation(UPDATE_BOOKING_INFO, {
    onCompleted: data => {
      if (data) {
        dispatch(setIsLoading(false))
        toast({
          description: t('Your booking information has been updated!'),
        })
      }
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return {
    mutateUpdateBooking,
  }
}
