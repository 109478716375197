import { request } from './request'

export const getListInvitationTemplateReq = (eventId: string) => {
  return request.request({
    method: 'GET',
    url: `/v1/events/${eventId}/invitation/templates`,
  })
}

export const getEventInvitationTemplateReq = (eventId: string) => {
  return request.request({
    method: 'GET',
    url: `/v1/events/${eventId}/invitation`,
  })
}

export const updateEventInvitationTemplateReq = (eventId: string, data) => {
  return request.request({
    method: 'POST',
    url: `/v1/events/${eventId}/invitation`,
    data,
  })
}
