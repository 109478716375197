import { FC, ReactNode, useState } from 'react'

import { UserOutLine } from '@/assets/images'
import LanguageDropdown from '@/components/organisms/language/language-dropdown'
import { getCurrentUser } from '@/components/pages/Users/mixins'
import { Button } from '@/components/ui/button'
import { USER_ROLE, USER_SCOPE } from '@/constants/userRole'
import { cn } from '@/lib/utils'
import { PATH_NAME } from '@/routes/routesMap'
import { logout } from '@/services/authServices'
import { setLoggedIn } from '@/store/Authentication'
import { useUserStore } from '@/store/zustand'
import { Link, useApolloClient, useHistory } from '@/utils/adapters'
import { isActiveRoute, isAttendee } from '@/utils/helpers'
import { useAppDispatch } from '@/utils/hooks'
import useOverlapVar from '@/utils/hooks/useNavbar'
import ErrorBoundary from 'components/containers/ErrorBoundary'
import {
  ChevronLeft,
  History,
  Landmark,
  LogOut,
  Menu,
  MessagesSquare,
  Plus,
  Rocket,
  Send,
  Settings,
  Tag,
  Ticket,
  Users,
  X,
} from 'lucide-react'
import { useTranslation } from 'react-i18next'
import AdminSidebar, { renderSideBarItem } from './AdminSidebar'
import SidebarPermission from './SidebarPermission'

type AdminLayoutProps = {
  children: ReactNode
  title: string
  displayBackIcon?: boolean
  displayAddEventButton?: boolean
}

const AdminLayout: FC<AdminLayoutProps> = ({
  children,
  title,
  displayBackIcon = false,
  displayAddEventButton = false,
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const client = useApolloClient()
  const { isCollapse } = useOverlapVar()

  const { data: user } = getCurrentUser()
  const organizationId = user?.currentUser?.organization?._id

  const [isOpenMenu, setIsOpenMenu] = useState(false)

  const handleGoBack = () => {
    history.goBack()
  }

  const navbarRoutes = [
    {
      path: PATH_NAME.DASHBOARD,
      name: t('Events'),
      icon: <Ticket width='20' height='20' />,
    },
    {
      path: PATH_NAME.USERS_MANAGEMENT,
      name: t('Users'),
      allowedScopes: [USER_SCOPE.USER_READ],
      allowedRoles: [USER_ROLE.ROOT_ADMIN, USER_ROLE.ADMIN],
      icon: <Users width='20' height='20' />,
    },
    {
      path: PATH_NAME.ORGANIZATION_MANAGEMENT,
      name: t('Organizations'),
      allowedRoles: [USER_ROLE.ROOT_ADMIN],
      icon: <Landmark width='20' height='20' />,
    },
    {
      path: `/organizations/admin/${organizationId}`,
      name: t('Organization'),
      allowedRoles: [USER_ROLE.ADMIN],
      ignoreRootAdmin: true,
      icon: <Landmark width='20' height='20' />,
    },
    {
      path: PATH_NAME.CUSTOM_FIELDS_LIST,
      name: t('library_label'),
      allowedRoles: [
        USER_ROLE.ADMIN,
        USER_ROLE.EVENT_ORGANISER,
        USER_ROLE.ROOT_ADMIN,
      ],
      icon: <Settings width='20' height='20' />,
    },
    {
      path: PATH_NAME.SEND_MAIL_LOG,
      name: t('Send Mail Log'),
      allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.ROOT_ADMIN],
      icon: <Send width='20' height='20' />,
    },
    {
      path: PATH_NAME.CHANGES_LOG,
      name: t('Changes Log'),
      allowedRoles: [USER_ROLE.ADMIN, USER_ROLE.ROOT_ADMIN],
      icon: <History width='20' height='20' />,
    },
    {
      path: PATH_NAME.FEEDBACKS,
      name: t('Feedbacks'),
      allowedRoles: [USER_ROLE.ROOT_ADMIN],
      icon: <MessagesSquare width='20' height='20' />,
    },
    {
      path: PATH_NAME.STATUS_MANAGEMENT,
      name: t('Custom Status'),
      allowedRoles: [USER_ROLE.ROOT_ADMIN, USER_ROLE.ADMIN],

      icon: <Tag width='20' height='20' />,
    },
    // {
    //   path: PATH_NAME.RELEASES,
    //   name: t('releases.title'),
    //   allowedRoles: [USER_ROLE.ROOT_ADMIN],
    //   icon: <Rocket width='20' height='20' />,
    // },
  ]

  const handleLogout = async () => {
    await logout()
    dispatch(setLoggedIn(false))
    useUserStore.getState().resetStore()
    localStorage.clear()
    sessionStorage.clear()
    client.resetStore()
  }

  return (
    <>
      <ErrorBoundary>
        <div>
          {/* sidebar */}
          <AdminSidebar navbarRoutes={navbarRoutes} />
          {/* header */}
          <div
            className={cn(
              'h-full w-full bg-lightPrimary dark:!bg-navy-900',
              isCollapse ? 'md:pl-[64px]' : 'md:pl-64'
            )}
          >
            <div>
              <header
                className={cn(
                  'flex border-b justify-between items-center h-[54px] fixed md:h-[80px] z-10 bg-white p-2',
                  isCollapse
                    ? 'w-full md:w-[calc(100%-64px)]'
                    : 'w-full md:w-[calc(100%-280px)]'
                )}
              >
                {!displayBackIcon && (
                  <div
                    className='block md:hidden'
                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                  >
                    {!isOpenMenu ? <Menu /> : <X />}
                  </div>
                )}

                {displayBackIcon && (
                  <button className='focus:outline-none'>
                    <div className='h-[22px] items-center font-medium text-palette-900 transition-all duration-300'>
                      <ChevronLeft onClick={handleGoBack} />
                    </div>
                  </button>
                )}

                <div className='text-primary w-full text-palette-800 text-xl font-bold text-center ml-auto mr-auto'>
                  {title}
                </div>
                <LanguageDropdown />

                {displayAddEventButton && !isAttendee() && (
                  <div className='text-primary right-12 absolute'>
                    <Button
                      className='rounded-10 hidden md:flex'
                      onClick={() => history.push(PATH_NAME.CREATE_EVENT)}
                    >
                      <Plus className='cursor-pointer mr-2 h-5' />
                      {t(' New Event')}
                    </Button>
                    <div className='p-1 rounded-full bg-primary block md:hidden'>
                      <Plus
                        className='cursor-pointer text-white'
                        onClick={() => history.push(PATH_NAME.CREATE_EVENT)}
                      />
                    </div>
                  </div>
                )}
              </header>
              {/* responsive menu */}
              {isOpenMenu && (
                <div className='fixed top-[54px] md:top-[80px] bg-white w-full z-50 md:hidden border-b'>
                  <div className='border-b'>
                    {navbarRoutes.map((route, i) => {
                      return (
                        <SidebarPermission
                          key={i}
                          allowedRoles={route?.allowedRoles}
                          allowedScopes={route?.allowedScopes}
                        >
                          {renderSideBarItem(route, false, isActiveRoute)}
                        </SidebarPermission>
                      )
                    })}
                  </div>

                  <Link
                    to={PATH_NAME.SETTINGS}
                    className='flex gap-2 items-center text-primary px-5 py-3'
                  >
                    <img
                      className='w-[20px] h-[20px] cursor-pointer'
                      src={UserOutLine}
                    />
                    <div className='mr-1 text-sm break-all'>
                      {user?.currentUser?.email}
                    </div>
                  </Link>
                  <div
                    className='flex gap-2 items-center text-primary px-5 py-3'
                    onClick={handleLogout}
                  >
                    <LogOut className='w-[20px] h-[20px] cursor-pointer' />
                    {t('Logout')}
                  </div>
                </div>
              )}
              {/* content */}
              <div className='px-8 py-4 pt-[70px] md:pt-[96px]'>{children}</div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </>
  )
}

export default AdminLayout
