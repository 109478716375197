import React from 'react'
import { useTranslation } from 'react-i18next'

import TwoFactorMethodsForm from './TwoFactorMethodsForm'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useUserStore } from '@/store/zustand'
import { TwoFactorAuthDisableForm } from './TwoFactorAuthDisableForm'
import { Badge } from '@/components/ui/badge'
import { DialogClose } from '@radix-ui/react-dialog'

interface IProps {}

export default function TwoFactorMethodsDialog(props: IProps) {
  const { t } = useTranslation()
  const { currentUser } = useUserStore.getState()
  const [open, setOpen] = React.useState(false)

  const function2FADisabled = import.meta.env.VITE_DISABLE_2FA === 'true'

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {currentUser?.is2FAEnabled ? (
          <Button variant='destructive'>{t('Disable 2FA')}</Button>
        ) : (
          <Button disabled={function2FADisabled}>
            {t('Enable 2FA')}
            {function2FADisabled && (
              <Badge variant='destructive' className='ml-2'>
                {t('Coming soon')}
              </Badge>
            )}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>{t('Two-Factor Authentication (2FA)')}</DialogTitle>

          {!currentUser?.phoneNumber && (
            <>
              <DialogDescription>
                {t('Before continuing, please update your phone number')}
              </DialogDescription>
              <DialogFooter>
                <DialogClose asChild>
                  <Button type='button' className='mt-[20px]'>
                    {t('Close')}
                  </Button>
                </DialogClose>
              </DialogFooter>
            </>
          )}
          {/* {t(
              'You can select your preferred method to receive the two- factor authentication code.'
            )} */}
        </DialogHeader>
        {currentUser?.phoneNumber && (
          <div className='grid gap-4 py-4'>
            {currentUser?.is2FAEnabled ? (
              <TwoFactorAuthDisableForm setOpenDialog={setOpen} />
            ) : (
              <TwoFactorMethodsForm isDialog setOpenDialog={setOpen} />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
