import { COLORS } from 'constants/colors'

export const hexToRgb = hex => {
  const match = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i)
  if (!match) {
    return null
  }

  return {
    r: parseInt(match[1], 16),
    g: parseInt(match[2], 16),
    b: parseInt(match[3], 16),
  }
}

export const setTextColorBasedOnBackground = bgColor => {
  if (bgColor) {
    // Convert hexadecimal color to RGB object
    const rgb = hexToRgb(bgColor)

    if (rgb) {
      // Calculate relative luminance (brightness) according to W3C formula
      const luminance = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255

      // Choose text color based on luminance
      return luminance > 0.5 ? COLORS.BLACK : COLORS.WHITE
    }
  }
  return COLORS.BLACK
}

export const isSimilarColor = (color1, color2) => {
  if (!color1 || !color2) return false

  const rgb1 = hexToRgb(color1)
  const rgb2 = hexToRgb(color2)

  if (!rgb1 || !rgb2) return false

  // Calculate luminance for both colors using same W3C formula
  const luminance1 = (0.2126 * rgb1.r + 0.7152 * rgb1.g + 0.0722 * rgb1.b) / 255
  const luminance2 = (0.2126 * rgb2.r + 0.7152 * rgb2.g + 0.0722 * rgb2.b) / 255

  // Compare luminance values with small threshold for floating point comparison
  return Math.abs(luminance1 - luminance2) < 0.2
}
