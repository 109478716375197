import React, { FC, lazy, useState } from 'react'
import { useTranslation } from 'react-i18next'

import moment from 'moment'

import {
  clearFormData,
  setFormData,
  setIsCreateCustomField,
  setStep,
} from 'store/Events'

import { CREATE_EVENT, UPDATE_EVENT } from 'graphql/mutations'

import { styled, useHistory, useMutation, useParams } from 'utils/adapters'
import { AdminEventStep, reorder } from 'utils/helpers'
import {
  useAppDispatch,
  useAppSelector,
  useOrganizationIdVar,
  useSearch,
} from 'utils/hooks'

import { CustomField } from 'models'

import { Button } from '@/components/ui/button'
import Modal from 'components/molecules/Modal'
import CustomFieldItem from 'components/pages/CustomField/components/CustomFieldItem'
import BookingWithPaymentForm from './BookingWithPaymentForm'

import { Separator } from '@/components/ui/separator'
import { toast } from '@/components/ui/use-toast'
import { cn } from '@/lib/utils'
import { PATH_NAME } from '@/routes/routesMap'
import useDateRange from '@/utils/hooks/useDateRange'
import { SORT_BY_TYPE, SORT_TYPE } from 'constants/sortEvent'
import { GET_EVENTS } from 'graphql/queries'
import { ChevronLeft, Plus } from 'lucide-react'
import { useRouteMatch } from 'react-router'
import { Icons } from '@/components/ui/icon'

const DragableItem = lazy(
  () => import('components/molecules/DragnDrop/DragableItem')
)
const DragnDrop = lazy(() => import('components/molecules/DragnDrop'))

interface ParamTypes {
  id: string
}

const EventCustomField: FC = () => {
  const { t } = useTranslation()
  const matchEditRoute = useRouteMatch(PATH_NAME.EDIT_EVENT)

  const { id } = useParams<ParamTypes>()
  const history = useHistory()
  const { organizationId } = useOrganizationIdVar()
  const { searchKey } = useSearch()
  const { formData, step } = useAppSelector(state => state.eventsReducer)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false)
  const { filterByDate } = useDateRange()
  const dispatch = useAppDispatch()

  const [mutateCreateEvent, { loading: createLoading }] = useMutation(
    CREATE_EVENT,
    {
      onCompleted: data => {
        if (data) {
          toast({
            description: t('Create event success!'),
          })
          history.push('/')
          resetPage()
        }
      },
      refetchQueries: [
        {
          query: GET_EVENTS,
          variables: {
            filter: {
              title: searchKey.length > 0 ? searchKey : '',
              isExpired:
                localStorage.getItem('tabState') === 'true' ? true : false,
              filterBy:
                localStorage.getItem('eventOrderBy') || SORT_BY_TYPE.START_DATE,
              direction:
                localStorage.getItem('eventDirection') || SORT_TYPE.DESC,
              ...(filterByDate.fromTime &&
                filterByDate.toTime && {
                  fromTime: filterByDate.fromTime.toISOString(),
                  toTime: filterByDate.toTime.toISOString(),
                }),
            },
            organizationId: organizationId ? organizationId : undefined,
          },
        },
      ],
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  const [mutateUpdateEvent, { loading: updateLoading }] = useMutation(
    UPDATE_EVENT,
    {
      onCompleted: data => {
        if (data && !updateLoading) {
          toast({
            title: t('Update event success!'),
            variant: 'default',
          })
          history.push(`/admin/events/${id}`)
          resetPage()
        }
      },

      onError: err => {
        toast({
          title: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  const onRemove = (index: number) => {
    dispatch(
      setFormData({
        ...formData,
        customFields: formData?.customFields.filter(
          (_: any, i: number) => i !== index
        ),
      })
    )
  }

  const onEdit = (field: any, index: number) => {
    localStorage.setItem('selected_custom_field', JSON.stringify(field))
    localStorage.setItem('selected_custom_field_index', JSON.stringify(index))
    dispatch(setStep(step + 1))
  }

  const addCustomField = () => {
    dispatch(setIsCreateCustomField(true))
    dispatch(setStep(AdminEventStep.AddCustomField))
  }

  const onSubmit = () => {
    const country = formData?.address?.country?.value
    const eventForm = {
      ...formData,
      capacity: formData.capacity !== null ? Number(formData.capacity) : null,
      address: {
        ...formData.address,
        country,
      },
      startEvent: moment(formData.startEvent).toDate().toISOString(),
      endEvent: moment(formData.endEvent).toDate().toISOString(),
    }

    delete eventForm['attendeesCount']
    delete eventForm['webcastEventId']

    let totalCustomFieldFee = 0
    eventForm?.customFields?.forEach((customField: CustomField) => {
      customField?.options?.forEach(
        option => (totalCustomFieldFee += option?.fee)
      )
      return +totalCustomFieldFee
    })

    if (
      formData.isBookable ||
      (!formData.isBookable && +totalCustomFieldFee === 0)
    ) {
      if (id) {
        mutateUpdateEvent({
          variables: {
            updateEventInput: {
              ...eventForm,
              organization: eventForm.organization._id,
            },
          },
        })
      } else {
        mutateCreateEvent({
          variables: {
            createEventInput: {
              ...eventForm,
              organization: localStorage.getItem('organizationId'),
            },
          },
        })
      }
    } else {
      return setShowConfirmModal(true)
    }
  }

  const resetPage = () => {
    dispatch(setStep(AdminEventStep.EventDetailForm))
    dispatch(clearFormData())
    localStorage.removeItem('selected_custom_field_index')
    localStorage.removeItem('selected_custom_field')
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return
    }

    const _items = reorder(
      formData?.customFields,
      result.source.index,
      result.destination.index
    )
    dispatch(
      setFormData({
        ...formData,
        customFields: _items,
      })
    )
  }

  return (
    <Wrapper>
      <ChevronLeft
        onClick={() => dispatch(setStep(step - 1))}
        className={cn(
          'absolute top-[15px] left-14 cursor-pointer md:top-[28px] md:left-24',
          !matchEditRoute && 'text-white left-5 md:left-8'
        )}
      />

      <div>
        <h3 className='text-lg font-medium'>{t('Custom Fields')}</h3>
        <p className='text-sm text-muted-foreground'>
          {t('Customize event custom fields.')}
        </p>
      </div>
      <Separator />
      <Container className='space-y-6'>
        <div className='flex items-center space-x-2 justify-end mt-6'>
          <Button onClick={addCustomField}>
            <Plus className='cursor-pointer mr-2 h-5' />
            {t('Add Custom Field')}
          </Button>
        </div>
        {formData?.customFields?.length > 0 ? (
          <DragnDrop onDragEnd={onDragEnd}>
            {formData?.customFields?.map((item: CustomField, index: number) => (
              <DragableItem key={index} item={item} index={index}>
                <Item
                  {...item}
                  onRemove={() => onRemove(index)}
                  onEdit={() => onEdit(item, index)}
                />
              </DragableItem>
            ))}
          </DragnDrop>
        ) : (
          <span>{t('Currently no custom fields are existing')}</span>
        )}

        <SubmitButton type='submit' onClick={onSubmit}>
          {(createLoading || updateLoading) && (
            <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
          )}
          {id ? t('Update Event') : t('Create Event')}
        </SubmitButton>
        <StyledModal
          isShow={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={() => {
            setShowPaymentModal(true)
            setShowConfirmModal(false)
          }}
        >
          <p style={{ maxWidth: '400px', padding: '10px' }}>
            {t('Choose custom field with fee')}
          </p>
        </StyledModal>

        <StyledModal
          isShow={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          hideFooter
        >
          <div style={{ padding: '10px' }}>
            <BookingWithPaymentForm
              onClose={() => setShowPaymentModal(false)}
              triggerCloseModal={() => setShowPaymentModal(false)}
            />
          </div>
        </StyledModal>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Item = styled(CustomFieldItem)`
  border: none;

  &:hover {
    border: none;
  }
`

const SubmitButton = styled(Button)`
  margin: 0 auto;
  width: 100%;
`

const StyledModal = styled(Modal)`
  max-width: 400px;
`
export default EventCustomField
