import { FC, lazy } from 'react'
import { styled } from 'utils/adapters'
import DOMPurify from 'dompurify'

const TermModalContent = lazy(() => import('./TermModalContent'))

const OrganizationTermModalContent: FC<{ terms: string }> = ({ terms }) => {
  if (!terms) {
    return <TermModalContent />
  }

  return (
    <>
      <Wrapper
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(terms) as string,
        }}
      />
    </>
  )
}

const Wrapper = styled.div`
  overflow-y: auto;
  max-height: 500px;
  padding: 0 5px;

  a {
    color: #b7deff;
    text-decoration: underline;
  }

  li {
    padding-left: 8px;
  }
`

export default OrganizationTermModalContent
