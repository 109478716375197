import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { useMutation } from '@/utils/adapters'
import { RESET_PASSWORD } from '@/graphql/mutations'
import { toast } from '@/components/ui/use-toast'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Icons } from '@/components/ui/icon'
import { MESSAGE } from '@/constants/message'
import { MAX_LENGTH, PASSWORD_REGEX } from '@/constants/regex'

interface ResetPasswordFormProps {
  token?: string
  onBackToLogin: () => void
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  token,
  onBackToLogin,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isShowPassword, setIsShowPassword] = useState(false)

  const formSchema = z
    .object({
      password: z
        .string()
        .min(8, { message: t(MESSAGE.INVALID_PASSWORD) })
        .max(MAX_LENGTH.PASSWORD, {
          message: t(MESSAGE.TOO_LONG, { max: MAX_LENGTH.PASSWORD }),
        })
        .regex(PASSWORD_REGEX, {
          message: t(MESSAGE.INVALID_PASSWORD),
        }),
      confirmPassword: z.string(),
    })
    .refine(data => data.password === data.confirmPassword, {
      message: t(MESSAGE.PASSWORDS_DO_NOT_MATCH),
      path: ['confirmPassword'],
    })

  type FormValues = z.infer<typeof formSchema>

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const [mutateResetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      toast({
        description: t('Your password has been changed!'),
      })
      onBackToLogin()
    },
    onError: ({ message }) => {
      toast({
        title: t('Error'),
        description: t(message),
        variant: 'destructive',
      })
      setIsLoading(false)
    },
  })

  const onSubmit = (values: FormValues) => {
    setIsLoading(true)
    mutateResetPassword({
      variables: {
        token,
        password: values.password,
      },
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
        <FormField
          control={form.control}
          name='password'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('New password')}</FormLabel>
              <FormControl>
                <div className='relative'>
                  <Input
                    {...field}
                    type={isShowPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                  />
                  <Button
                    type='button'
                    variant='ghost'
                    size='sm'
                    className='absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent'
                    onClick={() => setIsShowPassword(!isShowPassword)}
                  >
                    {isShowPassword ? (
                      <Icons.eyeOff className='h-4 w-4' />
                    ) : (
                      <Icons.eye className='h-4 w-4' />
                    )}
                  </Button>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='confirmPassword'
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('Confirm new password')}</FormLabel>
              <FormControl>
                <Input {...field} type='password' autoComplete='new-password' />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className='flex space-x-4'>
          <Button
            type='button'
            variant='outline'
            onClick={onBackToLogin}
            disabled={isLoading}
          >
            {t('Back')}
          </Button>
          <Button type='submit' disabled={isLoading} className='flex-1'>
            {isLoading && (
              <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
            )}
            {t('Reset password')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
