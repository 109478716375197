import { FC } from 'react'
import { styled } from 'utils/adapters'
import { COLORS } from 'constants/colors'

type CheckboxType = {
  name?: any
  value?: any
  className?: string
  checked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  disabled?: boolean
  color?: string
}

const Checkbox: FC<CheckboxType> = ({
  name,
  value,
  className,
  checked,
  onChange,
  disabled,
  color,
  ...props
}) => (
  <label>
    <CheckboxContainer className={className}>
      <HiddenCheckbox
        name={name}
        value={value}
        checked={checked || false}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <StyledCheckbox checked={checked} disabled={disabled} color={color}>
        <Icon viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  </label>
)

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  -webkit-clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div<{
  checked?: boolean
  disabled?: boolean
  color?: string
}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props =>
    props.disabled
      ? COLORS.BACKGROUND_DISABLED
      : props.checked
      ? props.color || COLORS.BLUE
      : '#757575'};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #f0ecec;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

export default Checkbox
