import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { USER_ROLE } from '@/constants/userRole'
import { useLazyQuery } from '@/utils/adapters'
import { EXPORT_EVENT_ATTENDEES } from '@/graphql/queries'
import XLSX from 'xlsx'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getCSVFileName = (text: string | undefined) => {
  if (!text) {
    return 'attendees'
  }

  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

/*
 * Check if user has permission with specific role or scope
 * The role is priority over the scope
 * @param user
 * @param scope
 * @param role
 * */
export const hasPermission = (
  user: any,
  scope: string | undefined,
  role?: string | undefined
) => {
  if (user?.roles?.includes(USER_ROLE.ROOT_ADMIN)) {
    return true
  }

  if (role && scope) {
    return !!(
      user?.roles?.includes(role as string) &&
      user?.scopes?.includes(scope as string)
    )
  }

  if (user?.roles?.includes(role as string)) {
    return true
  }

  return !!user?.scopes?.includes(scope as string)
}

export const useExportEventAttendee = (title: string | undefined) => {
  const [exportEventAttendee, { data, variables }] = useLazyQuery(
    EXPORT_EVENT_ATTENDEES,
    {
      onCompleted() {
        if (data) {
          const { exportEventAttendeesSelectField } = data
          if (
            exportEventAttendeesSelectField.status === 200 &&
            exportEventAttendeesSelectField.data
          ) {
            const response = JSON.parse(exportEventAttendeesSelectField.data)
            const ws = XLSX.utils.aoa_to_sheet(response)

            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, 'Event')
            const fileName = variables?.status
              ? `${getCSVFileName(title)}-${variables?.status}`
              : getCSVFileName(title)
            XLSX.writeFile(wb, `${fileName}.xlsx`)
          }
        }
      },
      onError: err => {
        err.graphQLErrors.map((error: any) => error?.statusCode === 404)
      },
    }
  )
  return exportEventAttendee
}
