import { LANGUAGE } from 'constants/language'
import { CountryDe } from 'constants/country_de'
import { CountryTr } from 'constants/country_tr'
import { Country } from 'constants/country'

export const getListCountriesByLanguage = (language: string | null) => {
  switch (language) {
    case LANGUAGE.GERMAN:
      return CountryDe
    case LANGUAGE.TURKISH:
      return CountryTr
    default:
      return Country
  }
}

export const germanCountry = (language: string | null = LANGUAGE.ENGLISH) => {
  return getListCountriesByLanguage(language).find(({ code }) => code === 'DE')
}
const getCountryByCode = (
  countryList: { code: string; name: string }[],
  countryCode: string
) => countryList.find(country => country.code === countryCode)

export const getCountryByName = (
  countryName: string,
  language: string | null = LANGUAGE.ENGLISH
) => {
  const countries = [...CountryDe, ...CountryTr, ...Country]
  const countryCode = countries.find(
    country => country.name === countryName
  )?.code
  if (!countryCode) return null

  switch (language) {
    case LANGUAGE.GERMAN:
      return getCountryByCode(CountryDe, countryCode)
    case LANGUAGE.TURKISH:
      return getCountryByCode(CountryTr, countryCode)
    default:
      return getCountryByCode(Country, countryCode)
  }
}
