import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import BasicTable from '@/components/organisms/BasicTable'
import { Badge } from '@/components/ui/badge'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { toast } from '@/components/ui/use-toast'
import { FULL_DATE_TIMEZONE_FORMAT } from '@/constants/time'
import { defaultTimezone, isAdmin } from '@/utils/helpers'
import { ColumnDef } from '@tanstack/react-table'
import FilterColumnModal from 'components/organisms/FilterModal'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import {
  changesLogFilterOption,
  emailConsumerTypeOptions,
} from 'constants/changesLog'
import { Copy } from 'lucide-react'
import moment from 'moment'
import CopyToClipboard from 'react-copy-to-clipboard'
import {
  getMailLogsData,
  resetFiltersSendMailLog,
  useSendMailLogStore,
} from 'store/zustand/ChangesLog'

export const displayTooltipContent = (subject: string, body: string) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild className='cursor-pointer'>
          <p className='truncate max-w-[200px]'>{subject}</p>
        </TooltipTrigger>
        <TooltipContent className='whitespace-pre-wrap overflow-auto max-h-[400px] max-w-[400px]'>
          <div>{body}</div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

const SendMailLog: FC = () => {
  const { t } = useTranslation()
  const lang = localStorage.getItem('i18nextLng') as string
  const user = JSON.parse(sessionStorage.getItem('user') as string)
  const organizationId = user?.organization?._id

  const columnVisibility = {
    organization: !isAdmin(),
  }

  const sendMailLogData = useSendMailLogStore(state => state.sendMailLogData)
  const totalItems = useSendMailLogStore(state => state.totalItems)

  const { filters, setFilters } = useSendMailLogStore()

  const copyLinkEvent = () => {
    toast({
      variant: 'default',
      description: t('Copied to clipboard!'),
    })
  }

  useEffect(() => {
    const params = {
      page: filters.page,
      limit: filters.limit,
      timeFrame: filters.timeFrame,
      ...(isAdmin() && {
        orgId: organizationId,
      }),
    }
    getMailLogsData(params)
  }, [filters])

  useEffect(() => {
    return () => resetFiltersSendMailLog()
  }, [])

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: t('No'),
        cell: ({ row }) => <b>{row?.index + 1}</b>,
      },
      {
        header: t('To.'),
        accessorKey: 'to',
      },
      {
        header: t('Status'),
        accessorKey: 'sent',
        cell: ({ row }) =>
          row?.original?.sent ? (
            <Badge variant='secondary'>{t('Successful')}</Badge>
          ) : (
            <Badge variant='destructive'>
              {displayTooltipContent(t('Failed'), row?.original?.errors)}
            </Badge>
          ),
      },
      {
        header: t('Type'),
        accessorKey: 'consumerType',
        cell: ({ row }) => (
          <b>{row?.original?.consumerType.replace(/_/g, ' ')}</b>
        ),
        Filter: ({ column }) => (
          <FilterColumnModal
            column={column}
            filterData={emailConsumerTypeOptions}
          />
        ),
        enableSorting: false,
        // allow multi filter
        filterFn: (row, id, value) => {
          if (!value || value.length === 0) {
            return true
          }
          return value.includes(row.getValue(id))
        },
        // size: 180,
      },
      {
        header: t('Last updated'),
        accessorKey: 'lastUpdated',
        cell: ({ row }) => {
          const displayDate = moment(
            new Date(Number(row?.original?.lastUpdated))
          )
            .tz(defaultTimezone)
            .format(FULL_DATE_TIMEZONE_FORMAT)
          return (
            <div>
              {displayTooltipContent(
                String(moment(Number(row?.original?.lastUpdated)).fromNow()),
                `${displayDate} GMT`
              )}
            </div>
          )
        },
        // size: 200,
      },
      {
        header: t('Mail subject'),
        accessorKey: 'subject',
        // size: 200,
      },
      {
        header: t('Mail body'),
        accessorKey: 'body',
        cell: ({ row }) => {
          return (
            <div className='flex gap-2 items-center'>
              {displayTooltipContent(row?.original?.body, row?.original?.body)}
              {row?.original?.body && (
                <div className=' justify-end'>
                  <CopyToClipboard
                    text={row?.original?.body}
                    onCopy={copyLinkEvent}
                  >
                    <Copy className='text-gray-400 cursor-pointer' />
                  </CopyToClipboard>
                </div>
              )}
            </div>
          )
        },
        size: 200,
      },
      {
        header: t('Organization'),
        accessorKey: 'organization',
        enableSorting: false,
        // size: 200,
      },
      {
        header: t('Message'),
        accessorKey: 'message',
        cell: ({ row }) => (
          <>
            <p>{row?.original?.message}</p>
            <br />
            <p>
              {row?.original?.items && JSON.stringify(row?.original?.items)}
            </p>
          </>
        ),
        // size: 500,
      },
    ],
    [lang]
  )

  return (
    <div className='flex flex-col'>
      <div className='mx-auto overflow-x-auto p-[16px] max-w-full md:p-[40px] w-full'>
        <div className='mb-[40px] flex justify-between'>
          <Select
            defaultValue={String(changesLogFilterOption[0].value)}
            onValueChange={value =>
              setFilters({ ...filters, timeFrame: value })
            }
          >
            <SelectTrigger className='w-[180px]'>
              <SelectValue placeholder='Select filter' />
            </SelectTrigger>
            <SelectContent>
              {changesLogFilterOption.map(option => (
                <SelectItem key={option.value} value={String(option.value)}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <BasicTable
          columns={columns}
          data={sendMailLogData}
          filters={filters}
          setFilters={setFilters}
          columnVisibility={columnVisibility}
          totalItems={totalItems}
        />
      </div>
    </div>
  )
}

export default SendMailLog
