import {
  ACTIVE_USER,
  REMOVE_USER,
  TRANSFORM_USER_ROLE,
  UPDATE_USER,
} from 'graphql/mutations'
import { GET_ORGANIZATIONS } from 'graphql/queries'
import {
  GET_ALL_ORGANISERS,
  GET_ALL_ROLES,
  GET_ALL_SCOPES,
  GET_ALL_USERS,
  GET_CURRENT_USER,
  GET_EVENT_ORGANIZER_SCOPES,
  GET_SELECTED_USER,
} from 'graphql/queries/user'
import { t } from 'i18next'
import { useHistory, useLazyQuery, useMutation, useQuery } from 'utils/adapters'
import { useOrganizationIdVar } from 'utils/hooks'
import { isRootAdmin } from 'utils/helpers'

import { useReactiveVar } from '@apollo/client'
import { userFilterByRoleVar, userFilterByStatusVar } from 'graphql/variables'
import { toast } from '@/components/ui/use-toast'
import { PATH_NAME } from '@/routes/routesMap'
import { encryptArray } from '@/utils/helpers/role'

export const useUserFilterVar = () => {
  const role = useReactiveVar(userFilterByRoleVar)
  const setUserFilterByRole = (role: string | undefined | any) => {
    userFilterByRoleVar(role)
  }
  const status = useReactiveVar(userFilterByStatusVar)
  const setUserFilterByStatus = (status: string | undefined | any) => {
    userFilterByStatusVar(status)
  }
  return { role, status, setUserFilterByRole, setUserFilterByStatus }
}

export const getOrganizations = () => {
  if (!isRootAdmin()) {
    return {
      organizationsData: null,
    }
  }
  const { organizationId } = useOrganizationIdVar()
  const { data: organizationsData, loading: organizationLoading } = useQuery(
    GET_ORGANIZATIONS,

    {
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
      variables: {
        organizationId: organizationId || undefined,
      },
    }
  )

  return {
    organizationsData,
    organizationLoading,
  }
}

export const getAllUsers = () => {
  const { organizationId } = useOrganizationIdVar()
  const { role, status } = useUserFilterVar()

  const { data, loading } = useQuery(GET_ALL_USERS, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
    variables: {
      organizationId: organizationId ? organizationId : null,
      role: role ? role : undefined,
      status: status ?? undefined,
    },
  })
  return {
    data,
    loading,
  }
}

export const getAllOrganisers = () => {
  const [queryAllOrganisers, { data, loading }] = useLazyQuery(
    GET_ALL_ORGANISERS,
    {
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )
  return {
    queryAllOrganisers,
    data,
    loading,
  }
}

export const getSelectedUser = (userId: string) => {
  const { data, loading } = useQuery(GET_SELECTED_USER, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
    skip: !userId,
  })

  return { data, loading }
}

export const getAllRoles = () => {
  const { data, loading } = useQuery(GET_ALL_ROLES, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return { data, loading }
}

export const getAllScopes = () => {
  const { data, loading } = useQuery(GET_ALL_SCOPES, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return { data, loading }
}

export const getEventOrganizerScopes = () => {
  const { data, loading } = useQuery(GET_EVENT_ORGANIZER_SCOPES, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return { data, loading }
}

export const removeUser = () => {
  const [mutateRemoveUser, { data, loading }] = useMutation(REMOVE_USER, {
    onCompleted() {
      toast({
        description: t('Deactivate user success'),
      })
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return {
    mutateRemoveUser,
    data,
    loading,
  }
}

export const activeUser = () => {
  const [mutateActiveUser, { data, loading }] = useMutation(ACTIVE_USER, {
    onCompleted() {
      toast({
        description: t('Active user success'),
      })
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return {
    mutateActiveUser,
    data,
    loading,
  }
}

export const getCurrentUser = () => {
  const { data, loading } = useQuery(GET_CURRENT_USER, {
    onCompleted() {
      if (data) {
        const encryptedUser = {
          ...data.currentUser,
          roles: encryptArray(data.currentUser.roles),
          scopes: encryptArray(data.currentUser.scopes),
        }

        sessionStorage.setItem('user', JSON.stringify(encryptedUser))
      }
    },
    onError: ({ message }) => {
      toast({
        title: t(message),
        variant: 'destructive',
      })
    },
  })

  return {
    data,
    loading,
  }
}

export const updateUser = () => {
  const history = useHistory()
  const [mutateUpdateUser, { loading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      toast({
        description: t('Update user success!'),
      })
      history.push(PATH_NAME.USERS_MANAGEMENT)
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return { mutateUpdateUser, loading }
}

export const transformUserRole = (organizationId: string) => {
  const history = useHistory()
  const [mutateTransformUserRole, { loading }] = useMutation(
    TRANSFORM_USER_ROLE,
    {
      onCompleted: () => {
        toast({
          description: t('users.transform_user_role_success'),
        })
        history.push(PATH_NAME.USERS_MANAGEMENT)
      },
      update: (cache, { data: { transformUserRole } }) => {
        if (organizationId) {
          const { users } = cache.readQuery<any>({
            query: GET_ALL_USERS,
            variables: { organizationId },
          })

          cache.writeQuery({
            query: GET_ALL_USERS,
            data: { users: [...users, transformUserRole] },
            variables: { organizationId },
          })
        }
      },
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  return { mutateTransformUserRole, loading }
}
