import { useQuery } from '@apollo/client'
import { GET_CURRENT_USER } from 'graphql/queries'
import { some } from 'lodash'
import { IUser } from 'models'
import { useEffect, useState } from 'react'
import { USER_ROLE } from '@/constants/userRole'

export type SidebarPermissionProps = React.PropsWithChildren<{
  allowedRoles?: string[]
  allowedScopes?: string[]
  ignoreRootAdmin?: boolean
}>

export default function SidebarPermission({
  allowedRoles,
  allowedScopes,
  ignoreRootAdmin = false,
  children,
}: SidebarPermissionProps) {
  const { data } = useQuery(GET_CURRENT_USER)
  const [user, setUser] = useState<IUser>()

  useEffect(() => {
    if (data) {
      setUser(data.currentUser)
    }
  }, [data])

  const isHasPermissionRole = allowedRoles
    ? some(allowedRoles, permission => user?.roles?.includes(permission))
    : true
  const isHasPermissionScope = allowedScopes
    ? some(allowedScopes, scope => user?.scopes?.includes(scope))
    : true

  if (
    (isHasPermissionRole && isHasPermissionScope) ||
    (!ignoreRootAdmin && user?.roles?.includes(USER_ROLE.ROOT_ADMIN))
  ) {
    return <>{children}</>
  }

  return null

  // const isHasPermissionRole = some(allowedRoles, permission =>
  //   user?.roles?.includes(permission)
  // )
  // const isHasPermissionScope = some(allowedScopes, scope =>
  //   user?.scopes?.includes(scope)
  // )

  // if (allowedRoles && allowedScopes)
  //   return isHasPermissionRole && isHasPermissionScope ? <>{children}</> : null

  // if (allowedRoles) return isHasPermissionRole ? <>{children}</> : null

  // if (allowedScopes) return isHasPermissionScope ? <>{children}</> : null
}
