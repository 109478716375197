import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { IEvent } from '@/models'
import { EventType } from 'constants/events'
import { CalendarDays, Clock, MapPin } from 'lucide-react'
import {
  defaultTimezone,
  getEventDateByTimeZone,
  getEventTime,
  transformAddress,
} from 'utils/helpers'

const EventInformation: React.FC<{
  event: IEvent
}> = ({ event }) => {
  const lang = localStorage.getItem('i18nextLng') as string
  const { t } = useTranslation()

  const isDigitalEvent = event?.type === EventType.DIGITAL
  let tz = event?.timezone || defaultTimezone

  if (isDigitalEvent) {
    const { timeZone: clientTimeZone } = Intl.DateTimeFormat().resolvedOptions()
    tz = clientTimeZone
  }

  const address = transformAddress(
    event?.address?.street as string,
    '',
    event?.address?.city as string,
    event?.address?.country as string
  )

  const location = event?.hotelName ? `${event.hotelName}, ${address}` : address

  const { isTheSameDay, startTime, endTime, utcOffset } = getEventTime(
    event?.mainLanguage,
    tz,
    event?.startEvent,
    event?.endEvent
  )

  return (
    <div className='grid gap-6'>
      <Card>
        <CardHeader className='border-b pb-8'>
          <h1 className='text-xl font-bold'>{event?.title}</h1>
        </CardHeader>
        <CardContent className='grid gap-6 pt-6'>
          <div className='flex flex-wrap gap-6 text-base flex-col'>
            <div className='flex items-center gap-2'>
              <CalendarDays className='h-4 w-4 text-muted-foreground' />
              <span>
                {isTheSameDay
                  ? getEventDateByTimeZone(event?.startEvent, lang, tz)
                  : `${getEventDateByTimeZone(
                      event?.startEvent,
                      lang,
                      tz
                    )} - ${getEventDateByTimeZone(event?.endEvent, lang, tz)}`}
              </span>
            </div>
            {isTheSameDay && (
              <div className='flex items-center gap-2'>
                <Clock className='h-4 w-4 text-muted-foreground' />
                <span>
                  {isTheSameDay &&
                    `${startTime} - ${endTime} (GMT${utcOffset})`}
                </span>
              </div>
            )}

            <div className='flex gap-2'>
              <MapPin className='h-4 w-4 text-muted-foreground mt-1 flex-shrink-0' />
              <span>
                {event?.type === EventType.DIGITAL ? t('Digital') : location}
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default EventInformation
