import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { styled } from 'utils/adapters'
import {
  defaultTimezone,
  getEventDateByTimeZone,
  getEventTime,
  priceFormat,
  transformAddress,
} from 'utils/helpers'
import DOMPurify from 'dompurify'
import { CalendarDays, Clock, MapPin } from 'lucide-react'

import { IEvent } from 'models'

import { EventType } from 'constants/events'
import { COLORS } from 'constants/colors'

import 'moment/min/locales.min'
import { cn } from '@/lib/utils'
import { BadgeCurrency } from '@/components/atoms/BadgeCurrency'

type LeftBlockType = {
  event: IEvent
  copyToClipBoard?: React.ReactNode
  editBookingBlock?: React.ReactNode
  copyWebcastUrlJoinLinks?: React.ReactNode
}

const EventDetail: FC<LeftBlockType> = ({
  event,
  copyToClipBoard,
  copyWebcastUrlJoinLinks,
  editBookingBlock,
  children,
}) => {
  const { t } = useTranslation()

  const lang = localStorage.getItem('i18nextLng') as string
  const logoWidth = event?.eventSetting?.logoSize?.split('x')[0]

  const isDigitalEvent = event?.type === EventType.DIGITAL
  let tz = event?.timezone || defaultTimezone

  if (isDigitalEvent) {
    const { timeZone: clientTimeZone } = Intl.DateTimeFormat().resolvedOptions()
    tz = clientTimeZone
  }

  const fontColor = event?.eventSetting?.fontColor || COLORS.BLUE

  const { isTheSameDay, startTime, endTime, utcOffset, isTheSameTime } =
    getEventTime(event?.mainLanguage, tz, event?.startEvent, event?.endEvent)

  return (
    <div
      className='flex flex-col gap-4 p-6 max-w-screen-md'
      style={{ color: fontColor }}
    >
      <div>
        {event?.eventSetting?.logo && (
          <Logo width={logoWidth}>
            <img alt='logo' src={event?.eventSetting?.logo} />
          </Logo>
        )}
        <h2 className='text-4xl font-bold tracking-tight sm:text-6xl mt-4'>
          {event?.title}
        </h2>
        <div className='mt-6 flex flex-col gap-2'>
          {!isDigitalEvent ? (
            <>
              <div className='flex items-center gap-2 justify-start'>
                <MapPin width='16' height='16' />
                <p className='text-base'>
                  {event?.hotelName ? `${event?.hotelName}, ` : ''}
                  {transformAddress(
                    event?.address?.street as string,
                    '',
                    event?.address?.city as string,
                    event?.address?.country as string
                  )}
                </p>
              </div>
              <div className='flex items-center gap-2 justify-start	'>
                <CalendarDays width='16' height='16' />
                <p className='text-base'>
                  {getEventDateByTimeZone(event?.startEvent, lang, tz)}
                  {!isTheSameDay &&
                    ` - ${getEventDateByTimeZone(event?.endEvent, lang, tz)}`}
                </p>
              </div>
              {isTheSameDay && (
                <div className='flex items-center gap-2 justify-start'>
                  <Clock />
                  <p className='text-base'>
                    {isTheSameTime
                      ? `${startTime} (GMT${utcOffset})`
                      : `${startTime} - ${endTime} (GMT${utcOffset})`}
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className='flex items-center gap-2 justify-start	'>
                <CalendarDays width='16' height='16' />
                <p className='text-base'>
                  {getEventDateByTimeZone(event?.startEvent, lang, tz)}{' '}
                  {!isTheSameDay &&
                    ` - ${getEventDateByTimeZone(event?.endEvent, lang, tz)}`}
                </p>
              </div>
              {isTheSameDay && (
                <div className='flex items-center gap-2 justify-start'>
                  <Clock width='16' height='16' />
                  <p className='text-base'>
                    {`${startTime} - ${endTime} (GMT${utcOffset})`}
                  </p>
                </div>
              )}
            </>
          )}
          {!event?.isBookable ||
            (event?.price !== 0 && (
              <div className='flex items-center gap-2 justify-start'>
                <BadgeCurrency currency={event?.currency} />
                <p className='text-base'>
                  {priceFormat(event?.price, lang)} / {t('person')}
                </p>
              </div>
            ))}
        </div>
      </div>

      {event?.description && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(event?.description) as string,
          }}
          className={cn('text-base leading-8')}
        />
      )}

      {editBookingBlock}
      {copyToClipBoard}
      {copyWebcastUrlJoinLinks}

      <div className='mt-6'>{children}</div>
    </div>
  )
}

const Logo = styled.div<{ width?: string }>`
  width: ${props => (props.width ? `${props.width}px` : '50%')};
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
`

export default EventDetail
