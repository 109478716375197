import Modal from '@/components/molecules/Modal'
import { getCurrentUser } from '@/components/pages/Users/mixins'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { logout } from '@/services/authServices'
import { useUserStore } from '@/store/zustand'
import { isActiveRoute, isAttendee, isRootAdmin } from '@/utils/helpers'
import useOverlapVar from '@/utils/hooks/useNavbar'
import { LogoIcon, UserOutLine } from 'assets/images'
import { t } from 'i18next'
import { cn } from 'lib/utils'
import { ChevronsLeft, ChevronsRight, LogOut, User } from 'lucide-react'
import { useState } from 'react'
import { setLoggedIn } from 'store/Authentication'
import { Link, useApolloClient, useHistory } from 'utils/adapters'
import { useAppDispatch } from 'utils/hooks'
import Icon from '../../atoms/Icon'
import SidebarPermission from './SidebarPermission'
import { PATH_NAME } from '@/routes/routesMap'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

export const renderSideBarItem = (route, isCollapse, isActiveRoute) => {
  if (isCollapse)
    return (
      <div className='min-w-max'>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger>
              <Link
                to={route.path}
                className={`relative pointer flex items-center space-x-4 text-primary from-sky-600 to-cyan-400 hover:text-primary hover:bg-muted px-5 py-3 ${
                  isActiveRoute(route.path) ? 'bg-primary text-white' : ''
                }`}
              >
                <div
                  className={cn(
                    'flex space-x-4 items-center',
                    !isActiveRoute(route.path) ? 'side-icon' : ''
                  )}
                >
                  {route.icon}
                </div>
              </Link>
            </TooltipTrigger>
            <TooltipContent side='right'>
              <p className='text-sm'>{route.name}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    )
  return (
    <div className='min-w-max'>
      <Link
        to={route.path}
        className={`relative pointer flex items-center space-x-4 text-primary from-sky-600 to-cyan-400 hover:text-primary hover:bg-muted px-5 py-3 ${
          isActiveRoute(route.path) ? 'bg-primary text-white' : ''
        }`}
      >
        <div
          className={cn(
            'flex space-x-4 items-center',
            !isActiveRoute(route.path) ? 'side-icon' : ''
          )}
        >
          {route.icon}
          {!isCollapse && (
            <div className='-mr-1 text-sm font-semibold	'>{route.name}</div>
          )}
        </div>
      </Link>
    </div>
  )
}

const AdminSidebar = ({ navbarRoutes }) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const client = useApolloClient()

  const { data: user, loading } = getCurrentUser()

  const { isCollapse, setIsCollapse } = useOverlapVar()

  const [showModalLogout, setShowModalLogout] = useState(false)

  const handleLogout = async () => {
    await logout()
    dispatch(setLoggedIn(false))
    useUserStore.getState().resetStore()
    localStorage.clear()
    sessionStorage.clear()
    client.resetStore()
  }

  return (
    <nav
      className={cn(
        'sidebar min-h-screen overflow-hidden border-r hover:shadow-lg h-screen fixed hidden md:block',
        isCollapse ? 'w-[64px]' : 'w-64'
      )}
    >
      <div className='flex h-screen flex-col justify-between pt-2 pb-6'>
        <div>
          <div
            className='flex items-center px-5 justify-end cursor-pointer'
            onClick={() => setIsCollapse(!isCollapse)}
          >
            {isCollapse ? <ChevronsRight /> : <ChevronsLeft />}
          </div>

          {!loading && (
            <div
              className={cn(
                'flex items-center space-x-4 mt-3',
                isCollapse ? 'flex-col px-0' : 'px-5'
              )}
            >
              <Link to='/dashboard'>
                {isRootAdmin() || !user?.currentUser?.organization?.logo ? (
                  <Icon
                    className='logo'
                    source={LogoIcon}
                    height='32px'
                    width='32px'
                    onClick={() => history.push('/')}
                  />
                ) : (
                  <img
                    src={user.currentUser.organization.logo}
                    alt='Organization Logo'
                    className='max-h-8'
                  />
                )}
              </Link>

              {!isCollapse && (
                <div className='text-primary text-bold font-medium'>
                  {isRootAdmin()
                    ? 'ConferenceDirect'
                    : user?.currentUser?.organization?.name}
                </div>
              )}
            </div>
          )}

          <ul className='mt-3 space-y-2 tracking-wide p-0'>
            {navbarRoutes.map((route, i) => {
              return (
                <SidebarPermission
                  key={i}
                  allowedRoles={route?.allowedRoles}
                  allowedScopes={route?.allowedScopes}
                  ignoreRootAdmin={route?.ignoreRootAdmin}
                >
                  {renderSideBarItem(route, isCollapse, isActiveRoute)}
                </SidebarPermission>
              )
            })}
          </ul>
        </div>

        <div
          className={cn(
            'flex gap-2 px-5 py-3 items-center text-primary relative',
            isCollapse ? 'flex-col' : 'flex-row'
          )}
        >
          <div className={cn('flex gap-2')}>
            {isCollapse ? (
              <Popover>
                <PopoverTrigger>
                  <img
                    className='w-[20px] h-[20px] cursor-pointer'
                    src={UserOutLine}
                  />
                </PopoverTrigger>

                <PopoverContent className='w-60 bg-[#fff] border p-4 rounded-md z-20 absolute bottom-8 -left-4'>
                  <div className='space-y-2 flex items-center space-x-2 justify-between'>
                    <User className='w-12' />
                    <Link to={PATH_NAME.SETTINGS}>
                      <div className='text-sm break-all m-0'>
                        {user?.currentUser?.email}
                      </div>
                    </Link>
                  </div>
                </PopoverContent>
              </Popover>
            ) : (
              <>
                <Link
                  to={PATH_NAME.SETTINGS}
                  className='flex gap-2 items-center'
                >
                  <img
                    className='w-[20px] h-[20px] cursor-pointer'
                    src={UserOutLine}
                  />
                  <div className='mr-1 text-sm break-all'>
                    {user?.currentUser?.email}
                  </div>
                </Link>
              </>
            )}
          </div>
          <div>
            <LogOut
              onClick={() => setShowModalLogout(true)}
              className='w-[20px] h-[20px] cursor-pointer'
            />
          </div>
        </div>
      </div>
      <Modal
        isShow={showModalLogout}
        isInfoModal
        description={t('Are you sure you want to log out?')}
        onConfirm={handleLogout}
        onClose={() => setShowModalLogout(false)}
      />
    </nav>
  )
}

export default AdminSidebar
