export enum MESSAGE {
  CONFIRM_BACK = 'Your data has not been save! Are you sure to go back?',
  INVALID_EMAIL = 'The email address entered is invalid',
  INVALID_HOTEL_BOOKING_URL = 'Url must start with https://',
  INVALID_PHONE_NUMBER = 'Phone number can only contain numbers and hyphens and spaces',
  INVALID_ZIP_CODE = 'Zip code can only contain numbers and hyphens',
  INVALID_TIME = 'Invalid time',
  TOO_LONG = 'The length of the property is too long. The maximum length is {{max}}',
  INVALID_PASSWORD = 'Password must be at least eight characters long and contain at least one letter, one number, and one special character',
  REQUIRED_FIELD = 'This field is required!',
  REQUIRED_OPTION = 'Select one of the options!',
  REQUIRED_MULTI_OPTIONS = 'Select at least 1 option!',
  CONFIRM_DELETE = 'This data will be remove',
  CONFIRM_DEACTIVE = 'This data will be deactive',
  CONFIRM_ACTIVE = 'This data will be activation',
  WARNING = 'Warning!',
  PASSWORDS_DO_NOT_MATCH = 'Passwords do not match',
}

export enum ERROR_CODE {
  ACCOUNT_DEACTIVATED = '1002',
}
