import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'

import Modal from 'components/molecules/Modal'
import { styled, useMutation } from 'utils/adapters'
import { GET_CANCELLATION_FEE } from 'graphql/queries'
import Loading from 'components/atoms/Loading'
import { Currency } from 'models'
import { renderSymbolCurrency } from 'utils/helpers'
import { CANCEL_BOOKING } from 'graphql/mutations'
import { useAppDispatch } from 'utils/hooks'
import Input from 'components/atoms/Input'
import { toast } from '@/components/ui/use-toast'

interface IProps {
  isShowCancelBookingModal: boolean
  setIsShowCancelBookingModal: React.Dispatch<React.SetStateAction<boolean>>
  _id: string
  currency: Currency | undefined
}

const CancelModal: FC<IProps> = ({
  _id,
  isShowCancelBookingModal,
  setIsShowCancelBookingModal,
  currency,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [reason, setReason] = useState<string>('default reason')

  const { data, loading } = useQuery(GET_CANCELLATION_FEE, {
    variables: {
      eventId: _id,
    },
    skip: !isShowCancelBookingModal,
  })

  const [onCancelBooking] = useMutation(CANCEL_BOOKING, {
    onCompleted({ cancelBooking }) {
      if (cancelBooking?.message) {
        toast({
          description: t('Cancel booking success!'),
        })
      }
    },
    onError: err => {
      err.graphQLErrors.map(({ message }) => {
        toast({
          title: t('Error'),
          description: t(message),
          variant: 'destructive',
        })
      })
    },
  })

  const onConfirmCancelEvent = (eventId: string) => {
    onCancelBooking({
      variables: {
        reason,
        eventId,
      },
    })
    setIsShowCancelBookingModal(false)
  }

  if (!isShowCancelBookingModal) {
    return null
  }

  const onChangeReason = (event: any) => {
    setReason(event.target.value)
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Modal
      isShow={isShowCancelBookingModal}
      title={t('Are you sure to cancel this event?')}
      onClose={() => setIsShowCancelBookingModal(false)}
      onConfirm={() => onConfirmCancelEvent(_id)}
    >
      <Content>
        <Input label={'Reason'} type={'text'} onChange={onChangeReason} />

        <span>
          {data?.cancellationFee?.fee &&
            t(
              'The cancellation fee will be {{fee}} and the refund amount will be {{refundFee}}.',
              {
                fee: `${data?.cancellationFee?.fee} ${renderSymbolCurrency(
                  currency
                )}`,
                refundFee: `${
                  data?.cancellationFee?.refundFee
                } ${renderSymbolCurrency(currency)}`,
              }
            )}{' '}
          {t(
            'Please note that it may take up to 3-5 business days for the refund to be processed and appear on your account.'
          )}
        </span>
      </Content>
    </Modal>
  )
}

const Content = styled.div`
  padding: 10px;
`
export default CancelModal
