import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import debounce from 'lodash/debounce'

import { useHistory } from 'utils/adapters'
import { useOrganizationIdVar, useSearch } from 'utils/hooks'
import { cn } from '@/lib/utils'
import { Search } from 'lucide-react'

type SearchbarProps = {
  className?: string
  setRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined
}

const Searchbar: FC<SearchbarProps> = ({ className, setRef }) => {
  const { t } = useTranslation()

  const history = useHistory()
  const { setSearchKey } = useSearch()
  const { organizationId } = useOrganizationIdVar()

  const inputRef = useRef<any>(undefined)

  const getSearchData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value)
    if (e.target.value) {
      addQuery('query', e.target.value)
    }
  }

  useEffect(() => {
    if (!inputRef.current.value) {
      setSearchKey('')
    }
  }, [inputRef])

  const debounceSearch = useCallback(debounce(getSearchData, 1500), [
    organizationId
  ])

  const onSearchEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      removeQuery('query')
      debounceSearch(e)
      return
    }

    debounceSearch(e)
  }

  const addQuery = (key: string, value: string) => {
    const pathname = location.pathname
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(key, value)

    history.push({
      pathname: pathname,
      search: searchParams.toString()
    })
  }

  const removeQuery = (key: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete(key)
    history.push({
      search: searchParams.toString()
    })
  }

  return (
    <div className='w-full max-w-lg mx-auto relative' ref={setRef}>
      <div
        className={cn(
          'flex h-10 items-center rounded-md border border-input bg-white pl-3 text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2',
          className,
          'flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50'
        )}
      >
        <Search className='h-[16px] w-[16px]' />
        <input
          type='search'
          ref={inputRef}
          className='w-full p-2 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
          onChange={e => {
            onSearchEvent(e)
          }}
          placeholder={t('Search')}
        />
      </div>
    </div>
  )
}

export default Searchbar
