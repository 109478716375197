import { IStatus } from '@/models/IStatus'
import { request } from './request'

export const getListStatusReq = (orgId: string) => {
  return request.request({
    method: 'GET',
    url: `/v1/admin/organizations/${orgId}/attendee_statuses`,
  })
}

export const createStatusReq = (orgId: string, data: IStatus) => {
  return request.request({
    method: 'POST',
    url: `/v1/admin/organizations/${orgId}/attendee_statuses`,
    data,
  })
}

export const updateStatusReq = (orgId: string, data: IStatus) => {
  return request.request({
    method: 'PUT',
    url: `/v1/admin/organizations/${orgId}/attendee_statuses`,
    data,
  })
}

export const deleteStatusReq = (orgId: string, id: string) => {
  return request.request({
    method: 'DELETE',
    url: `/v1/admin/organizations/${orgId}/attendee_statuses/${id}`,
  })
}

export const getEventListStatusReq = (eventId: string) => {
  return request.request({
    method: 'GET',
    url: `/v2/admin/events/${eventId}/custom_statuses`,
  })
}
