import { createGlobalStyle } from 'utils/adapters'

export default createGlobalStyle`
#root {
  height: 100%;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div,
h1,
h2,
h3,
a,
p,
span {
  line-height: normal;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}


::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 

::-webkit-scrollbar-thumb {
  background: #384047; 
  border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
  background: #384047; 
}

// revert tailwind css 
ul, ol {
  padding: revert;
  list-style: revert;
}

`
