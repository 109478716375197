import { useReactiveVar } from '@apollo/client'
import { searchKeyVar } from 'graphql/variables'

const useSearchKey = () => {
  const searchKey = useReactiveVar(searchKeyVar)
  const setSearchKey = (searchKey: string) => {
    searchKeyVar(searchKey)
  }

  return {
    searchKey,
    setSearchKey,
  }
}

export default useSearchKey
