import { gql } from 'utils/adapters'

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    user(email: $email) {
      _id
      firstName
      lastName
      email
      company
    }
  }
`
export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    currentUser {
      _id
      company
      email
      firstName
      lastName
      roles
      scopes
      userName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
      organization {
        _id
        name
        logo
      }
    }
  }
`

export const GET_SELECTED_USER = gql`
  query GetSelectedUser($userId: ID!) {
    user(id: $userId) {
      _id
      company
      email
      firstName
      isRemoved
      lastName
      roles
      scopes
      userName
    }
  }
`

export const GET_ALL_USERS = gql`
  query GetAllUsers($organizationId: ID, $role: Role, $status: Boolean) {
    users(organizationId: $organizationId, role: $role, status: $status) {
      _id
      roles
      firstName
      lastName
      isRemoved
      email
      company
      userName
      organization {
        _id
        email
        name
      }
    }
  }
`

export const GET_ALL_ORGANISERS = gql`
  query GetAllOrganisers($organizationId: ID) {
    organisers(organizationId: $organizationId) {
      _id
      company
      email
      firstName
      isRemoved
      lastName
      roles
      scopes
      userName
    }
  }
`

export const GET_ALL_ROLES = gql`
  query GetAllRoles {
    getAllRoles {
      data
    }
  }
`

export const GET_ALL_SCOPES = gql`
  query GetAllScopes {
    getAllScopes {
      data
    }
  }
`

export const GET_EVENT_ORGANIZER_SCOPES = gql`
  query GetEventOrganizerScopes {
    getEventOrganizerScopes {
      data
    }
  }
`
