import { useReactiveVar } from '@apollo/client'
import { filterByDateVar } from 'graphql/variables'

const useDateRange = () => {
  const filterByDate = useReactiveVar(filterByDateVar)

  const setFilterByDate = (
    fromTime: Date | undefined,
    toTime: Date | undefined
  ) => {
    filterByDateVar({ fromTime, toTime })
  }

  return {
    filterByDate,
    setFilterByDate,
  }
}

export default useDateRange
