import Checkbox from '@/components/atoms/Checkbox'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { SelectAdapter } from '@/components/ui/select-adapter'
import { Switch } from '@/components/ui/switch'
import { CUSTOM_FIELD_TYPE, customFieldType } from '@/constants/customFieldType'
import { Field } from '@/utils/adapters'
import { useAppSelector } from '@/utils/hooks'
import { Trash2 } from 'lucide-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EventType } from '@/constants/events'
import HelpText from '@/components/atoms/HelpText'

interface ToggleDefaultFieldsProps {}

export const ToggleDefaultFields: FC<ToggleDefaultFieldsProps> = () => {
  const { t } = useTranslation()
  const { formData } = useAppSelector(state => state.eventsReducer)

  const defaultToggleFields = {
    firstName: {
      enabled: true,
      required: true,
      title: t('First Name'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    lastName: {
      enabled: true,
      required: true,
      title: t('Last Name'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    salutation: {
      enabled: true,
      required: true,
      title: t('Salutation'),
      type: CUSTOM_FIELD_TYPE.SELECT_BOX,
    },
    professional: {
      enabled: true,
      required: false,
      title: t('Academic Title'),
      type: CUSTOM_FIELD_TYPE.SELECT_BOX,
    },
    jobTitle: {
      enabled: false,
      required: false,
      title: t('Job title'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    company: {
      enabled: false,
      required: true,
      title: t('Company'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    'address.street': {
      enabled: true,
      required: formData.type === EventType.CLASSIC,
      title: t('Street'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    'address.zipCode': {
      enabled: true,
      required: formData.type === EventType.CLASSIC,
      title: t('Zip'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    'address.city': {
      enabled: true,
      required: formData.type === EventType.CLASSIC,
      title: t('City'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    'address.country': {
      enabled: true,
      required: formData.type === EventType.CLASSIC,
      title: t('Country'),
      type: CUSTOM_FIELD_TYPE.SELECT_BOX,
    },
    email: {
      enabled: true,
      required: true,
      title: t('Email'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    workPhone: {
      enabled: false,
      required: false,
      title: t('Work Phone'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    mobilePhone: {
      enabled: false,
      required: false,
      title: t('Mobile phone'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
    fax: {
      enabled: false,
      required: false,
      title: t('Fax'),
      type: CUSTOM_FIELD_TYPE.TEXT,
    },
  }

  return (
    <div className='mb-3'>
      {Object.entries(defaultToggleFields).map(
        ([key, { enabled, required, title, type }], index) => (
          <div
            key={index}
            className='flex gap-6 justify-between bg-slate-100 px-4 py-6 mb-4'
          >
            <div className='font-bold'>
              <HelpText
                text={`Q${index + 1}`}
                helpMessage={t(
                  'custom_selections.help_message_default_question'
                )}
              />
            </div>
            <div className='w-[85%]'>
              <div className='flex gap-6 flex-col md:flex-row'>
                <div className='z-999'>
                  <div className='w-[200px] md-w-[170px]'>
                    <SelectAdapter
                      options={customFieldType}
                      title={t('Type')}
                      isRequired
                      input={{
                        value: type,
                        label: type,
                      }}
                      disabled
                      meta={undefined}
                    />
                  </div>
                </div>
                <div className='w-[80%] min-w-[200px]'>
                  <div className='space-y-2'>
                    <FormLabel isRequired>{title}</FormLabel>
                    <Input disabled placeholder={title} className='bg-white' />
                  </div>
                </div>
              </div>
              <div className='flex gap-10 mt-6 items-center'>
                <Field
                  name={`toggleDefaultFields.${key}.required`}
                  type='checkbox'
                  defaultValue={required}
                >
                  {({ input, meta }: any) => (
                    <div className='flex gap-2'>
                      <Checkbox {...input} disabled={key === 'email'} />
                      <div className=''>{t('Is required?')}</div>
                    </div>
                  )}
                </Field>
                <Field
                  name={`toggleDefaultFields.${key}.enabled`}
                  formatOnBlur
                  className='h-full'
                  defaultValue={enabled}
                >
                  {({ input, meta }: any) => {
                    return (
                      <FormItem className='flex flex-row gap-4'>
                        <FormLabel meta={meta} className='text-base'>
                          {t('custom_selections.enable_label')}
                        </FormLabel>
                        <div className=''>
                          <Switch
                            disabled={key === 'email'}
                            defaultChecked={enabled}
                            checked={input.value}
                            onCheckedChange={value => input.onChange(value)}
                          />
                        </div>
                      </FormItem>
                    )
                  }}
                </Field>
              </div>
            </div>
            <Trash2 className='w-[20px] text-gray-600 cursor-pointer opacity-0' />
          </div>
        )
      )}
    </div>
  )
}
