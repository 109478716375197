import {
  IAttendeeFeedback,
  IEventFeedbackStatistics,
  IFeedbackEvent,
} from 'models/IFeedback'
import {
  clientSendEventFeedbackReq,
  createEventFeedbackReq,
  getAttendeeFeedbackReq,
  getEventFeedbackReq,
  getEventFeedbackStatisticsReq,
  getListEventFeedbackReq,
} from 'services/feedbacksServices'
import { create } from 'zustand'
import { FeedbacksState } from './Feedback'
import { toast } from '@/components/ui/use-toast'
import { t } from 'i18next'
import { FEEDBACK_FORM_TYPE } from '@/constants'

export const useFeedbackEventStore = create<FeedbacksState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  feedbackEventData: null,
  setFeedbackEventData: (feedbackEventData: IFeedbackEvent) =>
    set({
      feedbackEventData,
    }),
  listEventFeedback: [],
  setListEventFeedback: (listEventFeedback: IAttendeeFeedback[]) =>
    set({
      listEventFeedback,
    }),
  totalFeedback: 0,
  setTotalFeedback: (totalFeedback: number) =>
    set({
      totalFeedback,
    }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) =>
    set({
      isLoading,
    }),
  eventFeedbackStatistics: [],
  setEventFeedbackStatistics: (
    eventFeedbackStatistics: IEventFeedbackStatistics[]
  ) =>
    set({
      eventFeedbackStatistics,
    }),
  questionList: [],
  setQuestionList: (questionList: IEventFeedbackStatistics[]) =>
    set({
      questionList,
    }),
  attendeeFeedbackInfo: null,
  setAttendeeFeedbackInfo: (attendeeFeedbackInfo: IAttendeeFeedback) =>
    set({
      attendeeFeedbackInfo,
    }),
}))

export const getFeedbackEventData = async (eventId: string) => {
  try {
    useFeedbackEventStore.getState().setIsLoading(true)
    const { data } = await getEventFeedbackReq(eventId)
    if (data) {
      useFeedbackEventStore.getState().setFeedbackEventData(data)
    } else {
      useFeedbackEventStore
        .getState()
        .setFeedbackEventData({} as IFeedbackEvent)
    }
  } catch (error) {
    useFeedbackEventStore.getState().setErrorMessage('')
  } finally {
    useFeedbackEventStore.getState().setIsLoading(false)
  }
}

export const createFeedbackEvent = async (eventId: string, data: any) => {
  try {
    useFeedbackEventStore.getState().setIsLoading(true)
    const res = await createEventFeedbackReq(eventId, data)
    if (res) {
      toast({
        variant: 'default',
        description: t('feedback_form.update_feedback_form_event_success'),
      })
      return res.data
    }
  } catch (error) {
    toast({
      variant: 'destructive',
      description: t('feedback_form.update_feedback_form_event_failed'),
    })
  } finally {
    useFeedbackEventStore.getState().setIsLoading(false)
  }
}

export const clientCreateFeedbackEvent = async (eventId: string, data: any) => {
  try {
    useFeedbackEventStore.getState().setIsLoading(true)
    const res = await clientSendEventFeedbackReq(eventId, data)
    if (res) {
      toast({
        variant: 'default',
        description: t('feedback_form.update_feedback_form_event_success'),
      })
    }
  } catch (error) {
    toast({
      variant: 'destructive',
      description: t('feedback_form.update_feedback_form_event_failed'),
    })
  } finally {
    useFeedbackEventStore.getState().setIsLoading(false)
  }
}

export const getListEventFeedback = async (eventId: string) => {
  try {
    useFeedbackEventStore.getState().setIsLoading(true)
    const { data } = await getListEventFeedbackReq(eventId)
    if (data) {
      useFeedbackEventStore.getState().setListEventFeedback(data.data.feedbacks)
      useFeedbackEventStore.getState().setTotalFeedback(data.data.total)
    }
  } catch (error) {
    toast({
      variant: 'destructive',
      description: t('feedback_form.update_feedback_form_event_failed'),
    })
  } finally {
    useFeedbackEventStore.getState().setIsLoading(false)
  }
}

export const getEventFeedbackStatistics = async (eventId: string) => {
  try {
    useFeedbackEventStore.getState().setIsLoading(true)
    const { data } = await getEventFeedbackStatisticsReq(eventId)
    if (data) {
      const statistics: IEventFeedbackStatistics[] = data.data.questionAnalytics
        .filter(
          ({ questionType, data }) =>
            questionType === FEEDBACK_FORM_TYPE.RATING && data.average
        )
        .map(
          ({ questionId, questionLabel, questionType, data: { average } }) => ({
            questionId,
            questionLabel,
            questionType,
            average,
          })
        )

      useFeedbackEventStore.getState().setEventFeedbackStatistics(statistics)

      useFeedbackEventStore
        .getState()
        .setQuestionList(data.data.questionAnalytics)
    }
  } catch (error) {
    toast({
      variant: 'destructive',
      description: t('feedback_form.update_feedback_form_event_failed'),
    })
  } finally {
    useFeedbackEventStore.getState().setIsLoading(false)
  }
}

export const getAttendeeFeedback = async (
  eventId: string,
  attendeeId: string
) => {
  try {
    useFeedbackEventStore.getState().setIsLoading(true)
    const { data } = await getAttendeeFeedbackReq(eventId, attendeeId)
    if (data) {
      useFeedbackEventStore.getState().setAttendeeFeedbackInfo(data)
    }
  } catch (error) {
    toast({
      variant: 'destructive',
      description: t('feedback_form.update_feedback_form_event_failed'),
    })
  } finally {
    useFeedbackEventStore.getState().setIsLoading(false)
  }
}
export const submitAttendeeFeedback = async (eventId: string, data: any) => {
  try {
    const res = await clientSendEventFeedbackReq(eventId, data)
  } catch (error) {
    toast({
      variant: 'destructive',
      description: t('client_feedback.submit_feedback_failed'),
    })
  }
}
