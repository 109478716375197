import { FC } from 'react'
import { FieldMetaState, styled } from 'utils/adapters'
import Validation from 'components/atoms/ValidationText'
import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'

type InputType = {
  id?: string
  name?: any
  label: string
  type: string
  meta?: FieldMetaState<any>
  value?: string | number | readonly string[] | undefined
  isRequired?: boolean
  className?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  disabled?: boolean
}

const Input: FC<InputType> = ({
  id,
  name,
  label,
  type,
  value,
  isRequired,
  meta,
  onChange,
  className,
  children,
  disabled,
  ...props
}) => {
  return (
    <>
      <Wrapper className={className} touched={meta?.error}>
        <InputField
          id={id}
          name={name}
          error={meta?.error && meta.touched}
          type={type || 'text'}
          placeholder=' '
          value={value}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
        <Label htmlFor='name'>
          {label ? label : ''} {isRequired && label && <span>*</span>}
        </Label>
        <TrailingIcon>{children}</TrailingIcon>
        {meta?.error && meta?.touched && <ValidationText error={meta?.error} />}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ touched?: any }>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${props => (props.touched ? '1.5em' : '10px')};
`

const Label = styled.label`
  position: absolute;
  top: 1em;
  left: 8px;
  user-select: none;
  color: #999;
  pointer-events: none;
  transition: 0.25s ease;

  span {
    color: red;
  }
`

const InputField = styled.input<{ error?: string }>`
  padding: 14px 8px 4px 8px !important;
  border: 0;
  border-bottom: 1px solid
    ${props => (props.error ? COLORS.ERROR : COLORS.BORDER_FIELD)};
  height: 40px !important;
  transition: 0.25s ease;
  outline: none;
  font-size: 16px;
  margin-top: 0;

  &:focus {
    border-color: ${COLORS.BLUE};
  }

  &:not(:placeholder-shown) + ${Label}, &:focus + ${Label} {
    top: 0;
    display: inline-block;
    background-color: transparent;
    font-size: 12px;
  }

  &:-webkit-autofill {
    // &:-webkit-autofill:hover,
    // &:-webkit-autofill:focus,
    // &:-webkit-autofill:active {
    height: 30px;

    &:not(:placeholder-shown) + ${Label}, &:focus + ${Label} {
      font-size: 12px;
    }
  }

  -webkit-box-shadow: 0 0 0px 1000px white inset;

  ${responsive.md`
    font-size: 14px;
  `}
`

const ValidationText = styled(Validation)`
  margin-top: 4px;
`

const TrailingIcon = styled.div`
  position: absolute;
  right: 0;
  top: 15%;
  transform: translateY(50%);
  cursor: pointer;

  img {
    filter: none;
    opacity: 0.8;
  }

  &:hover {
    opacity: 0.8;
  }
`

export default Input
