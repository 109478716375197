import { FC } from 'react'
import { styled } from 'utils/adapters'
import { Row } from '../AttendeeInfo'
import { CustomField } from 'models'
import { COLORS } from 'constants/colors'
import { responsive } from 'assets/scss/mixin'

type CustomFieldItemType = {
  borderColor: string
  customField: CustomField
  errorMessage?: React.ReactNode
  title?: React.ReactNode
  type?: string
  isDangerouslyTitle?: boolean
}
const CustomFieldItem: FC<CustomFieldItemType> = ({
  customField,
  borderColor,
  errorMessage,
  type,
  children,
  isDangerouslyTitle,
}) => {
  return (
    <Card borderColor={borderColor}>
      {!type ? (
        <Title>
          {isDangerouslyTitle ? (
            <>
              <span
                className='ql-link pointer-events-auto'
                dangerouslySetInnerHTML={{
                  __html: customField.title,
                }}
              />
            </>
          ) : (
            <>{customField.title} </>
          )}{' '}
          {customField.isRequired && (
            <span className='font-bold text-[#d9534f]'>*</span>
          )}
        </Title>
      ) : null}

      {children}
      {errorMessage}
    </Card>
  )
}

const Card = styled(Row)<{ borderColor?: string }>`
  padding: 10px 8px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid ${props => props.borderColor};
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  ${responsive.sm`
      width: 100%;
      padding: 4px;
  `};
`

const Title = styled.span`
  font-size: 14px;
  color: #757575;
  margin-bottom: 4px;

  ${responsive.md`
    font-size: 12px;
  `}
`

export default CustomFieldItem
