import { FC } from 'react'
import { Link } from 'utils/adapters'

const Error404View: FC = () => {
  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 dark:bg-gray-800'>
      <div className='max-w-md w-full space-y-8 text-center'>
        <svg
          className=' h-24 w-24 mx-auto text-red-500 dark:text-red-400'
          fill='none'
          height='24'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          viewBox='0 0 24 24'
          width='24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='12' cy='12' r='10' />
          <line x1='12' x2='12' y1='8' y2='12' />
          <line x1='12' x2='12.01' y1='16' y2='16' />
        </svg>
        <h2 className='mt-6 text-3xl font-extrabold text-gray-900 dark:text-white'>
          404 - Page Not Found
        </h2>
        <p className='mt-2 text-sm text-gray-600 dark:text-gray-300'>
          The page you are looking for does not exist. It might have been moved
          or deleted.
        </p>
        <Link
          className='mt-6 inline-flex h-10 items-center justify-center rounded-md bg-primary px-8 text-sm font-medium text-white shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-blue-700 disabled:pointer-events-none disabled:opacity-50 dark:bg-blue-400 dark:hover:bg-blue-500 dark:focus-visible:ring-blue-200'
          to='/'
        >
          Go Home
        </Link>
      </div>
    </div>
  )
}

export default Error404View
