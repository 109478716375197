import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from 'graphql/mutations/organization'
import {
  FETCH_PLACEHOLDERS,
  GET_CURRENT_USER,
  GET_ORGANIZATIONS,
} from 'graphql/queries'

import { Field, Form, useHistory, useMutation, useQuery } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import {
  capitalizeFirstLetter,
  composeValidators,
  defaultTimezone,
  emailValidation,
  maxLengthValidation,
  requiredValidation,
  trimWhiteSpace,
} from 'utils/helpers'

import { Button } from '@/components/ui/button'
import { alphabets } from 'constants/alphabets'
import SelectAdapter from 'components/atoms/Select'
import { Language } from 'constants/language'
import { IDailyReportConfig, IEmailCredentials } from 'models'
import { UserRole } from '@/constants/userRole'
import Editor from 'components/atoms/Editor'
import Loading from '@/components/atoms/Loading'
import { setEmailPlaceHolders } from '@/store/Events'
import { toast } from '@/components/ui/use-toast'
import { RenderSwitch } from '@/components/atoms/NewSwitch/new-switch'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { Icons } from '@/components/ui/icon'
import HelpText from '@/components/atoms/HelpText'
import { Label } from '@/components/ui/label'
import { IMAGE_MAX_SIZE } from '@/constants'
import { uploadImageReq } from '@/services/uploadServices'
import DotsLoading from '@/components/atoms/DotsLoading'
import { XCircle } from 'lucide-react'

type OrganizationInput = {
  name: string
  email: string
  capacity: number
  eventSourceId: string
  hasConfirmedMail: boolean
  sendDailyRegistrationReport: boolean
  dailyReportConfig: IDailyReportConfig
  emailCredentials: IEmailCredentials
  terms: string
  defaultConfirmationEmail: string
  logo: string
}

const renderEditor: FC<{ input: any }> = ({ input }) => {
  return (
    <Editor
      value={input.value}
      onChange={content => {
        input.onChange(content)
      }}
      isMention={false}
      className='content-editor'
    />
  )
}

const renderConfirmationEmailEditor: FC<{
  input: any
}> = ({ input }) => {
  const { emailPlaceHolders } = useAppSelector(state => state.eventsReducer)

  if (!emailPlaceHolders) {
    return <Loading />
  }

  return (
    <Editor
      className='defaultConfirmationEmail'
      value={input.value}
      onChange={content => {
        input.onChange(content)
      }}
      isMention={true}
      emailPlaceHolders={emailPlaceHolders}
      // emailPlaceHolders={data?.emailPlaceHolders?.filter(
      //   ({ name }) => name !== '$eventJoinLink'
      // )}
    />
  )
}

const OrganizationForm: FC<{
  id: string
  userRole: UserRole
  organizationData: any
}> = ({ id, userRole, organizationData }) => {
  const { t } = useTranslation()
  const [logoPreview, setLogoPreview] = useState('')
  const [updateLogoLoading, setUpdateLogoLoading] = useState(false)
  const [logoErrMsg, setLogoErrMsg] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState<any>(
    organizationData?.organization?.dailyReportConfig?.timezone?.value ||
      defaultTimezone
  )

  useEffect(() => {
    setLogoPreview(organizationData?.organization?.logo)
  }, [])

  const { data } = useQuery(FETCH_PLACEHOLDERS)

  useEffect(() => {
    if (data) {
      const { fetchPlaceHolders } = data
      dispatch(setEmailPlaceHolders(fetchPlaceHolders))
    }
  }, [data])

  const dispatch = useAppDispatch()
  const history = useHistory()

  const required = (value: any) =>
    value ? undefined : t('This field is required')

  const isRootAdmin = userRole === UserRole.RootAdmin

  const [mutateUpdateOrganization, { loading: updateLoading }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      onCompleted: () => {
        toast({
          description: t('Update organization success!'),
        })
        const path = isRootAdmin ? '/organizations' : '/dashboard'
        history.push(path)
      },
      refetchQueries: [{ query: GET_CURRENT_USER }],

      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  const [mutateCreateOrganization, { loading: createLoading }] = useMutation(
    CREATE_ORGANIZATION,
    {
      onCompleted: () => {
        toast({
          description: t('Create organization success!'),
        })
        history.push('/organizations')
      },

      update: (cache, { data: { createOrganization } }) => {
        const { organizations } = cache.readQuery<any>({
          query: GET_ORGANIZATIONS,
        })

        cache.writeQuery({
          query: GET_ORGANIZATIONS,
          data: { organizations: [...organizations, createOrganization] },
        })
      },

      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  const onSubmit = (values: OrganizationInput) => {
    const formData = {
      ...values,
      name: values.name.trim(),
      email: values.email.trim(),
      capacity: Number(values.capacity),
      eventSourceId: values.eventSourceId ? values.eventSourceId : null,
      dailyReportConfig: {
        ...values.dailyReportConfig,
        // @ts-ignore
        language: values?.dailyReportConfig?.language?.value,
        timezone: {
          value: selectedTimezone?.value || 'Europe/Berlin',
          label: selectedTimezone?.label || '(GMT+2:00) Frankfurt',
          offset: selectedTimezone?.offset || 2,
        },
      },
      terms: values.terms || '',
      defaultConfirmationEmail: values.defaultConfirmationEmail || '',
      logo: logoPreview || '',
    }

    // @ts-ignore
    delete formData?.userCreated

    if (id) {
      mutateUpdateOrganization({
        variables: {
          updateOrganizationInput: {
            _id: id,
            ...formData,
          },
        },
      })
    } else {
      mutateCreateOrganization({
        variables: {
          createOrganizationInput: formData,
        },
      })
    }
  }

  const languageOptions = [
    {
      value: 'GERMAN',
      label: Language.German,
    },
    {
      value: 'ENGLISH',
      label: Language.English,
    },
    {
      value: 'TURKISH',
      label: Language.Turkish,
    },
  ]

  const handleUploadLogo = async (event: any, form: any) => {
    const file = event.target.files[0]

    if (file) {
      if (file.size > IMAGE_MAX_SIZE) {
        setLogoErrMsg(
          t('event_invitation.template_form.error.logo_size', {
            max: 5,
          })
        )
      } else {
        setLogoErrMsg('')
        const formData = new FormData()
        formData.append('file', file)
        setUpdateLogoLoading(true)
        const res = await uploadImageReq(formData)
        setUpdateLogoLoading(false)
        if (res) {
          setLogoPreview(res?.data?.uri)
          form.change('logo', res?.data?.uri)
        }
      }
    }
  }

  return (
    <div className='max-w-3xl mx-auto'>
      <h2 className='text-2xl font-bold mb-6'>
        {id ? t('Update Organization') : t('Create Organization')}
      </h2>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          ...organizationData?.organization,
          dailyReportConfig: {
            ...organizationData?.organization?.dailyReportConfig,
            language: {
              value:
                organizationData?.organization?.dailyReportConfig?.language ||
                languageOptions[0].value,
              label:
                capitalizeFirstLetter(
                  organizationData?.organization?.dailyReportConfig?.language
                ) || languageOptions[0].label,
            },
          },
          emailCredentials: {
            ...organizationData?.organization?.emailCredentials,
            secure:
              organizationData?.organization?.emailCredentials?.secure || false,
          },
        }}
        render={({ handleSubmit, submitting, pristine, invalid, form }) => (
          <form onSubmit={handleSubmit} className='space-y-6'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
              <Field
                name='name'
                type='text'
                validate={required}
                format={trimWhiteSpace}
                formatOnBlur
                defaultValue=''
              >
                {({ input, meta }: any) => (
                  <FormItem>
                    <FormLabel
                      meta={meta}
                      isRequired
                      helpMessage={t('organization.name.helpMessage')}
                    >
                      {t('organization.name.label')}
                    </FormLabel>
                    <Input {...input} meta={meta} maxLength='150' />
                  </FormItem>
                )}
              </Field>
              <Field
                name='email'
                type='text'
                validate={composeValidators(
                  emailValidation,
                  maxLengthValidation,
                  requiredValidation
                )}
                format={trimWhiteSpace}
                formatOnBlur
                defaultValue=''
              >
                {({ input, meta }: any) => (
                  <FormItem>
                    <FormLabel
                      meta={meta}
                      isRequired
                      helpMessage={t('organization.email.helpMessage')}
                    >
                      {t('organization.email.label')}
                    </FormLabel>
                    <Input {...input} meta={meta} maxLength='150' isRequired />
                  </FormItem>
                )}
              </Field>
            </div>

            {isRootAdmin && (
              <>
                <Field name='capacity' validate={required} defaultValue={0}>
                  {({ input, meta }: any) => (
                    <FormItem>
                      <FormLabel
                        meta={meta}
                        isRequired
                        helpMessage={t('organization.capacity.helpMessage')}
                      >
                        {t('organization.capacity.label')}
                      </FormLabel>
                      <Input
                        meta={meta}
                        {...input}
                        type='number'
                        min={0}
                        max={100}
                        step={1}
                        pattern='[1-9]'
                        onKeyDown={(e: any) => {
                          if (alphabets.includes(e.key)) {
                            e.preventDefault()
                          }
                          if (e.keyCode === 32) {
                            e.preventDefault()
                          }
                        }}
                        isRequired={true}
                      />
                    </FormItem>
                  )}
                </Field>
                <Field
                  name='eventSourceId'
                  type='text'
                  format={trimWhiteSpace}
                  formatOnBlur
                  defaultValue={''}
                >
                  {({ input, meta }: any) => (
                    <FormItem>
                      <FormLabel
                        meta={meta}
                        helpMessage={t(
                          'organization.eventSourceId.helpMessage'
                        )}
                      >
                        {t('organization.eventSourceId.label')}
                      </FormLabel>
                      <Input {...input} />
                    </FormItem>
                  )}
                </Field>
                <div className='space-y-2'>
                  <FormLabel>{t('Terms')}</FormLabel>
                  <Field name='terms' component={renderEditor}></Field>
                </div>
              </>
            )}

            <div className='space-y-2'>
              <FormLabel>
                <HelpText
                  text={t('organization.defaultConfirmationEmail.label')}
                  helpMessage={t(
                    'organization.defaultConfirmationEmail.helpMessage'
                  )}
                />
              </FormLabel>
              <Field
                name='defaultConfirmationEmail'
                component={renderConfirmationEmailEditor}
              />
            </div>

            <Field name='logo' type='file'>
              {({ input, meta }: any) => (
                <FormItem>
                  <FormLabel
                    meta={meta}
                    helpMessage={t('organization.logo.helpMessage')}
                  >
                    {t('organization.logo.label')}
                  </FormLabel>
                  <div className='p-4 border-2 rounded-md border-dashed flex flex-col items-center'>
                    <Input
                      id='upload'
                      type='file'
                      accept='.png, .jpeg, .jpg'
                      className='cursor-pointer hidden'
                      onChange={() => handleUploadLogo(event, form)}
                    />
                    <Label
                      htmlFor='upload'
                      className='bg-accent rounded-sm px-4 py-2'
                    >
                      {t('event_invitation.template_form.choose_logo')}
                    </Label>
                    {logoErrMsg && (
                      <p className='text-sm font-medium text-destructive'>
                        {logoErrMsg}
                      </p>
                    )}
                    <div className='relative min-h-[30px]'>
                      <div className='absolute mt-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        {updateLogoLoading && <DotsLoading />}
                      </div>
                      {!updateLogoLoading && logoPreview && (
                        <div className='relative border border-dashed border-primary p-1 cursor-pointer mt-4'>
                          <img
                            className='max-h-[50px] max-w-[200px] mx-auto'
                            src={logoPreview}
                          />
                          <div
                            className='absolute top-[-10px] right-[-10px] w-[20px] z-100 cursor-pointer'
                            onClick={() => {
                              setLogoPreview('')
                              form.change('logo', '')
                            }}
                          >
                            <XCircle
                              color='#757575'
                              size='20px'
                              className='cursor-pointer'
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </FormItem>
              )}
            </Field>

            <div className='space-y-4'>
              <h3 className='text-lg font-semibold'>
                {t('Additional Settings')}
              </h3>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div className='space-y-2'>
                  <FormLabel>
                    <HelpText
                      text={t('organization.force2FAEnabled.label')}
                      helpMessage={t(
                        'organization.force2FAEnabled.helpMessage'
                      )}
                    />
                  </FormLabel>
                  <Field
                    name='force2FAEnabled'
                    component={RenderSwitch}
                    defaultValue={false}
                  />
                </div>
                <div className='space-y-2'>
                  <FormLabel>
                    <HelpText
                      text={t('organization.sendDailyRegistrationReport.label')}
                      helpMessage={t(
                        'organization.sendDailyRegistrationReport.helpMessage'
                      )}
                    />
                  </FormLabel>
                  <Field
                    name='sendDailyRegistrationReport'
                    component={RenderSwitch}
                    defaultValue={false}
                  />
                </div>
              </div>
            </div>

            <div className='space-y-4'>
              <h3 className='text-lg font-semibold'>
                {t('Daily Report Config')}
              </h3>
              <div className='space-y-2'>
                <FormLabel>{t('Language')}</FormLabel>
                <Field
                  name='dailyReportConfig.language'
                  component={SelectAdapter}
                  placeholder={t('Language')}
                  options={languageOptions}
                />
              </div>
            </div>

            <Button
              type='submit'
              disabled={submitting || invalid || updateLogoLoading}
              className='w-full'
            >
              {(updateLoading || createLoading) && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {id ? t('Update Organization') : t('Create organization')}
            </Button>
          </form>
        )}
      />
    </div>
  )
}

export default OrganizationForm
