import { FC } from 'react'
import { styled } from 'utils/adapters'
import DotsLoading from 'components/atoms/DotsLoading'

const Loading: FC = () => {
  return <Wrapper />
}

const Wrapper = styled(DotsLoading)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`

export default Loading
