import { createSlice } from '@reduxjs/toolkit'

const userReducer = createSlice({
  name: 'user',
  initialState: {
    totalUser: 0,
    amountOfUsers: 0,
    resetPageFlag: false,
    selectedRecipents: [],
    userCreated: 0,
  },
  reducers: {
    setSelectedRecipients: (state, action) => {
      state.selectedRecipents = action.payload
    },
    setTotalUser: (state, action) => {
      state.totalUser = action.payload
    },
    setAmountOfUser: (state, action) => {
      state.amountOfUsers = action.payload
    },
    setResetPageFlag: (state, action) => {
      state.resetPageFlag = action.payload
    },
    setUserCreated: (state, action) => {
      state.userCreated = action.payload
    },
  },
})

export const {
  setTotalUser,
  setAmountOfUser,
  setResetPageFlag,
  setSelectedRecipients,
  setUserCreated,
} = userReducer.actions
export default userReducer.reducer
