import { FC, lazy } from 'react'
import { useTranslation } from 'react-i18next'

import { styled, useHistory } from 'utils/adapters'

import Content from './Content'
import Header from 'components/organisms/Header'

const Footer = lazy(() => import('components/organisms/Footer'))

const Privacy: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Wrapper>
      <Header
        title={t('Privacy')}
        showLanguageSelector={false}
        displayBackIcon
        handleBack={() => history.goBack()}
      />
      <Content />
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

export default Privacy
