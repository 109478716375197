import { FC, lazy, useEffect, useState } from 'react'

import axios from 'axios'
import { useTranslation } from 'react-i18next'

import {
  CHECK_EVENT_ATTENDEE,
  GET_EVENT_TERMS,
  GET_PROFESSIONALS,
  GET_SALUTATIONS,
  PUBLIC_GET_EVENT_BY_ID,
} from 'graphql/queries'
import { setStep } from 'store/Events'
import {
  composeValidators,
  convertExternalLink,
  emailValidation,
  germanCountry,
  getListCountriesByLanguage,
  maxLengthValidation,
  postCodeValidation,
  requiredValidation,
  Step,
  trimWhiteSpace,
} from 'utils/helpers'

import { Environment } from 'environments'

import {
  Field,
  Form,
  FormSpy,
  styled,
  useLazyQuery,
  useParams,
  useQuery,
} from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import { responsive } from 'assets/scss/mixin'
import { Button } from '@/components/ui/button'
import ReactSelectAdapter from 'components/atoms/Select'
import { COLORS } from 'constants/colors'
import { EventType } from 'constants/events'
import { getLanguage } from 'constants/language'
import AccommodationButton from './AccommodationButton'
import { CustomSelections } from './CustomSelection'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Label } from '@/components/ui/label'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import TermModal from '@/components/molecules/TermModal'

const Term = lazy(() => import('./Term'))
const Loading = lazy(() => import('components/atoms/Loading'))

interface ParamsType {
  id: string
}

const AttendeeForm: FC = () => {
  const { t } = useTranslation()

  const { id } = useParams<ParamsType>()
  const [showTerm, setShowTerm] = useState(false)
  const [isDigitalEvent, setIsDigitalEvent] = useState<boolean>(false)
  const event = JSON.parse(sessionStorage.getItem('currentEvent') as string)
  const appLanguage = localStorage.getItem('i18nextLng')
  const attendeeInfo = JSON.parse(
    sessionStorage.getItem('attendee_info') as string
  )

  const { isFullyBooked, eventTerms } = useAppSelector(
    state => state.eventsReducer
  )
  const [isChecked, setIsChecked] = useState(false)
  const dispatch = useAppDispatch()

  const [salutationList, setSalutationList] = useState<
    { key: string; value: string }[]
  >([])

  useEffect(() => {
    getSalutationList()
  }, [appLanguage])

  const { data: professionalData, loading: professionalLoading } = useQuery(
    GET_PROFESSIONALS,
    {
      onError: ({ message }) => {
        console.log(message)
      },
    }
  )

  const fireDuplicatedEmailMessage = () => {
    toast({
      variant: 'destructive',
      title: t('Failed'),
      description: t('Email already registered'),
    })
    setIsChecked(false)
  }

  const [
    getSalutationList,
    { data: salutationData, loading: loadingSalutation },
  ] = useLazyQuery(GET_SALUTATIONS, {
    onCompleted: () => {
      setSalutationList(salutationData?.fetchSalutations?.data)
    },
    onError: ({ message }) => {
      console.log(message)
    },
    variables: {
      language: getLanguage(appLanguage),
    },
  })

  const [checkEmailDuplicate, { loading, data }] = useLazyQuery(
    CHECK_EVENT_ATTENDEE,
    {
      onCompleted: () => {
        const attendeeInfo = JSON.parse(
          sessionStorage.getItem('attendee_info') as string
        )
        if (data && data.checkEventAttendee) {
          const statusCode = data.checkEventAttendee.status
          if (event?.customFields?.length === 0) {
            if (!event.isBookable) {
              if (statusCode === 200) {
                dispatch(setStep(Step.DetailCost))
              } else {
                fireDuplicatedEmailMessage()
              }
            } else {
              if (statusCode === 200) {
                axios
                  .post(`${Environment.serverUrl}/payments/customers`, {
                    email: attendeeInfo.email,
                    name: `${attendeeInfo.firstName} ${attendeeInfo.lastName}`,
                    phone: attendeeInfo.mobilePhone,
                    metadata: {
                      email: attendeeInfo.email,
                      firstName: attendeeInfo.firstName,
                      lastName: attendeeInfo.lastName,
                      mobilePhone: attendeeInfo.mobilePhone,
                    },
                  })
                  .then(response => {
                    localStorage.setItem(
                      'stripeCustomerInfo',
                      JSON.stringify(response.data)
                    )
                  })
                  .catch(error => {
                    console.log(error)
                  })

                dispatch(setStep(Step.DetailCost))
              } else {
                fireDuplicatedEmailMessage()
              }
            }
          } else {
            if (statusCode === 200) {
              dispatch(setStep(Step.AttendeeCustomField))
            } else {
              fireDuplicatedEmailMessage()
            }
          }
        }
      },
      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const { data: eventData } = useQuery(PUBLIC_GET_EVENT_BY_ID, {
    onCompleted: () => {
      if (eventData) {
        eventData?.event?.type === EventType.DIGITAL && setIsDigitalEvent(true)
      }
    },
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  })

  const { data: terms, loading: loadingTerms } = useQuery(GET_EVENT_TERMS, {
    variables: {
      id,
    },
    onError: ({ message }) => {
      console.log(message)
    },
  })

  const onSubmit = (values: any) => {
    const formattedValues = {
      ...values,
      salutation: values?.salutation === '-' ? '' : values?.salutation || '',
      professional:
        values?.professional === '-' ? '' : values?.professional || '',
    }
    sessionStorage.setItem('attendee_info', JSON.stringify(formattedValues))

    checkEmailDuplicate({
      variables: {
        attendeeEmail: values.email,
        eventId: event._id,
      },
    })
  }

  const handleCheckedTerm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  const requiredTextField = composeValidators(
    requiredValidation,
    maxLengthValidation
  )

  const {
    firstName = {
      enabled: true,
      required: true,
    },
    lastName = {
      enabled: true,
      required: true,
    },
    salutation = {
      enabled: true,
      required: true,
    },
    professional = {
      enabled: true,
      required: false,
    },
    jobTitle = {
      enabled: false,
      required: false,
    },
    company = {
      enabled: false,
      required: true,
    },
    address = {
      street: {
        enabled: true,
        required: !isDigitalEvent,
      },
      zipCode: {
        enabled: true,
        required: !isDigitalEvent,
      },
      city: {
        enabled: true,
        required: !isDigitalEvent,
      },
      country: {
        enabled: true,
        required: !isDigitalEvent,
      },
    },
    workPhone = {
      enabled: false,
      required: false,
    },
    mobilePhone = {
      enabled: true,
      required: false,
    },
    fax = {
      enabled: false,
      required: false,
    },
  } = eventData?.event?.toggleDefaultFields || {}

  const salutationOptions = salutationList?.map(({ key, value }) => ({
    label: value || '-',
    value: key || '-',
  }))

  const professionalOptions = professionalData?.fetchProfessionals?.data?.map(
    ({ key, value }) => ({
      label: value || '-',
      value: key || '-',
    })
  )

  if (loading || loadingTerms) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }

  return (
    <>
      <div className='py-10'>
        <div className='container mx-auto px-4'>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              ...attendeeInfo,
              address: {
                country: {
                  label: germanCountry(appLanguage)?.name,
                  value: germanCountry(appLanguage)?.name,
                },
                ...attendeeInfo?.address,
              },
            }}
            render={({ handleSubmit, submitting, invalid }) => (
              <form
                onSubmit={handleSubmit}
                className='container mx-auto px-4 py-2 flex-wrap justify-around gap-4 md:gap-6 md:py-6'
              >
                <FormSpy
                  subscription={{ values: true, valid: true }}
                  onChange={(state: any) => {
                    const { values } = state
                    sessionStorage.setItem(
                      'attendee_info',
                      JSON.stringify(values)
                    )
                  }}
                />
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                  <div className='col-span-1 lg:w-4/5 w-full space-y-4 lg:mx-auto'>
                    {firstName?.enabled !== false && (
                      <Field
                        name='firstName'
                        type='text'
                        validate={
                          firstName?.required !== false
                            ? composeValidators(
                                requiredTextField,
                                maxLengthValidation
                              )
                            : maxLengthValidation
                        }
                        format={trimWhiteSpace}
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem className='w-full'>
                            <FormLabel
                              meta={meta}
                              isRequired={firstName?.required !== false}
                            >
                              {t('First Name')}
                            </FormLabel>
                            <Input
                              {...input}
                              meta={meta}
                              isRequired={firstName?.required !== false}
                              maxLength='255'
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}

                    {lastName?.enabled !== false && (
                      <Field
                        name='lastName'
                        type='text'
                        validate={
                          lastName?.required !== false
                            ? composeValidators(
                                requiredTextField,
                                maxLengthValidation
                              )
                            : maxLengthValidation
                        }
                        format={trimWhiteSpace}
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem className='w-full'>
                            <FormLabel
                              meta={meta}
                              isRequired={lastName?.required !== false}
                            >
                              {t('Last Name')}
                            </FormLabel>
                            <Input
                              {...input}
                              meta={meta}
                              isRequired={lastName?.required !== false}
                              maxLength='255'
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}
                    {!loadingSalutation &&
                      salutationList?.length &&
                      salutation?.enabled !== false && (
                        <div className='relative flex flex-col'>
                          <Label className='mb-2'>
                            {t('Salutation')}
                            {salutation?.required !== false && (
                              <span className='leading-none'>*</span>
                            )}
                          </Label>
                          <Field
                            name='salutation'
                            validate={
                              salutation?.required !== false
                                ? requiredValidation
                                : undefined
                            }
                            component={ReactSelectAdapter}
                            placeholder={''}
                            isSearchable={false}
                            options={salutationOptions}
                            format={(value: any) => {
                              return {
                                label:
                                  salutationOptions.find(
                                    item => item.value === value
                                  )?.label || '',
                                value,
                              }
                            }}
                            parse={(option: any) => option.value}
                          />
                        </div>
                      )}
                    {!professionalLoading &&
                      professional?.enabled !== false &&
                      professionalData?.fetchProfessionals?.data?.length && (
                        <div className='relative flex flex-col'>
                          <Label className='mb-2'>
                            {t('Academic Title')}
                            {professional?.required !== false && (
                              <span className='leading-none'>*</span>
                            )}
                          </Label>
                          <Field
                            name='professional'
                            validate={
                              professional?.required !== false
                                ? requiredValidation
                                : undefined
                            }
                            component={ReactSelectAdapter}
                            placeholder={''}
                            isSearchable={false}
                            options={professionalOptions}
                            format={(value: any) => ({
                              label:
                                professionalOptions.find(
                                  item => item.value === value
                                )?.label || '',
                              value,
                            })}
                            parse={(option: any) => option.value}
                          />
                        </div>
                      )}
                    {jobTitle?.enabled !== false && (
                      <Field
                        name='jobTitle'
                        type='text'
                        format={trimWhiteSpace}
                        validate={
                          jobTitle?.required !== false
                            ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                            : maxLengthValidation
                        }
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel
                              meta={meta}
                              isRequired={jobTitle?.required !== false}
                            >
                              {t('Job title')}
                            </FormLabel>
                            <Input
                              {...input}
                              meta={meta}
                              maxLength='255'
                              isRequired={jobTitle?.required !== false}
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}
                    {company?.enabled !== false && (
                      <Field
                        name='company'
                        type='text'
                        validate={
                          company?.required !== false
                            ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                            : maxLengthValidation
                        }
                        format={trimWhiteSpace}
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel
                              meta={meta}
                              isRequired={company?.required !== false}
                            >
                              {t('Company')}
                            </FormLabel>
                            <Input
                              {...input}
                              isRequired={company?.required !== false}
                              meta={meta}
                              maxLength='255'
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}

                    {event?.customSelections && (
                      <CustomSelections
                        data={event?.customSelections}
                        eventPrimaryColor={event?.eventSetting?.primaryColor}
                      />
                    )}
                  </div>

                  <div className='col-span-1 lg:w-4/5 w-full space-y-4 lg:mx-auto'>
                    {address?.street?.enabled !== false && (
                      <Field
                        name='address.street'
                        type='text'
                        validate={
                          address?.street?.required !== false
                            ? requiredTextField
                            : maxLengthValidation
                        }
                        format={trimWhiteSpace}
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel
                              meta={meta}
                              isRequired={address?.street?.required !== false}
                            >
                              {t('Street')}
                            </FormLabel>
                            <Input
                              {...input}
                              type='text'
                              meta={meta}
                              isRequired={address?.street?.required !== false}
                              maxLength='150'
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}
                    {address?.zipCode?.enabled !== false && (
                      <Field
                        name='address.zipCode'
                        type='text'
                        validate={
                          address?.zipCode?.required !== false
                            ? composeValidators(
                                requiredValidation,
                                maxLengthValidation,
                                postCodeValidation
                              )
                            : composeValidators(
                                maxLengthValidation,
                                postCodeValidation
                              )
                        }
                      >
                        {({ input, meta }: any) => (
                          <FormItem className='w-full'>
                            <FormLabel
                              meta={meta}
                              isRequired={address?.zipCode?.required !== false}
                            >
                              {t('Zip')}
                            </FormLabel>
                            <Input
                              {...input}
                              type='text'
                              meta={meta}
                              isRequired={address?.zipCode?.required !== false}
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}

                    {address?.city?.enabled !== false && (
                      <Field
                        name='address.city'
                        type='text'
                        validate={
                          address?.city?.required !== false
                            ? composeValidators(
                                requiredTextField,
                                maxLengthValidation
                              )
                            : maxLengthValidation
                        }
                        format={trimWhiteSpace}
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem className='w-full'>
                            <FormLabel
                              meta={meta}
                              isRequired={address?.city?.required !== false}
                            >
                              {t('City')}
                            </FormLabel>
                            <Input
                              {...input}
                              isRequired={address?.city?.required !== false}
                              type='text'
                              meta={meta}
                              maxLength='255'
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}
                    {address?.country?.enabled !== false && (
                      <div className='relative flex flex-col'>
                        <Label className='mb-2'>
                          {t('Country')}{' '}
                          {address?.country?.required !== false && (
                            <span className='leading-none'>*</span>
                          )}
                        </Label>
                        <Field
                          className='select-country'
                          name='address.country'
                          validate={
                            address?.country?.required !== false
                              ? composeValidators(
                                  requiredTextField,
                                  maxLengthValidation
                                )
                              : maxLengthValidation
                          }
                          component={ReactSelectAdapter}
                          placeholder={t('Please select your country')}
                          options={getListCountriesByLanguage(appLanguage).map(
                            country => ({
                              label: country.name,
                              value: country.name,
                              code: country.code,
                            })
                          )}
                        />
                      </div>
                    )}
                    <Field
                      name='email'
                      type='text'
                      validate={composeValidators(
                        requiredValidation,
                        maxLengthValidation,
                        emailValidation
                      )}
                      format={trimWhiteSpace}
                      formatOnBlur
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta} isRequired={true}>
                            {t('Email')}
                          </FormLabel>
                          <Input
                            {...input}
                            meta={meta}
                            isRequired={true}
                            maxLength='255'
                          />
                        </FormItem>
                      )}
                    </Field>
                    {workPhone?.enabled !== false && (
                      <Field
                        name='workPhone'
                        type='text'
                        validate={
                          workPhone?.required !== false
                            ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                            : maxLengthValidation
                        }
                        format={trimWhiteSpace}
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel
                              meta={meta}
                              isRequired={workPhone?.required !== false}
                            >
                              {t('Work phone')}
                            </FormLabel>
                            <Input
                              {...input}
                              meta={meta}
                              isRequired={workPhone?.required !== false}
                              maxLength='20'
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}
                    {mobilePhone?.enabled !== false && (
                      <Field
                        name='mobilePhone'
                        type='text'
                        format={trimWhiteSpace}
                        validate={
                          mobilePhone?.required !== false
                            ? composeValidators(
                                maxLengthValidation,
                                requiredValidation
                              )
                            : maxLengthValidation
                        }
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel
                              meta={meta}
                              isRequired={mobilePhone?.required !== false}
                            >
                              {t('Mobile phone')}
                            </FormLabel>
                            <Input
                              {...input}
                              maxLength='20'
                              meta={meta}
                              isRequired={mobilePhone?.required !== false}
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}
                    {fax?.enabled !== false && (
                      <Field
                        name='fax'
                        type='text'
                        format={trimWhiteSpace}
                        validate={
                          fax?.required !== false
                            ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                            : maxLengthValidation
                        }
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel
                              meta={meta}
                              isRequired={fax?.required !== false}
                            >
                              {t('Fax')}
                            </FormLabel>
                            <Input
                              {...input}
                              meta={meta}
                              maxLength='20'
                              isRequired={fax?.required !== false}
                            />
                          </FormItem>
                        )}
                      </Field>
                    )}
                    <div className='space-y-6 lg:col-start-2'>
                      <Term
                        checked={isChecked}
                        onChange={handleCheckedTerm}
                        onClick={() => setShowTerm(true)}
                      />

                      {event?.hotelBookingUrl && (
                        <AccommodationButton
                          href={convertExternalLink(event.hotelBookingUrl)}
                          primaryColor={event?.eventSetting?.primaryColor}
                        />
                      )}

                      <SubmitButton
                        primaryColor={event?.eventSetting?.primaryColor}
                        color={setTextColorBasedOnBackground(
                          event?.eventSetting?.primaryColor
                        )}
                        type='submit'
                        disabled={!isChecked || isFullyBooked || submitting}
                        className='w-full'
                      >
                        {t('Continue')}
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
        </div>
      </div>
      <TermModal
        isShow={showTerm}
        primaryColor={event?.eventSetting?.primaryColor}
        terms={eventTerms}
        setShowTerm={setShowTerm}
      />
    </>
  )
}

const Wrapper = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  ${responsive.lg`
    height: unset;
  `}
`

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${responsive.sm`
      width: 100%;
  `}
`

const SubmitButton = styled(Button)<{
  primaryColor?: string
  color?: string
}>`
  background-color: ${props => props.primaryColor || COLORS.BLUE};
  color: ${props => props.color};
`

export default AttendeeForm
