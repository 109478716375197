import { FC } from 'react'
import { useHistory } from 'utils/adapters'

import Loading from '@/components/atoms/Loading'
import { useAdminEventDetail } from '@/components/pages/EventDetail/mixins'
import { PATH_NAME } from '@/routes/routesMap'
import { ChevronLeftIcon } from 'assets/images'
import { useParams, useRouteMatch } from 'react-router-dom'
import Icon from '../../atoms/Icon'
import ErrorBoundary from '../../containers/ErrorBoundary'
import LanguageDropdown from '../../organisms/language/language-dropdown'
import EventAdminMainNav from './main.nav'
import Sidebar from './side.nav'
import './side.nav.css'

const AdminEventLayout: FC = ({ children }) => {
  const history = useHistory()
  const match = useRouteMatch(PATH_NAME.EDIT_EVENT)

  const handleGoBack = () => {
    history.push('/')
  }

  const { id: eventID } = useParams<{ id: string }>()

  // if (!isObjectID(eventID)) {
  //   throw new Error('Invalid Event ID')
  // }

  const { data, loading } = useAdminEventDetail(eventID)

  if (loading) {
    return <Loading />
  }

  const title = data?.event?.title

  return (
    <ErrorBoundary>
      <div className='flex'>
        <Sidebar />
        <div className='h-full bg-lightPrimary dark:!bg-navy-900 w-[calc(100%-60px)]'>
          {/* Main Content */}
          <div className=''>
            {/* Content Navbar */}
            <div className='border-b'>
              <div className='items-center px-4 grid w-full grid-cols-5 h-[54px] md:h-[80px]'>
                <div className='flex justify-start space-x-5'>
                  <button className='focus:outline-none'>
                    {!match && (
                      <div className='h-[22px] items-center font-medium text-palette-900 transition-all duration-300 active:text-palette-500 smarthover:hover:text-palette-500'>
                        <Icon
                          className='logo'
                          source={ChevronLeftIcon}
                          height='22px'
                          width='22px'
                          onClick={handleGoBack}
                        />
                      </div>
                    )}
                  </button>
                </div>
                <EventAdminMainNav
                  title={title}
                  className='col-span-3 text-center'
                />
                <div className='ml-auto flex items-center space-x-4'>
                  {/* <UserNav /> */}
                  <LanguageDropdown />
                </div>
              </div>
            </div>
            {/* End Navbar */}
            {children}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default AdminEventLayout
