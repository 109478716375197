import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { NetworkStatus } from '@apollo/client'

import { DELETE_CUSTOM_FIELD } from 'graphql/mutations'
import { CUSTOM_FIELDS } from 'graphql/queries'
import { styled, useHistory, useMutation, useQuery } from 'utils/adapters'
import { useOrganizationIdVar } from 'utils/hooks'

import OrganizationsContainer from 'components/organisms/OrganizationsContainer'
import CustomFieldItem from './CustomFieldItem'
import DotsLoading from 'components/atoms/DotsLoading'

import { CustomField } from 'models'
import { Plus } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'

const CustomFieldList: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { organizationId } = useOrganizationIdVar()

  const { data, loading, networkStatus } = useQuery(CUSTOM_FIELDS, {
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
    variables: {
      organizationId: organizationId ? organizationId : undefined,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const [mutateDeleteCustomField] = useMutation(DELETE_CUSTOM_FIELD, {
    onCompleted: () => {
      toast({
        description: t('Remove custom field success'),
      })
    },
    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  const removeCustomField = (id: string) => {
    mutateDeleteCustomField({
      variables: { id },
      update(cache) {
        cache.modify({
          fields: {
            customFields(existingCustomFieldRef, { readField }) {
              return existingCustomFieldRef.filter(
                (customFieldRef: any) => id !== readField('_id', customFieldRef)
              )
            },
          },
        })
      },
    })
  }

  if (loading || networkStatus === NetworkStatus.refetch) return <Loading />

  return (
    <>
      <div className='flex justify-between mt-4'>
        <OrganizationsContainer />
        <div className='text-primary right-16 flex justify-end mb-2'>
          <Button
            onClick={() => {
              if (organizationId) {
                history.push(
                  `/admin/custom-field/create?orgId=${organizationId}`
                )
              } else {
                history.push('/admin/custom-field/create')
              }
            }}
          >
            <Plus className='cursor-pointer mr-2 h-5' />
            {t('library.add_new')}
          </Button>
        </div>
      </div>

      <div className='space-y-2'>
        {data?.customFields?.length > 0 && (
          <>
            {data?.customFields?.map((customField: CustomField) => (
              <CustomFieldItem
                className='custom-field-item'
                key={customField._id}
                {...customField}
                onEdit={() => {
                  localStorage.setItem(
                    'selected_custom_field',
                    JSON.stringify(customField)
                  )
                  history.push(`/admin/custom-field/edit/${customField._id}`)
                }}
                onRemove={() => removeCustomField(customField._id)}
              />
            ))}
          </>
        )}

        {!data?.customFields?.length && (
          <span>{t('Currently no custom fields are existing')}</span>
        )}
      </div>
    </>
  )
}

const Loading = styled(DotsLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export default CustomFieldList
