import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'
import { cn } from '@/lib/utils'

export const NewStyledDatePicker = (props: any) => {
  return (
    <DatePicker
      showIcon={false}
      // icon={<Calendar />}
      className={cn(props?.className,
                    'inline-flex items-center whitespace-nowrap rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 w-[280px] justify-start text-left font-normal text-muted-foreground')}
      {...props} />
  )
}