import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'

import { setFormData } from 'store/Events'

import { styled } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import Icon from 'components/atoms/Icon'
import ValidationText from 'components/atoms/ValidationText'
import DotsLoading from 'components/atoms/DotsLoading'

import { FileType } from 'models'
import { responsive } from 'assets/scss/mixin'
import { AttachIcon, RemoveIcon } from 'assets/images'
import { COLORS } from 'constants/colors'
import './styles.css'

const MAX_SIZE = 50000000
const acceptedMimeType = '.png, .jpeg, .jpg, .csv, .xlsx, .doc, .docx, .pdf'

const EventAttachments: FC<{
  files?: FileType[]
  errorMessage?: string
  removeLocalAttachment?: (index: number) => void
  uploadLoading?: boolean
  onDrop?:
    | (<T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent
      ) => void)
    | undefined
}> = ({
  onDrop,
  files,
  removeLocalAttachment,
  errorMessage,
  uploadLoading,
  children,
}) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const { formData } = useAppSelector(state => state.eventsReducer)

  const maxSizeValidator = (file: File) => {
    if (file.size > MAX_SIZE) {
      return {
        code: 'maximum-file-size',
        message: t('File(s) size must be less than 50Mb'),
      }
    }
    return null
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: acceptedMimeType,
    validator: maxSizeValidator,
    onDrop,
  })

  const removeAttachment = (index: number) => {
    const attachments = [...formData.emailAttachments]
    dispatch(
      setFormData({
        ...formData,
        emailAttachments: attachments.filter(
          (_: FileType, i: number) => i !== index
        ),
      })
    )
  }

  const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
    <ListAttachment key={file.path}>
      <span className='attachment-text'>{file.name}</span>
      {errors.map((e: any, index: number) => (
        <ValidationText key={index} error={e.message} />
      ))}
    </ListAttachment>
  ))

  return (
    <Container className='rounded-md'>
      <Title>{t('Email Attachments (50mb/file)')}</Title>
      <ButtonContainer uploadLoading={uploadLoading} {...getRootProps()}>
        <input {...getInputProps()} />
        <Button uploadLoading={uploadLoading}>
          {uploadLoading ? <DotsLoading /> : t('Attach File(s)')}
        </Button>
      </ButtonContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <ListAttachment>
        {formData &&
          formData?.emailAttachments &&
          formData?.emailAttachments?.map((file: FileType, index: number) => (
            <li key={index}>
              <AttachContainer>
                <StyleAttachIcon source={AttachIcon} />
                <a target='_blank' href={file?.uri} rel='noreferrer'>
                  {file?.filename}
                </a>
                <StyleRemoveIcon
                  source={RemoveIcon}
                  onClick={() => removeAttachment(index)}
                />
              </AttachContainer>
            </li>
          ))}

        {files &&
          files.map((file, index: number) => (
            <li key={index}>
              <AttachContainer>
                <StyleAttachIcon source={AttachIcon} />
                <a target='_blank' href={file?.uri} rel='noreferrer'>
                  {file?.filename}
                </a>
                <StyleRemoveIcon
                  source={RemoveIcon}
                  onClick={() => removeLocalAttachment?.(index)}
                />
              </AttachContainer>
            </li>
          ))}
      </ListAttachment>
      {fileRejections.length > 0 && (
        <>
          <Title>{t('Rejected file(s)')}</Title>
          {fileRejectionItems}
        </>
      )}

      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
  border: 1px dashed ${COLORS.BLUE};
  gap: 10px;
`

const Title = styled.span`
  font-size: 20px;
  color: #757575;
  font-weight: bold;
  ${responsive.md`
      font-size: 14px;
    `}
`

const ButtonContainer = styled.div<{ uploadLoading?: boolean }>`
  z-index: ${props => (props.uploadLoading ? -1 : 0)};
`

const Button = styled.div<{ uploadLoading?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 114px;
  border-radius: 0.375rem;
  padding: 10px;
  box-sizing: border-box;
  background-color: ${props =>
          props.uploadLoading ? COLORS.BACKGROUND_DISABLED : COLORS.BLUE};
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: opacity 0.25s linear;

  &:hover {
    opacity: 0.8;
  }

  ${responsive.md`
      font-size: 14px;
    `}
`

const ListAttachment = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const AttachContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    ${responsive.md`
      font-size: 14px;
    `}
  }
`

const StyleAttachIcon = styled(Icon)`
  img {
    filter: none;
    width: 14px;
    height: 14px;
    ${responsive.md`
      width: 12px;
      height: 12px
    `}
  }
`

const StyleRemoveIcon = styled(StyleAttachIcon)`
  margin-left: auto;
  cursor: pointer;
`

const ErrorMessage = styled.span`
  font-size: 14px;
  color: red;
`

export default EventAttachments
