export const transformAddress = (
  street: string,
  zipCode: string,
  city: string,
  country: string
) => {
  let res = ''
  const addressParts = [street, zipCode, city]
  const filteredAddressParts = addressParts.filter(part => !!part?.trim())

  if (filteredAddressParts.length > 0) {
    res = filteredAddressParts.join(', ')
    if (country.trim() !== '') {
      res += ', ' + country
    }
  } else {
    res = country
  }

  return res
}
