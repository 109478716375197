import React from 'react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { HelpCircle } from 'lucide-react'

interface HelpTextProps {
  text: string
  helpMessage: string
}

export default function HelpText({ text, helpMessage }: HelpTextProps) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={300}>
        <TooltipTrigger asChild>
          <span className='inline-flex items-center cursor-help'>
            {text}
            <HelpCircle className='ml-1 h-4 w-4 text-muted-foreground' />
          </span>
        </TooltipTrigger>
        <TooltipContent side='top' align='center' className='max-w-xs'>
          <p className='max-h-48 overflow-y-auto !font-normal !text-sm'>{helpMessage}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
