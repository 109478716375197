import { FC } from 'react'
import { Switch } from '@/components/ui/switch'

export const RenderSwitch: FC<{ input: any }> = ({ input, ...rest }) => {
  return (
    <Switch
      checked={!!input.value}
      onCheckedChange={input.onChange}
      {...rest}
    />
  )
}
