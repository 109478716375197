import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { Textarea } from '@/components/ui/textarea'
import { FEEDBACK_FORM_TYPE } from '@/constants'
import { cn } from '@/lib/utils'
import { Field } from '@/utils/adapters'
import { trimWhiteSpace } from '@/utils/helpers'
import { t } from 'i18next'
import { FC } from 'react'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

interface ClientFeedbackFormItemProps {
  index: number
  label: string
  type: string
  isRequired: boolean
}

const required = (value: any) =>
  value ? undefined : t('This field is required!')

export const ClientFeedbackFormItem: FC<ClientFeedbackFormItemProps> = ({
  index,
  label,
  type,
  isRequired,
}) => {
  const { t } = useTranslation()
  const radioOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ]

  const form = useForm()

  const renderFeedbackFormItem = () => {
    switch (type) {
      case FEEDBACK_FORM_TYPE.RATING:
        return (
          <>
            <div className='flex flex-row justify-between items-center mt-6'>
              <div className=''>{t('feedback_form.very_dissatisfied')}</div>
              <div className='flex flex-col gap-2'>
                <Field
                  name={`questions.${index}.value`}
                  type='text'
                  validate={isRequired ? required : undefined}
                  format={trimWhiteSpace}
                  formatOnBlur
                >
                  {({ input, meta }: any) => (
                    <FormItem className='w-full'>
                      <FormLabel
                        meta={meta}
                        isRequired={isRequired}
                      ></FormLabel>
                      <RadioGroup
                        onValueChange={value => {
                          input.onChange(value)
                        }}
                        className='flex flex-row gap-20 justify-center'
                        value={input.value}
                      >
                        {radioOptions.map(option => (
                          <div
                            className='flex items-center flex-col gap-4'
                            key={option.value}
                          >
                            <Label htmlFor={option.value} className='font-bold'>
                              {option.value}
                            </Label>
                            <RadioGroupItem
                              value={option.value}
                              id={option.value}
                            />
                          </div>
                        ))}
                      </RadioGroup>
                    </FormItem>
                  )}
                </Field>
              </div>
              <div className=''>{t('feedback_form.very_satisfied')}</div>
            </div>
            <div className='mt-8'>
              <Field
                name={`questions.${index}.additional_comment`}
                type='text'
                format={trimWhiteSpace}
                formatOnBlur
              >
                {({ input, meta }: any) => (
                  <FormItem className='w-full'>
                    <FormLabel className='font-bold'>
                      {t('Additional comments')}
                    </FormLabel>
                    <Textarea
                      {...input}
                      meta={meta}
                      className='mt-2 text-base border-b border-gray-300 bg-transparent focus-visible:ring-0 
												focus-visible:ring-offset-0 focus:border-2 focus:border-primary'
                    />
                  </FormItem>
                )}
              </Field>
            </div>
          </>
        )
      case FEEDBACK_FORM_TYPE.TEXT:
        return (
          <div className='mt'>
            <Field
              name={`questions.${index}.value`}
              type='text'
              validate={isRequired ? required : undefined}
              format={trimWhiteSpace}
              formatOnBlur
            >
              {({ input, meta }: any) => (
                <FormItem className='w-full'>
                  <FormLabel meta={meta} isRequired={isRequired}></FormLabel>
                  <Input
                    {...input}
                    meta={meta}
                    className='font-bold border-b border-gray-300 px-0 rounded-none border-t-0 border-x-0 py-6 focus-visible:ring-0 
												focus-visible:ring-offset-0 focus:border-b-2 focus:border-primary'
                  />
                </FormItem>
              )}
            </Field>
          </div>
        )
    }
  }

  return (
    <div
      className={cn(
        'flex gap-4 justify-between bg-white px-6 py-6 mb-4 items-center'
      )}
    >
      <div className='w-full'>
        <p className='font-bold text-lg'>
          {label} {isRequired ? '*' : ''}
        </p>
        {renderFeedbackFormItem()}
      </div>
    </div>
  )
}
