import { FC } from 'react'
import Select from 'react-select'

type OptionType = { value: string; label: string }

type CustomSelectProps = {
  options: OptionType[]
  placeholder: string
  onChange: (option: OptionType | null) => void
  value: OptionType
  isSearchable?: boolean
  defaultValue?: OptionType
}

const CustomSelect: FC<CustomSelectProps> = ({
  options,
  placeholder,
  onChange,
  value,
  isSearchable = false,
  defaultValue,
}) => {
  return (
    <Select
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      isSearchable={isSearchable}
      defaultValue={defaultValue}
    />
  )
}

export default CustomSelect
