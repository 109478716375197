import { FC } from 'react'
import { styled } from 'utils/adapters'
import { renderSymbolCurrency } from 'utils/helpers'
import { CustomFieldOption } from 'models'
import { COLORS } from 'constants/colors'

type CustomFieldFeeType = {
  title: string
  options: CustomFieldOption[]
  currency?: string
  isPaid?: boolean
}

const CustomFieldFee: FC<CustomFieldFeeType> = ({
  title,
  options,
  currency,
  isPaid = false,
}) => {
  return (
    <Wrapper>
      <span dangerouslySetInnerHTML={{ __html: title || '' }} />
      {options?.map((option, index: number) => {
        return (
          <Option key={index}>
            <span>{option.name}</span>
            <Fee isPaid={isPaid}>
              {isPaid && '- '}
              {renderSymbolCurrency(currency)}
              {option.fee}
            </Fee>
          </Option>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  font-weight: bold;
`

const Option = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;

  span {
    font-weight: 500;
  }
`

const Fee = styled.span<{ isPaid: boolean }>`
  color: ${props => (props.isPaid ? COLORS.DANGER : 'inherit')};
`

export default CustomFieldFee
