import { useTranslation } from 'react-i18next'
import {
  CancellationPolicyIcon,
  DuplicateIcon,
  EditIcon,
  FileUpIcon,
  LayoutDashboardIcon,
  LogoIcon,
  QRCodeIcon,
  SendEmailIcon,
  UsersIcon,
  MailOpen,
  FolderHeart,
  Feedback,
  ChartBar,
} from 'assets/images'
import { USER_ROLE, USER_SCOPE } from 'constants/userRole'
import Icon from '../../atoms/Icon'
import { Link, useLocation, useParams } from 'utils/adapters'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Fragment } from 'react'
import { getCurrentUser } from '@/components/pages/Users/mixins'
import Loading from '@/components/atoms/Loading'
import Permission from '@/components/atoms/Permission'
import { MessageSquareQuote } from 'lucide-react'

const Sidebar = () => {
  const { id: eventID } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const location = useLocation()
  const { data: user } = getCurrentUser()

  const routes = [
    {
      path: `/admin/events/${eventID}`,
      name: t('Event Detail'),
      icon: LayoutDashboardIcon,
    },
    {
      path: `/admin/events/${eventID}/attendees`,
      name: t('Attendee List'),
      scope: USER_SCOPE.ATTENDEE_CHECKIN,
      icon: UsersIcon,
    },
    {
      path: `/admin/events/${eventID}/edit`,
      name: t('Edit Event'),
      scope: USER_SCOPE.EVENT_UPDATE,
      icon: EditIcon,
    },
    {
      path: `/admin/events/${eventID}/duplicate`,
      name: t('Duplicate Event'),
      scope: USER_SCOPE.EVENT_DUPLICATE,
      icon: DuplicateIcon,
    },
    // {
    //   path: `/admin/events/${eventID}/duplicate`,
    //   name: t('Duplicate Event'),
    //   permission: USER_SCOPE.EVENT_DUPLICATE,
    //   icon: DuplicateIcon,
    // },
    {
      path: `/admin/events/${eventID}/email`,
      name: t('Send Email'),
      scope: USER_SCOPE.EVENT_SEND_NEWSLETTER,
      icon: SendEmailIcon,
    },
    {
      path: `/admin/events/${eventID}/import`,
      name: t('Import Attendees'),
      scope: USER_SCOPE.EVENT_IMPORT,
      icon: FileUpIcon,
    },
    // {
    //   path: `/admin/events/${eventID}/attendees/export`,
    //   name: t('Export Attendees'),
    //   permission: USER_SCOPE.EVENT_READ,
    //   icon: FileDownIcon,
    // },
    // {
    //   path: `/admin/events/${eventID}/move-organization`,
    //   name: t('Move Organization'),
    //   permission: USER_SCOPE.EVENT_READ,
    //   icon: MoveIcon,
    // },
    {
      path: `/admin/events/${eventID}/cancellation-policy`,
      name: t('Configure Cancellation Policy'),
      icon: CancellationPolicyIcon,
      role: USER_ROLE.ROOT_ADMIN,
    },
    {
      path: `/admin/events/${eventID}/qr`,
      name: t('QR'),
      icon: QRCodeIcon,
      // role: USER_ROLE.ROOT_ADMIN,
    },
    {
      path: `/admin/events/${eventID}/invitation-template`,
      name: t('event_invitation.title'),
      icon: MailOpen,
      // role: USER_ROLE.ROOT_ADMIN,
    },
    {
      path: `/admin/events/${eventID}/invitations`,
      name: t('event_invitation_list.title'),
      icon: FolderHeart,
    },
    {
      path: `/admin/events/${eventID}/feedback`,
      name: t('feedback_form.title'),
      icon: Feedback,
    },
    {
      path: `/admin/events/${eventID}/feedback/statistics`,
      name: t('event_feedback_statistics.title'),
      icon: ChartBar,
    },
  ]

  const isActiveRoute = (path: string) => {
    const definedPaths = [
      '/email',
      '/email-history',
      '/qr',
      '/invitation-template',
      '/cancellation-policy',
      '/import',
      '/attendees',
      '/invitations',
    ]
    return (
      location.pathname === path ||
      definedPaths.some(
        definedPath =>
          path.includes(definedPath) && location.pathname.includes(definedPath)
      )
    )
  }

  const renderSideBarItem = (route: any, disabled = false) => {
    return (
      <li className='min-w-max list-none p-0'>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger>
              {!disabled ? (
                <Link
                  to={route.path}
                  className={`relative pointer flex items-center space-x-4 from-sky-600 to-cyan-400 px-5 py-3 text-white ${
                    isActiveRoute(route.path) ? 'bg-primary' : ''
                  }`}
                >
                  <Icon
                    className={
                      !isActiveRoute(route.path) ? 'side-icon text-red-600' : ''
                    }
                    source={route.icon}
                  />
                  {/* <span className='-mr-1 font-medium'>{route.name}</span> */}
                </Link>
              ) : (
                <div
                  className={`relative pointer flex items-center space-x-4 from-sky-600 to-cyan-400 px-5 py-3 text-white ${
                    isActiveRoute(route.path) ? 'bg-primary' : ''
                  }`}
                >
                  <Icon
                    className={!isActiveRoute(route.path) ? 'side-icon' : ''}
                    source={route.icon}
                  />
                </div>
              )}
            </TooltipTrigger>
            <TooltipContent side='right'>
              <p>{route.name}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </li>
    )
  }

  if (!user) {
    return <Loading />
  }

  return (
    <div className='sidebar min-h-screen border-r hover:bg-white hover:shadow-lg'>
      <div className='flex h-screen flex-col justify-between pt-2 pb-6'>
        <div>
          <div className='w-max p-2.5 m-auto'>
            <Link to='/dashboard'>
              {user?.currentUser?.organization?.logo ? (
                <img
                  src={user?.currentUser?.organization?.logo}
                  className='rounded-[25px] w-[32px] h-[32px]'
                />
              ) : (
                <Icon
                  className='logo'
                  source={LogoIcon}
                  height='32px'
                  width='32px'
                />
              )}
            </Link>
          </div>
          <ul className='mt-6 space-y-2 tracking-wide list-none p-0'>
            {routes.map((route, i) => {
              if (!route.scope && !route.role) {
                return <Fragment key={i}>{renderSideBarItem(route)}</Fragment>
              } else {
                // return (
                //   <Fragment key={i}>
                //     {renderSideBarItem(
                //       route,
                //       !hasPermission(
                //         user?.currentUser,
                //         route?.scope,
                //         route?.role
                //       )
                //     )}
                //   </Fragment>
                // )
                return (
                  <Permission key={i} scope={route?.scope} role={route?.role}>
                    {renderSideBarItem(route)}
                  </Permission>
                )
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
