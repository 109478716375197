import Quill from 'quill'

const Parchment = Quill.import('parchment')

class IndentAttributor extends Parchment.Attributor.Style {
  constructor() {
    super('indent', 'margin-left', {
      scope: Parchment.Scope.BLOCK,
    })
  }

  add(node, value) {
    if (parseFloat(value) > 0) return super.add(node, `${value}em`)
  }

  value(node) {
    return parseFloat(super.value(node)) || undefined
  }
}

const IndentStyle = new IndentAttributor()

Quill.register(IndentStyle)
