import { gql } from 'utils/adapters'

export const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      _id
      roles
      firstName
      lastName
      email
      company
      userName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
      organization {
        _id
        email
        name
      }
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      _id
      roles
      firstName
      lastName
      email
      company
      userName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
      organization {
        _id
        email
        name
      }
    }
  }
`

export const UPDATE_CURRENT_USER = gql`
  mutation UpdateCurrentUser($user: UpdateCurrentUserInput!) {
    updateCurrentUser(user: $user) {
      _id
      roles
      firstName
      lastName
      email
      company
      userName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
      organization {
        _id
        email
        name
      }
    }
  }
`

export const UPDATE_PHONE_NUMBER = gql`
  mutation UpdateCurrentUser($user: UpdateCurrentUserInput!) {
    updateCurrentUser(user: $user) {
      phoneNumber
    }
  }
`

export const REQUEST_RESET_PASSWORD = gql`
  mutation requestResetPassword($email: String!) {
    requestResetPassword(email: $email) {
      message
      status
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      message
      status
    }
  }
`

export const REMOVE_USER = gql`
  mutation RemoveUser($removeUserId: ID!) {
    removeUser(id: $removeUserId) {
      _id
      company
      email
      firstName
      lastName
      roles
      scopes
      userName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
    }
  }
`

export const ACTIVE_USER = gql`
  mutation ActiveUser($activeUserId: ID!) {
    activeUser(id: $activeUserId) {
      _id
      company
      email
      firstName
      lastName
      roles
      scopes
      userName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
    }
  }
`
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
      message
    }
  }
`

export const TRANSFORM_USER_ROLE = gql`
  mutation TransformUserRole($transformUserRole: CreateUserInput!) {
    transformUserRole(transformUserRole: $transformUserRole) {
      _id
      roles
      firstName
      lastName
      email
      company
      userName
      phoneNumber
      is2FAEnabled
      twoFactorMethod
      organization {
        _id
        email
        name
      }
    }
  }
`
