import { IStatus } from 'models/IStatus'
import {
  createStatusReq,
  deleteStatusReq,
  getEventListStatusReq,
  getListStatusReq,
  updateStatusReq,
} from 'services/statusServices'
import { create } from 'zustand'
import { StatusState } from './StatusState'
import { toast } from '@/components/ui/use-toast'
import { t } from 'i18next'

export const useStatusManagement = create<StatusState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) =>
    set({
      isLoading,
    }),
  listStatus: [],
  setListStatus: (listStatus: IStatus[]) =>
    set({
      listStatus,
    }),
  eventListStatus: [],
  setEventListStatus: (eventListStatus: IStatus[]) =>
    set({
      eventListStatus,
    }),
}))

export const getListStatus = async (orgId: string) => {
  try {
    useStatusManagement.getState().setIsLoading(true)
    const { data } = await getListStatusReq(orgId)
    if (data) {
      useStatusManagement.getState().setListStatus(data.data)
    }
    useStatusManagement.getState().setIsLoading(false)
  } catch (error: any) {
    useStatusManagement.getState().setErrorMessage(error.message)
  }
}

export const createStatus = async (orgId: string, info: IStatus) => {
  try {
    const { data } = await createStatusReq(orgId, info)
    if (data) {
      const oldData = useStatusManagement.getState().listStatus
      useStatusManagement
        .getState()
        .setListStatus([...oldData, { ...data.data }])

      toast({
        description: t('status_management.msg.create_success'),
      })
    }
  } catch {
    toast({
      variant: 'destructive',
      description: t('status_management.msg.create_failed'),
    })
  }
}

export const updateStatus = async (orgId: string, info: IStatus) => {
  try {
    const { data } = await updateStatusReq(orgId, info)
    if (data) {
      let newListStatus = [...useStatusManagement.getState().listStatus]
      const statusIndex = useStatusManagement
        .getState()
        .listStatus.findIndex(status => status._id === data.data._id)

      if (statusIndex !== -1) {
        newListStatus[statusIndex] = data.data

        useStatusManagement.getState().setListStatus(newListStatus)

        toast({
          description: t('status_management.msg.update_success'),
        })
      }
    }
  } catch (err: any) {
    toast({
      variant: 'destructive',
      description:
        err?.response?.data?.message === 'CUSTOM_STATUS_ALREADY_EXISTS'
          ? t('status_management.msg.duplicate_status')
          : t('status_management.msg.update_failed'),
    })
  }
}

export const deleteStatus = async (orgId: string, id: string) => {
  try {
    const { data } = await deleteStatusReq(orgId, id)
    if (data) {
      const oldData = useStatusManagement.getState().listStatus
      const newData = oldData.filter(status => status._id !== data.data._id)
      useStatusManagement.getState().setListStatus(newData)

      toast({
        description: t('status_management.msg.remove_success'),
      })
    }
  } catch {
    toast({
      variant: 'destructive',
      description: t('status_management.msg.remove_failed'),
    })
  }
}

export const getEventListStatus = async (eventId: string) => {
  try {
    useStatusManagement.getState().setIsLoading(true)
    const { data } = await getEventListStatusReq(eventId)
    if (data) {
      useStatusManagement.getState().setEventListStatus(data.data)
    }
    useStatusManagement.getState().setIsLoading(false)
  } catch (error: any) {
    useStatusManagement.getState().setErrorMessage(error.message)
  }
}
