import { useState } from 'react'
import { isJSON } from '../helpers'

export default function usePersistedState<T>(key: string, initialValue?: T) {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue
    }
    try {
      const item = window.localStorage.getItem(key)

      return item ? (isJSON(item) ? JSON.parse(item) : item) : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value: any) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value

    setStoredValue(valueToStore)

    if (typeof window !== 'undefined') {
      typeof valueToStore === 'string'
        ? window.localStorage.setItem(key, valueToStore)
        : window.localStorage.setItem(key, JSON.stringify(valueToStore))
    }
  }
  return [storedValue, setValue]
}
