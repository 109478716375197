import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'utils/adapters'
import { RootState } from 'store'

import { PATH_NAME } from '../../routes/routesMap'

const AttendeeGuard: FC = ({ children }) => {
  const location = useLocation()

  const { isAuth } = useSelector(
    (state: RootState) => state.authenticationReducer
  )

  return isAuth ? (
    <>{children}</>
  ) : (
    <Redirect
      to={{ pathname: PATH_NAME.LOGIN, state: { from: location.pathname } }}
    />
  )
}

export default AttendeeGuard
