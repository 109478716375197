import { Button as Btn } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Resizable } from 'react-resizable'
import 'react-resizable/css/styles.css'

const ResizableLogo: FC<{
  isShow: boolean
  hideModal: () => void
  size: { width: number; height: number }
  setSize: ({ width, height }) => void
  imgSrc: string | undefined
  logoSize: string | null | undefined
  setLogoSize: any
}> = ({ isShow, hideModal, size, setSize, imgSrc, setLogoSize }) => {
  const { t } = useTranslation()
  const [temp, setTemp] = useState({
    width: size?.width,
    height: size?.height,
  })
  const onResize = (event, { size }) => {
    setTemp({ width: Math.round(size.width), height: Math.round(size.height) })
  }

  const handleSaveSize = () => {
    setSize(temp)
    hideModal()
    setLogoSize(`${temp.width}x${temp.height}`)
  }

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'dashed 1px #2C506C',
    padding: '10px',
  }

  return (
    <Dialog open={isShow} onOpenChange={hideModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='item-center'>
            {t('Custom logo size')}
          </DialogTitle>
          <DialogDescription>
            {t('Drag the logo corner to resize it here')}
          </DialogDescription>
        </DialogHeader>
        <div className='grid gap-4 py-4 m-auto text-center'>
          <Resizable
            style={style}
            width={temp?.width}
            height={temp?.height}
            maxConstraints={[350, (size.height * 350) / size.width]}
            minConstraints={[100, (size.height * 100) / size.width]}
            minCons
            onResize={onResize}
            lockAspectRatio={true}
          >
            <div
              className='box'
              style={{
                width: `${temp.width}px`,
                height: `${temp.height}px`,
              }}
            >
              <img alt='logo' src={imgSrc} />
            </div>
          </Resizable>
          <div>
            {t('Logo size', {
              width: Math.round(temp.width),
              height: Math.round(temp.height),
            })}
          </div>
        </div>
        <DialogFooter>
          <Btn type='submit' onClick={handleSaveSize}>
            {t('Save changes')}
          </Btn>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ResizableLogo
