/* eslint-disable @typescript-eslint/ban-types */
import { ApolloLink } from '@apollo/client'

export const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.operationName.includes('UploadFile')) {
    return forward(operation)
  }

  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename')
  }
  return forward(operation).map(data => {
    return data
  })
})

const omitDeep = (obj: object, key: string | number): object => {
  const keys: Array<any> = Object.keys(obj)
  const newObj: any = {}
  keys.forEach((i: any) => {
    if (i !== key) {
      // @ts-ignore
      const val: any = obj[i]
      if (val instanceof Date) newObj[i] = val
      else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
      else if (typeof val === 'object' && val !== null)
        newObj[i] = omitDeep(val, key)
      else newObj[i] = val
    }
  })
  return newObj
}

const omitDeepArrayWalk = (arr: any, key: string | number) => {
  return arr.map((val: any) => {
    if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
    else if (typeof val === 'object') return omitDeep(val, key)
    return val
  })
}
