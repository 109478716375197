import { makeVar } from '@apollo/client'

const organizationId = localStorage.getItem('organizationId')
export const searchKeyVar = makeVar('')
export const filterByDateVar = makeVar<{
  fromTime: Date | undefined
  toTime: Date | undefined
}>({
  fromTime: undefined,
  toTime: undefined,
})
export const organizationIdVar = makeVar(organizationId || '')
export const userFilterByRoleVar = makeVar(undefined)
export const userFilterByStatusVar = makeVar(undefined)
