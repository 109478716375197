export enum TwoFactorMethod {
  NONE = 'none',
  PHONE = 'phone',
  SMS = 'sms',
}

// export const getTwoFactorMethod = (
//   method: string | undefined
// ): TwoFactorMethod => {
//   if (method === 'SMS') {
//     return TwoFactorMethod.SMS
//   } else if (method === 'PHONE') {
//     return TwoFactorMethod.PHONE
//   } else {
//     return TwoFactorMethod.NONE
//   }
// }
