import TwoFactorAuthForm from '@/components/pages/OwnAccount/components/TwoFactorAuthen/TwoFactorToggle'
import PhoneNumberForm from '@/components/pages/OwnAccount/components/PhoneNumberForm/page'
import { ChangePasswordForm } from '@/components/pages/OwnAccount/components/change-password/ChangePasswordForm'
import { TwoFactorMethods } from './TwoFactorAuthen/TwoFactorMethods'

export default function SettingsAuthPage() {
  return (
    <div className='space-y-8 flex-1 lg:max-w-2xl'>
      <PhoneNumberForm />
      <TwoFactorAuthForm />
      <TwoFactorMethods />
      <ChangePasswordForm />
    </div>
  )
}
