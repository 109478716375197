import { FC } from 'react'
import { styled } from 'utils/adapters'

type OverlayProps = {
  className?: string
  isToggle: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}
const Overlay: FC<OverlayProps> = ({ isToggle, onClick, className }) => {
  return (
    <OverlayWrapper
      className={className}
      isToggle={isToggle}
      onClick={onClick}
    />
  )
}

const OverlayWrapper = styled.div<{ isToggle?: boolean }>`
  position: fixed;
  display: ${props => (props.isToggle ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
`

export default Overlay
