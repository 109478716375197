import { getPayments } from '@/services/paymentServices'
import { create } from 'zustand'
import {
  EditCustomFieldState,
  EditCustomFieldType,
  PaymentState,
} from './PaymentState'
import { CUSTOM_FIELD_TYPE } from '@/constants/customFieldType'
import { EventCustomFieldValue } from '@/models/IEvent'

export const usePaymentInfo = create<PaymentState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  amount: 0,
  setAmount: (amount: number) =>
    set({
      amount,
    }),
}))

export const useEditCustomField = create<EditCustomFieldState>(set => ({
  inputDate: {},
  setDate: (inputDate: EditCustomFieldType) =>
    set({
      inputDate,
    }),
  selectedRadio: {},
  setSelectedRadio: (selectedRadio: EditCustomFieldType) =>
    set({
      selectedRadio,
    }),
  inputField: {},
  setInputField: (inputField: EditCustomFieldType) =>
    set({
      inputField,
    }),
  selectedFields: [],
  setSelectedFields: (selectedFields: EventCustomFieldValue[]) =>
    set({
      selectedFields,
    }),
  checkedOption: {},
  setCheckedOption: (checkedOption: EditCustomFieldType) =>
    set({
      checkedOption,
    }),
}))

export const getPaymentInfo = async queryParams => {
  try {
    const { data } = await getPayments(queryParams)
    if (data) {
      const total = data.reduce((sum, payment) => sum + payment.amount, 0)
      usePaymentInfo.getState().setAmount(total)
      return total
    }
  } catch (error) {
    usePaymentInfo.getState().setErrorMessage('')
  }
}

export const getInitialEditCustomField = (initCustomField: any) => {
  const initialText: { [x: string]: EventCustomFieldValue } = {}
  const initialCheckboxes: { [x: string]: any } = {}
  const initialSelectors: EventCustomFieldValue[] = []
  const initialDate: { [x: string]: EventCustomFieldValue } = {}
  const initialRadio: { [x: string]: EventCustomFieldValue } = {}

  initCustomField?.forEach(customField => {
    const customFieldType = customField?.eventCustomField?.type
    const customFieldId = customField?.eventCustomField?._id
    if (customFieldType === CUSTOM_FIELD_TYPE.TEXT) {
      initialText[customFieldId] = {
        eventCustomField: customFieldId,
        value: customField?.value?.map(({ fee, name }) => ({ name, fee })),
      }
    } else if (customFieldType === CUSTOM_FIELD_TYPE.DATE_TIME) {
      initialDate[customFieldId] = {
        eventCustomField: customFieldId,
        value: customField?.value,
      }
    } else if (customFieldType === CUSTOM_FIELD_TYPE.CHECK_BOX) {
      customField?.value?.forEach(option => {
        initialCheckboxes[`${option?.name}${customFieldId}`] = {
          value: true,
          id: customFieldId,
          customValues: option,
        }
      })
    } else if (customFieldType === CUSTOM_FIELD_TYPE.SELECT_BOX) {
      initialSelectors.push({
        eventCustomField: customFieldId,
        value: customField?.value,
      })
    } else if (customFieldType === CUSTOM_FIELD_TYPE.RADIO_BUTTON) {
      initialRadio[`${customField?.eventCustomField?.title}${customFieldId}`] =
        {
          eventCustomField: customFieldId,
          value: customField?.value,
        }
    }
  })

  return {
    initialRadio,
    initialDate,
    initialText,
    initialSelectors,
    initialCheckboxes,
  }
}

export const setInitialEditCustomField = (initCustomField: any) => {
  const {
    initialRadio,
    initialDate,
    initialText,
    initialSelectors,
    initialCheckboxes,
  } = getInitialEditCustomField(initCustomField)
  useEditCustomField.getState().setSelectedRadio(initialRadio)
  useEditCustomField.getState().setDate(initialDate)
  useEditCustomField.getState().setInputField(initialText)
  useEditCustomField.getState().setSelectedFields(initialSelectors)
  useEditCustomField.getState().setCheckedOption(initialCheckboxes)
}
