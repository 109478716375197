import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NetworkStatus } from '@apollo/client'

import { styled } from 'utils/adapters'
import { IEvent } from 'models'
import EventItem from '../EventItem'
import EventLoader from 'components/molecules/Skeleton/EventLoader'
import { GopherProximity } from '@/components/organisms/Events/components/EventList/GopherProximity'
import { cn } from '@/lib/utils'
import { Card, CardContent } from '@/components/ui/card'
import { Label } from '@/components/ui/label'

interface IEventList {
  data: any
  loading: boolean
  networkStatus: NetworkStatus
}

export const EventList: FC<IEventList> = ({ data, loading, networkStatus }) => {
  const { t } = useTranslation()

  const scrollPosition = sessionStorage.getItem('scrollPosition')
  const eventsFiltered = data?.events

  useEffect(() => {
    if (data && data.events && data.events?.length) {
      if (scrollPosition) {
        document.documentElement?.scrollTo({
          top: parseInt(scrollPosition),
          left: 0,
          behavior: 'smooth',
        })
        sessionStorage.removeItem('scrollPosition')
      }
    }
  }, [data])

  if (loading || networkStatus === NetworkStatus.refetch)
    return (
      <LoadingWrapper>
        <EventLoader />
      </LoadingWrapper>
    )

  return (
    <GopherProximity>
      <div
        className={cn(
          'event-items grid grid-cols-1 gap-6'
          // eventsFiltered?.length <= 1 ? 'lg:grid-cols-1' : 'lg:grid-cols-2'
        )}
      >
        {eventsFiltered?.length > 0 && (
          <>
            {eventsFiltered.map((event: IEvent, index: number) => (
              <EventItem key={event._id} idx={index} event={event} />
            ))}
          </>
        )}

        {eventsFiltered && eventsFiltered.length === 0 && (
          <Card className='w-full p-6'>
            <CardContent className='flex items-center justify-center'>
              <Label className='text-lg text-gray-500'>
                {t('No events found')}
              </Label>
            </CardContent>
          </Card>
        )}
      </div>
    </GopherProximity>
  )
}

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2%;
  gap: 1rem;
`
