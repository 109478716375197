import { create } from 'zustand'
import { QRState } from './QRState'
import { QRAttendeeDetail } from '@/models/IEvent'
import { getQRInfoReq } from '@/services/qrservices'

export const useQRInfo = create<QRState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) =>
    set({
      isLoading,
    }),
  qrInfo: null,
  setQrInfo: (qrInfo: QRAttendeeDetail) =>
    set({
      qrInfo,
    }),
}))

export const getQRInfo = async (
  eventId: string,
  attendeeId: string,
  agendaURL?: string
) => {
  try {
    useQRInfo.getState().setIsLoading(true)

    const { data } = await getQRInfoReq(eventId, attendeeId)
    if (data) {
      useQRInfo.getState().setQrInfo(data.data)
      useQRInfo.getState().setIsLoading(false)
    }
  } catch (error: any) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      agendaURL
        ? (window.location.href = agendaURL)
        : (window.location.href = '/')
    }

    useQRInfo.getState().setErrorMessage('')
  }
}
