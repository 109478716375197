import { publicRequest, request } from './request'

export const receive2FA = (method: string) => {
  return request.request({
    method: 'POST',
    url: '/auth/receive-2fa',
    data: {
      method,
    },
  })
}

export const enable2FA = (
  code: string,
  method: string,
  refreshToken: string
) => {
  return request.request({
    method: 'POST',
    url: '/auth/enable-2fa',
    data: {
      code,
      method,
    },
    headers: {
      refresh_token: refreshToken,
    },
  })
}

export const disable2FA = (password: string, refreshToken: string) => {
  return request.request({
    method: 'POST',
    url: '/auth/disable-2fa',
    data: {
      password,
    },
    headers: {
      refresh_token: refreshToken,
    },
  })
}

export const verify2FA = (code: string) => {
  return request.request({
    method: 'POST',
    url: '/auth/verify-2fa',
    data: {
      code,
    },
  })
}

export const update2FAMethod = (code: string, method: string) => {
  return request.request({
    method: 'POST',
    url: '/auth/update-2fa-method',
    data: {
      code,
      method,
    },
  })
}

export const getToken = (refreshToken: string) => {
  return publicRequest.request({
    method: 'POST',
    url: '/auth/refresh',
    headers: {
      refresh_token: refreshToken,
    },
  })
}

export const logout = () => {
  return request.request({
    method: 'POST',
    url: '/auth/logout',
  })
}

export const getEncryptedTokenReq = (returnUrl: string) => {
  return request.request({
    method: 'POST',
    url: '/auth/encrypt-token',
    data: {
      returnUrl,
    },
  })
}

export const updatePhoneNumberOnboardingReq = (data: {
  email: string
  phoneNumber: string
}) => {
  return request.request({
    method: 'POST',
    url: '/users/update-phone-number-onboarding',
    data,
  })
}
