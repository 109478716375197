import { create } from 'zustand'
import { InvitationTemplateState } from './InvitationTemplate'
import {
  TEventInvitationTemplate,
  TInvitationTemplate,
} from 'models/IInvitationTemplate'
import {
  getEventInvitationTemplateReq,
  getListInvitationTemplateReq,
  updateEventInvitationTemplateReq,
} from 'services/invitationTemplateServices'

export const useInvitationTemplate = create<InvitationTemplateState>(set => ({
  errorMessage: '',
  setErrorMessage: (message: string) =>
    set({
      errorMessage: message,
    }),
  isLoading: false,
  setIsLoading: (isLoading: boolean) =>
    set({
      isLoading,
    }),
  invitationTemplateList: [],
  setInvitationTemplateList: (invitationTemplateList: TInvitationTemplate[]) =>
    set({
      invitationTemplateList,
    }),
  eventInvitationTemplate: null,
  setEventInvitationTemplate: (
    eventInvitationTemplate: TEventInvitationTemplate
  ) =>
    set({
      eventInvitationTemplate,
    }),
  templateDetailPreview: null,
  setTemplateDetailPreview: (templateDetailPreview: any) =>
    set({
      templateDetailPreview,
    }),
  selectedTemplate: '',
  setSelectedTemplate: (selectedTemplate: null | string) =>
    set({
      selectedTemplate,
    }),
}))

export const getInvitationTemplateList = async (eventId: string) => {
  try {
    useInvitationTemplate.getState().setIsLoading(true)

    const { data } = await getListInvitationTemplateReq(eventId)
    if (data) {
      useInvitationTemplate.getState().setInvitationTemplateList(data.data)
      useInvitationTemplate.getState().setIsLoading(false)
    }
  } catch (error: any) {
    useInvitationTemplate.getState().setErrorMessage('')
  }
}

export const getEventInvitationTemplate = async (eventId: string) => {
  try {
    useInvitationTemplate.getState().setIsLoading(true)

    const { data } = await getEventInvitationTemplateReq(eventId)
    if (data) {
      useInvitationTemplate.getState().setEventInvitationTemplate(data.data)
      useInvitationTemplate.getState().setIsLoading(false)
    }
  } catch (error: any) {
    useInvitationTemplate.getState().setErrorMessage('')
  }
}

export const updateEventInvitationTemplate = async (eventId: string, data) => {
  try {
    const res = await updateEventInvitationTemplateReq(eventId, data)

    return res.data.data
  } catch (error: any) {
    useInvitationTemplate.getState().setErrorMessage('')
  }
}
