import { Turnstile } from '@marsidev/react-turnstile'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { Environment } from '@/environments'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import { EMAIL_REGEX, MAX_LENGTH } from '@/constants/regex'
import { MESSAGE } from '@/constants/message'
import { zodResolver } from '@hookform/resolvers/zod'

interface LoginFormProps {
  onSubmit: (values: any) => void
  onForgotPasswordClick: () => void
  isLoading: boolean
}

export const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  onForgotPasswordClick,
  isLoading,
}) => {
  const { t } = useTranslation()

  const language = (localStorage.getItem('i18nextLng') as string) || 'en'

  const formSchema = z.object({
    email: z
      .string()
      .min(1, { message: t(MESSAGE.REQUIRED_FIELD) })
      .max(MAX_LENGTH.COMMON_FIELD, {
        message: t(MESSAGE.TOO_LONG, {
          max: MAX_LENGTH.COMMON_FIELD,
        }),
      })
      .regex(EMAIL_REGEX, { message: t(MESSAGE.INVALID_EMAIL) }),
    password: z.string().min(1, { message: t(MESSAGE.REQUIRED_FIELD) }),
  })

  type FormValues = z.infer<typeof formSchema>

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })

  const [turnstileStatus, setTurnstileStatus] = useState<
    'pending' | 'solved' | 'error' | 'expired'
  >('pending')

  const handleSubmit = (values: FormValues) => {
    if (turnstileStatus !== 'solved') {
      return
    }
    onSubmit(values)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className='space-y-6'>
        <div className='space-y-4'>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('Email')}</FormLabel>
                <FormControl>
                  <Input placeholder='email@example.com' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({ field }) => (
              <FormItem>
                <div className='flex justify-between items-center'>
                  <FormLabel>{t('Password')}</FormLabel>
                  <Button
                    variant='link'
                    className='p-0 h-auto font-normal'
                    onClick={onForgotPasswordClick}
                    type='button'
                  >
                    {t('Forgot password?')}
                  </Button>
                </div>
                <FormControl>
                  <Input type='password' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Turnstile
            siteKey={import.meta.env.VITE_TURNSTILE_SITE_KEY}
            options={{
              action: 'login',
              theme: 'light',
              language,
            }}
            onError={() => setTurnstileStatus('error')}
            onExpire={() => setTurnstileStatus('expired')}
            onSuccess={() => setTurnstileStatus('solved')}
          />
        </div>
        <Button
          className='w-full'
          disabled={isLoading || turnstileStatus !== 'solved'}
          type='submit'
        >
          {isLoading && <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />}
          {t('Sign In with Email')}
        </Button>
      </form>
      {Environment.nodeEnv !== 'production' &&
        Environment.nodeEnv !== 'staging' && (
          <div className='grid grid-cols-4 gap-1'>
            <Button
              variant='outline'
              onClick={() =>
                onSubmit({
                  email: import.meta.env.VITE_ROOT_ADMIN_ROLE_EMAIL,
                  password: import.meta.env.VITE_ROOT_ADMIN_ROLE_PASSWORD,
                })
              }
            >
              Root admin
            </Button>
            <Button
              variant='outline'
              onClick={() =>
                onSubmit({
                  email: import.meta.env.VITE_ADMIN_ROLE_EMAIL,
                  password: import.meta.env.VITE_ADMIN_ROLE_PASSWORD,
                })
              }
            >
              Admin
            </Button>
            <Button
              variant='outline'
              onClick={() =>
                onSubmit({
                  email: import.meta.env.VITE_EVENT_ORGANIZER_ROLE_EMAIL,
                  password: import.meta.env.VITE_EVENT_ORGANIZER_ROLE_PASSWORD,
                })
              }
            >
              Organizer
            </Button>
            <Button
              variant='outline'
              onClick={() =>
                onSubmit({
                  email: import.meta.env.VITE_ATTENDEE_ROLE_EMAIL,
                  password: import.meta.env.VITE_ATTENDEE_ROLE_PASSWORD,
                })
              }
            >
              Attendee
            </Button>
          </div>
        )}
    </Form>
  )
}
