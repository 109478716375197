import { FC } from 'react'
import { useQuery } from '@apollo/client'
import { useSearch } from 'utils/hooks'
import useOrganizationIdVar from 'utils/hooks/useOrganizationIdVar'

import { GET_EVENTS } from 'graphql/queries'
import { EventList } from './EventList'
import { SORT_BY_TYPE, SORT_TYPE } from 'constants/sortEvent'
import useDateRange from '@/utils/hooks/useDateRange'
import Loading from '@/components/atoms/Loading'
import { isRootAdmin } from '@/utils/helpers'

type AdminEventListListType = {
  tabState: boolean
  orderBy: SORT_BY_TYPE
  direction: SORT_TYPE
}

const AdminEventList: FC<AdminEventListListType> = ({
  tabState,
  orderBy,
  direction,
}) => {
  const { searchKey } = useSearch()
  const { organizationId } = useOrganizationIdVar()
  const { filterByDate } = useDateRange()

  const {
    data: eventData,
    loading: eventDataLoading,
    networkStatus,
  } = useQuery(GET_EVENTS, {
    variables: {
      filter: {
        title: searchKey.length > 0 ? searchKey : '',
        isExpired: tabState,
        filterBy: orderBy,
        direction: direction,
        ...(filterByDate.fromTime &&
          filterByDate.toTime && {
            fromTime: filterByDate.fromTime.toISOString(),
            toTime: filterByDate.toTime.toISOString(),
          }),
      },
      organizationId: organizationId ? organizationId : undefined,
    },
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'network-only',
    fetchPolicy: 'network-only',
  })

  if (isRootAdmin() && !organizationId) {
    return <Loading />
  }

  return (
    <EventList
      data={eventData}
      loading={eventDataLoading}
      networkStatus={networkStatus}
    />
  )
}

export default AdminEventList
