import { FC } from 'react'

// services

// actions

const Auth: FC = ({ children }) => {
  //   const dispatch = useDispatch();

  //   useEffect(() => {
  //     function initAuth() {
  //     //   authService.handleAuthentication();

  //     //   if (authService.isAuthenticated()) {
  //     //     const user = authService.getUser();
  //     //     const parseUser = JSON.parse(user);
  //     //     dispatch(setUserData(parseUser.username, parseUser.roleUser));
  //       }
  //     }
  //     initAuth()
  //   }, [dispatch])

  return <>{children}</>
}

export default Auth
