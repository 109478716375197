import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { COLORS } from '@/constants/colors'
import { MESSAGE } from '@/constants/message'
import { IStatus } from '@/models/IStatus'
import { createStatus, updateStatus } from '@/store/zustand/StatusManagement'
import useClickOutside from '@/utils/hooks/useClickOutside'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC, useEffect, useRef, useState } from 'react'
import { ColorPicker, useColor } from 'react-color-palette'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

type AddStatusModalProps = {
  open: boolean
  onClose: any
  title: string
  selectedOrg: string
  selectedStatus: IStatus | null
  setSelectedStatus: (status: IStatus | null) => void
}

const AddStatusModal: FC<AddStatusModalProps> = ({
  open,
  onClose,
  title,
  selectedOrg,
  selectedStatus,
  setSelectedStatus,
}) => {
  const { t } = useTranslation()

  const [toggleColorPicker, setToggleColorPicker] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const colorPickerRef = useRef(null)

  const [bgColor, setBgColor] = useColor('hex', COLORS.BLUE)

  useEffect(() => {
    if (selectedStatus) {
      form.setValue('name', selectedStatus.name)
      form.setValue('description', selectedStatus.name)
      setBgColor({ ...bgColor, hex: selectedStatus.bgColor })
    }
  }, [selectedStatus])

  useClickOutside(colorPickerRef, () => {
    setToggleColorPicker(false)
  })

  const formSchema = z.object({
    name: z.string().nonempty({ message: t(MESSAGE.REQUIRED_FIELD) }),
    description: z.string(),
  })

  type FormValues = z.infer<typeof formSchema>

  const defaultValues: Partial<FormValues> = {
    name: '',
    description: '',
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const onSubmit = async (values: any) => {
    setIsLoading(true)
    // update
    if (selectedStatus) {
      await updateStatus(selectedOrg, {
        ...values,
        _id: selectedStatus._id,
        bgColor: bgColor.hex,
      })
    } else {
      // add new
      await createStatus(selectedOrg, {
        ...values,
        bgColor: bgColor.hex,
      })
    }
    setIsLoading(false)
    onClose()
    resetData()
  }

  const resetData = () => {
    form.reset({ name: '', description: '' })
    setBgColor({ ...bgColor, hex: COLORS.BLUE })
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => {
        onClose(), resetData()
        setSelectedStatus(null)
      }}
    >
      <DialogContent className='sm:max-w-[425px]'>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
            <div className='grid gap-6 py-4'>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => (
                  <FormItem>
                    <div className='grid grid-cols-4 items-center gap-4'>
                      <FormLabel className='text-right col-span-1'>
                        {t('status_management.column.status')}
                      </FormLabel>
                      <div className='col-span-3 relative'>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <div className='absolute top-11'>
                          <FormMessage />
                        </div>
                      </div>
                    </div>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='description'
                render={({ field }) => (
                  <FormItem className='grid grid-cols-4 items-center gap-4'>
                    <FormLabel className='text-right col-span-1'>
                      {t('status_management.modal.desc')}
                    </FormLabel>
                    <FormControl>
                      <div className='col-span-3'>
                        <Input {...field} />
                      </div>
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className='grid grid-cols-4 items-center gap-4'>
                <Label htmlFor='username' className='text-right'>
                  {t('status_management.modal.bgColor')}
                </Label>
                <div className='col-span-3'>
                  <div
                    style={{
                      background: `${bgColor.hex}`,
                    }}
                    className='w-[40px] h-[40px]'
                    onClick={() => setToggleColorPicker(!toggleColorPicker)}
                  />
                  {toggleColorPicker && (
                    <div
                      className='w-[215px] h-[200px] absolute z-3 inset-0 flex items-center justify-center top-[30%] left-[45%]'
                      ref={colorPickerRef}
                    >
                      <ColorPicker
                        width={300}
                        height={120}
                        color={bgColor}
                        onChange={(color: any) => {
                          setBgColor(color)
                        }}
                        hideHSV
                        hideRGB
                        dark
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <DialogFooter className='gap-2'>
              <Button variant='outline' onClick={onClose} type='button'>
                {t('status_management.modal.cancel_btn')}
              </Button>
              <Button type='submit' disabled={isLoading}>
                {isLoading && (
                  <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
                )}
                {t('status_management.modal.save_btn')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default AddStatusModal
