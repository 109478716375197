import { request, publicRequest } from './request'

export const getFeedbacksList = params => {
  return request.request({
    method: 'GET',
    url: '/v1/feedback',
    params,
  })
}

// for event feedback form admin
export const createEventFeedbackReq = (eventId: string, data) => {
  return request.request({
    method: 'POST',
    url: `/v2/admin/events/${eventId}/feedback`,
    data,
  })
}

export const getListEventFeedbackReq = (eventId: string) => {
  return request.request({
    method: 'GET',
    url: `/v2/admin/events/${eventId}/attendees/feedback`,
  })
}

export const getEventFeedbackStatisticsReq = (eventId: string) => {
  return request.request({
    method: 'GET',
    url: `/v2/admin/events/${eventId}/feedback-analytics`,
  })
}

// api for admin & client
export const getEventFeedbackReq = (eventId: string) => {
  return publicRequest.request({
    method: 'GET',
    url: `/v2/events/${eventId}/feedback-form`,
  })
}

// api for client
export const clientSendEventFeedbackReq = (eventId: string, data) => {
  return publicRequest.request({
    method: 'POST',
    url: `/v2/events/${eventId}/attendee/feedback`,
    data,
  })
}

export const getAttendeeFeedbackReq = (eventId: string, attendeeId: string) => {
  return publicRequest.request({
    method: 'GET',
    url: `/v2/events/${eventId}/attendees/${attendeeId}/feedback`,
  })
}
