import { createSlice } from '@reduxjs/toolkit'

const appReducer = createSlice({
  name: 'app',
  initialState: {
    lang: 'en',
  },
  reducers: {
    setAppLang: (state, action) => {
      state.lang = action.payload
    },
  },
})

export const { setAppLang } = appReducer.actions
export default appReducer.reducer
