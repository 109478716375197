import { request } from './request'

export const updateAttendeeDetailReq = (
  eventId: string,
  attendeeId: string,
  data
) => {
  return request.request({
    method: 'PATCH',
    url: `/v2/admin/events/${eventId}/attendees/${attendeeId}/details`,
    data,
  })
}
