import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  SimplePaginationLink,
  SimplePaginationNext,
  SimplePaginationPrevious,
} from '@/components/ui/pagination'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { pageSizeOptions } from '@/constants'
import { LIMIT_PAGINATION } from '@/constants/changesLog'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { cn } from '@/lib/utils'
import { BasicTableSearchInput } from 'components/molecules/BasicTableSearchInput'
import { Button } from '@/components/ui/button'
import { ArrowDownIcon, ArrowUpIcon, ChevronsUpDownIcon } from 'lucide-react'

type BasicTableProps = {
  columns: any
  data: any
  filters?: any
  setFilters?: any
  columnVisibility?: any
  loading?: boolean
  selectedRows?: any
  onRowSelectionChange?: any
  isPaginationByClient?: boolean
  isGlobalSearchable?: boolean
  globalSearchPlaceholder?: string
  totalItems: number
  hasPagination?: boolean
}

type ColumnSort = {
  id: string
  desc: boolean
}
type SortingState = ColumnSort[]

const BasicTable: FC<BasicTableProps> = ({
  columns,
  data,
  filters,
  setFilters,
  columnVisibility,
  loading,
  selectedRows,
  onRowSelectionChange,
  isPaginationByClient = false,
  isGlobalSearchable = false,
  globalSearchPlaceholder = '',
  totalItems,
  hasPagination = true,
}) => {
  const { t } = useTranslation()

  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageSize: LIMIT_PAGINATION[0],
      },
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      rowSelection: selectedRows,
      globalFilter,
      sorting,
    },
    onRowSelectionChange: onRowSelectionChange,
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(), 
    onSortingChange: setSorting,
  })

  const currentPageIndex = isPaginationByClient
    ? table.getState().pagination.pageIndex
    : filters?.page - 1

  const itemsPerPage = table.getState().pagination.pageSize
  const totalPages = isPaginationByClient
    ? table.getPageCount()
    : Math.ceil(totalItems / itemsPerPage)
  const maxPageToDisplay = 3

  let startPage, endPage

  const calculatePagination = () => {
    if (totalPages <= maxPageToDisplay) {
      // Less than or equal to 3 pages, show all pages
      startPage = 0
      endPage = totalPages - 1
    } else if (currentPageIndex === 0) {
      // Current page is the first page
      startPage = 0
      endPage = 2
    } else if (currentPageIndex === totalPages - 1) {
      // Current page is the last page
      startPage = totalPages - 3
      endPage = totalPages - 1
    } else {
      // Current page is in the middle
      startPage = currentPageIndex - 1
      endPage = currentPageIndex + 1
    }

    const pages: number[] = []
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }
    return pages
  }

  const pages = calculatePagination()

  return (
    <div className='w-full'>
      <div className='mb-5 flex justify-between'>
        {isGlobalSearchable && (
          <BasicTableSearchInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            className='p-2 font-lg shadow border border-block w-[100%] sm:w-96'
            placeholder={globalSearchPlaceholder}
          />
        )}
      </div>

      <div className={cn('overflow-x-auto rounded-md border')}>
        <Table>
          <TableHeader className='bg-gray-100 border-b'>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow
                key={headerGroup.id}
                className='hover:bg-gray-200 transition-colors'
              >
                {headerGroup.headers.map(header => {
                  const canDisplayFilter =
                    header.column.getCanFilter() &&
                    // @ts-ignore
                    header.column.columnDef.Filter
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className='py-3 px-4 text-left text-sm font-semibold text-gray-700 uppercase tracking-wider'
                      style={{
                        width:
                          header.getSize() !== 150
                            ? header.getSize()
                            : undefined,
                      }}
                    >
                      <div
                        className={`flex items-center justify-between ${
                          canDisplayFilter ? 'space-x-2' : ''
                        }`}
                      >
                        {header.isPlaceholder ? null : (
                          <span className='flex items-center'>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.getCanSort() && (
                              <Button
                                variant='ghost'
                                size='sm'
                                className='ml-2 h-8 w-8 p-0'
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                <span className='sr-only'>Sort</span>
                                {header.column.getIsSorted() === 'asc' ? (
                                  <ArrowUpIcon className='h-4 w-4' />
                                ) : header.column.getIsSorted() === 'desc' ? (
                                  <ArrowDownIcon className='h-4 w-4' />
                                ) : (
                                  <ChevronsUpDownIcon className='h-4 w-4' />
                                )}
                              </Button>
                            )}
                          </span>
                        )}
                        {canDisplayFilter && (
                          <span className='cursor-pointer'>
                            {/* @ts-ignore */}
                            <header.column.columnDef.Filter
                              column={header.column}
                            />
                          </span>
                        )}
                      </div>
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>

          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  <div className='flex justify-center items-center'>
                    <svg
                      className='animate-spin h-5 w-5 mr-3 text-primary'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                        fill='none'
                      />
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      />
                    </svg>
                    {t('Loading')}...
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : table.getRowModel().rows.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  <div className='flex flex-col items-center justify-center space-y-2'>
                    <svg
                      className='w-12 h-12 text-gray-400'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth={2}
                        d='M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                      />
                    </svg>
                    <p className='font-medium text-gray-500'>
                      {t('No results found')}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {table.getRowModel().rows.map(row => {
                return (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      return (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          )}
        </Table>
      </div>

      {/* pagination */}
      {hasPagination && (
        <Pagination className='gap-2 mt-6 flex flex-col sm:flex-row items-center overflow-auto'>
          <PaginationContent className='p-0'>
            <PaginationItem>
              <SimplePaginationPrevious
                onClick={() =>
                  isPaginationByClient
                    ? table.previousPage()
                    : setFilters({ ...filters, page: filters?.page - 1 })
                }
                disabled={
                  isPaginationByClient
                    ? !table.getCanPreviousPage()
                    : filters?.page <= 1
                }
              />
              {}
            </PaginationItem>
            {totalPages > maxPageToDisplay && (
              <PaginationItem className='hidden md:block'>
                <SimplePaginationLink
                  onClick={() => {
                    isPaginationByClient
                      ? table.setPageIndex(0)
                      : setFilters({ ...filters, page: 1 })
                  }}
                  disabled={
                    isPaginationByClient
                      ? !table.getCanPreviousPage()
                      : filters?.page <= 1
                  }
                >
                  {t('First')}
                </SimplePaginationLink>
              </PaginationItem>
            )}

            {startPage > 0 && (
              <PaginationItem className='hidden md:block'>
                <PaginationEllipsis />
              </PaginationItem>
            )}

            {pages.map((page, index) => (
              <PaginationItem key={index}>
                <SimplePaginationLink
                  className={`bg-none hover:bg-none hover:text-black cursor-pointer ${
                    page == currentPageIndex ? 'bg-primary text-white' : ''
                  }`}
                  onClick={() => {
                    isPaginationByClient
                      ? table.setPageIndex(page)
                      : setFilters({ ...filters, page: page + 1 })
                  }}
                >
                  {page + 1}
                </SimplePaginationLink>
              </PaginationItem>
            ))}
            {endPage < totalPages - 1 && (
              <PaginationItem className='hidden md:block'>
                <PaginationEllipsis />
              </PaginationItem>
            )}
            {totalPages > maxPageToDisplay && (
              <PaginationItem className='hidden md:block'>
                <SimplePaginationLink
                  onClick={() => {
                    isPaginationByClient
                      ? table.setPageIndex(totalPages - 1)
                      : setFilters({ ...filters, page: totalPages })
                  }}
                  disabled={
                    isPaginationByClient
                      ? !table.getCanNextPage()
                      : filters?.page >= totalPages
                  }
                >
                  {t('Last')}
                </SimplePaginationLink>
              </PaginationItem>
            )}

            <PaginationItem>
              <SimplePaginationNext
                onClick={() =>
                  isPaginationByClient
                    ? table.nextPage()
                    : setFilters({ ...filters, page: filters?.page + 1 })
                }
                disabled={
                  isPaginationByClient
                    ? !table.getCanNextPage()
                    : data?.length == 0 ||
                      totalPages === 1 ||
                      currentPageIndex === totalPages - 1
                }
              />
            </PaginationItem>
          </PaginationContent>
          <Select
            value={table.getState().pagination.pageSize.toString()}
            onValueChange={value => {
              table.setPageSize(Number(value))
              !isPaginationByClient &&
                setFilters({ ...filters, limit: Number(value) })
            }}
          >
            <SelectTrigger className='w-[180px]'>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {pageSizeOptions.map(pageSize => (
                  <SelectItem key={pageSize} value={pageSize.toString()}>
                    {t('Show')} {pageSize}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </Pagination>
      )}
    </div>
  )
}

export default BasicTable
