import { FC, useEffect, useState } from 'react'
import { useQuery } from 'utils/adapters'
import { GET_CURRENT_USER } from 'graphql/queries'
import { IUser } from 'models'
import { USER_ROLE } from 'constants/userRole'

type PropsType = {
  scope?: string
  role?: string
  ignoreRootAdmin?: boolean
  isShow?: boolean
}

const Permission: FC<PropsType> = ({
  role,
  scope,
  children,
  ignoreRootAdmin = false,
  isShow = true,
}) => {
  const { data } = useQuery(GET_CURRENT_USER)
  const [user, setUser] = useState<IUser>()
  useEffect(() => {
    if (data) {
      setUser(data.currentUser)
    }
  }, [data])

  const permissionRender = () => {
    if (!ignoreRootAdmin && user?.roles?.includes(USER_ROLE.ROOT_ADMIN)) {
      return children
    }

    if (role && scope) {
      if (
        user?.roles?.includes(role as string) &&
        user?.scopes?.includes(scope as string)
      ) {
        return children
      }
      return null
    }

    if (user?.roles?.includes(role as string)) {
      return children
    }

    if (user?.scopes?.includes(scope as string)) {
      return children
    }

    return null
  }

  return <>{user && isShow && permissionRender()}</>
}

export default Permission
