import { gql } from 'utils/adapters'

export const CREATE_EVENT = gql`
  mutation CreateEvent($createEventInput: CreateEventInput!) {
    createEvent(createEventInput: $createEventInput) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      emailAttachments {
        filename
        uri
      }
      isExpired
      startEvent
      endEvent
      price
      isBookable
      eventSetting {
        primaryColor
        bgColor
        bgColorOpacity
        logo
        eventPicture
        backgroundPicture
        backgroundTransparent
      }
      isBusinessEvent
    }
  }
`

export const ADD_OR_UPDATE_CANCELATION_POLICIES = gql`
  mutation CreateOrUpdateCancellationPolicies(
    $cancellationPolicies: [UpdateCancellationPolicyInput!]!
    $eventId: ID!
  ) {
    createOrUpdateCancellationPolicies(
      cancellationPolicies: $cancellationPolicies
      eventId: $eventId
    ) {
      message
      status
    }
  }
`

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      encoding
      uri
      filename
      mimetype
      fileKey
    }
  }
`

export const UPLOAD_FILES = gql`
  mutation UploadFiles($files: [Upload!]!) {
    uploadFiles(files: $files) {
      encoding
      uri
      filename
      mimetype
      fileKey
    }
  }
`

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($updateEventInput: UpdateEventInput!) {
    updateEvent(updateEventInput: $updateEventInput) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      currency
      attendeesCount
      isExpired
      type
    }
  }
`

export const MOVE_EVENT_ORGANIZATION = gql`
  mutation moveEventOrganization($eventId: ID!, $orgId: ID!) {
    moveEventOrganization(eventId: $eventId, orgId: $orgId) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      currency
      attendeesCount
      isExpired
      type
    }
  }
`

export const REGISTER_EVENT = gql`
  mutation RegisterEvent(
    $attendee: CreateAttendeeInput!
    $eventId: ID!
    $orderId: ID
  ) {
    registerEvent(attendee: $attendee, eventId: $eventId, orderId: $orderId) {
      status
      message
    }
  }
`

export const DUPLICATE_EVENT = gql`
  mutation DuplicateEvent(
    $eventId: ID!
    $organizationId: ID
    $duplicateEventInput: DuplicateEventInput!
  ) {
    duplicateEvent(
      eventId: $eventId
      organizationId: $organizationId
      duplicateEventInput: $duplicateEventInput
    ) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      currency
      attendeesCount
      isExpired
      type
    }
  }
`

export const REMOVE_EVENT = gql`
  mutation Mutation($removeEventId: ID!) {
    removeEvent(id: $removeEventId) {
      _id
      title
      description
      address {
        city
        country
        street
      }
      startEvent
      endEvent
      price
      currency
      attendeesCount
      isExpired
      type
    }
  }
`

export const SEND_NEWSLETTER = gql`
  mutation SendNewsletter($eventNewsletterInput: EventNewsletterInput!) {
    sendNewsletter(eventNewsletterInput: $eventNewsletterInput) {
      message
      status
    }
  }
`

export const SEND_TEST_NEWSLETTER = gql`
  mutation SendTestEmailNewsletter(
    $eventNewsletterInput: EventNewsletterInput!
    $email: String!
  ) {
    sendTestEmailNewsletter(
      eventNewsletterInput: $eventNewsletterInput
      email: $email
    ) {
      message
      status
    }
  }
`

export const SEND_TEST_MAIL_EVENT = gql`
  mutation SendTestMailEvent($sendTestMailInput: TestEmailInput!) {
    sendTestMailEvent(sendTestMailInput: $sendTestMailInput) {
      message
      status
    }
  }
`
