import { Currency } from '@/models/IEvent'
import { DollarSign, Euro } from 'lucide-react'
import { FC } from 'react'

type BadgeCurrencyProps = {
  currency?: string | Currency
  className?: string
  strokeWidth?: number
}
export const BadgeCurrency: FC<BadgeCurrencyProps> = ({
  currency,
  className,
  strokeWidth = 2,
}) => {
  switch (currency) {
    case 'EUR':
      return (
        <Euro
          width='18'
          height='18'
          className={className}
          style={{ strokeWidth: `${strokeWidth}px` }}
        />
      )
    default:
      return (
        <DollarSign
          width='18'
          height='18'
          className={className}
          style={{ strokeWidth: `${strokeWidth}px` }}
        />
      )
  }
}
