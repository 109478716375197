import Portal from 'portal'
import { FC, useEffect } from 'react'
import { CheckIcon, RemoveIcon, InfoIcon, WarningIcon } from 'assets/images'

import { setIsShowToast } from 'store/Toast'

import { keyframes, styled } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import Icon from 'components/atoms/Icon'
import { COLORS } from 'constants/colors'

const Toast: FC = () => {
  const dispatch = useAppDispatch()
  const { variant, message, displayTime, isShowToast } = useAppSelector(
    state => state.toastReducer
  )

  useEffect(() => {
    if (displayTime) {
      setTimeout(() => {
        dispatch(setIsShowToast(false))
      }, displayTime)
    }
  }, [displayTime, isShowToast])

  return isShowToast ? (
    <Portal>
      <NotificationContainer>
        <ToastContainer>
          <Line variant={variant}></Line>
          {renderToast(variant).icon}
          <Message>{message}</Message>
        </ToastContainer>
      </NotificationContainer>
    </Portal>
  ) : null
}

const renderToast = (type?: string): any => {
  switch (type) {
    case 'success':
      return {
        backgroundColor: `${COLORS.SUCCESS}`,
        icon: <ToastIcon source={CheckIcon} />,
      }

    case 'danger':
      return {
        backgroundColor: `${COLORS.DANGER}`,
        icon: <ToastIcon source={RemoveIcon} />,
      }

    case 'info':
      return {
        backgroundColor: `${COLORS.INFO}`,
        icon: <ToastIcon source={InfoIcon} />,
      }

    case 'warning':
      return {
        backgroundColor: `${COLORS.WARNING}`,
        icon: <ToastIcon source={WarningIcon} />,
      }

    default:
      return
  }
}

const slide = keyframes`
from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
`

const NotificationContainer = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999;
  top: 12px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  animation: ${slide} 0.7s;
`

const Notification = styled.div`
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  width: 300px;
  max-height: 100px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-position: 15px;
  background-repeat: no-repeat;
`

const ToastContainer = styled(Notification)<{ variant?: string }>`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 365px;
  height: 65px;
  min-height: 40px;
  padding-right: 15px;
  color: gray;
`

const Line = styled.div<{ variant?: string }>`
  height: 100%;
  box-sizing: border-box;
  width: 4px;
  background-color: ${props => {
    return renderToast(props.variant)?.backgroundColor
  }};
`

const Message = styled.p`
  display: flex;
  word-break: break-word;
  padding: 10px 0;
`

const ToastIcon = styled(Icon)`
  img {
    filter: none;
  }
`

export default Toast
