import axios from 'axios'
import { getToken } from './authServices'
import { matchPath } from 'react-router'
import { PATH_NAME } from '@/routes/routesMap'

export const request = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})
export const publicRequest = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

publicRequest.interceptors.response.use(
  response => response.data,
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.request.use(
  (config: any) => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN')
    if (accessToken) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    } else {
      return config
    }
  },
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config

    const pathname = error.config.url
    const pattern = PATH_NAME.QR_ATTENDEE_INFO
    const match = matchPath(pathname, { path: pattern, exact: true })
    if (error.response.status === 401 && !match) {
      const refreshToken = localStorage.getItem('REFRESH_TOKEN')
      let token
      if (refreshToken) {
        try {
          const res: any = await getToken(refreshToken)
          if (res) {
            const { accessToken } = res
            token = accessToken
            localStorage.setItem('ACCESS_TOKEN', accessToken)
            // localStorage.setItem('REFRESH_TOKEN', refreshToken)
          }
          originalRequest.headers.Authorization = `Bearer ${token}`
          return request(originalRequest)
        } catch (refreshError) {
          window.location.href = '/'
        }
      } else {
        window.location.href = '/'
      }
    }
    return Promise.reject(error)
  }
)
