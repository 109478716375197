import { FC, useEffect } from 'react'
import { useHistory } from 'utils/adapters'
import { PATH_NAME } from './routesMap'
import { USER_ROLE } from '@/constants/userRole'
import { decryptArray } from '@/utils/helpers/role'

type IProps = {
  requireRoles: string[] | []
}

const RoleRoute: FC<IProps> = ({ children, requireRoles = [] }) => {
  const history = useHistory()
  const user = JSON.parse(sessionStorage.getItem('user') as string)

  useEffect(() => {
    if ((user && user.roles.length === 0) || requireRoles.length === 0) return

    const checkRole =
      requireRoles.some(role => decryptArray(user?.roles).includes(role)) ||
      decryptArray(user?.roles).includes(USER_ROLE.ROOT_ADMIN)

    if (!checkRole) {
      history.replace(PATH_NAME.LOGIN)
    }
  }, [history, user, requireRoles])

  return <>{children}</>
}

export default RoleRoute
