/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'
import { Environment } from 'environments'
import { useMutationEditBooking, useMutationRegisterEvent } from './mixins'
import { useParams } from 'utils/adapters'
import { useAppSelector } from '@/utils/hooks'
import { useDispatch } from 'react-redux'
import { setIsUpdated } from '@/store/Events'

interface IProps {
  children: any
  isUpdateBooking: boolean
}

const SuccessRegister: FC<IProps> = ({ children, isUpdateBooking }) => {
  const stripe = useStripe()
  const dispatch = useDispatch()
  const { isUpdated } = useAppSelector(state => state.eventsReducer)
  const { id } = useParams<{ id: string }>()
  const event = JSON.parse(sessionStorage.getItem('currentEvent') as string)

  const [message, setMessage] = useState<string | undefined>(undefined)

  let attendee = JSON.parse(sessionStorage.getItem('attendee_info') as string)
  const eventId = event?._id || id

  const { mutateRegisterEvent } = useMutationRegisterEvent()
  const { mutateUpdateBooking } = useMutationEditBooking()

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      axios
        .post(`${Environment.serverUrl}/payments/logs`, {
          status: paymentIntent?.status,
          type: 'paymentIntent',
          message: '',
          eventId,
          metadata: {
            attendee,
            ...paymentIntent,
          },
        })
        .then(res => res.data)
        .catch(error => {
          console.error('Error payment intent: ', error)
        })
      if (attendee && eventId) {
        let country = attendee?.address?.country
        if (typeof country !== 'string') {
          country = attendee?.address?.country?.value
        }

        if (isUpdateBooking) {
          const {
            _id,
            email,
            status,
            webcastParticipantId,
            webcastUrlJoinLinks,
            ...rest
          } = attendee
          const customValues = attendee?.registration?.customValues
          rest.registration = {
            customValues,
          }
          attendee = rest
        }

        const formatAttendee = {
          ...attendee,
          address: {
            ...attendee.address,
            country,
          },
        }

        // console.log('payment intent: ', paymentIntent?.status)

        switch (paymentIntent?.status) {
          case 'succeeded':
            if (!isUpdated) {
              isUpdateBooking
                ? mutateUpdateBooking({
                    variables: {
                      attendee: formatAttendee,
                      eventId,
                      orderId: paymentIntent.id,
                    },
                  })
                : mutateRegisterEvent({
                    variables: {
                      attendee: formatAttendee,
                      eventId,
                      orderId: paymentIntent.id,
                    },
                  })
            }
            setMessage('Payment succeeded!')
            dispatch(setIsUpdated(true))
            break
          case 'processing':
            sessionStorage.setItem('isPendingPayment', JSON.stringify(true))
            isUpdateBooking
              ? mutateUpdateBooking({
                  variables: {
                    attendee: formatAttendee,
                    eventId,
                    orderId: `processing|${paymentIntent.id}`,
                  },
                })
              : mutateRegisterEvent({
                  variables: {
                    attendee: formatAttendee,
                    eventId,
                    orderId: `processing|${paymentIntent.id}`,
                  },
                })

            setMessage('Your payment is processing.')
            dispatch(setIsUpdated(true))
            break
          case 'requires_payment_method':
            setMessage('Your payment was not successful, please try again.')
            break
          default:
            setMessage('Something went wrong.')
            break
        }
      }
    })
  }, [stripe])

  return children
}

export default SuccessRegister
