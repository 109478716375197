import { FC } from 'react'
import { useParams } from 'utils/adapters'
import { UserRole } from 'constants/userRole'
import OrganizationFormWrapper from './components/OrganizationFormWrapper'

const OrganizationWrapper: FC = () => {
  return <OrganizationFormWrapper userRole={UserRole.RootAdmin} />
}

export default OrganizationWrapper
