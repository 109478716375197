import { FC } from 'react'
import OrganizationsContainer from '../OrganizationsContainer'
import { AdminEventList, AttendeeEventList } from './components/EventList'
import { usePersistedState } from 'utils/hooks'
import EventTab from './components/EventTab'
import EventActions from './components/Action'
import { SORT_BY_TYPE, SORT_TYPE } from 'constants/sortEvent'

const Events: FC<{
  isAttendee: boolean
}> = ({ isAttendee }) => {
  const [tabState, setTab] = usePersistedState('tabState', false)
  const [eventOrderBy, setEventOrderBy] = usePersistedState(
    'eventOrderBy',
    SORT_BY_TYPE.START_DATE
  )
  const [eventDirection, setEventDirection] = usePersistedState(
    'eventDirection',
    SORT_TYPE.DESC
  )
  return (
    <>
      {!isAttendee && (
        <div className='mx-auto'>
          <OrganizationsContainer />
        </div>
      )}
      <EventTab isExpired={tabState} activeTab={index => setTab(!!index)} />
      <EventActions
        orderBy={eventOrderBy}
        direction={eventDirection}
        setOrderBy={setEventOrderBy}
        setDirection={setEventDirection}
      />
      {isAttendee ? (
        <AttendeeEventList
          tabState={tabState}
          orderBy={eventOrderBy}
          direction={eventDirection}
        />
      ) : (
        <AdminEventList
          tabState={tabState}
          orderBy={eventOrderBy}
          direction={eventDirection}
        />
      )}
    </>
  )
}

export default Events
