import { FormItem, FormLabel } from '@/components/ui/final-form'
import { getLanguage } from '@/constants/language'
import axios from 'axios'
import Loading from 'components/atoms/Loading'
import ReactSelectAdapter from 'components/atoms/Select'
import { Environment } from 'environments'
import { GET_PROFESSIONALS, GET_SALUTATIONS } from 'graphql/queries'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setAttendeeInfo, setStep } from 'store/Events'
import { Field, Form, FormSpy, styled, useLazyQuery, useQuery } from 'utils/adapters'
import {
  composeValidators,
  emailValidation,
  getCountryByName,
  getListCountriesByLanguage,
  maxLengthValidation,
  postCodeValidation,
  requiredValidation,
  Step,
  trimWhiteSpace
} from 'utils/helpers'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { SelectAdapter } from '@/components/ui/select-adapter'
import { COLORS } from '@/constants/colors'
import { EventType } from '@/constants/events'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { isEmpty } from 'lodash'
import { CustomSelections } from '../../../components/AttendeeInfo/CustomSelection'

const AttendeeForm: FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const appLanguage = localStorage.getItem('i18nextLng')
  const { event, attendeeInfo } = useAppSelector(state => state.eventsReducer)

  const [salutationList, setSalutationList] = useState<
    { key: string; value: string }[]
  >([])

  useEffect(() => {
    getSalutationList()
  }, [appLanguage])

  const [
    getSalutationList,
    { data: salutationData, loading: loadingSalutation }
  ] = useLazyQuery(GET_SALUTATIONS, {
    onCompleted: () => {
      setSalutationList(salutationData?.fetchSalutations?.data)
    },
    onError: ({ message }) => {
      console.log(message)
    },
    variables: {
      language: getLanguage(appLanguage)
    }
  })

  const { data: professionalData, loading: professionalLoading } = useQuery(
    GET_PROFESSIONALS,
    {
      onError: ({ message }) => {
        console.log(message)
      }
    }
  )

  const onSubmit = () => {
    sessionStorage.setItem('eventPrice', event?.price?.toString() as string)

    const attendee = JSON.parse(
      sessionStorage.getItem('attendee_info') as string
    )
    dispatch(setAttendeeInfo(attendee))

    if (event?.customFields?.length === 0) {
      if (!event.isBookable) {
        dispatch(setStep(Step.DetailCost))
      } else {
        axios
          .post(`${Environment.serverUrl}/payments/customers`, {
            email: attendeeInfo.email,
            name: `${attendeeInfo.firstName} ${attendeeInfo.lastName}`,
            phone: attendeeInfo.mobilePhone,
            metadata: {
              email: attendeeInfo.email,
              firstName: attendeeInfo.firstName,
              lastName: attendeeInfo.lastName,
              mobilePhone: attendeeInfo.mobilePhone,
            }
          })
          .then(response => {
            localStorage.setItem(
              'stripeCustomerInfo',
              JSON.stringify(response.data)
            )
          })
          .catch(error => {
            console.log(error)
          })

        dispatch(setStep(Step.DetailCost))
      }
    } else {
      dispatch(setStep(Step.AttendeeCustomField))
    }
  }

  const requiredTextField = composeValidators(
    requiredValidation,
    maxLengthValidation
  )

  const isDigitalEvent = event?.type === EventType.DIGITAL

  const initialValues = {
    ...attendeeInfo,
    address: {
      ...attendeeInfo?.address,
      country: {
        label: getCountryByName(attendeeInfo?.address?.country, appLanguage)
          ?.name,
        value: getCountryByName(attendeeInfo?.address?.country, appLanguage)
          ?.name
      }
    }
  }

  if (isEmpty(attendeeInfo) || loadingSalutation) {
    return <Loading />
  }

  const {
    firstName = {
      enabled: true,
      required: true
    },
    lastName = {
      enabled: true,
      required: true
    },
    salutation = {
      enabled: true,
      required: true
    },
    professional = {
      enabled: true,
      required: false
    },
    jobTitle = {
      enabled: false,
      required: false
    },
    company = {
      enabled: false,
      required: true
    },
    address = {
      street: {
        enabled: true,
        required: !isDigitalEvent
      },
      zipCode: {
        enabled: true,
        required: !isDigitalEvent
      },
      city: {
        enabled: true,
        required: !isDigitalEvent
      },
      country: {
        enabled: true,
        required: !isDigitalEvent
      }
    },
    workPhone = {
      enabled: false,
      required: false
    },
    mobilePhone = {
      enabled: true,
      required: false
    },
    fax = {
      enabled: false,
      required: false
    }
  } = event?.toggleDefaultFields || {}

  return (
    <>
      <div className='py-10'>
        <div className='container mx-auto px-4'>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className='container mx-auto px-4 py-2 flex-wrap justify-around gap-4 md:gap-6 md:py-6'
                >
                  <FormSpy
                    subscription={{ values: true, valid: true }}
                    onChange={(state: any) => {
                      const { values } = state
                      sessionStorage.setItem(
                        'attendee_info',
                        JSON.stringify(values)
                      )
                    }}
                  />
                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                    <div className='col-span-1 lg:w-4/5 w-full space-y-4 lg:mx-auto'>
                      {firstName?.enabled !== false && (
                        <Field
                          name='firstName'
                          type='text'
                          validate={
                            firstName?.required !== false
                              ? composeValidators(requiredTextField, maxLengthValidation)
                              : maxLengthValidation
                          }
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem className='w-full'>
                              <FormLabel meta={meta} isRequired={firstName?.required !== false}>
                                {t('First Name')}
                              </FormLabel>
                              <Input
                                {...input}
                                meta={meta}
                                isRequired={firstName?.required !== false}
                                maxLength='255'
                              />
                            </FormItem>
                          )}
                        </Field>
                      )}

                      {lastName?.enabled !== false && (
                        <Field
                          name='lastName'
                          type='text'
                          validate={
                            lastName?.required !== false
                              ? composeValidators(requiredTextField, maxLengthValidation)
                              : maxLengthValidation
                          }
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem className='w-full'>
                              <FormLabel meta={meta} isRequired={lastName?.required !== false}>
                                {t('Last Name')}
                              </FormLabel>
                              <Input
                                {...input}
                                meta={meta}
                                isRequired={lastName?.required !== false}
                                maxLength='255'
                              />
                            </FormItem>
                          )}
                        </Field>
                      )}
                      {!loadingSalutation &&
                        salutationList?.length &&
                        salutation?.enabled !== false && (
                          <Field
                            name='salutation'
                            component={SelectAdapter}
                            options={salutationList?.map(({ key, value }) => ({
                              label: value || '-',
                              value: key || '-'
                            }))}
                            validate={salutation?.required !== false
                              ? requiredValidation
                              : undefined}
                            title={t('Salutation')}
                            isRequired={salutation?.required !== false}
                          />
                        )}
                      {!professionalLoading &&
                        professional?.enabled !== false &&
                        professionalData?.fetchProfessionals?.data?.length && (
                          <Field
                            name='professional'
                            component={SelectAdapter}
                            options={professionalData?.fetchProfessionals?.data?.map(
                              ({ key, value }) => ({
                                label: value || '-',
                                value: key || '-'
                              })
                            )}
                            validate={
                              professional?.required !== false
                                ? requiredValidation
                                : undefined
                            }
                            title={t('Academic Title')}
                            isRequired={professional?.required !== false}
                          />
                        )}
                      {jobTitle?.enabled !== false && (
                        <Field
                          name='jobTitle'
                          type='text'
                          format={trimWhiteSpace}
                          validate={
                            jobTitle?.required !== false
                              ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                              : maxLengthValidation
                          }
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel
                                meta={meta}
                                isRequired={jobTitle?.required !== false}
                              >
                                {t('Job title')}
                              </FormLabel>
                              <Input {...input} meta={meta} maxLength='255' isRequired={jobTitle?.required !== false} />
                            </FormItem>
                          )}
                        </Field>
                      )}
                      {company?.enabled !== false && (
                        <Field
                          name='company'
                          type='text'
                          validate={
                            company?.required !== false
                              ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                              : maxLengthValidation
                          }
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel meta={meta} isRequired={company?.required !== false}>
                                {t('Company')}
                              </FormLabel>
                              <Input
                                {...input}
                                isRequired={company?.required !== false}
                                meta={meta}
                                maxLength='255'
                              />
                            </FormItem>
                          )}
                        </Field>
                      )}
                      {event?.customSelections && (
                        <CustomSelections data={event?.customSelections} />
                      )}
                    </div>

                    <div className='col-span-1 lg:w-4/5 w-full space-y-4 lg:mx-auto'>
                      {address?.street?.enabled !== false && (
                        <Field
                          name='address.street'
                          type='text'
                          validate={
                            address?.street?.required !== false
                              ? requiredTextField
                              : maxLengthValidation
                          }
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel meta={meta} isRequired={address?.street?.required !== false}>
                                {t('Street')}
                              </FormLabel>
                              <Input
                                {...input}
                                type='text'
                                meta={meta}
                                isRequired={address?.street?.required !== false}
                                maxLength='150'
                              />
                            </FormItem>
                          )}
                        </Field>
                      )}
                      {address?.zipCode?.enabled !== false && (
                        <Field
                          name='address.zipCode'
                          type='text'
                          validate={
                            address?.zipCode?.required !== false
                              ? composeValidators(
                                requiredValidation,
                                maxLengthValidation,
                                postCodeValidation
                              )
                              : composeValidators(
                                maxLengthValidation,
                                postCodeValidation
                              )
                          }
                        >
                          {({ input, meta }: any) => (
                            <FormItem className='w-full'>
                              <FormLabel meta={meta} isRequired={address?.zipCode?.required !== false}>
                                {t('Zip')}
                              </FormLabel>
                              <Input
                                {...input}
                                type='text'
                                meta={meta}
                                isRequired={address?.zipCode?.required !== false}
                              />
                            </FormItem>
                          )}
                        </Field>
                      )}

                      {address?.city?.enabled !== false && (
                        <Field
                          name='address.city'
                          type='text'
                          validate={
                            address?.city?.required !== false
                              ? composeValidators(
                                requiredTextField,
                                maxLengthValidation
                              )
                              : maxLengthValidation
                          }
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem className='w-full'>
                              <FormLabel meta={meta} isRequired={address?.city?.required !== false}>
                                {t('City')}
                              </FormLabel>
                              <Input
                                {...input}
                                isRequired={address?.city?.required !== false}
                                type='text'
                                meta={meta}
                                maxLength='255'
                              />
                            </FormItem>
                          )}
                        </Field>
                      )}
                      {address?.country?.enabled !== false && (
                        <Field
                          className='select-country'
                          name='address.country'
                          validate={
                            address?.country?.required !== false
                              ? composeValidators(
                                requiredTextField,
                                maxLengthValidation
                              )
                              : maxLengthValidation
                          }
                          component={ReactSelectAdapter}
                          placeholder={t('Please select your country')}
                          options={getListCountriesByLanguage(appLanguage).map(
                            country => ({
                              label: country.name,
                              value: country.name,
                              code: country.code
                            })
                          )}
                          title={t('Country')}
                          isRequired={address?.country?.required !== false}
                        />
                      )}

                      <Field
                        name='email'
                        type='text'
                        validate={composeValidators(
                          requiredValidation,
                          maxLengthValidation,
                          emailValidation
                        )}
                        format={trimWhiteSpace}
                        formatOnBlur
                      >
                        {({ input, meta }: any) => (
                          <FormItem>
                            <FormLabel meta={meta} isRequired={true}>
                              {t('Email')}
                            </FormLabel>
                            <Input
                              {...input}
                              meta={meta}
                              isRequired={true}
                              maxLength='255'
                              disabled
                            />
                          </FormItem>
                        )}
                      </Field>

                      {workPhone?.enabled !== false && (
                        <Field
                          name='workPhone'
                          type='text'
                          validate={
                            workPhone?.required !== false
                              ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                              : maxLengthValidation
                          }
                          format={trimWhiteSpace}
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel meta={meta} isRequired={workPhone?.required !== false}>
                                {t('Work phone')}
                              </FormLabel>
                              <Input
                                {...input}
                                meta={meta}
                                isRequired={workPhone?.required !== false}
                                maxLength='20'
                              />
                            </FormItem>
                          )}
                        </Field>
                      )}
                      {mobilePhone?.enabled !== false && (
                        <Field
                          name='mobilePhone'
                          type='text'
                          format={trimWhiteSpace}
                          validate={
                            mobilePhone?.required !== false
                              ? composeValidators(
                                maxLengthValidation,
                                requiredValidation
                              )
                              : maxLengthValidation
                          }
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel meta={meta} isRequired={mobilePhone?.required !== false}>
                                {t('Mobile phone')}
                              </FormLabel>
                              <Input {...input} maxLength='20' meta={meta}
                                     isRequired={mobilePhone?.required !== false} />
                            </FormItem>
                          )}
                        </Field>)}

                      {fax?.enabled !== false && (
                        <Field
                          name='fax'
                          type='text'
                          format={trimWhiteSpace}
                          validate={
                            fax?.required !== false
                              ? composeValidators(
                                requiredValidation,
                                maxLengthValidation
                              )
                              : maxLengthValidation
                          }
                          formatOnBlur
                        >
                          {({ input, meta }: any) => (
                            <FormItem>
                              <FormLabel meta={meta} isRequired={fax?.required !== false}>{t('Fax')}</FormLabel>
                              <Input {...input} meta={meta} maxLength='20' isRequired={fax?.required !== false} />
                            </FormItem>
                          )}
                        </Field>
                      )}
                      <div className='space-y-6 lg:col-start-2'>
                        <SubmitButton
                          primaryColor={event?.eventSetting?.primaryColor}
                          color={setTextColorBasedOnBackground(
                            event?.eventSetting?.primaryColor
                          )}
                          type='submit'
                          className='w-full'
                          disabled={submitting}
                        >
                          {t('Continue')}
                        </SubmitButton>
                      </div>
                    </div>
                  </div>
                </form>
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

const SubmitButton = styled(Button)<{
  primaryColor?: string
  color?: string
}>`
  background-color: ${props => props.primaryColor || COLORS.BLUE};
  color: ${props => props.color};
`

export default AttendeeForm
