import { MESSAGE } from 'constants/message'
import {
  EMAIL_REGEX,
  HOTEL_BOOKING_URL_REGEX,
  MAX_LENGTH,
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
} from 'constants/regex'
import { t } from 'i18next'

export const hotelBookingURLValidation = (value: string) => {
  if (value) {
    if (HOTEL_BOOKING_URL_REGEX.test(value)) {
      return undefined
    } else {
      return t(MESSAGE.INVALID_HOTEL_BOOKING_URL)
    }
  }
}

export const emailValidation = (value: string) => {
  if (value) {
    if (EMAIL_REGEX.test(value)) {
      return undefined
    } else {
      return t(MESSAGE.INVALID_EMAIL)
    }
  }
}

export const phoneNumberValidation = (value: string) => {
  if (value) {
    if (PHONE_NUMBER_REGEX.test(value)) {
      return undefined
    } else {
      return t(MESSAGE.INVALID_PHONE_NUMBER)
    }
  }
}

export const postCodeValidation = (value: string) => {
  // if (value) {
  //   if (ZIP_CODE_REGEX.test(value)) {
  //     return undefined
  //   } else {
  //     return t(MESSAGE.INVALID_ZIP_CODE)
  //   }
  // }
  return undefined
}

export const requiredValidation = (value: string) => {
  if (!value) return t(MESSAGE.REQUIRED_FIELD)
}

export const maxLengthValidation = (value: string) => {
  if (value) {
    if (value.length > MAX_LENGTH.COMMON_FIELD)
      return t(MESSAGE.TOO_LONG, { max: MAX_LENGTH.COMMON_FIELD })
    else return undefined
  }
}

export const passwordValidation = (value: string) => {
  if (value) {
    if (/\s/.test(value)) {
      return t('Password cannot contain spaces')
    } else if (PASSWORD_REGEX.test(value)) {
      return undefined
    } else {
      return t(MESSAGE.INVALID_PASSWORD)
    }
  }
}

export const requiredEmailValidation = (value: string) => {
  if (value && EMAIL_REGEX.test(value)) {
    return undefined
  } else if (!value) {
    return t(MESSAGE.REQUIRED_FIELD)
  } else {
    return t(MESSAGE.INVALID_EMAIL)
  }
}

export const trimWhiteSpace = (value: any) =>
  value === undefined ? '' : value?.trim()

export const trimZeroNumber = (value: any) => {
  return value === undefined || value === null ? null : +value.toString()
}

export const formatPrice = (value: any) => {
  const _value = value?.toString().split('.')
  const input = _value?.shift() + (_value?.length ? '.' + _value?.join('') : '')
  return value === undefined || value === null ? null : input
}

export const blockTypingNonNumberic = (event: any) => {
  if (
    event.keyCode === 69 ||
    event.keyCode === 188 ||
    event.keyCode === 189 ||
    (event.keyCode !== 190 &&
      event.keyCode > 31 &&
      (event.keyCode > 57 || event.keyCode < 48))
  ) {
    return event.preventDefault()
  }
}

export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )

export const validateDescription = (value: string) => {
  if (value && value.length > MAX_LENGTH.MAX_DESCRIPTION_SIZE) {
    return t('Maximum description size ({{length}}/{{size}})', {
      length: value?.length,
      size: MAX_LENGTH.MAX_DESCRIPTION_SIZE,
    })
  }
}
