import { t } from 'i18next'

import { useMutation } from 'utils/adapters'
import { SEND_TEST_MAIL_EVENT, SEND_TEST_NEWSLETTER } from 'graphql/mutations'
import { toast } from '@/components/ui/use-toast'

export const useMutationNewsletter = () => {
  const [mutateSendTestEmailNewsletter, { loading }] = useMutation(
    SEND_TEST_NEWSLETTER,
    {
      onCompleted: ({ sendTestEmailNewsletter }) => {
        if (sendTestEmailNewsletter && sendTestEmailNewsletter.status === 200) {
          toast({
            description: t('Send test email success'),
          })
        }
      },

      onError: err => {
        toast({
          title: t('Error'),
          description: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  return {
    mutateSendTestEmailNewsletter,
    loading,
  }
}

export const useMutationSendTestMail = () => {
  const [mutateSendTestMail, { loading }] = useMutation(SEND_TEST_MAIL_EVENT, {
    onCompleted: ({ sendTestMailEvent }) => {
      if (sendTestMailEvent?.status === 200) {
        toast({
          description: t('Send test email success'),
        })
      }
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  return {
    mutateSendTestMail,
    loading,
  }
}
