import Modal from '../Modal'
import styled from 'styled-components'
import { responsive } from '@/assets/scss/mixin'
import OrganizationTermModalContent from '@/components/pages/EventRegister/components/AttendeeInfo/OrganizationTermModalContent'
import { Button } from '@/components/ui/button'

const TermModal = ({ isShow, primaryColor, terms, setShowTerm }) => {
  return (
    <Modal isShow={isShow} hideFooter={true} hideHeader={true}>
      <Content className='rounded-md' primaryColor={primaryColor}>
        <OrganizationTermModalContent terms={terms} />
        <Button
          className='p-4 w-full max-w-full'
          variant='secondary'
          name='OK'
          onClick={() => setShowTerm(false)}
        >
          OK
        </Button>
      </Content>
    </Modal>
  )
}

const Content = styled.div<{
  primaryColor?: string
}>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: ${props => props.primaryColor};
  color: #fff;

  span {
    font-size: 14px;
    line-height: 24px;
    ${responsive.md`
      max-height: 350px;
      overflow-y: scroll;
      font-size: 12px;
  `}
  }
`

export default TermModal
