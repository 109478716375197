import { cn } from 'lib/utils'
import * as React from 'react'

import { Icons } from './icon'
import { FieldMetaState } from '@/utils/adapters'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  meta?: FieldMetaState<any>
  isRequired?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ meta, className, type, isRequired, placeholder, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false)

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword)
    }

    const inputType = type === 'password' && showPassword ? 'text' : type

    return (
      <div className='relative'>
        <input
          type={inputType}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className,
            meta?.error && meta?.touched && 'border-rose-500'
          )}
          ref={ref}
          // placeholder={isRequired ? `${placeholder} *` : placeholder}
          placeholder={placeholder}
          {...props}
        />
        {type === 'password' && (
          <button
            type='button'
            className='absolute right-3 top-1/2 -translate-y-1/2'
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <Icons.eye className='h-4 w-4 text-gray-500' />
            ) : (
              <Icons.eyeOff className='h-4 w-4 text-gray-500' />
            )}
          </button>
        )}
        {/*{meta?.error && meta?.touched && <ValidationText error={meta?.error} />}*/}
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
