import { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import BasicTable from '@/components/organisms/BasicTable'
import { FULL_DATE_TIMEZONE_FORMAT } from '@/constants/time'
import { defaultTimezone } from '@/utils/helpers'
import { ColumnDef } from '@tanstack/react-table'
import FilterColumnModal from 'components/organisms/FilterModal'
import { FEEDBACK_TYPE } from 'constants/feedback'
import moment from 'moment'

import { Badge } from '@/components/ui/badge'
import {
  getFeedbacksData,
  resetFilters,
  useFeedbacksStore,
} from 'store/zustand/Feedbacks'
import { displayTooltipContent } from '../SendMailLog'
import { Button } from '@/components/ui/button'

const Feedbacks: FC = () => {
  const { t } = useTranslation()

  const lang = localStorage.getItem('i18nextLng') as string

  const feedbackTypeOption = Object.keys(FEEDBACK_TYPE).map(key => ({
    value: FEEDBACK_TYPE[key as keyof typeof FEEDBACK_TYPE],
    label: FEEDBACK_TYPE[key as keyof typeof FEEDBACK_TYPE],
  }))

  const { filters, setFilters, feedbacksData, loading, totalItems } =
    useFeedbacksStore()

  useEffect(() => {
    const params = {
      page: filters.page,
      limit: filters.limit,
    }
    getFeedbacksData(params)
  }, [filters])
  useEffect(() => {
    return () => resetFilters()
  }, [])

  const renderType = (type: string) => {
    switch (type) {
      case FEEDBACK_TYPE.BUG:
        return <Badge variant='destructive'>{type}</Badge>
      case FEEDBACK_TYPE.GENERAL:
        return <Badge variant='secondary'>{type}</Badge>
      case FEEDBACK_TYPE.IDEA:
        return <Badge>{type}</Badge>
    }
  }

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        header: t('No'),
        cell: ({ row }) => <b>{row?.index + 1}</b>,
        size: 50,
      },
      {
        header: t('Email'),
        cell: ({ row }) => <b>{row?.original?.email}</b>,
        size: 100,
      },
      {
        header: t('Path'),
        cell: ({ row }) => <b>{row?.original?.path}</b>,
        size: 50,
      },
      {
        header: t('Organization'),
        accessorKey: 'organization',
        cell: ({ row }) => <b>{row?.original?.organization}</b>,
        size: 200,
        enableSorting: false,
      },
      {
        header: t('Type'),
        accessorKey: 'type',
        cell: ({ row }) => <>{renderType(row?.original?.type)}</>,
        Filter: ({ column }) => (
          <FilterColumnModal column={column} filterData={feedbackTypeOption} />
        ),
        // allow multi filter
        filterFn: (row, id, value) => {
          if (!value || value.length === 0) {
            return true
          }
          return value.includes(row.getValue(id))
        },
        size: 100,
        enableSorting: false,
      },
      {
        header: t('Last updated'),
        accessorKey: 'lastUpdated',
        cell: ({ row }) => {
          const displayDate = moment(
            new Date(Number(row?.original?.lastUpdated))
          )
            .tz(defaultTimezone)
            .format(FULL_DATE_TIMEZONE_FORMAT)
          return (
            <div>
              {displayTooltipContent(
                String(moment(Number(row?.original?.lastUpdated)).fromNow()),
                `${displayDate} GMT`
              )}
            </div>
          )
        },
      },

      {
        header: t('Message'),
        accessorKey: 'message',
        cell: ({ row }) => (
          <>
            <p>{row?.original?.message}</p>
          </>
        ),
        // size: 500,
      },
      {
        header: t('Action'),
        accessorKey: 'action',
        cell: ({ row }) => {
          if (row?.original?.images?.length) {
            return (
              <a
                href={row?.original?.images[0]}
                target='_blank'
                rel='noreferrer'
              >
                <Button variant='outline' size={'sm'}>
                  {t('Image')}
                </Button>
              </a>
            )
          } else {
            return <></>
          }
        },
        enableSorting: false,
      },
    ],
    [lang]
  )

  return (
    <div className='flex flex-col'>
      <div className='mx-auto overflow-x-auto p-[16px] max-w-full md:p-[40px] w-full'>
        <BasicTable
          columns={columns}
          data={feedbacksData}
          filters={filters}
          setFilters={setFilters}
          loading={loading}
          totalItems={totalItems}
        />
      </div>
    </div>
  )
}

export default Feedbacks
