// this is where you'd implement some pagination logic like whether a next page is available, which can then be imported to the DataTable

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { generatePaginationLinks } from './generate-pages'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { pageSizeOptions } from '@/constants'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

type PaginatorProps = {
  currentPage: number
  totalPages: number
  onPageChange: (pageNumber: number) => void
  showPreviousNext: boolean
  pageSize: number
  setPageSize: (pageSize: number) => void
}

export default function Paginator({
  currentPage,
  totalPages,
  onPageChange,
  showPreviousNext,
  pageSize,
  setPageSize,
}: PaginatorProps) {
  const { t } = useTranslation()

  return (
    <Pagination className='mt-6'>
      <PaginationContent className='mr-2.5'>
        {showPreviousNext && totalPages ? (
          <PaginationItem>
            <PaginationPrevious
              onClick={() => onPageChange(currentPage - 1)}
              isActive={!(currentPage - 1 < 1)}
            />
          </PaginationItem>
        ) : null}
        {generatePaginationLinks(currentPage, totalPages, onPageChange)}
        {showPreviousNext && totalPages ? (
          <PaginationItem>
            <PaginationNext
              onClick={() => onPageChange(currentPage + 1)}
              isActive={!(currentPage > totalPages - 1)}
            />
          </PaginationItem>
        ) : null}
      </PaginationContent>
      <Select
        value={pageSize.toString()}
        onValueChange={value => {
          setPageSize(Number(value))
        }}
      >
        <SelectTrigger className='w-[180px]'>
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {pageSizeOptions.map(pageSize => (
              <SelectItem key={pageSize} value={pageSize.toString()}>
                {t('Show')} {pageSize}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </Pagination>
  )
}
