import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FETCH_PLACEHOLDERS } from 'graphql/queries'
import { setEmailPlaceHolders, setFormData, setStep } from 'store/Events'

import { styled, useQuery } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

// import Button from 'components/atoms/Button'
import { Button } from '@/components/ui/button'
import Validation from 'components/atoms/ValidationText'

import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { PATH_NAME } from '@/routes/routesMap'
import { responsive } from 'assets/scss/mixin'
import Editor from 'components/atoms/Editor'
import { ChevronLeft } from 'lucide-react'
import { useParams, useRouteMatch } from 'react-router'
import { EventAction } from '../../../../models'
import useOrganizationIdVar from '../../../../utils/hooks/useOrganizationIdVar'
import TestEmail from '../../../molecules/TestEmail'
import { MAX_LENGTH } from '@/constants/regex'
import { SaveChangesNowButton } from '@/components/molecules/SaveChangesNow'

type ParamsType = {
  id: string
}

const EmailStep: FC = () => {
  const { t } = useTranslation()
  const [content, setContent] = useState('')
  const matchEditRoute = useRouteMatch(PATH_NAME.EDIT_EVENT)
  const { id } = useParams<ParamsType>()

  const dispatch = useAppDispatch()
  const { formData, step, emailPlaceHolders, action } = useAppSelector(
    state => state.eventsReducer
  )

  const { data } = useQuery(FETCH_PLACEHOLDERS)
  const [descriptionError, setDescriptionError] = useState('')

  useEffect(() => {
    if (data) {
      const { fetchPlaceHolders } = data
      dispatch(setEmailPlaceHolders(fetchPlaceHolders))
    }
  }, [data])

  const onSubmit = () => {
    if (formData.emailContent > MAX_LENGTH.MAX_DESCRIPTION_SIZE) {
      const message = `Maximum email content size (${formData.emailContent.length}/${MAX_LENGTH.MAX_DESCRIPTION_SIZE})`
      setDescriptionError(message)
    } else {
      dispatch(
        setFormData({
          ...formData,
          emailContent: content,
        })
      )
      dispatch(setStep(step + 1))
    }
  }

  const handleGoBack = () => {
    dispatch(setStep(step - 1))
  }

  const onChangeEditor = (value: string) => {
    setContent(value)
  }

  const { organizationId } = useOrganizationIdVar()

  return (
    <Wrapper>
      <Container>
        <div>
          <h3 className='text-lg font-medium'>{t('Email Content')}</h3>
          <p className='text-sm text-muted-foreground'>
            {t('Customize confirmation email.')}
          </p>
          <ChevronLeft
            onClick={handleGoBack}
            className={cn(
              'absolute top-[15px] left-14 cursor-pointer md:top-[28px] md:left-24',
              !matchEditRoute && 'text-white left-5 md:left-8'
            )}
          />
        </div>
        <Separator />
        <Editor
          className='content-editor'
          value={formData.emailContent}
          onChange={onChangeEditor}
          isMention={true}
          displayInsertAsHtml
          emailPlaceHolders={
            formData?.webcastEventId || formData?.hasWebcastIntegration
              ? emailPlaceHolders
              : emailPlaceHolders?.filter(
                  ({ name }) => name !== '$eventJoinLink'
                )
          }
          isRawContent={formData.isRawContent}
          setIsRawContent={(by: boolean) => {
            dispatch(
              setFormData({
                ...formData,
                isRawContent: by,
              })
            )
          }}
        />
        <Validation error={descriptionError} />

        <div className='w-full flex justify-center flex-col gap-4 sm:flex-row'>
          {action === EventAction.UPDATE && (
            <div className='w-[100%] sm:w-[50%]'>
              <TestEmail
                disabled={false}
                action={'update-event'}
                files={formData?.emailAttachments}
                eventId={formData._id}
                organizationId={organizationId}
                sendQrCode={formData?.sendQrCode}
              />
            </div>
          )}
          <div className='w-[100%] sm:w-[50%] flex flex-col justify-end gap-4'>
            <Button
              name={t('Continue')}
              type='submit'
              onClick={onSubmit}
              className='w-[100%]'
            >
              {t('Continue')}
            </Button>
            <div className='w-[100%]'>
              {matchEditRoute && (
                <SaveChangesNowButton
                  handleGetFormValues={() => {
                    return {
                      ...formData,
                      emailContent: content,
                    }
                  }}
                  id={id}
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 2%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  gap: 10px;
  ${responsive.lg`
      max-width: 100%;
  `}
`

const SubmitButton = styled(Button)`
  width: 100%;
  margin: 0 auto;
  ${responsive.lg`
      width: 100%;
  `}
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export default EmailStep
