import { Separator } from '@/components/ui/separator'
import { ProfileForm } from './ProfileForm'
import { useTranslation } from 'react-i18next'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'
import { useState } from 'react'

export default function SettingsProfilePage() {
  const { t } = useTranslation()

  const [toggled, setToggled] = useState(localStorage.getItem('enableGopherProximity') === 'true')

  const toggleGopherProximity = () => {
    setToggled(!toggled)
    localStorage.setItem('enableGopherProximity', !toggled + '')
  }

  return (
    <>
      <div className='space-y-6 flex-1 lg:max-w-2xl'>
        <div>
          <h3 className='text-lg font-medium'>{t('Profile')}</h3>
          <p className='text-sm text-muted-foreground'>
            {t('This is how others will see you on the site.')}
          </p>
        </div>
        <Separator />
        <ProfileForm />
      </div>
      <div className=''>
        <div className='space-x-2 flex items-center'>
          <Switch id='airplane-mode' checked={toggled}
                  onCheckedChange={toggleGopherProximity} />
          <Label htmlFor='airplane-mode'>{t('Funny Dashboard')}</Label>
        </div>

      </div>
    </>
  )
}
