import { FC } from 'react'

import { useDispatch } from 'react-redux'

import { setIsPendingPayment, setIsUpdated, setStep } from 'store/Events'
import { useHistory, useParams } from 'utils/adapters'

import Description from './Description'
import SuccessIcon from './SuccessIcon'

import { Step } from '@/utils/helpers'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import ConfettiFireworks from './confetti-fireworks'
import { useTranslation } from 'react-i18next'

interface ParamsType {
  id?: string
}

const SuccessRegister: FC<{ isPendingPayment?: boolean }> = ({
  isPendingPayment,
}) => {
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()

  const dispatch = useDispatch()

  const history = useHistory()

  const removeLocalStorage = () => {
    localStorage.removeItem('inputDate')
    localStorage.removeItem('inputField')
    localStorage.removeItem('selectedFields')
    localStorage.removeItem('selectedRadio')
    localStorage.removeItem('isChecked')
  }

  const handleBackToEvent = () => {
    sessionStorage.clear()
    dispatch(setStep(Step.AttendeeForm))
    dispatch(setIsPendingPayment(false))
    dispatch(setIsUpdated(false))
    removeLocalStorage()
    history.push(`/events/${id}`)
  }

  return (
    <Dialog open={true} onOpenChange={handleBackToEvent}>
      <DialogContent
        className={`w-[400px] md:w-1/2 ${
          isPendingPayment ? 'bg-yellow-800' : 'bg-primary'
        }`}
      >
        <ConfettiFireworks />
        <div className='flex flex-col items-center justify-center text-white p-5'>
          <SuccessIcon isPendingPayment={isPendingPayment} />
          <Description isPendingPayment={isPendingPayment} />
          <Button
            variant='secondary'
            onClick={handleBackToEvent}
            className='mt-4'
          >
            {t('register_event.back_to_event')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SuccessRegister
