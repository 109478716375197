import { FC } from 'react'
import { styled } from 'utils/adapters'
import Icon from 'components/atoms/Icon'
import { LikeIcon, SandClockIcon } from 'assets/images'
import { responsive } from 'assets/scss/mixin'

const SuccessIcon: FC<{ isPendingPayment?: boolean }> = ({
  isPendingPayment,
}) => {
  return (
    <IconBlockFirst>
      <IconBlockSecond>
        <IconBlockThird>
          {isPendingPayment ? (
            <StyleIcon source={SandClockIcon} />
          ) : (
            <StyleIcon source={LikeIcon} />
          )}
        </IconBlockThird>
      </IconBlockSecond>
    </IconBlockFirst>
  )
}

const IconBlock = styled.div`
  padding: 20px;
  border-radius: 50%;
  ${responsive.sm`
    padding: 15px;
  `}
`

const IconBlockFirst = styled(IconBlock)`
  background-color: rgba(255, 255, 255, 0.08);
`

const IconBlockSecond = styled(IconBlock)`
  background-color: rgba(255, 255, 255, 0.11);
`

const IconBlockThird = styled(IconBlock)`
  background-color: rgba(255, 255, 255, 0.13);
`

const StyleIcon = styled(Icon)`
  padding: 10px;
  img {
    width: 60px;
    height: 60px;
    ${responsive.md`
        width: 40px;
        height: 40px;
    `}
  }
`

export default SuccessIcon
