import { FC } from 'react'
import { styled } from 'utils/adapters'
import Icon from 'components/atoms/Icon'
import { Error404 } from 'assets/images'
import { responsive } from 'assets/scss/mixin'

const NotFound: FC<{ errorMessage?: string }> = ({
  errorMessage,
  children,
}) => {
  const notFoundMessage = 'The page you requested cannot be found'
  return (
    <Wrapper>
      <Container>
        <Icon404 source={Error404} />
        <Title>Oops! Something&apos;s wrong here.</Title>
        <Text>{errorMessage ? errorMessage : notFoundMessage}</Text>
        {children}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  ${responsive.sm`
    gap: 10px;
  `}
`
const Icon404 = styled(Icon)`
  img {
    filter: none;
    width: 200px;
    height: 200px;
  }
  ${responsive.sm`
  img{
    width: 150px;
    height: 150px;
  }
  `}
`

const Title = styled.p`
  font-size: 2em;
  text-align: center;
  ${responsive.sm`
    font-size: 1.5em;
  `}
`

const Text = styled.p`
  font-size: 1em;
  color: #d5d5d5;
  font-weight: 500;
  text-align: center;
  ${responsive.sm`
    font-size: 14px;
  `}
`
export default NotFound
