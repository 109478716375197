import { Button } from '@/components/ui/button'
import { Icons } from '@/components/ui/icon'
import { toast } from '@/components/ui/use-toast'
import { UPDATE_EVENT } from '@/graphql/mutations'
import { CustomField } from '@/models'
import { clearFormData, setStep } from '@/store/Events'
import { AdminEventStep } from '@/utils/helpers'
import { useAppDispatch } from '@/utils/hooks'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

type SaveChangesNowButtonType = {
  id?: string
  handleGetFormValues: any
  disabled?: boolean
}
export const SaveChangesNowButton: FC<SaveChangesNowButtonType> = ({
  id,
  handleGetFormValues,
  disabled = false,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const [mutateUpdateEvent, { loading: updateLoading }] = useMutation(
    UPDATE_EVENT,
    {
      onCompleted: data => {
        if (data && !updateLoading) {
          toast({
            title: t('Update event success!'),
            variant: 'default',
          })
          history.push(`/admin/events/${id}`)
          resetPage()
        }
      },

      onError: err => {
        toast({
          title: t(err.message),
          variant: 'destructive',
        })
      },
    }
  )

  const resetPage = () => {
    dispatch(setStep(AdminEventStep.EventDetailForm))
    dispatch(clearFormData())
    localStorage.removeItem('selected_custom_field_index')
    localStorage.removeItem('selected_custom_field')
  }

  const handleSaveChangesNow = (values: any) => {
    const country = values?.address?.country?.value
    const eventForm = {
      ...values,
      capacity: values.capacity !== null ? Number(values.capacity) : null,
      address: {
        ...values.address,
        country,
      },
      startEvent: moment(values.startEvent).toDate().toISOString(),
      endEvent: moment(values.endEvent).toDate().toISOString(),
    }

    delete eventForm['attendeesCount']
    delete eventForm['webcastEventId']

    let totalCustomFieldFee = 0
    eventForm?.customFields?.forEach((customField: CustomField) => {
      customField?.options?.forEach(
        option => (totalCustomFieldFee += option?.fee)
      )
      return +totalCustomFieldFee
    })

    if (
      values.isBookable ||
      (!values.isBookable && +totalCustomFieldFee === 0)
    ) {
      mutateUpdateEvent({
        variables: {
          updateEventInput: {
            ...eventForm,
            organization: eventForm.organization._id,
          },
        },
      })
    }
  }

  return (
    <Button
      type='button'
      variant='outline'
      className='w-full bg-accent'
      onClick={() => {
        const values = handleGetFormValues()
        handleSaveChangesNow(values)
      }}
      disabled={updateLoading || disabled}
    >
      {updateLoading && <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />}
      {t('Save Changes Now')}
    </Button>
  )
}
