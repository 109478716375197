import { gql } from 'utils/adapters'

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $createOrganizationInput: CreateOrganizationInput!
  ) {
    createOrganization(createOrganizationInput: $createOrganizationInput) {
      _id
      email
      name
      isRemoved
      capacity
      defaultConfirmationEmail
    }
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $updateOrganizationInput: UpdateOrganizationInput!
  ) {
    updateOrganization(updateOrganizationInput: $updateOrganizationInput) {
      _id
      email
      isRemoved
      name
      capacity
      defaultConfirmationEmail
    }
  }
`

export const REMOVE_ORGANIZATION = gql`
  mutation RemoveOrganization($removeOrganizationId: ID!) {
    removeOrganization(id: $removeOrganizationId) {
      _id
      email
      isRemoved
      name
      capacity
    }
  }
`
export const ACTIVE_ORGANIZATION = gql`
  mutation ActiveOrganization($activeOrganizationId: ID!) {
    activeOrganization(id: $activeOrganizationId) {
      _id
      capacity
      createdAt
      email
      isRemoved
      name
    }
  }
`
