import React, { FC, useState } from 'react'

import { isEqual } from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Select from 'react-select'
import { registerLocale } from 'react-datepicker'
import { setStep, setTotalPrice } from 'store/Events'

import { styled, useParams } from 'utils/adapters'
import { defaultDateFormat, renderSymbolCurrency, Step } from 'utils/helpers'
import { useAppDispatch, useAppSelector, usePersistedState } from 'utils/hooks'

import { Button } from '@/components/ui/button'
import Input from 'components/atoms/InputNoTransition'
import Checkbox from 'components/atoms/Checkbox'
import RadioButton from 'components/atoms/RadioButton'
import ValidationText from 'components/atoms/ValidationText'
import CustomFieldItem from './CustomFieldItem'
import Loading from './Loading'

import {
  checkMissingRequiredField,
  compareDataSelected,
  customFieldDataType,
  mapCheckedObject,
  mapSelectedFields,
  transformSelectedOption,
} from './customFieldMixin'
import { CustomField, CustomFieldOption } from 'models'

import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'
import { MESSAGE } from 'constants/message'
import { CUSTOM_FIELD_TYPE } from 'constants/customFieldType'

import tr from 'date-fns/locale/tr'
import de from 'date-fns/locale/de'
import { NewStyledDatePicker } from '@/components/atoms/NewDatePicker/new-datepicker'
import { toast } from '@/components/ui/use-toast'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import Term from '../AttendeeInfo/Term'
import TermModal from '@/components/molecules/TermModal'
import { GET_EVENT_TERMS } from '@/graphql/queries/event'

registerLocale('tr', tr)
registerLocale('de', de)

const initCustomField = {
  1: {
    eventCustomField: '',
    value: [
      {
        name: '',
        fee: 0,
        currency: '',
      },
    ],
  },
}
interface ParamsType {
  id: string
}

const AttendeeCustomField: FC = () => {
  const { t } = useTranslation()
  const appLanguage = localStorage.getItem('i18nextLng')
  const { id } = useParams<ParamsType>()

  const { isLoading, eventTerms } = useAppSelector(state => state.eventsReducer)
  const dispatch = useAppDispatch()
  const attendeeInfo = JSON.parse(
    sessionStorage.getItem('attendee_info') as string
  )
  const event = JSON.parse(sessionStorage.getItem('currentEvent') as string)

  const [inputDate, setDate] = usePersistedState('inputDate', {})
  const [inputField, setInputField] = usePersistedState(
    'inputField',
    initCustomField
  )
  const [selectedFields, setSelectedFields] = usePersistedState(
    'selectedFields',
    []
  )
  const [selectedRadio, setSelectedRadio] = usePersistedState(
    'selectedRadio',
    initCustomField
  )
  const [checkedOption, setCheckedOption] = usePersistedState(
    'isChecked',
    initCustomField
  )
  const [missingRequired, setMissingRequired] = useState<CustomField[]>([])

  const [isAcceptedTerm, setIsAcceptedTerm] = useState(false)
  const [showTerm, setShowTerm] = useState(false)

  const mapDataToStandard = () => {
    const radioFields = Object.values(selectedRadio)
    const textFields = Object.values(inputField as customFieldDataType).filter(
      item => item.value[0].name !== ''
    )
    const dateFields = Object.values(inputDate)
    const listChecked = mapCheckedObject(checkedOption)

    const checkedOptions = Object.values(
      transformSelectedOption(listChecked)
    ) as customFieldDataType[]

    const selectedOptions = mapSelectedFields(
      transformSelectedOption(selectedFields)
    ) as customFieldDataType[]

    const standardData = [
      ...textFields,
      ...checkedOptions,
      ...dateFields,
      ...selectedOptions,
      ...radioFields,
    ] as customFieldDataType[]

    const _standardData = standardData.filter(item => item.eventCustomField)

    return _standardData
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const customFields = mapDataToStandard()

    const isRequired = validateRequiredField(event?.customFields, customFields)

    if (isRequired) {
      customFields.map(field => {
        const value = field.value as unknown as CustomFieldOption[]
        value.map(value => {
          event.price += value.fee
        })
      })

      if (customFields.length !== 0) {
        dispatch(setTotalPrice(event.price))
      }

      sessionStorage.setItem(
        'attendee_info',
        JSON.stringify({
          ...attendeeInfo,
          registration: {
            customValues: customFields,
          },
        })
      )

      dispatch(setTotalPrice(event.price))
      dispatch(setStep(Step.DetailCost))
    } else {
      toast({
        title: t('Error'),
        description: t('Please fill in all the required fields'),
        variant: 'destructive',
      })
    }
  }

  const validateRequiredField = (requiredArray: [], formArray: any[]) => {
    const requiredArr = requiredArray?.filter((requiredField: any) => {
      return requiredField.isRequired
    })

    const formArr = event.customFields.filter((eventObject: any) => {
      if (eventObject.isRequired) {
        return formArray.find((formDataObject: any) => {
          return formDataObject.eventCustomField === eventObject._id
        })
      }
    })

    const missingRequiredFields = requiredArr.filter(item => {
      return !formArr.includes(item)
    })

    setMissingRequired(missingRequiredFields)

    return isEqual(requiredArr, formArr)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { name, value } = event.target

    setInputField({
      ...inputField,
      [name]: {
        eventCustomField: id,
        value: [{ name: value, fee: 0 }],
      },
    })
  }

  const handleCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    option: CustomFieldOption
  ) => {
    setCheckedOption({
      ...checkedOption,
      [event.target.name]: {
        id,
        customValues: option,
        value: event.target.checked,
      },
    })
  }

  const handleDateChange = (date: Date, id: string, index: number) => {
    const selectedDate = moment(date).format('YYYY-MM-DD')

    setDate({
      ...inputDate,
      [index]: {
        eventCustomField: id,
        value: [{ name: selectedDate, fee: 0 }],
      },
    })
  }

  const handleSelectChange = (selectedValue: any, id: string) => {
    const cloneSelectedField = [...selectedFields]
    const value = selectedValue.map((data: any) => data.value)

    const selectedField = {
      eventCustomField: id,
      value,
    }
    const foundIndex = cloneSelectedField.findIndex(
      (el: any) => el.eventCustomField === id
    )
    if (foundIndex !== -1) {
      if (selectedField.value.length > 0) {
        cloneSelectedField[foundIndex].value = selectedField.value
      } else {
        cloneSelectedField.splice(foundIndex, 1)
      }
    } else {
      cloneSelectedField.push(selectedField)
    }
    setSelectedFields(cloneSelectedField)
  }

  const handleRadioChange =
    (option: CustomFieldOption, id: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedRadio({
        ...selectedRadio,
        [event.target.name]: {
          eventCustomField: id,
          value: [option],
        },
      })
    }

  const renderLabel = (value: CustomFieldOption) => {
    if (value.fee !== null && value.fee && value.fee > 0) {
      return `${value?.name} +${value?.fee}${renderSymbolCurrency(
        event?.currency
      )}`
    } else {
      return `${value?.name}`
    }
  }

  const renderErrorMessage = (type: string, customField: CustomField) => {
    const isMissingRequired = checkMissingRequiredField(
      missingRequired,
      customField
    )
    if (isMissingRequired) {
      if (type === CUSTOM_FIELD_TYPE.TEXT)
        return <ErrorMessage error={t(MESSAGE.REQUIRED_FIELD)} />

      if (type === CUSTOM_FIELD_TYPE.CHECK_BOX)
        return <ValidationText error={t(MESSAGE.REQUIRED_MULTI_OPTIONS)} />

      if (type === CUSTOM_FIELD_TYPE.RADIO_BUTTON)
        return <ValidationText error={t(MESSAGE.REQUIRED_OPTION)} />

      if (type === CUSTOM_FIELD_TYPE.SELECT_BOX)
        return <ValidationText error={t(MESSAGE.REQUIRED_MULTI_OPTIONS)} />

      if (type === CUSTOM_FIELD_TYPE.DATE_TIME)
        return <ValidationText error={t(MESSAGE.REQUIRED_FIELD)} />
    }
    return null
  }

  return (
    <>
      {isLoading && <Loading />}
      <Wrapper submitLoading={isLoading}>
        <FormContainer onSubmit={onSubmit}>
          {event?.customFields?.map(
            (customField: CustomField, index: number) => {
              if (customField.type === CUSTOM_FIELD_TYPE.TEXT) {
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : event?.eventSetting?.primaryColor
                    }
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    customField={customField}
                    type={customField.type}
                  >
                    <StyledInput
                      type='text'
                      name={index}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(event, customField._id)
                      }
                      value={
                        inputField[index]?.value[0].name
                          ? inputField[index]?.value[0].name
                          : ''
                      }
                      label={
                        <span
                          className='ql-link pointer-events-auto'
                          dangerouslySetInnerHTML={{
                            __html: customField.title,
                          }}
                        />
                      }
                      isRequired={customField.isRequired}
                      isShowSpace={checkMissingRequiredField(
                        missingRequired,
                        customField
                      )}
                    />
                  </CustomFieldItem>
                )
              }

              if (customField.type === CUSTOM_FIELD_TYPE.CHECK_BOX) {
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : event?.eventSetting?.primaryColor
                    }
                    isDangerouslyTitle
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                  >
                    <BoxContainer>
                      {customField.options.map(
                        (option: CustomFieldOption, i: number) => (
                          <BoxWrapper key={i}>
                            <Checkbox
                              name={`${option.name}${customField._id}`}
                              checked={
                                checkedOption[
                                  `${option.name}${customField._id}`
                                ]?.value
                              }
                              onChange={event =>
                                handleCheckbox(event, customField._id, option)
                              }
                              color={event?.eventSetting?.primaryColor}
                            />
                            <Label>{renderLabel(option)}</Label>
                          </BoxWrapper>
                        )
                      )}
                    </BoxContainer>
                  </CustomFieldItem>
                )
              }

              if (customField.type === CUSTOM_FIELD_TYPE.DATE_TIME) {
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : event?.eventSetting?.primaryColor
                    }
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    isDangerouslyTitle
                  >
                    <NewStyledDatePicker
                      locale={appLanguage || 'en'}
                      dateFormat={defaultDateFormat}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      onChange={(date: Date) =>
                        handleDateChange(date, customField._id, index)
                      }
                      value={
                        inputDate
                          ? inputDate[index]?.value[0]?.name
                          : new Date()
                      }
                    />
                  </CustomFieldItem>
                )
              }

              if (customField.type === CUSTOM_FIELD_TYPE.SELECT_BOX) {
                const options = customField.options.map(
                  (value: CustomFieldOption) => ({
                    id: customField._id,
                    label: renderLabel(value),
                    value,
                  })
                )
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : event?.eventSetting?.primaryColor
                    }
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    isDangerouslyTitle
                  >
                    <Select
                      isMulti
                      styles={{ ...customStyles(event) }}
                      placeholder='Select box'
                      options={options}
                      value={compareDataSelected(options, selectedFields) || []}
                      onChange={selectedValue =>
                        handleSelectChange(selectedValue, customField._id)
                      }
                    />
                  </CustomFieldItem>
                )
              }

              if (customField.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON) {
                return (
                  <CustomFieldItem
                    key={index}
                    borderColor={
                      checkMissingRequiredField(missingRequired, customField)
                        ? COLORS.DANGER
                        : event?.eventSetting?.primaryColor
                    }
                    customField={customField}
                    errorMessage={renderErrorMessage(
                      customField.type,
                      customField
                    )}
                    isDangerouslyTitle
                  >
                    <BoxContainer>
                      {customField.options.map(
                        (option: CustomFieldOption, i: number) => (
                          <BoxWrapper key={i}>
                            <RadioButton
                              name={`${customField.title}${customField._id}`}
                              value={JSON.stringify(option)}
                              checked={isEqual(
                                selectedRadio[
                                  `${customField.title}${customField._id}`
                                ]?.value[0],
                                option
                              )}
                              onChange={handleRadioChange(
                                option,
                                customField._id
                              )}
                              color={event?.eventSetting?.primaryColor}
                            >
                              {renderLabel(option)}
                            </RadioButton>
                          </BoxWrapper>
                        )
                      )}
                    </BoxContainer>
                  </CustomFieldItem>
                )
              }
            }
          )}

          <Term
            checked={isAcceptedTerm}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setIsAcceptedTerm(event.target.checked)
            }
            onClick={() => setShowTerm(true)}
          />

          <SubmitButton
            disabled={!isAcceptedTerm}
            primaryColor={event?.eventSetting?.primaryColor}
            color={setTextColorBasedOnBackground(
              event?.eventSetting?.primaryColor
            )}
          >
            {t('Continue')}
          </SubmitButton>
        </FormContainer>

        <TermModal
          isShow={showTerm}
          primaryColor={event?.eventSetting?.primaryColor}
          terms={eventTerms}
          setShowTerm={setShowTerm}
        />
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ submitLoading?: boolean }>`
  padding: 2% 4%;
  opacity: ${props => (props.submitLoading ? 0.5 : 1)};
  min-height: 100%;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  gap: 10px;
  max-width: 650px;
`

const ErrorMessage = styled(ValidationText)`
  position: absolute;
  bottom: 10px;
`

const Label = styled.span`
  font-size: 16px;
  color: ${COLORS.PRIMARY};
  ${responsive.md`
    font-size: 12px;
  `}
`

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  box-sizing: border-box;
`

const BoxWrapper = styled.div`
  display: flex;
  gap: 5px;
  position: relative;
`

const SubmitButton = styled(Button)<{ primaryColor?: string; color?: string }>`
  margin: 10px 0;
  background-color: ${props => props.primaryColor || COLORS.BLUE};
  color: ${props => props.color};
`

const StyledInput = styled(Input)`
  label {
    font-size: 14px;
  }
`

const customStyles = (event: any) => ({
  control: () => ({
    display: 'flex',
    borderBottom: '2px solid #d8d8d8',
    minWidth: '150px',
  }),
  option: (styles: any, { data, isSelected }: any) => {
    return {
      ...styles,
      color: data.value,
      backgroundColor: isSelected ? '#F0F8FF' : 'null',
      ':hover': {
        backgroundColor: '#f8f8f8',
      },
    }
  },
  multiValue: (styles: any) => ({
    ...styles,
    borderRadius: '50px',
    padding: '5px',
    backgroundColor: event?.eventSetting?.primaryColor || COLORS.BLUE,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#fff',
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#fff',
    ':hover': {
      color: '#DE350B',
      backgroundColor: 'transparent',
    },
  }),
})

export default AttendeeCustomField
