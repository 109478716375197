import { createSlice } from '@reduxjs/toolkit'

const toastReducer = createSlice({
  name: 'toast',
  initialState: {
    isShowToast: false,
    variant: '',
    message: '',
    displayTime: 0,
  },
  reducers: {
    setIsShowToast: (state, action) => {
      state.isShowToast = action.payload
    },
    setDisplayTime: (state, action) => {
      state.displayTime = action.payload
    },
    setToast: (state, action) => {
      state.isShowToast = true
      state.message = action.payload.message
      state.variant = action.payload.variant
      state.displayTime = action.payload.displayTime
    },
  },
})

export const { setIsShowToast, setDisplayTime, setToast } = toastReducer.actions
export default toastReducer.reducer
