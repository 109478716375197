import * as Sentry from '@sentry/react'
import { FC, useEffect } from 'react'

import { setAppLang } from 'store/App'
import { useAppDispatch } from 'utils/hooks'

const AppContainer: FC = ({ children }) => {
  const dispatch = useAppDispatch()
  const lang = localStorage.getItem('i18nextLng') as string

  useEffect(() => {
    dispatch(setAppLang(lang))
  }, [])

  // const user = JSON.parse(sessionStorage.getItem('user') as string)
  // const transaction = Sentry?.getCurrentHub()?.getScope()?.getTransaction()
  // transaction?.setTag('user', user?.email || 'guest')

  return <>{children}</>
}

export default AppContainer
