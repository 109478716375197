import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useModal } from 'utils/hooks'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Card, CardDescription } from '@/components/ui/card'
import { cn } from '@/lib/utils'
import { Pencil, Trash } from 'lucide-react'

type CustomFieldItemType = {
  _id: string
  title?: string
  className?: string
  onRemove?: () => void
  onEdit?: () => void
}

const CustomFieldItem: FC<CustomFieldItemType> = ({
  onRemove,
  onEdit,
  className,
  ...props
}) => {
  const { t } = useTranslation()

  const { isShow, showModal, hideModal } = useModal()

  return (
    <>
      <Card className={cn('flex justify-between items-center p-2', className)}>
        <CardDescription
          className='truncate hover:whitespace-normal hover:overflow-visible'
          title={props.title || ''}
        >
          <div
            dangerouslySetInnerHTML={{ __html: props.title || '' }}
            className='ql-link'
          />
        </CardDescription>
        <div className='flex gap-2'>
          <Button
            variant='ghost'
            size='icon'
            onClick={onEdit}
            className='custom-field-item__edit-icon'
          >
            <Pencil className='h-4 w-4' />
          </Button>
          <Button
            variant='destructive'
            size='icon'
            onClick={event => {
              event.stopPropagation()
              showModal()
            }}
          >
            <Trash className='h-4 w-4' />
          </Button>
        </div>
      </Card>
      <AlertDialog open={isShow} onOpenChange={hideModal}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t('Warning!')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('Are you sure to delete this custom field?')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={hideModal}>
              {t('Cancel')}
            </AlertDialogCancel>
            <AlertDialogAction onClick={onRemove}>
              {t('Delete')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default CustomFieldItem
