import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { COLORS } from 'constants/colors'
import { ChevronLeft } from 'lucide-react'
import { FC } from 'react'
import LanguageDropdown from '../language/language-dropdown'

type HeaderProps = {
  title: string
  subTitle?: string
  pageTitle?: string
  className?: string
  showLanguageSelector?: boolean
  defaultLanguage?: string
  bgColor?: string
  displayBackIcon?: boolean
  handleBack?: any
}
const Header: FC<HeaderProps> = ({
  title,
  subTitle,
  showLanguageSelector = false,
  bgColor = COLORS.BLUE,
  displayBackIcon = false,
  handleBack,
  pageTitle,
}) => {
  return (
    <div
      className='relative flex items-center px-4 md:px-8 h-[54px] md:h-[80px]'
      style={{
        backgroundColor: bgColor,
        color: setTextColorBasedOnBackground(bgColor),
      }}
    >
      {displayBackIcon && (
        <button className='focus:outline-none'>
          <div className='h-[22px] items-center font-medium text-palette-900 transition-all duration-300'>
            <ChevronLeft onClick={handleBack} />
          </div>
        </button>
      )}

      <div className='w-full text-palette-800 text-xl font-bold text-center ml-auto mr-auto'>
        {title}
        {pageTitle && (
          <p className='text-sm text-muted-foreground'>{pageTitle}</p>
        )}
        {subTitle && (
          <p className='text-sm text-muted-foreground'>{subTitle}</p>
        )}
      </div>

      {showLanguageSelector && <LanguageDropdown />}
    </div>
  )
}

export default Header
