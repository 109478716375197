import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setIsLoading, setStep } from 'store/Events'
import { REGISTER_EVENT } from 'graphql/mutations'

import { styled, useHistory, useMutation } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'
import { renderSymbolCurrency, Step } from 'utils/helpers'

import { Button } from '@/components/ui/button'

import { COLORS } from 'constants/colors'
import { CUSTOM_FIELD_TYPE } from 'constants/customFieldType'
import RegistrationOverview from 'components/organisms/EventRegister'
import CustomFieldFee from 'components/organisms/EventRegister/DetailCost/CustomFieldFee'
import ChosenItem from 'components/organisms/EventRegister/DetailCost/ChosenItem'
import { cn } from '@/lib/utils'
import { toast } from '@/components/ui/use-toast'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { Card, CardContent, CardHeader } from '@/components/ui/card'

const PAYMENT_FEE_COLOR = '#e4edf4'

const DetailCost: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { totalPrice } = useAppSelector(state => state.eventsReducer)
  const event = JSON.parse(sessionStorage.getItem('currentEvent') as string)
  const attendee = JSON.parse(sessionStorage.getItem('attendee_info') as string)

  const fee = totalPrice ? totalPrice : event.price
  const eventPrice = parseFloat(event.price)
  const [convertedArray, setConvertedArray] = useState<any>([])
  const [chosenOption, setChosenOption] = useState([])

  const [mutateRegisterEvent] = useMutation(REGISTER_EVENT, {
    onCompleted: data => {
      if (data) {
        dispatch(setIsLoading(false))
        history.push(`/attendee/register/${event._id}/success`)
      }
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  useEffect(() => {
    transformCustomFieldData()
  }, [])

  const transformCustomFieldData = () => {
    const newArr: any = []
    attendee?.registration?.customValues?.forEach((item: any) => {
      const foundIndex = event?.customFields?.findIndex(
        (el: any) => el._id === item.eventCustomField
      )
      if (foundIndex !== -1) {
        const obj = {
          ...event?.customFields?.[foundIndex],
          options: item.value,
        }
        newArr.push(obj)
      }
    })

    setChosenOption(newArr)

    const convertedArray = newArr.filter(
      (item: any) =>
        item.type === CUSTOM_FIELD_TYPE.CHECK_BOX ||
        item.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON ||
        item.type === CUSTOM_FIELD_TYPE.SELECT_BOX
    )

    const listChoosenOption: any = []
    convertedArray?.forEach((item: any) => {
      item?.options?.forEach((option: any) => {
        if (option?.fee > 0) {
          const listOptionsFee: any[] = []
          listOptionsFee.push(option)
          const choosenOption = {
            ...item,
            options: listOptionsFee,
          }
          listChoosenOption.push(choosenOption)
        }
      })
    })
    setConvertedArray(listChoosenOption)
  }

  const removeLocalStorage = () => {
    localStorage.removeItem('inputDate')
    localStorage.removeItem('inputField')
    localStorage.removeItem('selectedFields')
    localStorage.removeItem('selectedRadio')
    localStorage.removeItem('isChecked')
  }

  const onSubmit = () => {
    const formData = {
      ...attendee,
      address: {
        ...attendee.address,
        country: attendee?.address?.country?.value,
      },
      registration: {
        customValues: chosenOption.map((item: any) => {
          return {
            eventCustomField: item._id,
            value: item.options,
          }
        }),
      },
    }

    if (event.isBookable) {
      if (fee > 0) {
        dispatch(setStep(Step.Payment))
      } else {
        mutateRegisterEvent({
          variables: {
            attendee: formData,
            eventId: event._id,
            orderId: 'zero_fee',
          },
        })
      }
    } else {
      dispatch(setIsLoading(true))
      mutateRegisterEvent({
        variables: {
          attendee: formData,
          eventId: event._id,
        },
      })
      removeLocalStorage()
    }
  }

  return (
    <div className='container mx-auto py-10'>
      <div
        className={cn('grid grid-cols-1', fee > 0 && 'lg:grid-cols-3 lg:gap-6')}
      >
        <div
          className={cn('space-y-4 w-full mx-auto', fee > 0 && 'col-span-2')}
        >
          <RegistrationOverview
            attendee={attendee}
            options={chosenOption}
            event={event}
          />
          {fee === 0 && (
            <div className={cn('mx-auto flex items-center justify-center')}>
              <SubmitButton
                backgroundColor={event?.eventSetting?.primaryColor}
                color={setTextColorBasedOnBackground(
                  event?.eventSetting?.primaryColor
                )}
                type='submit'
                onClick={onSubmit}
                className={'hover:opacity-80'}
              >
                {event.isBookable && fee > 0
                  ? t('Choose Payment option')
                  : t('Complete Registration')}
              </SubmitButton>
            </div>
          )}
        </div>
        {fee > 0 && (
          <div className='w-full'>
            <Card className=''>
              <CardHeader className='pb-0 px-4'>
                <h2 className='text-xl font-semibold'>{t('Costs')}</h2>
              </CardHeader>
              <CardContent className='px-2 py-4'>
                <div className='p-0'>
                  {event?.price !== 0 && (
                    <ChosenItem
                      title={t('Ticket')}
                      price={event?.price}
                      currency={renderSymbolCurrency(event.currency)}
                    />
                  )}

                  {convertedArray?.map((field: any, index: number) => {
                    return (
                      <CustomFieldFee
                        key={index}
                        title={field.title}
                        options={field.options}
                        currency={event.currency}
                      />
                    )
                  })}

                  {totalPrice > 0 && eventPrice !== totalPrice && (
                    <TotalPrice
                      title={t('Total price')}
                      price={fee}
                      currency={renderSymbolCurrency(event.currency)}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
            <SubmitButton
              backgroundColor={event?.eventSetting?.primaryColor}
              color={setTextColorBasedOnBackground(
                event?.eventSetting?.primaryColor
              )}
              type='submit'
              onClick={onSubmit}
            >
              {event.isBookable
                ? t('Choose Payment option')
                : t('Complete Registration')}
            </SubmitButton>
          </div>
        )}
      </div>
    </div>
  )
}

const TotalPrice = styled(ChosenItem)`
  background-color: ${PAYMENT_FEE_COLOR};

  span {
    color: ${COLORS.BLUE};
    font-weight: bold;
  }
`

const SubmitButton = styled(Button)<{
  backgroundColor?: string
  width?: number
  color?: string
}>`
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  background-color: ${props => props.backgroundColor || COLORS.BLUE};
  color: ${props => props.color};
  align-self: center;
`

export default DetailCost
