import { FC } from 'react'
import { styled } from 'utils/adapters'

type ChosenItemType = {
  title: string
  price: number
  currency?: string
  className?: string
  isPaid?: boolean
}

const ChosenItem: FC<ChosenItemType> = ({
  title,
  price,
  currency,
  className,
  isPaid,
}) => {
  return (
    <Wrapper className={className}>
      <span>{title}</span>
      <PaidItem isPaid={isPaid}>
        <p className='flex items-center'>
          {currency}
          {price?.toLocaleString()}
        </p>
      </PaidItem>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  font-weight: bold;
`

const PaidItem = styled.span<{ isPaid?: boolean }>`
  color: ${props => props.isPaid && 'red'};
`

export default ChosenItem
