import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import detector from 'i18next-browser-languagedetector'

import translationEN from 'locales/en'
import translationDE from 'locales/de'
import translationTR from 'locales/tr'

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  tr: {
    translation: translationTR,
  },
}
i18n
  .use(detector)
  //@ts-ignore
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    react: {
      defaultTransParent: 'div',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      transWrapTextNodes: '',
    },
  })
export default i18n
