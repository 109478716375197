import { Checkbox } from '@/components/ui/checkbox'
import { Button } from 'components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { COLORS } from 'constants/colors'
import { Filter } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const FilterColumnModal = ({ column, filterData }) => {
  const { setFilterValue, getFilterValue } = column
  const selectedValues = Array.isArray(getFilterValue()) ? getFilterValue() : []
  const { t } = useTranslation()

  const handleCheckboxChange = value => {
    const newFilter = selectedValues.includes(value)
      ? selectedValues.filter(item => item !== value)
      : [...selectedValues, value]
    setFilterValue(newFilter)
  }

  return (
    <div className=''>
      <Popover>
        <PopoverTrigger asChild>
          <Filter
            color={selectedValues.length > 0 ? '#005CC8' : COLORS.PRIMARY}
            size={16}
          />
        </PopoverTrigger>
        <PopoverContent className='w-auto'>
          <>
            <div
              className='absolute w-0 h-0 top-[-4px] left-1/2 transform -translate-x-1/2 border-l-[6px] border-r-[6px] border-transparent border-b-[6px]'
              style={{ borderBottomColor: '#fff' }}
            />

            {filterData.map(option => (
              <div key={option.value} className='flex'>
                <div className='flex items-center space-x-2 m-1'>
                  <Checkbox
                    id={option.value}
                    onCheckedChange={() => handleCheckboxChange(option.value)}
                    checked={selectedValues.includes(option.value)}
                    className='mr-[5px]'
                  />
                  <label
                    htmlFor={option.value}
                    className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                  >
                    {option.label}{' '}
                    {option?.quantity ? `(${option.quantity})` : ''}
                  </label>
                </div>
              </div>
            ))}
          </>
          <div className='mt-[10px] flex justify-center'>
            <Button
              variant='outline'
              className='h-[30px]'
              onClick={() => setFilterValue([])}
            >
              {t('Reset')}
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  )
}

export default FilterColumnModal
