import { request } from './request'

export const apiGetActiveRelease = () => {
  return request.request({
    method: 'GET',
    url: '/v1/releases/active',
  })
}

export const getReleasesList = () => {
  return request.request({
    method: 'GET',
    url: '/v1/releases',
  })
}

export const createRelease = data => {
  return request.request({
    method: 'POST',
    url: '/v1/releases',
    data,
  })
}
