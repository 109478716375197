import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Icons } from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { useUserStore } from '@/store/zustand'
import { disableTwoFA, use2FAStore } from '@/store/zustand/TowFA'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'

export function TwoFactorAuthDisableForm({ setOpenDialog }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { currentUser, setCurrentUser } = useUserStore.getState()

  const formSchema = z.object({
    email: z.string().email(),
    password: z.string({
      required_error: t('Please select password'),
    }),
  })
  type FormValues = z.infer<typeof formSchema>

  const defaultValues: Partial<FormValues> = {
    email: currentUser?.email,
  }
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
  })

  const onSubmit = async (data: FormValues) => {
    setLoading(true)
    const res = await disableTwoFA(data.password)
    if (res) {
      if (currentUser)
        setCurrentUser({
          ...currentUser,
          is2FAEnabled: false,
        })
      localStorage.setItem('ACCESS_TOKEN', res.data.accessToken as string)
      localStorage.setItem('REFRESH_TOKEN', res.data.refreshToken as string)
      toast({
        title: t('Two-Factor Authentication (2FA) successfully disabled'),
      })
      setOpenDialog(false)
    } else {
      form.setError(
        'password',
        {
          message: use2FAStore.getState().errorMessage,
        },
        {
          shouldFocus: true,
        }
      )
    }
    setLoading(false)
  }

  return (
    <div className='space-y-6'>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-6'>
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('Email')}</FormLabel>
                <FormControl>
                  <Input disabled {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('Password')}</FormLabel>
                <FormControl>
                  <Input type='password' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className='grid grid-cols-2'>
            <div></div>
            <Button type='submit' variant='destructive' disabled={loading}>
              {loading && (
                <Icons.spinner className='mr-2 h-4 w-4 animate-spin' />
              )}
              {t('Disable')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
