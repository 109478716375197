import { FC, useEffect } from 'react'
import { Redirect, useLocation } from 'utils/adapters'
import { useAppSelector } from 'utils/hooks'
import { PATH_NAME } from 'routes/routesMap'
import { getEncryptedToken } from '@/store/zustand/TowFA'

const LoginGuard: FC = ({ children }) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const returnUrl = queryParams.get('returnUrl')
  const { isAuth } = useAppSelector(state => state.authenticationReducer)

  useEffect(() => {
    const redirectWithToken = async () => {
      if (isAuth && returnUrl) {
        try {
          const encryptedToken = await getEncryptedToken(returnUrl)
          if (encryptedToken) {
            window.location.href = `${returnUrl}?token=${encryptedToken}`
          }
        } catch (error) {}
      }
    }

    redirectWithToken()
  }, [isAuth, returnUrl])

  return isAuth && !returnUrl ? (
    <Redirect to={PATH_NAME.ROOT} />
  ) : (
    <>{children}</>
  )
}

export default LoginGuard
