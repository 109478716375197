import Quill from 'quill'

const Blockquote = Quill.import('formats/blockquote')

class BlockquoteInlineStyle extends Blockquote {
  static create(value) {
    BlockquoteInlineStyle.blotName = 'blockquote'
    BlockquoteInlineStyle.tagName = 'blockquote'
    const node = super.create(value)
    node.style.borderLeft = '5px solid #ccc'
    node.style.paddingLeft = '10px'
    node.style.margin = '0px'
    return node
  }
}

Quill.register(BlockquoteInlineStyle, true)
