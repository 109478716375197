import Portal from 'portal'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { styled } from 'utils/adapters'

type ModalType = {
  title?: string
  className?: string
  isShow: boolean
  hideFooter?: true
  hideHeader?: true
  onClose?: React.MouseEventHandler<any>
  onConfirm?: React.MouseEventHandler<any>
  description?: string
  isInfoModal?: boolean
}

const Modal: FC<ModalType> = ({
  title,
  description,
  className,
  isShow,
  hideFooter,
  hideHeader,
  onClose,
  onConfirm,
  children,
  isInfoModal = false,
}) => {
  const { t } = useTranslation()

  return (
    <Portal>
      <Wrapper
        showModal={isShow}
        onClick={onClose}
        className='fixed inset-0 z-50 bg-slate-950/40 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0'
      >
        <ModalContent
          className={cn(
            className,
            'rounded-md fixed left-[50%] top-[50%] z-50 grid translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-3 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg'
          )}
          onClick={e => e.stopPropagation()}
        >
          {!hideHeader && (
            <Header className='flex flex-col space-y-1.5 text-center sm:text-left'>
              <Title className='text-lg font-semibold leading-none tracking-tight'>
                {title}
              </Title>
              {description && (
                <div className='text-sm text-muted-foreground'>
                  {description}
                </div>
              )}
              <CloseIcon onClick={onClose} />
            </Header>
          )}

          {children && <Body>{children}</Body>}
          {!hideFooter && (
            <Footer>
              <Button
                variant='destructive'
                onClick={onConfirm}
                className={cn('w-[80px]', isInfoModal && 'bg-primary')}
              >
                {t('OK')}
              </Button>
              <Button
                variant='outline'
                type='button'
                onClick={onClose}
                className='w-[80px]'
              >
                {t('Cancel')}
              </Button>
            </Footer>
          )}
        </ModalContent>
      </Wrapper>
    </Portal>
  )
}

const Wrapper = styled.div<{ showModal?: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: all 0.3s ease-in-out;
  opacity: ${props => (props.showModal ? 1 : 0)};
  pointer-events: ${props => (props.showModal ? 'visible' : 'none')};
  z-index: 99;
`

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%;
  min-width: 300px;
  background-color: #fff;
`

const Header = styled.div``

const Title = styled.h4`
  margin: 0;
`

const CloseIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  opacity: 0.3;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }

  ::before,
  ::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 16px;
    background-color: #333;
    transform: rotate(45deg) translate(-50%, -50%);
    transform-origin: top left;
    content: '';
  }

  ::after {
    transform: rotate(-45deg) translate(-50%, -50%);
  }
`

const Body = styled.div``

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`

export default Modal
