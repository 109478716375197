import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { COLORS } from 'constants/colors'
import { responsive } from 'assets/scss/mixin'

import { styled } from 'utils/adapters'

type ValidationTextType = {
  error?: string
  className?: string
}
const ValidationText: FC<ValidationTextType> = ({ error, className }) => {
  const { t } = useTranslation()

  return <Error className={className}>{t(`${error}`)}</Error>
}

const Error = styled.span`
  color: ${COLORS.ERROR};
  font-size: 12px;
  ${responsive.sm`
    font-size: 10px;
  `}
`

export default ValidationText
