import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import {
  BriefcaseBusiness,
  Building2,
  Mail,
  MapPinHouse,
  PhoneCall,
  PrinterCheck,
  User,
  Vibrate,
} from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { transformAddress } from 'utils/helpers'

type Attendee = {
  firstName: string
  lastName: string
  jobTitle: string
  company: string
  address: any
  email: string
  workPhone: string
  mobilePhone?: string
  fax?: string
  customSelection?: any[]
}

type UserDetailsType = {
  attendee: Attendee
  event?: any
}

const UserDetails: React.FC<UserDetailsType> = ({ attendee, event }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader>
        <h2 className='text-xl font-semibold'>{t('Overview')}</h2>
      </CardHeader>
      <CardContent className='grid gap-6'>
        <div className='grid gap-4 sm:grid-cols-2'>
          <div className='space-y-1.5'>
            <div className='flex items-center gap-2 text-sm text-muted-foreground'>
              <User className='h-4 w-4' />
              {t('Full name')}
            </div>
            <div className='font-medium'>
              {attendee?.firstName} {attendee?.lastName}
            </div>
          </div>

          <div className='space-y-1.5'>
            <div className='flex items-center gap-2 text-sm text-muted-foreground'>
              <Mail className='h-4 w-4' />
              {t('Email')}
            </div>
            <div className='font-medium'>{attendee?.email}</div>
          </div>

          {attendee?.jobTitle && (
            <div className='space-y-1.5'>
              <div className='flex items-center gap-2 text-sm text-muted-foreground'>
                <BriefcaseBusiness className='h-4 w-4' />
                {t('Job title')}
              </div>
              <div className='font-medium'>{attendee.jobTitle}</div>
            </div>
          )}

          {attendee?.company && (
            <div className='space-y-1.5'>
              <div className='flex items-center gap-2 text-sm text-muted-foreground'>
                <Building2 className='h-4 w-4' />
                {t('Company')}
              </div>
              <div className='font-medium'>{attendee.company}</div>
            </div>
          )}

          <div className='space-y-1.5'>
            <div className='flex items-center gap-2 text-sm text-muted-foreground'>
              <MapPinHouse className='h-4 w-4' />
              {t('Address')}
            </div>
            <div className='font-medium'>
              {transformAddress(
                attendee.address.street,
                attendee.address.zipCode,
                attendee.address.city,
                attendee.address.country.value
              )}
            </div>
          </div>

          {attendee?.workPhone && (
            <div className='space-y-1.5'>
              <div className='flex items-center gap-2 text-sm text-muted-foreground'>
                <PhoneCall className='h-4 w-4' />
                {t('Work phone')}
              </div>
              <div className='font-medium'>{attendee.workPhone}</div>
            </div>
          )}

          {attendee?.mobilePhone && (
            <div className='space-y-1.5'>
              <div className='flex items-center gap-2 text-sm text-muted-foreground'>
                <Vibrate className='h-4 w-4' />
                {t('Mobile phone')}
              </div>
              <div className='font-medium'>{attendee.mobilePhone}</div>
            </div>
          )}

          {attendee?.fax && (
            <div className='space-y-1.5'>
              <div className='flex items-center gap-2 text-sm text-muted-foreground'>
                <PrinterCheck className='h-4 w-4' />
                {t('Fax')}
              </div>
              <div className='font-medium'>{attendee.fax}</div>
            </div>
          )}
        </div>

        <Separator />

        {/* Additional Questions */}
        <div>
          <h3 className='mb-4 font-bold'>{t('Additional Information')}</h3>
          {attendee?.customSelection && (
            <div className='grid gap-4'>
              {Object.entries(attendee.customSelection).map(([key, value]) => (
                <div className='space-y-1.5' key={key}>
                  <div className='text-sm text-muted-foreground'>
                    {
                      event?.customSelections.find(
                        (item: any) => item.id === key
                      )?.label
                    }
                  </div>
                  <div>{value}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default UserDetails
